import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { loadTranslations } from "react-thunk-i18nify";
import { PersistGate } from "redux-persist/integration/react";

import PropTypes from "prop-types";

import CallHistory from "pages/CallHistory";
import CreateCallProfile from "pages/CallProfiles/CreateProfile";
import DefaultProfile from "pages/CallProfiles/DefaultCallProfile";
import EditCallProfile from "pages/CallProfiles/EditCallProfile";
import ChangePassword from "pages/ChangePassword";
import EditUserProfile from "pages/EditUserProfile";
import Forbidden from "pages/Forbidden";
import ForgotPassword from "pages/ForgotPassword";
import Home from "pages/Home/Home";
import Login from "pages/Login";
import NotFoundPage from "pages/NotFound";
import SetPassword from "pages/SetPassword";
import UserProfile from "pages/UserProfile";
import VoiceMail from "pages/Voicemail";
import ConfigureVoicemailBoxes from "pages/VoicemailBoxes/ConfigureVoicemailBoxes";
import VoicemailBoxes from "pages/VoicemailBoxes/VoicemailBoxes";

import FixedCalendar from "components/FixedCalendar/FixedCalendar";
import LogoutModal from "components/Modal/LogoutModal/LogoutModal";

import {
  AUTH,
  CALL_HISTORY,
  HOME,
  PROFILE,
  VOICEMAIL,
  VOICEMAIL_BOXES,
  FIXED_CALENDAR,
} from "constants/routes";

import cable from "services/socket/cable";

import { persistor, store } from "redux/store";

import locales from "locales/root";

import PrivateRoute from "./PrivateRoute";

function AppRoutes() {
  const { locale } = useSelector(({ i18n }) => i18n);
  const { sessionExpired } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTranslations(locales));

    return () => cable.close();
  }, []);

  return (
    <div className="h-100 w-100 app">
      <ReduxToastr
        timeOut={3000}
        key={locale}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr}
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <Router>
        <Switch>
          <Route path={AUTH.LOGIN} component={Login} />
          <Route path={AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
          <Route path={AUTH.SET_PASSWORD} component={SetPassword} />
          <PrivateRoute path={VOICEMAIL.INDEX} component={VoiceMail} />
          <PrivateRoute path={HOME.INDEX} component={Home} />
          <PrivateRoute
            exact
            path={PROFILE.USER_PROFILE}
            component={UserProfile}
          />
          <PrivateRoute
            path={PROFILE.USER_PROFILE_EDIT}
            component={EditUserProfile}
          />
          <PrivateRoute path={CALL_HISTORY.INDEX} component={CallHistory} />
          <PrivateRoute
            path={AUTH.CHANGE_PASSWORD}
            component={ChangePassword}
          />

          <PrivateRoute exact path={PROFILE.INDEX} component={DefaultProfile} />
          <PrivateRoute exact path={PROFILE.EDIT} component={EditCallProfile} />
          <PrivateRoute
            exact
            path={FIXED_CALENDAR.INDEX}
            component={FixedCalendar}
          />
          <PrivateRoute path={PROFILE.CREATE} component={CreateCallProfile} />
          <PrivateRoute
            exact
            path={VOICEMAIL_BOXES.INDEX}
            component={VoicemailBoxes}
          />
          <PrivateRoute
            path={VOICEMAIL_BOXES.CONFIG}
            component={ConfigureVoicemailBoxes}
          />
          <Route path={AUTH.FORBIDDEN} component={Forbidden} />
          <Route component={NotFoundPage} />
        </Switch>
        <Route exact path={AUTH.INDEX}>
          <Redirect to={AUTH.LOGIN} />
        </Route>
      </Router>
      <LogoutModal isVisible={sessionExpired} />
    </div>
  );
}

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppRoutes />
    </PersistGate>
  </Provider>
);

PrivateRoute.defaultProps = {
  children: null,
};
PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default App;
