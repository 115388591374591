import { UnAuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  SIGN_IN: `${apiUrlConfig.endPoint()}/oauth/token?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&client_secret=${process.env.REACT_APP_CLIENT_SECRET}`,
  RESET_PASSWORD: `${apiUrlConfig.endPoint()}/users/password?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&client_secret=${process.env.REACT_APP_CLIENT_SECRET}`,
  SET_PASSWORD: `${apiUrlConfig.endPoint()}/users/password?client_id=${
    process.env.REACT_APP_CLIENT_ID
  }&client_secret=${process.env.REACT_APP_CLIENT_SECRET}`,
};

class AuthApi extends UnAuthenticatedRequest {
  static login(username, password) {
    return this.post(URLS.SIGN_IN, {
      username,
      password,
      grant_type: "password",
    });
  }

  static refreshBearerToken(refreshToken) {
    return this.post(URLS.SIGN_IN, {
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    });
  }

  static resetPassword(email) {
    return this.post(URLS.RESET_PASSWORD, {
      user: { email },
    });
  }

  static setPassword(password, passwordConfirmation, resetPasswordToken) {
    return this.patch(URLS.SET_PASSWORD, {
      user: {
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken,
      },
    });
  }
}

export default AuthApi;
