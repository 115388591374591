import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import AutomaticCallProfileProvider from "context-api/AutomaticCallProfileContext";

import LocalStorageManager, { CONSTANTS } from "lib/LocalStorageManager";

import { setLanguage } from "services/i18n";

import { switchUserLanguageRequest } from "redux/actions/userProfile";

import Sidebar from "./Sidebar/Sidebar";
import TopBar from "./TopBar";

const languageType = {
  "en-US": "en",
  "da-DK": "da",
};

const Overlay = ({ show, overlayClick }) => {
  if (!show) return null;

  return (
    <div
      className="overlay position-fixed hv-100 wv-100 overflow-hidden d-block d-lg-none"
      type="button"
      tabIndex={0}
      onClick={overlayClick}
    />
  );
};

const PrivateRouteLayout = ({ children }) => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    const currentlySelectedLanguage = LocalStorageManager.get(
      CONSTANTS.CURRENT_SELECTED_LANGUAGE
    );

    if (
      currentlySelectedLanguage &&
      currentlySelectedLanguage !== currentUser?.locale
    ) {
      setLanguage(currentlySelectedLanguage);
      dispatch(
        switchUserLanguageRequest(
          currentUser?.id,
          languageType[currentlySelectedLanguage]
        )
      );
      LocalStorageManager.remove(CONSTANTS.CURRENT_SELECTED_LANGUAGE);
    }
  }, []);

  return (
    <AutomaticCallProfileProvider>
      <div className="private-route-layout d-flex flex-column w-100 h-100">
        <Overlay
          show={toggleMobileMenu}
          overlayClick={() => setToggleMobileMenu(false)}
        />
        <TopBar setToggleMobileMenu={setToggleMobileMenu} />
        <main className="d-flex w-100 flex-fill mt-5 pt-2 overflow-hidden">
          <Sidebar
            toggleMobileMenu={toggleMobileMenu}
            setToggleMobileMenu={setToggleMobileMenu}
          />
          <section className="mh-100 w-100 border-0 h-100 d-flex">
            <div className="w-100 ps-sm-3 pt-4 pb-2 flex-fill">{children}</div>
          </section>
        </main>
      </div>
    </AutomaticCallProfileProvider>
  );
};

Overlay.defaultProps = {
  show: false,
  overlayClick: () => {},
};

Overlay.propTypes = {
  show: PropTypes.bool,
  overlayClick: PropTypes.func,
};

PrivateRouteLayout.defaultProps = {
  children: <></>,
};

PrivateRouteLayout.propTypes = {
  children: PropTypes.node,
};

export default PrivateRouteLayout;
