import React from "react";
import Skeleton from "react-loading-skeleton";

const AlphabetTextSkeleton = () => (
  <div className="d-flex col-12 px-3 mx-auto mb-0 align-items-center">
    <Skeleton width={10} height={16} className="me-4 ms-2" />
    <Skeleton count={1} width={280} height={2} className="mt-3" />
  </div>
);

export default AlphabetTextSkeleton;
