import React from "react";

import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const DefaultProfileImage = ({
  firstName,
  lastName,
  handleProfileUpload,
  showUploadFeature,
}) => {
  const handleProfileReplacement = () => {
    const firstNameCharacter = firstName?.charAt(0).toUpperCase();
    const lastNameCharacter = lastName?.charAt(0).toUpperCase();

    return `${firstNameCharacter}${lastNameCharacter}`;
  };

  const showCameraIcon = showUploadFeature && (
    <>
      <input
        type="file"
        className="invisible position-absolute w-25"
        id="uploadProfile"
        onChange={handleProfileUpload}
      />
      <label
        data-cy="overlay-camera"
        className="overlay-camera h-50 position-absolute start-0 bottom-0 w-100 border-0 d-flex align-items-center justify-content-center"
        htmlFor="uploadProfile"
      >
        <FontAwesomeIcon icon={faCamera} className="camera-icon mb-2" />
      </label>
    </>
  );

  return (
    <div
      data-cy="default-profile-image"
      className="profile-image rounded-pill position-relative d-flex align-items-center justify-content-center overflow-hidden"
    >
      <p
        data-cy="profile-replacement-text"
        className="text-primary fw-bold profile-replacement-text mb-0"
      >
        {handleProfileReplacement()}
      </p>
      {showCameraIcon}
    </div>
  );
};

DefaultProfileImage.defaultProps = {
  firstName: "",
  lastName: "",
  handleProfileUpload: () => {},
  showUploadFeature: true,
};

DefaultProfileImage.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  handleProfileUpload: PropTypes.func,
  showUploadFeature: PropTypes.bool,
};

export default DefaultProfileImage;
