import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  FETCH_CALL_HISTORY: `${apiUrlConfig.asteriskEndpoint()}/user_licenses/:user_license_id/call_history?filter[type]=:type`,
  FILTER_BY_DATE: `${apiUrlConfig.asteriskEndpoint()}/user_licenses/:user_license_id/call_history?filter[type]=:type&filter[call_date_time][start_date]=:start_date&filter[call_date_time][end_date]=:end_date`,
};

class CallHistoryApi extends AuthenticatedRequest {
  static fetchCallHistory(userLicenseId, type) {
    const GET_URL = URLS.FETCH_CALL_HISTORY.replace(
      ":user_license_id",
      userLicenseId
    ).replace(":type", type);

    return this.get(GET_URL);
  }
  static filterByDate(userLicenseId, type, startDate, endDate) {
    const FILTER_BY_DATE_URL = URLS.FILTER_BY_DATE.replace(
      ":user_license_id",
      userLicenseId
    )
      .replace(":type", type)
      .replace(":start_date", startDate)
      .replace(":end_date", endDate);

    return this.get(FILTER_BY_DATE_URL);
  }
}

export default CallHistoryApi;
