import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { faUser } from "@fortawesome/free-regular-svg-icons";

import FormWrapper from "components/Form/FormWrapper";
import InputField from "components/Form/InputField/InputField";

import { PROFILE } from "constants/routes";

import { displaySuccess } from "utils/toaster";
import { composeValidators, required, validateProfile } from "utils/validation";

import {
  clearProfileErrors,
  profileUpdateRequest,
} from "redux/actions/userProfile";

const EditProfileForm = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const {
    currentUser: {
      user_license_id: userLicenseId,
      id: userId,
      first_name: firstName,
      last_name: lastName,
    },
  } = useSelector(({ auth }) => auth);
  const { profile, isLoading, commitSuccess } = useSelector(
    ({ userProfile }) => userProfile
  );
  const {
    contactDetailData: {
      first_name: contactFirstName,
      last_name: contactLastName,
    },
  } = useSelector(({ contact }) => contact);

  const handleProfileInfoNavigation = () => {
    push(PROFILE.USER_PROFILE);
  };

  const onSubmitHandler = ({ firstName, lastName }) => {
    const formData = new FormData();

    formData.append("user_license[user_attributes][id]", userId);
    if (firstName !== contactFirstName || lastName !== contactLastName) {
      formData.append("user_license[user_attributes][first_name]", firstName);
      formData.append("user_license[user_attributes][last_name]", lastName);
    }

    dispatch(profileUpdateRequest(userLicenseId, formData));
  };

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
      push(PROFILE.USER_PROFILE);
    }
  }, [profile]);

  useEffect(() => () => dispatch(clearProfileErrors()), []);

  return (
    <div
      data-cy="edit-profile-form"
      className="edit-profile-form bg-white w-100"
    >
      <FormWrapper
        validation={(values) => validateProfile(values)}
        isLoading={isLoading}
        onSubmitHandler={onSubmitHandler}
        showCancelButton
        handleCancel={handleProfileInfoNavigation}
        submitText="userProfile.saveProfileInformation"
        cancelText="common.cancel"
        submitClassName="btn btn-primary edit-button my-3"
        submitContainerClassName="justify-content-md-end mt-3"
      >
        <div className="row justify-content-between">
          <div className="mb-3 col-12 col-md-6">
            <Field
              name="firstName"
              initialValue={contactFirstName || firstName}
              validate={composeValidators(
                required("validationErrors.EMPTY_FIRST_NAME")
              )}
              component={InputField}
              labelTranslation="attributes.firstName"
              inputFieldIcon={faUser}
              placeholderTranslation="attributes.firstName"
            />
          </div>

          <div className="mb-3 col-12 col-md-6">
            <Field
              name="lastName"
              initialValue={contactLastName || lastName}
              validate={required("validationErrors.EMPTY_LAST_NAME")}
              component={InputField}
              placeholderTranslation="attributes.lastName"
              inputFieldIcon={faUser}
              labelTranslation="attributes.lastName"
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default EditProfileForm;
