import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";

const PlayVoicemail = ({ isSelected, isPlaying, isLoading }) => {
  const playButton = useRef(null);

  const handlePlay = () => {
    playButton.current.classList.remove("is-loading");
    playButton.current.classList.add("is-playing");
  };

  useEffect(() => {
    if (!isSelected) {
      playButton.current.classList.remove("is-loading");
    }
  }, [isSelected]);

  useEffect(() => {
    if (!isSelected) return playButton.current.classList.remove("is-playing");
    if (isPlaying) {
      handlePlay();
    } else {
      playButton.current.classList.remove("is-playing");
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isLoading && isSelected) {
      playButton.current.classList.add("is-loading");
    } else {
      playButton.current.classList.remove("is-loading");
    }
  }, [isLoading, isSelected]);

  return (
    <div
      className="play-voicemail position-relative rounded-circle me-2"
      ref={playButton}
    >
      <div className="play-border w-100 h-100"></div>
      <div className="play position-absolute "></div>
    </div>
  );
};

PlayVoicemail.defaultProps = {
  isSelected: false,
  isPlaying: false,
  isLoading: false,
};

PlayVoicemail.propTypes = {
  isSelected: PropTypes.bool,
  isPlaying: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PlayVoicemail;
