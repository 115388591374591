import { I18n } from "react-thunk-i18nify";

import { updateCurrentUser } from "redux/actions/auth";
import { store } from "redux/store";

import { displayError } from "./toaster";

export const snakeCaseToKebabCase = (value) => {
  const lowerCaseValue = value?.toLowerCase();

  return lowerCaseValue?.replaceAll("_", "-");
};

const getServerError = (response) => {
  const { status, statusText, data } = response || {};

  switch (status) {
    case 422: {
      if (typeof data === "string") return data;
      if (data.status === 400) return statusText;
      let attributes;

      Object.keys(data).forEach((key) => {
        const stringWithoutSeparator = key.replace(/[^a-z A-Z 0-9]/g, " ");
        const errorHeading =
          stringWithoutSeparator[0].toUpperCase() +
          stringWithoutSeparator.substr(1);

        attributes = `${errorHeading} ${data[key][0].error}`;
      });

      return attributes;
    }

    case 403:
      return I18n.t("toasterMessage.unauthorized");

    case 400:
    case 404:
      return statusText;

    case 500:
      return statusText;

    default:
      return I18n.t("toasterMessage.requestFailed");
  }
};

const getErrors = (message) => {
  const { response } = message;
  const error = getServerError(response);

  if (response?.status != 401) {
    displayError(error);
  }

  return error;
};

const getPasswordError = (message) => {
  const { response } = message;
  const { data } = response;

  const error = data["user.password"][0];

  displayError(error);

  return error;
};

const setCurrentUser = (data, attribute = "active_call_profile") => {
  const {
    auth: { currentUser },
  } = store.getState();

  store.dispatch(updateCurrentUser({ ...currentUser, [attribute]: data }));
};

export { getErrors, getServerError, getPasswordError, setCurrentUser };
export const addSelectedIcon = (icon) =>
  icon?.brand_name ? [icon?.brand_name || "", icon?.icon_name || ""] : null;
