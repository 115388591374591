import React from "react";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ActiveSelectValue = ({ checkError, placeholderTranslation, value }) => {
  if (!value || value[0] == "" || value.length == 0) {
    return (
      <p className={`mb-0 ms-1 placeholder-text fw-normal ${checkError()}`}>
        <Translate value={placeholderTranslation} />
      </p>
    );
  }

  return (
    <span className="mt-1">
      <FontAwesomeIcon icon={value} className="profile-icon value" />
    </span>
  );
};

ActiveSelectValue.defaultProps = {
  checkError: () => {},
  placeholderTranslation: "",
  value: [],
};

ActiveSelectValue.propTypes = {
  checkError: PropTypes.func,
  placeholderTranslation: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ActiveSelectValue;
