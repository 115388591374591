import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { resetPasswordClearErrors } from "redux/actions/auth";

const EmailNotification = () => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetPasswordClearErrors()), []);

  return (
    <div
      className="d-flex flex-column justify-content-center email-notification pt-3"
      data-testid="email-notification"
    >
      <div className="d-flex justify-content-center mb-3">
        <div className="rounded-circle email-icon d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faEnvelope}
            className="icon"
            data-testid="envelope-icon"
          />
        </div>
      </div>
      <h4 className="mb-1 pb-2 login-header text-center mb-1">
        <Translate value="auth.emailNotification.checkYourEmail" />
      </h4>
      <p className="description text-center fw-normal">
        <Translate value="auth.emailNotification.description" />
      </p>
    </div>
  );
};

export default EmailNotification;
