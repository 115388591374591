import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditProfileForm from "components/EditUserProfile/EditProfileForm";
import ProfileHeader from "components/ProfileHeader/ProfileHeader";
import EditProfileSkeleton from "components/Skeleton/UserProfile/EditProfileSkeleton";

import { contactDetailRequest } from "redux/actions/contact";

const EditUserProfile = () => {
  const dispatch = useDispatch();
  const {
    currentUser: { user_license_id: userLicenseId },
  } = useSelector(({ auth }) => auth);

  const [loading, setLoading] = useState(true);

  const { contactDetailLoading: isLoading } = useSelector(
    ({ contact }) => contact
  );

  useEffect(() => {
    dispatch(contactDetailRequest(userLicenseId, "colleague"));
    setLoading(false);
  }, []);

  if (loading || isLoading) {
    return <EditProfileSkeleton />;
  }

  return (
    <div className="layout-spacing overflow-auto h-100 mh-100">
      <div className="d-flex flex-column bg-white pb-4 h-100">
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="bg-white px-4 d-flex flex-fill pt-3">
            <ProfileHeader title="common.editYourProfile" />
          </div>
          <div className="flex-fill w-100 bg-white px-3">
            <EditProfileForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserProfile;
