import React from "react";
import Skeleton from "react-loading-skeleton";

const IndividualContactSkeleton = () => (
  <div className="col-12 px-3 mx-auto mb-3 d-flex align-items-center">
    <Skeleton circle height={32} width={32} className="me-2" />
    <Skeleton height={12} width={130} className="align-self-center mb-0 mt-2" />
  </div>
);

export default IndividualContactSkeleton;
