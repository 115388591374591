import { NoteApi } from "internal";

import { getErrors } from "utils/utils";

export const NOTE_UPDATE_REQUEST = "NOTE_UPDATE_REQUEST ";
export const NOTE_UPDATE_RECEIVED = "NOTE_UPDATE_RECEIVED";
export const NOTE_UPDATE_ERROR = "NOTE_UPDATE_ERROR";

export const FETCH_NOTE_RECEIVED = "FETCH_NOTE_RECEIVED";

export const CLEAR_NOTE_ERROR = "CLEAR_NOTE_ERROR";

export const clearNoteError = () => ({
  type: CLEAR_NOTE_ERROR,
});

export const noteUpdateReceived = (note) => ({
  type: NOTE_UPDATE_RECEIVED,
  payload: note,
});

export const noteUpdateError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: NOTE_UPDATE_ERROR,
    error,
  };
};

export const noteUpdateRequest = (id, attribute) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_UPDATE_REQUEST });

    await NoteApi.updateNote(id, attribute);

    dispatch(noteUpdateReceived(attribute));
  } catch (error) {
    dispatch(noteUpdateError(error));
  }
};

export const fetchNoteReceived = (note) => ({
  type: FETCH_NOTE_RECEIVED,
  note,
});
