// type of incoming call
export const INCOMING_CALL_TYPE = {
  BUSY_SIGNAL: "give_busy_signal",
  CALL_ON_SIP_DEVICES: "initiate_call_on_devices",
  FORWARD_TO: "forward_to",
  GO_TO_VOICEMAIL: "go_to_voicemail",
  CALL_ON_ALL_DEVICES: "call_on_all_devices",
};

// incoming form type
export const INCOMING_FORM_TYPE = {
  CREATE: "create",
  EDIT: "edit",
};

/**
 * Add incoming sip device selected by user
 * @param {array|null} sipDevices - It will return  all device selected by user
 * @returns
 */
const sipDeviceAttributes = (sipDevices) => {
  if (sipDevices == null || !sipDevices.length) {
    return null;
  }

  return sipDevices.map(({ sipDevice }) => sipDevice);
};

/**
 * arrange individual sip devices with respect to the device i.e., `deviceName` it belongs to
 * @param {array} removedSipDevices - consists of all sip devices that the user deselected
 * @returns [
      {
        deviceName: "9395719326",
        sipDeviceID: [495, 496, 497]
      },
      {
        deviceName: "982737173",
        sipDeviceID: [493],
      }
    ]
 */
export const arrangeSipDevices = (removedSipDevices) => {
  if (removedSipDevices == null || !removedSipDevices.length) {
    return null;
  }

  const arrangedSipDevices = [];

  removedSipDevices.forEach((sipDevice) => {
    let existing = arrangedSipDevices.filter(
      (device) => sipDevice.deviceName === device.deviceName
    );

    if (existing.length) {
      let existingIndex = arrangedSipDevices.indexOf(existing[0]);

      arrangedSipDevices[existingIndex].sipDeviceID = arrangedSipDevices[
        existingIndex
      ].sipDeviceID.concat(sipDevice.sipDeviceID);
    } else {
      if (typeof sipDevice.sipDeviceID === "number")
        sipDevice.sipDeviceID = [sipDevice.sipDeviceID];
      arrangedSipDevices.push(sipDevice);
    }
  });

  return arrangedSipDevices;
};

/**
 * edit form attributes to be submitted
 * @param {array} incomingCall - It consists all device setup for incoming call
 * @returns
 */
const editFormAttributes = (incomingCall) =>
  incomingCall.map(
    ({
      call_routing_device,
      device_name,
      selected_option,
      selected_sip_device,
      call_forwarding_option,
      forwardTo,
      ringing_duration,
      ringingStrategyAfterRingingDuration,
      ringingStrategyForwardTo,
    }) => {
      let attributes = {};

      const { value } = selected_option;

      // check if selected option for incoming is call on busy signal and return attribute accordingly
      switch (value) {
        case INCOMING_CALL_TYPE.BUSY_SIGNAL:
        case INCOMING_CALL_TYPE.GO_TO_VOICEMAIL:
          attributes = {
            ringing_strategy: value,
            call_routing_device_attributes: call_routing_device,
            device_name,
            ringing_duration_seconds_until_next_strategy: null,
            strategy_after_ringing_duration: null,
            call_forwarding_option_attributes: null,
            incoming_call_routing_initiation_devices_attributes: null,
          };
          break;
        case INCOMING_CALL_TYPE.FORWARD_TO:
          attributes = {
            ringing_strategy: value,
            call_routing_device_attributes: call_routing_device,
            device_name,
            call_forwarding_option_attributes: {
              ...call_forwarding_option,
              external_number: forwardTo.external_number,
              external_number_name: forwardTo.external_number_name,
            },
            ringing_duration_seconds_until_next_strategy: null,
            strategy_after_ringing_duration: null,
            incoming_call_routing_initiation_devices_attributes: null,
          };
          break;

        case INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES:
          if (
            ringingStrategyAfterRingingDuration?.value ==
            INCOMING_CALL_TYPE.FORWARD_TO
          ) {
            attributes = {
              ringing_strategy: value,
              call_routing_device_attributes: call_routing_device,
              device_name,
              ringing_duration_seconds_until_next_strategy:
                ringing_duration.value,

              strategy_after_ringing_duration:
                ringingStrategyAfterRingingDuration.value,
              call_forwarding_option_attributes: {
                external_number: ringingStrategyForwardTo.external_number,
                external_number_name:
                  ringingStrategyForwardTo.external_number_name,
              },
              incoming_call_routing_initiation_devices_attributes: null,
            };
          } else {
            attributes = {
              ringing_strategy: value,
              call_routing_device_attributes: call_routing_device,
              device_name,
              ringing_duration_seconds_until_next_strategy:
                ringing_duration.value,
              strategy_after_ringing_duration:
                ringingStrategyAfterRingingDuration.value,
              call_forwarding_option_attributes: null,
              incoming_call_routing_initiation_devices_attributes: null,
            };
          }
          break;

        default:
          // check for sip devices
          const sipDevices = sipDeviceAttributes(selected_sip_device);

          // when value is INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES
          if (
            ringingStrategyAfterRingingDuration?.value ==
            INCOMING_CALL_TYPE.FORWARD_TO
          ) {
            attributes = {
              ringing_strategy: value,
              call_routing_device_attributes: call_routing_device,
              device_name,
              ringing_duration_seconds_until_next_strategy:
                ringing_duration.value,
              strategy_after_ringing_duration:
                ringingStrategyAfterRingingDuration.value,
              incoming_call_routing_initiation_devices_attributes: sipDevices,
              call_forwarding_option_attributes: {
                external_number: ringingStrategyForwardTo.external_number,
                external_number_name:
                  ringingStrategyForwardTo.external_number_name,
              },
            };
          } else {
            attributes = {
              call_routing_device_attributes: call_routing_device,
              device_name,
              ringing_strategy: value,
              ringing_duration_seconds_until_next_strategy:
                ringing_duration.value,
              strategy_after_ringing_duration:
                ringingStrategyAfterRingingDuration.value,
              incoming_call_routing_initiation_devices_attributes: sipDevices,
              call_forwarding_option_attributes: null,
            };
          }
      }

      if (!selected_option?.id) {
        return attributes;
      } else {
        const { id: selectedOptionID } = selected_option;

        return {
          ...attributes,
          id: selectedOptionID,
        };
      }
    }
  );

/**
 * create form attributes
 * @param {array} incomingCall - It consists all device setup for incoming call
 * @returns
 */
const createFormAttributes = (incomingCall) =>
  incomingCall.map(
    ({
      call_routing_device,
      device_name,
      selected_option: { value },
      selected_sip_device,
      forwardTo,
      ringing_duration,
      ringingStrategyAfterRingingDuration,
      ringingStrategyForwardTo,
    }) => {
      if (value == INCOMING_CALL_TYPE.FORWARD_TO) {
        return {
          call_routing_device_attributes: call_routing_device,
          device_name,
          ringing_strategy: value,
          call_forwarding_option_attributes: {
            external_number: forwardTo.external_number,
            external_number_name: forwardTo.external_number_name,
          },
          ringing_duration_seconds_until_next_strategy: null,
          strategy_after_ringing_duration: null,
          incoming_call_routing_initiation_devices_attributes: null,
        };
      }

      if (
        ringingStrategyAfterRingingDuration?.value ===
        INCOMING_CALL_TYPE.FORWARD_TO
      ) {
        return {
          ringing_strategy: value,
          call_routing_device_attributes: call_routing_device,
          device_name,
          incoming_call_routing_initiation_devices_attributes:
            sipDeviceAttributes(selected_sip_device),
          ringing_duration_seconds_until_next_strategy: ringing_duration.value,
          strategy_after_ringing_duration:
            ringingStrategyAfterRingingDuration.value,
          call_forwarding_option_attributes: {
            external_number: ringingStrategyForwardTo.external_number,
            external_number_name: ringingStrategyForwardTo.external_number_name,
          },
        };
      }

      if (
        value == INCOMING_CALL_TYPE.BUSY_SIGNAL ||
        value == INCOMING_CALL_TYPE.GO_TO_VOICEMAIL
      ) {
        return {
          ringing_strategy: value,
          call_routing_device_attributes: call_routing_device,
          device_name,
          ringing_duration_seconds_until_next_strategy: null,
          strategy_after_ringing_duration: null,
          call_forwarding_option_attributes: null,
          incoming_call_routing_initiation_devices_attributes: null,
        };
      }

      return {
        ringing_strategy: value,
        call_routing_device_attributes: call_routing_device,
        device_name,
        call_forwarding_option_attributes: null,
        incoming_call_routing_initiation_devices_attributes:
          sipDeviceAttributes(selected_sip_device),
        ringing_duration_seconds_until_next_strategy: ringing_duration.value,
        strategy_after_ringing_duration:
          ringingStrategyAfterRingingDuration.value,
      };
    }
  );

/**
 * create form attributes
 * @param {array} incomingCall - It consists all device setup for incoming call
 * @param {string} formType - It is form type create or edit
 * @returns
 */

export const incomingCallAttributes = (
  incomingCall,
  type = INCOMING_FORM_TYPE.CREATE
) => {
  if (type == INCOMING_FORM_TYPE.CREATE) {
    return createFormAttributes(incomingCall);
  }

  return editFormAttributes(incomingCall);
};

export const updateWithRemovableDevicesForIncomingCalls = (
  incomingCalls,
  initialValues
) => {
  if (initialValues.length === 0 || !initialValues) return incomingCalls;

  return incomingCalls.map((incomingCallItem) => {
    const {
      selected_sip_device: selectedSipDevice,
      selected_option: { id },
    } = incomingCallItem;

    if (selectedSipDevice === null) {
      return incomingCallItem;
    }

    const removedSipDevices = [];

    const initialIncomingCallItem = initialValues.find(
      ({ selected_option: selectedOption }) => selectedOption?.id === id
    );

    if (!initialIncomingCallItem) return incomingCallItem;

    if (initialIncomingCallItem["selected_sip_device"]) {
      initialIncomingCallItem["selected_sip_device"].forEach(
        (initialSipDevice) => {
          const {
            sipDevice: { id: sipDeviceID },
          } = initialSipDevice;

          const elementIndex = selectedSipDevice.findIndex(
            ({ sipDevice }) => sipDevice.id === sipDeviceID
          );

          if (elementIndex < 0) {
            removedSipDevices.push({
              ...initialSipDevice,
              sipDevice: {
                id: sipDeviceID,
                _destroy: true,
              },
            });
          }
        }
      );
    }

    return {
      ...incomingCallItem,
      selected_sip_device: selectedSipDevice.concat(removedSipDevices),
    };
  });
};
