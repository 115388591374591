import {
  CLEAR_CONTACTS_ERRORS,
  CONTACT_DETAIL_CLEAR,
  CONTACT_LIST_SEARCH,
  FETCH_CONTACT_DETAIL_ERROR,
  FETCH_CONTACT_DETAIL_RECEIVED,
  FETCH_CONTACT_DETAIL_REQUEST,
  FETCH_CONTACTS_ERROR,
  FETCH_CONTACTS_RECEIVED,
  FETCH_CONTACTS_REQUEST,
  UPDATE_CONTACT_STATUS,
} from "redux/actions/contact";

const defaultContactState = {
  isLoading: true,
  contacts: [],
  fetchContactsError: "",
  contactDetailData: {},
  contactDetailLoading: false,
  fetchContactDetailError: "",
  isSearching: false,
};

function contact(state = defaultContactState, action) {
  switch (action.type) {
    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchContactsError: "",
      };
    case FETCH_CONTACTS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        contacts: action.contacts,
      };
    case FETCH_CONTACTS_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchContactsError: action.error,
      };

    case FETCH_CONTACT_DETAIL_REQUEST:
      return {
        ...state,
        contactDetailLoading: true,
        fetchContactDetailError: "",
      };

    case FETCH_CONTACT_DETAIL_RECEIVED:
      return {
        ...state,
        contactDetailLoading: false,
        contactDetailData: action.data,
      };

    case FETCH_CONTACT_DETAIL_ERROR:
      return {
        ...state,
        contactDetailLoading: false,
        fetchContactDetailError: "",
      };

    case CONTACT_DETAIL_CLEAR:
      return {
        ...state,
        contactDetailData: {},
      };

    case CLEAR_CONTACTS_ERRORS:
      return {
        ...state,
        fetchContactsError: "",
        fetchContactDetailError: "",
      };

    case CONTACT_LIST_SEARCH:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case UPDATE_CONTACT_STATUS:
      const { extensionNumber, status } = action.status;
      const contactIndex = state.contacts.findIndex(
        (element) => element.extension_number == extensionNumber
      );

      if (contactIndex >= 0) {
        const contacts = [...state.contacts];

        contacts[contactIndex].status = status;

        if (state.contactDetailData?.extension_number == extensionNumber) {
          return {
            ...state,
            contacts,
            contactDetailData: {
              ...state.contactDetailData,
              status,
            },
          };
        } else {
          return {
            ...state,
            contacts,
          };
        }
      } else {
        return state;
      }

    default:
      return state;
  }
}

export default contact;
