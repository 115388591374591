import React from "react";
import Skeleton from "react-loading-skeleton";

import InformationBoxWrapperSkeleton from "components/Skeleton/InformationBoxWrapperSkeleton";
import TableSkeleton from "components/Skeleton/TableSkeleton";

import { LIGHT_GREY } from "constants/skeletonColor";

const CellPhoneVoicemailBoxSkeleton = () => (
  <article className="cell-phone-voicemail-boxes p-3 bg-white">
    <InformationBoxWrapperSkeleton
      title="voicemailBoxes.general.generalVoicemailBoxes"
      titleContent={
        <>
          <Skeleton
            width={260}
            height={30}
            baseColor={LIGHT_GREY}
            className="d-none d-md-inline-block mt-2"
          />{" "}
          <Skeleton
            className="w-100 d-inline-block d-md-none"
            baseColor={LIGHT_GREY}
            height={30}
          />
        </>
      }
    >
      <div className="cell-phone-table overflow-auto">
        <TableSkeleton numberOfColumns={4} numberOfRows={4} />
      </div>
    </InformationBoxWrapperSkeleton>
  </article>
);

export default CellPhoneVoicemailBoxSkeleton;
