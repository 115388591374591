import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSortBy, useTable } from "react-table";
import { Localize, Translate } from "react-thunk-i18nify";

import {
  faDownload,
  faEnvelope,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import VoicemailTableSkeleton from "components/Skeleton/Voicemails/VoicemailTableSkeleton";
import Table from "components/Table/Table";
import TableBody from "components/Table/TableBody";
import TableHeader from "components/Table/TableHeader";

import { VOICEMAIL_TABLE_HEADER } from "constants/tableHeader";

import { formattedAudio } from "utils/audio";
import { convertToDuration } from "utils/timeFormatter";
import { displaySuccess } from "utils/toaster";

import audioController from "lib/AudioController";

import {
  voicemailDownloadRequest,
  voicemailSendRequest,
  voicemailDeleteRequest,
  voicemailDownloadAudioRequest,
} from "redux/actions/voicemail";

import EmailModal from "../Modal/EmailModal";
import PlayVoicemail from "../PlayVoicemail";

const MODAL_TYPES = {
  DELETE: "DELETE",
  DOWNLOAD: "DOWNLOAD",
  EMAIL: "EMAIL",
};

const VoicemailTable = ({ voicemails }) => {
  const {
    isProcessing,
    isSearching,
    isLoading,
    isFiltering,
    commitError,
    commitSuccess,
    audioFile,
  } = useSelector(({ voicemail }) => voicemail);
  const [tableData, setTableData] = useState([]);
  const [showActiveModal, setShowActiveModal] = useState(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [selectedVoicemail, setSelectedVoicemail] = useState({});
  const dispatch = useDispatch();

  const rowData = (cell) => {
    if (cell.column.id == "time") {
      const newDate = (
        <Localize value={cell.value} dateFormat="dateTime.long" />
      );

      return newDate;
    } else if (cell.column.id == "from") {
      return <p className="mb-0 me-3">{cell.value}</p>;
    }

    return cell.render("Cell");
  };

  const handleDownload = (item) => {
    setShowActiveModal(MODAL_TYPES.DOWNLOAD);
    setSelectedVoicemail(item);
  };

  const handleDownloadConfirm = () => {
    dispatch(voicemailDownloadRequest(selectedVoicemail.id));
  };

  const handleSendEmail = (voiceMail) => {
    setShowActiveModal(MODAL_TYPES.EMAIL);
    setSelectedVoicemail(voiceMail);
  };

  const handleSendEmailConfirm = (email) => {
    dispatch(voicemailSendRequest(selectedVoicemail.id, email));
  };

  const handleDeleteModal = (item) => {
    setSelectedVoicemail(item);
    setShowActiveModal(MODAL_TYPES.DELETE);
  };

  const handleDeleteConfirm = () => {
    dispatch(voicemailDeleteRequest(selectedVoicemail.id));
  };

  const resetVoiceMailModal = () => {
    setShowActiveModal(null);
  };

  const handleAudioDownload = (item) => {
    setAudioLoading(false);

    if (audioFile?.id == item.id) {
      if (!audioPlaying) {
        setAudioPlaying(true);
        audioController.updateAudio(formattedAudio(audioFile.audio), () => {
          setAudioPlaying(false);
          setAudioLoading(false);
        });
        audioController.playAudio();
      } else {
        setAudioPlaying(false);
        setAudioLoading(false);
        audioController.pauseAudio();
      }
    } else {
      setSelectedVoicemail(item);
      setAudioPlaying(false);
      setAudioLoading(true);
      dispatch(voicemailDownloadAudioRequest(item, "base64"));
    }
  };

  useEffect(() => {
    if (!audioFile?.id) return;

    audioController.updateAudio(formattedAudio(audioFile.audio), () => {
      setAudioPlaying(false);
    });

    setAudioPlaying(true);
    audioController.playAudio();
  }, [audioFile]);

  useEffect(() => {
    audioController.removeAudio();
  }, []);

  useEffect(() => {
    if (commitError && showActiveModal != MODAL_TYPES.EMAIL) {
      resetVoiceMailModal();
      setSelectedVoicemail({});
    } else if (commitSuccess) {
      if (showActiveModal != MODAL_TYPES.DOWNLOAD) {
        setSelectedVoicemail({});
        displaySuccess(commitSuccess);
      }
      resetVoiceMailModal();
    }
  }, [commitError, commitSuccess]);

  useEffect(() => {
    if (voicemails.length == 0) return setTableData([]);
    let newTableData = [];

    voicemails.forEach((item) => {
      const newObj = {
        time: new Date(item.received_date),
        from: Number(item.sender_number),
        listen: listenVoicemails(item),
        download: (
          <FontAwesomeIcon
            icon={faDownload}
            className=" download-icon"
            onClick={() => handleDownload(item)}
          />
        ),
        email: (
          <FontAwesomeIcon
            icon={faEnvelope}
            className="email-icon text-primary"
            onClick={() => handleSendEmail(item)}
          />
        ),
        delete: (
          <FontAwesomeIcon
            icon={faTrash}
            className="delete-icon text-danger"
            onClick={() => handleDeleteModal(item)}
            data-cy={`delete-voicemail-${item.id}`}
          />
        ),
      };

      newTableData.push(newObj);
    });

    setTableData(newTableData);
  }, [voicemails, audioFile, audioPlaying, audioLoading]);

  const listenVoicemails = (item) => (
    <div
      className="d-flex align-items-center justify-content-center"
      tabIndex={0}
      onClick={() => handleAudioDownload(item)}
      data-cy={`voicemail-audio-${item.id}`}
    >
      <PlayVoicemail
        isSelected={selectedVoicemail?.id == item.id}
        isPlaying={audioPlaying}
        isLoading={isProcessing || audioLoading}
      />
      {convertToDuration(item.audio_duration_in_seconds, "mm:ss")}
    </div>
  );

  const tableInstance = useTable(
    { columns: VOICEMAIL_TABLE_HEADER, data: tableData },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (isLoading) {
    return <VoicemailTableSkeleton />;
  }

  return (
    <div
      className="voicemail-table pt-3 px-2 overflow-auto h-100"
      data-cy="voicemail-table-data"
    >
      <Table getTableProps={getTableProps}>
        <TableHeader headerGroups={headerGroups} />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          rowData={rowData}
        />
      </Table>
      <ConfirmModal
        isVisible={showActiveModal === MODAL_TYPES.DOWNLOAD}
        onCancel={() => setShowActiveModal(null)}
        cancelButtonText="common.cancel"
        onConfirm={handleDownloadConfirm}
        confirmButtonText="common.yesImSure"
        isLoading={isProcessing}
        heading="voicemails.download.confirmDownloadingVoicemail"
      >
        <Translate
          value="voicemails.download.description"
          voicemail={selectedVoicemail.sender_number}
        />
      </ConfirmModal>
      <EmailModal
        isVisible={showActiveModal === MODAL_TYPES.EMAIL}
        handleClose={() => setShowActiveModal(null)}
        defaultEmailAddress={selectedVoicemail.email}
        handleOverlayClick={() => setShowActiveModal(null)}
        handleSubmit={handleSendEmailConfirm}
      />
      <ConfirmModal
        isVisible={showActiveModal === MODAL_TYPES.DELETE}
        deleteModal
        onCancel={() => setShowActiveModal(null)}
        cancelButtonText="common.cancel"
        onConfirm={handleDeleteConfirm}
        confirmButtonText="common.yesImSure"
        isLoading={isProcessing}
        heading="voicemails.delete.areYouSureYouWantToDeleteVoicemail"
      >
        <Translate
          value="voicemails.delete.description"
          voicemail={selectedVoicemail.sender_number}
        />
      </ConfirmModal>
      <EmptyTableUI
        records={voicemails}
        isSearching={isSearching}
        isFiltering={isFiltering}
        filterTitle="voicemails.empty.voicemailNotFound"
        filterDescription="voicemails.empty.recordOnFilteredDateNotFound"
        searchTitle="voicemails.empty.voicemailNotFound"
        searchDescription="voicemails.empty.searchQueryNotFound"
      />
    </div>
  );
};

VoicemailTable.defaultProps = {
  voicemails: [],
};

VoicemailTable.propTypes = {
  voicemails: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VoicemailTable;
