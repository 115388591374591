import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

const PasswordTitle = ({ commitSuccess }) => {
  if (commitSuccess) {
    return null;
  }

  return (
    <h5 data-cy="password-title" className="h5 fw-bold my-3 pb-2 ms-2 ps-3">
      <Translate value="common.changePassword" />
    </h5>
  );
};

PasswordTitle.defaultProps = {
  commitSuccess: "",
};

PasswordTitle.propTypes = {
  commitSuccess: PropTypes.string,
};

export default PasswordTitle;
