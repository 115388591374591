import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { AUTH } from "constants/routes";

import { setPasswordClear } from "redux/actions/auth";

const PasswordNotification = ({ showNavigateToLoginButton }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    history.push(AUTH.LOGIN);
  };

  useEffect(() => () => dispatch(setPasswordClear()), []);

  const navigationToLoginButton = showNavigateToLoginButton && (
    <button
      className="btn btn-primary w-100 text-center mt-3 mb-2 password-notification-button"
      onClick={handleNavigation}
    >
      <Translate value="auth.passwordNotification.okLetLoginNow" />
    </button>
  );

  return (
    <div className="d-flex flex-column justify-content-center password-notification pt-3">
      <div className="d-flex justify-content-center mb-3">
        <div className="rounded-circle completed-icon d-flex justify-content-center align-items-center">
          <FontAwesomeIcon icon={faCheckCircle} className="icon" />
        </div>
      </div>
      <h4 className="mb-1 pb-2 notification-title text-center mb-1">
        <Translate value="auth.passwordNotification.congratulationNewPassword" />
      </h4>
      <p className="description text-center">
        <Translate value="auth.passwordNotification.description" />
      </p>
      {navigationToLoginButton}
    </div>
  );
};

PasswordNotification.defaultProps = {
  showNavigateToLoginButton: true,
};

PasswordNotification.propTypes = {
  showNavigateToLoginButton: PropTypes.bool,
};

export default PasswordNotification;
