import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import arrayMutators from "final-form-arrays";

import IncomingCallIndex from "components/CallProfile//Form/IncomingCall/IncomingCallIndex";
import OutgoingCallForm from "components/CallProfile/Form/OutgoingCall/OutgoingCallForm";
import ProfileBasicForm from "components/CallProfile/Form/ProfileBasicForm";
import QueueRegistrationForm from "components/CallProfile/Form/QueueRegistration/QueueRegistrationForm";
import FormWrapper from "components/Form/FormWrapper";
import CallProfileDetailSkeleton from "components/Skeleton/CallProfiles/Detail/CallProfileDetailSkeleton";

import { CallProfileContext } from "context-api/CallProfileContext";

import { PROFILE } from "constants/routes";

import {
  incomingCallAttributes,
  INCOMING_FORM_TYPE,
} from "utils/incomingCallAttributes";
import { incomingCallOptions } from "utils/incomingCallOptions";
import {
  addOutgoingCallAttributes,
  outgoingCallInitialValues,
} from "utils/outgoingCallAttributes";
import {
  createFormAttributes,
  profileQueuesAttributes,
} from "utils/queueRegistrationAttributes";
import { displayError, displaySuccess } from "utils/toaster";
import { validateCallProfile } from "utils/validation";

import {
  createCallProfileRequest,
  clearCallProfileErrors,
  fetchAllOutgoingAvailableDevicesRequest,
  fetchCallProfilesRequest,
  fetchAllIncomingAvailableDevicesRequest,
  fetchAllIncomingSipDevicesRequest,
} from "redux/actions/callProfile";
import {
  fetchAllQueuesRequest,
  fetchAllQueueDevicesRequest,
} from "redux/actions/callProfile";

const CreateCallProfileForm = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const {
    commitError,
    commitSuccess,
    isSubmitting,
    isLoading,
    outgoingCallDevices,
    isProcessing,
    callProfileDetail: { id },
    incomingCallDevices,
    sipDevices,
  } = useSelector(({ callProfile }) => callProfile);
  const { callProfileQueues } = useSelector(({ callProfile }) => callProfile);
  const {
    currentUser: { user_license_id: userLicenseID, pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);

  const [initialDevicesLoading, setInitialDeviceLoading] = useState(true);

  const { setSubmitted } = useContext(CallProfileContext);

  const onSubmitHandler = ({
    name,
    icon: [brandName, iconName],
    description,
    incomingCalls,
    outgoingCalls,
    call_profile_queues_attributes: callProfileQueuesAttributes,
    is_automatic_queue_registration_enabled:
      isAutomaticQueueRegistrationEnabled,
  }) => {
    const conditionalCallProfileQueuesAttributes =
      isAutomaticQueueRegistrationEnabled
        ? {
            call_profile_queues_attributes: profileQueuesAttributes(
              callProfileQueuesAttributes
            ),
          }
        : {};

    const callProfileCreateData = {
      call_profile: {
        name: name,
        description: description,
        icon_json: {
          brand_name: brandName,
          icon_name: iconName,
        },
        outgoing_call_routings_attributes:
          addOutgoingCallAttributes(outgoingCalls),
        incoming_call_routings_attributes:
          incomingCallAttributes(incomingCalls),
        ...conditionalCallProfileQueuesAttributes,
        is_automatic_queue_registration_enabled:
          isAutomaticQueueRegistrationEnabled,
      },
    };

    dispatch(createCallProfileRequest(userLicenseID, callProfileCreateData));
  };

  const handleCancel = () => replace(PROFILE.INDEX);

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
      dispatch(fetchCallProfilesRequest(userLicenseID));
      replace(PROFILE.addID(PROFILE.EDIT, id)); // redirect to edit page
    } else if (commitError) {
      displayError(commitError);
    }
  }, [commitSuccess, commitError]);

  useEffect(() => () => dispatch(clearCallProfileErrors()), []);

  useEffect(() => {
    dispatch(fetchAllOutgoingAvailableDevicesRequest(userLicenseID));
    dispatch(fetchAllIncomingAvailableDevicesRequest(userLicenseID));
    dispatch(fetchAllIncomingSipDevicesRequest(userLicenseID));
    dispatch(fetchAllQueuesRequest(pbxID));
    dispatch(fetchAllQueueDevicesRequest(userLicenseID));
  }, []);

  const incomingCallInitialValues = useMemo(() => {
    setInitialDeviceLoading(true);
    const initialValues = incomingCallOptions(
      incomingCallDevices,
      INCOMING_FORM_TYPE.CREATE,
      sipDevices
    );

    setInitialDeviceLoading(false);

    return initialValues;
  }, [incomingCallDevices, sipDevices]);

  const initialValuesForOutGoingCalls = useMemo(
    () => outgoingCallInitialValues(outgoingCallDevices),
    [outgoingCallDevices]
  );

  const queueRegistrationInitialValues = useMemo(() => {
    const initialValues = createFormAttributes(callProfileQueues);

    return initialValues;
  }, [callProfileQueues]);

  if (isLoading || isProcessing || initialDevicesLoading) {
    return <CallProfileDetailSkeleton />;
  }

  return (
    <div className="call-profile-form flex-fill d-flex flex-column pt-4 pt-md-2 h-100">
      <h5 className="h5 fw-bold ms-1">
        <Translate value="callProfile.create.createNewProfile" />
      </h5>
      <FormWrapper
        formClassName="p-0 d-flex flex-column justify-content-between h-100"
        submitText="common.save"
        submitClassName="btn btn-primary save-button my-3"
        submitContainerClassName="justify-content-md-end mt-4 mt-md-3 me-2 pe-1"
        validation={(values) => validateCallProfile(values)}
        onSubmitHandler={onSubmitHandler}
        isLoading={isSubmitting}
        submitCallBack={() => setSubmitted(true)}
        handleCancel={handleCancel}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          incomingCalls: incomingCallInitialValues,
          outgoingCalls: initialValuesForOutGoingCalls,
          is_automatic_queue_registration_enabled: false,
          call_profile_queues_attributes: queueRegistrationInitialValues,
        }}
      >
        <ProfileBasicForm />
        <IncomingCallIndex />
        <OutgoingCallForm />
        <QueueRegistrationForm />
      </FormWrapper>
    </div>
  );
};

export default CreateCallProfileForm;
