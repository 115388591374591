import { ChangePasswordApi } from "internal";

import { getPasswordError } from "utils/utils";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_RECEIVED = "CHANGE_PASSWORD_RECEIVED";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const CLEAR_CHANGE_PASSWORD_ERROR = "CLEAR_CHANGE_PASSWORD_ERROR";

export const changePasswordReceived = (data) => ({
  type: CHANGE_PASSWORD_RECEIVED,
  data,
});

export const changePasswordError = (errorData) => {
  const error = getPasswordError(errorData);

  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
};

export const changePasswordRequest =
  (userLicenseId, attributes) => async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });

      const response = await ChangePasswordApi.updatePassword(
        userLicenseId,
        attributes
      );

      dispatch(changePasswordReceived(response.data));
    } catch (error) {
      dispatch(changePasswordError(error));
    }
  };

export const clearChangePasswordError = () => ({
  type: CLEAR_CHANGE_PASSWORD_ERROR,
});
