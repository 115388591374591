import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  FETCH_QUEUES_DASHBOARD: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/dashboard_queues`,
  FETCH_REGISTERS_AGENTS: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/queue_agents/registered?pbx_id=:pbxId`,
  FETCH_AVAILABLE_AGENTS_IN_QUEUE: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/available_devices?pbx_id=:pbxId`,
  FETCH_REGISTERED_AGENTS_IN_QUEUE: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/registered_devices?pbx_id=:pbxId`,
  UPDATE: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID?pbx_id=:pbxId`,
  FETCH_STATISTICS: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/statistics?pbx_id=:pbxId`,
  FETCH_TOTAL_CALLS: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/callers?pbx_id=:pbxId`,
  FETCH_DEVICES_IN_QUEUE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/queue_devices?queue_id=:queueID`,
  ADD_DEVICE_IN_QUEUE: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/add_agent?number=:number&pbx_id=:pbxId`,
  REMOVE_DEVICE_FROM_QUEUE: `${apiUrlConfig.asteriskEndpoint()}/queues/:queueID/remove_agent?number=:number&pbx_id=:pbxId`,
};

class CallQueueApi extends AuthenticatedRequest {
  /**
   *
   * @param {init} userLicenseID - user license id of the pbx user
   * @returns
   */
  static fetchQueueDashboard(userLicenseID) {
    const FETCH_ALL_URL = URLS.FETCH_QUEUES_DASHBOARD.replace(
      ":userLicenseID",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *
   * @param {*} pbxID - pbx id of the pbx user
   * @param {*} queueID -  queue id of the call queue when user select one
   * @returns
   */

  static fetchRegisterAgents(pbxID, queueID) {
    const FETCH_REGISTER_AGENTS_URL = URLS.FETCH_REGISTERS_AGENTS.replace(
      ":queueID",
      queueID
    ).replace(":pbxId", pbxID);

    return this.get(FETCH_REGISTER_AGENTS_URL);
  }

  /**
   *
   * @param {init} pbxID - pbx id of the pbx user
   * @param {init} queueID -  queue id of the call queue when user select one
   * @returns
   */

  static fetchAvailableAgentsInQueue(pbxID, queueID) {
    const FETCH_AVAILABLE_AGENTS_URL =
      URLS.FETCH_AVAILABLE_AGENTS_IN_QUEUE.replace(":queueID", queueID).replace(
        ":pbxId",
        pbxID
      );

    return this.get(FETCH_AVAILABLE_AGENTS_URL);
  }

  /**
   *
   * @param {init} pbxID - pbx id of the pbx user
   * @param {init} queueID -  queue id of the call queue when user select one
   * @returns
   */

  static fetchRegisteredDevicesInQueue(pbxID, queueID) {
    const FETCH_AVAILABLE_REGISTERED_AGENTS =
      URLS.FETCH_REGISTERED_AGENTS_IN_QUEUE.replace(
        ":queueID",
        queueID
      ).replace(":pbxId", pbxID);

    return this.get(FETCH_AVAILABLE_REGISTERED_AGENTS);
  }

  /**
   *
   * @param {init} pbxID - pbx id of the pbx user
   * @param {init} queueID -  queue id of the call queue when user select one
   * @returns
   */
  static update(pbxID, queueID, data) {
    const UPDATE = URLS.UPDATE.replace(":queueID", queueID).replace(
      ":pbxId",
      pbxID
    );

    return this.patch(UPDATE, data);
  }

  /**
   * @param {init} pbxID - pbx id of the pbx user
   * @param {init} queueID -  queue id of the call queue when user select one
   * @returns
   */

  static fetchStatistics(pbxID, queueID) {
    const fetchUrl = URLS.FETCH_STATISTICS.replace(":queueID", queueID).replace(
      ":pbxId",
      pbxID
    );

    return this.get(fetchUrl);
  }

  /**
   * @param {init} pbxID - pbx id of the pbx user
   * @param {init} queueID -  queue id of the call queue when user select one
   * @returns
   */

  static fetchTotalCalls(pbxID, queueID) {
    const fetchUrl = URLS.FETCH_TOTAL_CALLS.replace(
      ":queueID",
      queueID
    ).replace(":pbxId", pbxID);

    return this.get(fetchUrl);
  }

  /**
   * @param {init} userLicenseID - user license id of the pbx user
   * @param {init} queueID -  queue id of the call queue when user select one
   * @returns
   */

  static fetchDevicesInQueue(userLicenseID, queueID) {
    const fetchUrl = URLS.FETCH_DEVICES_IN_QUEUE.replace(
      ":userLicenseID",
      userLicenseID
    ).replace(":queueID", queueID);

    return this.get(fetchUrl);
  }

  /**
   * @param {init} pbxID - ID of the Pbx
   * @param {init} queueID -  queue id of the call queue when user select one
   * @param {init} number - number which will be added in registered agent
   * @returns
   */

  static addDeviceInQueue(pbxID, queueID, number) {
    const postUrl = URLS.ADD_DEVICE_IN_QUEUE.replace(":pbxId", pbxID)
      .replace(":queueID", queueID)
      .replace(":number", number);

    return this.post(postUrl);
  }

  /**
   * @param {init} pbxID - ID of the Pbx
   * @param {init} queueID -  queue id of the call queue when user select one
   * @param {init} number - number which will be added in registered agent
   * @returns
   */
  static removeDeviceInQueue(pbxID, queueID, number) {
    const removeUrl = URLS.REMOVE_DEVICE_FROM_QUEUE.replace(":pbxId", pbxID)
      .replace(":queueID", queueID)
      .replace(":number", number);

    return this.delete(removeUrl);
  }
}

export default CallQueueApi;
