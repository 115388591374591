import React, { useState } from "react";
import "bootstrap/js/src/collapse";
import { Field } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import FormWrapper from "components/Form/FormWrapper";
import InputField from "components/Form/InputField/InputField";

import { faMobileSignalOut } from "constants/customIcon/icons";

import { externalNumberValidation, required } from "utils/validation";

const MobileView = ({ handleSubmit }) => {
  const [open, setOpen] = useState(true);
  const [collapseClassName, setCollapseName] = useState("open");
  const dropdownPositionClassName = open ? "collapse show" : "collapsed";

  const handleCollapse = () => {
    if (open) {
      setCollapseName("closing");
      setTimeout(() => {
        setCollapseName("closed");
        setOpen(false);
      }, 100);
    } else {
      setCollapseName("opening");
      setTimeout(() => {
        setCollapseName("open");
        setOpen(true);
      }, 100);
    }
  };

  return (
    <div className="mobile-view-forward-to-modal d-block d-md-none row align-items-start modal-body">
      <div className="accordion accordion-flush">
        <div className="accordion-item">
          <h2 className="accordion-header" id="externalNumber">
            <button
              className={`accordion-button ${dropdownPositionClassName} py-2`}
              type="button"
              aria-expanded={open}
              aria-controls="externalNumberCollapse"
              onClick={handleCollapse}
            >
              <FontAwesomeIcon icon={faMobileSignalOut} className="me-3" />
              <Translate value="callProfile.incomingCalls.externalNumber" />
            </button>
          </h2>
          <div
            id="externalNumberCollapse"
            className={`forward-to-collapse ${collapseClassName}`}
            aria-labelledby="externalNumber"
          >
            <div className="accordion-body">
              <FormWrapper
                formClassName="p-0 d-flex flex-column justify-content-between h-100 w-100"
                submitText="common.add"
                submitClassName="btn btn-primary add-button my-3"
                submitContainerClassName="justify-content-md-end mt-md-3"
                cancelClassName="btn btn-outline-secondary d-block d-md-none my-md-3"
                showCancelButton={false}
                onSubmitHandler={handleSubmit}
              >
                <div className="mb-3">
                  <Field
                    name="external_number_name"
                    component={InputField}
                    placeholderTranslation="attributes.name"
                    labelTranslation="attributes.name"
                    validate={required("validationErrors.REQUIRED")}
                  />
                </div>
                <Field
                  name="external_number"
                  component={InputField}
                  placeholderTranslation="attributes.insertNumber"
                  labelTranslation="attributes.insertNumber"
                  validate={externalNumberValidation}
                />
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MobileView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default MobileView;
