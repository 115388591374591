import notFound from "assets/images/404.svg";
import alert from "assets/images/alert.svg";
import allCallHistory from "assets/images/all-call-history.svg";
import avatar from "assets/images/avatar-placeholder.svg";
import brandLogo from "assets/images/brand-logo.svg";
import callQueue from "assets/images/call-queue.png";
import defaultProfile from "assets/images/default-profile.svg";
import edit from "assets/images/edit.svg";
import emptyCallProfile from "assets/images/empty-call-profile.png";
import emptyContact from "assets/images/empty-contact.svg";
import forbiddenBanner from "assets/images/forbidden-banner.svg";
import incomingCall from "assets/images/incoming-call.svg";
import infographicQueue from "assets/images/infographic-queue.svg";
import missedCall from "assets/images/missed-call.svg";
import mobilePbxLogo from "assets/images/mobile.svg";
import noCallQueue from "assets/images/no-call-queue.png";
import nonSelectedContact from "assets/images/non-selected-contact.svg";
import outGoingCall from "assets/images/outgoing-call.svg";
import passwordBanner from "assets/images/password-banner.svg";
import passwordNotification from "assets/images/password-notification.svg";
import pbxUserBrandLogo from "assets/images/pbx-user-brand-logo.svg";
import sendEmail from "assets/images/send-email.svg";
import toolTipAlert from "assets/images/tooltip-alert.svg";
import totalCall from "assets/images/total-call.svg";
import userEmail from "assets/images/user-email.svg";
import waitTime from "assets/images/wait-time.svg";

const BRAND_LOGO = {
  SOURCE: brandLogo,
};

const AVATAR = {
  SOURCE: avatar,
};

const CONTACT_NOT_SELECTED = {
  SOURCE: nonSelectedContact,
};

const EMPTY_CONTACT = {
  SOURCE: emptyContact,
};

const PBX_USER_BRAND_LOGO = {
  SOURCE: pbxUserBrandLogo,
  MOBILE: {
    SOURCE: mobilePbxLogo,
  },
};

const EDIT = {
  SOURCE: edit,
};

const OUTGOING_CALL = {
  SOURCE: outGoingCall,
};

const MISSED_CALL = {
  SOURCE: missedCall,
};

const INCOMING_CALL = {
  SOURCE: incomingCall,
};

const ALL_CALL_HISTORY = {
  SOURCE: allCallHistory,
};

const SEND_EMAIL = {
  SOURCE: sendEmail,
};

const USER_EMAIL = {
  SOURCE: userEmail,
};

const PASSWORD_BANNER = {
  SOURCE: passwordBanner,
};

const DEFAULT_PROFILE = {
  SOURCE: defaultProfile,
};

const PASSWORD_NOTIFICATION = {
  SOURCE: passwordNotification,
};

const EMPTY_CALL_PROFILE = {
  SOURCE: emptyCallProfile,
};

const CALL_QUEUE = {
  SOURCE: callQueue,
};

const WAIT_TIME = {
  SOURCE: waitTime,
};

const TOTAL_CALL = {
  SOURCE: totalCall,
};

const NOT_FOUND = {
  SOURCE: notFound,
};

const FORBIDDEN_BANNER = {
  SOURCE: forbiddenBanner,
};

const NO_CALL_QUEUE = {
  SOURCE: noCallQueue,
};

const ALERT_ICON = {
  SOURCE: alert,
};

const TOOLTIP_ALERT = {
  SOURCE: toolTipAlert,
};

const INFOGRAPHIC_QUEUE = {
  SOURCE: infographicQueue,
};

export {
  BRAND_LOGO,
  AVATAR,
  CONTACT_NOT_SELECTED,
  EMPTY_CONTACT,
  PBX_USER_BRAND_LOGO,
  EDIT,
  OUTGOING_CALL,
  MISSED_CALL,
  INCOMING_CALL,
  ALL_CALL_HISTORY,
  SEND_EMAIL,
  USER_EMAIL,
  PASSWORD_BANNER,
  DEFAULT_PROFILE,
  PASSWORD_NOTIFICATION,
  EMPTY_CALL_PROFILE,
  CALL_QUEUE,
  WAIT_TIME,
  TOTAL_CALL,
  NOT_FOUND,
  FORBIDDEN_BANNER,
  NO_CALL_QUEUE,
  ALERT_ICON,
  TOOLTIP_ALERT,
  INFOGRAPHIC_QUEUE,
};
