import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Translate, I18n } from "react-thunk-i18nify";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import PropTypes from "prop-types";

import FormWrapper from "components/Form/FormWrapper";
import InputField from "components/Form/InputField/InputField";

import { AUTH } from "constants/routes";

import { displayError } from "utils/toaster";
import { composeValidators, emailValidation, required } from "utils/validation";

import {
  resetPasswordClearErrors,
  resetPasswordRequest,
} from "redux/actions/auth";

const RecoverPassword = ({ onEmailNotification }) => {
  const dispatch = useDispatch();
  const [onSubmit, setOnSubmit] = useState(false);

  const { commitError, commitSuccess, isLoading } = useSelector(
    ({ auth }) => auth
  );
  const { push } = useHistory();

  const onSubmitHandler = ({ username }) => {
    setOnSubmit(true);
    dispatch(resetPasswordRequest(username));
  };

  const handleNavigation = () => {
    push(AUTH.LOGIN);
  };

  useEffect(() => {
    if (commitSuccess && onSubmit) {
      onEmailNotification();
    }
  }, [commitSuccess]);

  useEffect(() => {
    if (commitError) {
      displayError(I18n.t(commitError));
      setTimeout(() => {
        dispatch(resetPasswordClearErrors());
      }, 3000);
    }
  }, [commitError]);

  return (
    <>
      <div
        className="w-100 h-100 mt-2 recover-password"
        data-testid="recover-password"
      >
        <h4 className="login-header">
          <Translate value="auth.forgotPassword.recoverYourPassword" />
        </h4>
        <p data-testid="description" className="description pb-3">
          <Translate value="auth.forgotPassword.description" />
        </p>
        <FormWrapper
          onSubmitHandler={onSubmitHandler}
          validation={(values) =>
            required(values, '"validationErrors.INVALID_PASSWORD"')
          }
          isLoading={isLoading}
          showCancelButton={false}
          submitClassName="btn btn-primary w-100 mt-3 recover-button my-3"
          submitText="auth.forgotPassword.sendAnInstruction"
        >
          <div className="mb-3 w-100 position-relative">
            <Field
              name="username"
              validate={composeValidators(emailValidation)}
              component={InputField}
              placeholderTranslation="attributes.emailAddress"
              labelTranslation="attributes.emailAddress"
              inputFieldIcon={faEnvelope}
            />
          </div>
          <div className="w-100 d-flex flex-column flex-sm-row justify-content-end pb-2 mx-1">
            <a
              data-testid="signin-instead-link"
              className="forgot-password mt-2 mt-sm-0 link"
              type="button"
              onClick={handleNavigation}
            >
              <Translate value="auth.forgotPassword.signInInstead" />
            </a>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

RecoverPassword.defaultProps = {
  onEmailNotification: () => {},
};

RecoverPassword.propTypes = {
  onEmailNotification: PropTypes.func,
};

export default RecoverPassword;
