import React from "react";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { DEVICE_TYPES } from "constants/agent";
import {
  faPhoneOffice,
  faMobileSignalOut,
  faAgentMobile,
} from "constants/customIcon/icons";

const Device = ({ deviceType, number, deviceName }) => {
  if (deviceType === DEVICE_TYPES.CELL_PHONE) {
    return (
      <div className="d-flex align-items-center mt-2">
        <FontAwesomeIcon icon={faAgentMobile} className="me-2 device-icon" />
        <p className="device-name mb-0">
          <Translate value="common.mobile" /> ({number})
        </p>
      </div>
    );
  }

  if (deviceType === DEVICE_TYPES.SIP_DEVICE) {
    return (
      <div className="d-flex align-items-center mt-2">
        <FontAwesomeIcon icon={faPhoneOffice} className="me-2 device-icon" />

        <p className="device-name mb-0">
          {deviceName} ({number})
        </p>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center mt-2">
      <FontAwesomeIcon icon={faMobileSignalOut} className="me-2 device-icon" />
      <p className="device-name mb-0">
        <Translate value="common.external" /> ({number})
      </p>
    </div>
  );
};

Device.defaultProps = {
  deviceType: "",
  number: "",
  deviceName: "",
};

Device.propTypes = {
  deviceType: PropTypes.oneOf(Object.values(DEVICE_TYPES)),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deviceName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Device;
