const filterSearchResults = ({ resource, searchResult }) => {
  const { result: resultIds, text } = searchResult || {};
  let results = resource;

  if (!text || text?.length === 0) {
    return results;
  }

  if (resultIds.length > 0 || (text && text.length > 0)) {
    if (typeof resultIds[0] === "string") {
      results = resource.filter((record) =>
        resultIds.includes(record.id.toString())
      );
    } else {
      results = resource.filter((record) => resultIds.includes(record.id));
    }
  }

  return results;
};

export default filterSearchResults;
