import React, { useState } from "react";
import { I18n, I18nReRender, Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Tooltip from "components/CustomTooltip/Tooltip";
import CustomInputField from "components/Form/InputField/CustomInputField";

import { faToolTip } from "constants/customIcon/icons";

import { externalNumberValidation } from "utils/validation";

const ExternalNumber = ({ setAddExternalNumber }) => {
  const initialValue = { value: "", error: "" };
  const [name, setName] = useState(initialValue);
  const [externalNumber, setExternalNumber] = useState(initialValue);

  const handleExternalNumberValidation = (e) => {
    const errorMessage = externalNumberValidation(e.target.value);

    if (errorMessage) {
      setExternalNumber((prev) => ({
        ...prev,
        error: errorMessage,
      }));
    } else {
      setExternalNumber((prev) => ({
        ...prev,
        error: "",
      }));
    }
  };

  const updateExternalNumber = (e) => {
    handleExternalNumberValidation(e);
    setExternalNumber((prev) => ({
      ...prev,
      value: e.target.value,
    }));
  };

  const updateExternalName = (e) => {
    handleNameValidation(e);
    setName((prev) => ({
      ...prev,
      value: e.target.value,
    }));
  };
  const handleNameValidation = (e) => {
    if (!e.target.value) {
      setName((prev) => ({
        ...prev,
        error: (
          <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
        ),
      }));
    } else {
      setName((prev) => ({
        ...prev,
        error: "",
      }));
    }
  };

  const disableExternalButton =
    externalNumber.error || name.error || !name.value || !externalNumber.value;

  const submitExternalNumber = () => {
    if (!name.error && !externalNumber.error) {
      setAddExternalNumber({
        name: name.value,
        externalNumber: externalNumber.value,
      });
      setName(initialValue);
      setExternalNumber(initialValue);
    }
  };

  const externalButtonContainerClassName =
    externalNumber.value != "" && externalNumber.error != ""
      ? "mb-4 pb-2"
      : externalNumber.error || name.error
      ? "mb-0 pb-2"
      : "mt-4 pt-2";

  return (
    <div className="external-number my-1 my-md-3">
      <div className="d-flex align-items-center mb-2">
        <p className="mb-0 header fw-bold">
          <Translate value="callProfile.incomingCalls.externalNumber" />
        </p>
        <Tooltip
          title={
            <Translate value="callQueue.agentManagement.externalNumberTooltipDescription" />
          }
        >
          <FontAwesomeIcon icon={faToolTip} className="ms-2 tooltip-icon" />
        </Tooltip>
      </div>
      <div className="row w-100 mx-auto gx-0 gx-md-3">
        <div className="col-md-5 ps-md-0 my-2 my-md-0">
          <CustomInputField
            value={name.value}
            error={name.error}
            onChange={updateExternalName}
            onBlur={handleNameValidation}
            placeholderTranslation="attributes.name"
            labelTranslation="attributes.name"
            name="externalName"
          />
        </div>
        <div className="col-md-5 ps-md-0 my-2 my-md-0">
          <CustomInputField
            value={externalNumber.value}
            error={externalNumber.error}
            onChange={updateExternalNumber}
            onBlur={handleExternalNumberValidation}
            placeholderTranslation="attributes.number"
            labelTranslation="attributes.number"
            name="externalNumber"
          />
        </div>
        <div
          className={`col-md-2 px-md-0 d-flex align-items-center ${externalButtonContainerClassName}`}
        >
          <button
            className="btn btn-primary external-button w-100"
            type="button"
            disabled={disableExternalButton}
            onClick={submitExternalNumber}
          >
            <Translate value="common.logOn" />
          </button>
        </div>
      </div>
    </div>
  );
};

ExternalNumber.defaultProps = {
  setAddExternalNumber: () => {},
};

ExternalNumber.propTypes = {
  setAddExternalNumber: PropTypes.func,
};

export default ExternalNumber;
