import React from "react";
import { I18n, I18nReRender } from "react-thunk-i18nify";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const SearchBar = ({
  placeholderText,
  onSearchHandler,
  className,
  isSearchDisabled,
  ...rest
}) => (
  <div className={` position-relative search-bar-container ${className}`}>
    <I18nReRender
      render={() => (
        <input
          type="text"
          className="form-control search-bar"
          id="searchBar"
          placeholder={I18n.t(placeholderText)}
          onChange={onSearchHandler}
          data-cy="search-bar"
          disabled={isSearchDisabled}
          {...rest}
        />
      )}
    />

    <FontAwesomeIcon
      icon={faSearch}
      className="search-icon position-absolute"
      size="1x"
    />
  </div>
);

SearchBar.defaultProps = {
  onSearchHandler: () => {},
  placeholderText: "",
  className: "",
  isSearchDisabled: false,
  rest: null,
};

SearchBar.propTypes = {
  onSearchHandler: PropTypes.func,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  isSearchDisabled: PropTypes.bool,
  rest: PropTypes.shape({}),
};

export default SearchBar;
