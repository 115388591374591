import { BaseRequest } from "internal";

import token from "utils/auth";

import { DEFAULT_HEADERS } from "services/requests/Base";

class AuthenticatedRequestService extends BaseRequest {
  static authenticatedHeaders() {
    const authHeader = { Authorization: token() };
    const headers = { ...DEFAULT_HEADERS, ...authHeader };

    return headers;
  }

  static headers() {
    let defaultHeaders = { headers: this.authenticatedHeaders() };

    return { ...defaultHeaders };
  }
}

export default AuthenticatedRequestService;
