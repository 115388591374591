import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const Dropdown = ({
  active,
  handleDropdownUpdate,
  handleDropdownDisabled,
  validActiveClassName,
  options,
  className,
  containerClassName,
  activeClassName,
}) => {
  const handleDisabled = (item) => {
    if (handleDropdownDisabled(item)) {
      return true;
    }

    return false;
  };

  return (
    <div
      data-cy="custom-dropdown"
      className={`btn-group custom-dropdown d-flex align-items-center ${containerClassName}`}
    >
      <button
        className={`btn-sm dropdown-toggle text-nowrap position-relative d-flex align-items-center  ${activeClassName}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-cy="filter-dropdown"
        data-testid="filter-dropdown"
      >
        <span>{active}</span>
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon ms-2" />
      </button>
      <ul
        data-cy="dropdown-menu"
        className="dropdown-menu custom-dropdown-menu py-2 border-0"
      >
        {options.map((item) => (
          <li key={item?.keyword}>
            <button
              className={`dropdown-item ${className} ${validActiveClassName(
                item
              )} py-0 px-3`}
              onClick={() => handleDropdownUpdate(item)}
              disabled={handleDisabled(item)}
              data-cy={item?.keyword}
              type="button"
              data-testid={item.dataTestId}
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.defaultProps = {
  options: [],
  active: <></>,
  className: "",
  handleDropdownUpdate: () => {},
  handleDropdownDisabled: () => {},
  validActiveClassName: () => {},
  containerClassName: "px-md-3 border-0 fw-bold",
  activeClassName: "custom-button fw-bold border-0 px-2",
};

Dropdown.propTypes = {
  options: PropTypes.array,
  active: PropTypes.shape({}),
  handleDropdownUpdate: PropTypes.func,
  handleDropdownDisabled: PropTypes.func,
  validActiveClassName: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  activeClassName: PropTypes.string,
};

export default Dropdown;
