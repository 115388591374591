export default function token() {
  const bearerToken = localStorage.getItem("bearerToken");

  return bearerToken;
}

export function getKeepMeSignedIn() {
  return localStorage.getItem("keep-me-signed-in");
}

export function setKeepMeSignedIn(value) {
  localStorage.setItem("keep-me-signed-in", value);
}
