import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Tooltip from "components/CustomTooltip/Tooltip";

import { ALERT_ICON } from "constants/images";

import {
  addDeviceInQueueRequest,
  removeDeviceInQueueRequest,
} from "redux/actions/callQueue";

import ConfigureDevice from "./ConfigureDevice";

const SIP_DEVICE = ["Sip Device", "SipDevice"];

const ConfigureIcon = ({
  hasRegistered,
  handleOpenDeviceList,
  showListClassName,
}) => {
  if (!hasRegistered)
    return (
      <Tooltip
        title={<Translate value="callQueue.youAreNotLoggedIntoQueue" />}
        className={`configure-device-tooltip d-flex ${showListClassName} mt-2 bg-dark text-white fw-normal p-3`}
      >
        <span type="button" tabIndex={0} onClick={handleOpenDeviceList}>
          <img src={ALERT_ICON.SOURCE} alt="no registered device" />
        </span>
      </Tooltip>
    );

  return (
    <FontAwesomeIcon
      icon={faGear}
      className={`gear-icon ${showListClassName}`}
      onClick={handleOpenDeviceList}
    />
  );
};

const ConfigureDevices = ({ totalDevices, queueID }) => {
  const dispatch = useDispatch();
  const [openDeviceList, setOpenDeviceList] = useState(false);
  const [isTouched, setTouched] = useState(false);

  const {
    currentUser: { pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);

  const handleToggleChange = ({ isRegistered, number }) => {
    if (isRegistered) {
      dispatch(addDeviceInQueueRequest(pbxID, queueID, number));
    } else {
      dispatch(removeDeviceInQueueRequest(pbxID, queueID, number));
    }
  };

  const handleBlur = () => {
    setOpenDeviceList(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleBlur);

    return () => document.removeEventListener("click", handleBlur);
  }, [isTouched]);

  const showListClassName = openDeviceList ? "active" : "";
  const hasRegistered = totalDevices.some(
    ({ is_registered: isRegistered }) => isRegistered == true
  );

  const handleOpenDeviceList = (e) => {
    // It will cancel out the event added over click
    e.stopPropagation();
    setTouched(true);
    setOpenDeviceList((previousValue) => !previousValue);
  };

  const handleStopProbation = (e) => e.stopPropagation();

  return (
    <div className="configure-devices position-relative">
      <ConfigureIcon
        showListClassName={showListClassName}
        handleOpenDeviceList={handleOpenDeviceList}
        hasRegistered={hasRegistered}
      />
      <ul
        className={`position-absolute bg-white start-0 device-list ${showListClassName} p-2 mt-2`}
        role="list"
        onClick={handleStopProbation}
      >
        <p className="mb-3 mt-2 device-title fw-bold">
          <Translate value="home.configureYourDevices" />
        </p>
        {totalDevices.map(
          ({
            device_type: deviceType,
            is_registered: isRegistered,
            number,
            device_name: deviceName,
          }) => (
            <ConfigureDevice
              key={number}
              deviceName={deviceName}
              number={number}
              deviceType={deviceType}
              isRegistered={isRegistered}
              isSipDevice={SIP_DEVICE.includes(deviceType)}
              handleToggleChange={handleToggleChange}
              queueID={queueID}
            />
          )
        )}
      </ul>
    </div>
  );
};

ConfigureIcon.defaultProps = {
  hasRegistered: false,
  handleOpenDeviceList: () => {},
  showListClassName: "",
};

ConfigureIcon.propTypes = {
  hasRegistered: PropTypes.bool,
  handleOpenDeviceList: PropTypes.func,
  showListClassName: PropTypes.string,
};

ConfigureDevices.defaultProps = {
  totalDevices: [],
  queueID: "",
  isTotalDevicesLoading: false,
};

ConfigureDevices.propTypes = {
  totalDevices: PropTypes.shape([]),
  queueID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isTotalDevicesLoading: PropTypes.bool,
};

export default React.memo(ConfigureDevices);
