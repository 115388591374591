import React from "react";

import PropTypes from "prop-types";

import CallQueueItemSkeleton from "components/Skeleton/CallQueue/Detail/CallQueueItemSkeleton";
import ContactDetailSkeleton from "components/Skeleton/Contacts/ContactDetail/ContactDetailSkeleton";

import { isEmpty } from "utils/isEmpty";

import ColleaguesDetail from "./ContactDetail/ContactDetail";
import CallQueueDashboard from "./QueueDashboard/CallQueueDashboard";

const HomeDetail = ({
  contactDetailLoading,
  contactDetailData,
  setActiveContact,
  isLoading,
  queueClassName,
}) => {
  if (isLoading) {
    return (
      <div className="call-queue-dashboard row ms-md-2 pe-lg-2 ms-lg-3 pe-lg-3 overflow-auto g-4">
        <CallQueueItemSkeleton />
        <CallQueueItemSkeleton />
      </div>
    );
  }

  if (contactDetailLoading) {
    return <ContactDetailSkeleton containerClassName="" />;
  }

  if (!isEmpty(contactDetailData)) {
    return <ColleaguesDetail setActiveContact={setActiveContact} />;
  }

  return <CallQueueDashboard containerClassName={queueClassName} />;
};

HomeDetail.propTypes = {
  contactDetailLoading: PropTypes.bool.isRequired,
  contactDetailData: PropTypes.shape({}).isRequired,
  setActiveContact: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  queueClassName: PropTypes.string.isRequired,
};

export default HomeDetail;
