import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Translate, I18n } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import FormWrapper from "components/Form/FormWrapper";
import PasswordField from "components/Form/PasswordField/PasswordField";

import { displayError } from "utils/toaster";
import { useQuery } from "utils/useQuery";
import {
  checkPasswordMatch,
  composeValidators,
  minValuePassword,
  required,
} from "utils/validation";

import {
  resetPasswordClearErrors,
  setPasswordRequest,
} from "redux/actions/auth";

const NewPassword = ({ onPasswordNotification }) => {
  const dispatch = useDispatch();
  const { token } = useQuery();

  const [onSubmit, setOnSubmit] = useState(false);

  const { commitError, commitSuccess, isLoading } = useSelector(
    ({ auth }) => auth
  );

  const onSubmitHandler = ({ newPassword, confirmPassword }) => {
    setOnSubmit(true);
    dispatch(setPasswordRequest(newPassword, confirmPassword, token));
  };

  useEffect(() => {
    if (commitSuccess && onSubmit) {
      onPasswordNotification();
    }
  }, [commitSuccess]);

  useEffect(() => {
    if (commitError) {
      displayError(I18n.t(commitError));
      setTimeout(() => {
        dispatch(resetPasswordClearErrors());
      }, 3000);
    }
  }, [commitError]);

  return (
    <div className="new-password pt-3 w-100">
      <h4 className="login-header mb-4">
        <Translate value="auth.newPassword.setNewPassword" />
      </h4>
      <FormWrapper
        onSubmitHandler={onSubmitHandler}
        validation={(values) => checkPasswordMatch(values)}
        isLoading={isLoading}
        showCancelButton={false}
        submitClassName="btn btn-primary w-100 my-3 new-password-button"
        submitText="auth.newPassword.setPassword"
      >
        <div className="mb-3 w-100 position-relative">
          <Field
            name="newPassword"
            validate={composeValidators(
              required("validationErrors.INVALID_PASSWORD"),
              minValuePassword(8)
            )}
            component={PasswordField}
            placeholderTranslation="attributes.newPassword"
            labelTranslation="attributes.newPassword"
          />
        </div>
        <div className="mb-3 pb-1 w-100 position-relative">
          <Field
            name="confirmPassword"
            validate={composeValidators(
              required("validationErrors.INVALID_PASSWORD"),
              minValuePassword(8)
            )}
            component={PasswordField}
            placeholderTranslation="attributes.confirmNewPassword"
            labelTranslation="attributes.confirmNewPassword"
          />
        </div>
      </FormWrapper>
    </div>
  );
};

NewPassword.defaultProps = {
  onPasswordNotification: () => {},
};

NewPassword.propTypes = {
  onPasswordNotification: PropTypes.func,
};

export default NewPassword;
