import React, { createContext, useState } from "react";

import PropTypes from "prop-types";

export const AutomaticCallProfileContext = createContext(null);

const AutomaticCallProfileProvider = ({ children }) => {
  const [isAutomaticCallProfileEnable, setAutomaticCallProfile] =
    useState(false);
  const [showFixedCalendar, setShowFixedCalendar] = useState(false);

  return (
    <AutomaticCallProfileContext.Provider
      value={{
        isAutomaticCallProfileEnable,
        setAutomaticCallProfile,
        showFixedCalendar,
        setShowFixedCalendar,
      }}
    >
      {children}
    </AutomaticCallProfileContext.Provider>
  );
};

AutomaticCallProfileProvider.defaultProps = {
  children: null,
};

AutomaticCallProfileProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
};

export default AutomaticCallProfileProvider;
