import { Form } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";

const FormWrapper = ({
  validation,
  onSubmitHandler,
  handleCancel,
  showCancelButton,
  isLoading,
  children,
  submitText,
  cancelText,
  submitClassName,
  cancelClassName,
  submitContainerClassName,
  formClassName,
  submitCallBack,
  ...rest
}) => {
  const cancelButton = showCancelButton && (
    <button
      data-testid="cancel-button"
      data-cy="cancel-button"
      className={`edit-button px-5 me-md-3 ${cancelClassName}`}
      onClick={handleCancel}
      type="button"
    >
      <Translate value={cancelText} />
    </button>
  );

  const loader = (submitting) => {
    if (submitting || isLoading) {
      return <MovingDottedLoader />;
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        onSubmitHandler(e);
      }}
      validate={(values) => validation(values)}
      {...rest}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError, pristine }) => (
        <form
          className={`w-100 needs-validation form-control border-0 ${formClassName}`}
          onSubmit={(e) => {
            handleSubmit(e);
            submitCallBack();
          }}
        >
          {children}
          {submitError && (
            <p data-testid="submit-error" className="error">
              {submitError}
            </p>
          )}
          <div
            className={`d-flex flex-column flex-md-row ${submitContainerClassName}`}
          >
            {cancelButton}
            <button
              type="submit"
              className={submitClassName}
              disabled={submitting || pristine || isLoading}
              role="button"
              data-cy="submit"
              data-testid="submit-button"
            >
              {loader(submitting)}
              <Translate value={submitText} />
            </button>
          </div>
        </form>
      )}
    </Form>
  );
};

FormWrapper.propTypes = {
  validation: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  submitClassName: PropTypes.string,
  cancelClassName: PropTypes.string,
  submitContainerClassName: PropTypes.string,
  formClassName: PropTypes.string,
  resetForm: PropTypes.bool,
  submitCallBack: PropTypes.func,
};

FormWrapper.defaultProps = {
  showCancelButton: true,
  validation: () => {},
  isLoading: false,
  children: <></>,
  handleCancel: () => {},
  onSubmitHandler: () => {},
  submitText: "common.submit",
  cancelText: "common.cancel",
  submitClassName: "btn btn-primary my-3",
  cancelClassName: "btn btn-outline-secondary my-md-3",
  submitContainerClassName: "",
  formClassName: "",
  resetForm: false,
  submitCallBack: () => {},
};

export default FormWrapper;
