export const downloadAudio = (audio, name) => {
  const blob = new Blob([audio]);
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = `${name}.mp3`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formattedAudio = (audio) => `data:audio/mp3;base64,${audio}`;
