import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { AutomaticCallProfileContext } from "context-api/AutomaticCallProfileContext";

import { isEmpty } from "utils/isEmpty";
import { displaySuccess } from "utils/toaster";
import { setCurrentUser } from "utils/utils";

import { updateAutomaticCallProfileRequest } from "redux/actions/callProfile";

const AutomaticCallProfileToggler = () => {
  const { isAutomaticCallProfileEnable, setAutomaticCallProfile } = useContext(
    AutomaticCallProfileContext
  );

  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  const handleAutomaticCallProfileToggle = () => {
    if (!isAutomaticCallProfileEnable) {
      // Added timeout to add a small delay for toaster to appear.
      setTimeout(() => {
        displaySuccess("message.callProfile.enablingAutomaticProfileSwitching");
      }, 500);
    }

    dispatch(
      updateAutomaticCallProfileRequest(
        userLicenseID,
        !isAutomaticCallProfileEnable,
        (data) => {
          const {
            is_automatic_call_profile_switching_enabled: isAutomaticCallProfile,
            active_call_profile: callProfile,
          } = data;

          if (!isEmpty(callProfile)) {
            setCurrentUser(callProfile, "active_call_profile");
          }

          setCurrentUser(
            isAutomaticCallProfile,
            "is_automatic_call_profile_switching_enabled"
          );
        }
      )
    );
    setAutomaticCallProfile((prev) => !prev);
  };

  return (
    <div
      className="btn btn-outline-secondary form-check form-switch automatic-call-profile-toggler d-flex me-md-2"
      onClick={handleAutomaticCallProfileToggle}
      type="button"
      data-testid="call-profile-toggle"
    >
      <span
        className="me-2 toggler-text text-nowrap"
        data-testid="switch-profile-text"
      >
        <Translate value="callProfile.switchProfileAutomatically" />
      </span>
      <input
        className="form-check-input ms-2"
        type="checkbox"
        role="switch"
        checked={isAutomaticCallProfileEnable}
      />
    </div>
  );
};

export default AutomaticCallProfileToggler;
