import {
  FETCH_CALL_QUEUES_ERROR,
  FETCH_CALL_QUEUES_RECEIVED,
  FETCH_CALL_QUEUES_REQUEST,
  FETCH_REGISTERED_AGENTS_REQUEST,
  FETCH_REGISTERED_AGENTS_RECEIVED,
  FETCH_REGISTERED_AGENTS_FAILED,
  FETCH_AVAILABLE_AGENTS_IN_QUEUE_REQUEST,
  FETCH_AVAILABLE_AGENTS_IN_QUEUE_RECEIVED,
  FETCH_AVAILABLE_AGENTS_IN_QUEUE_FAILED,
  FETCH_REGISTERED_DEVICES_IN_QUEUE_REQUEST,
  FETCH_REGISTERED_DEVICES_IN_QUEUE_RECEIVED,
  FETCH_REGISTERED_DEVICES_IN_QUEUE_FAILED,
  FETCH_CALL_QUEUE_STATISTICS_FAILED,
  FETCH_CALL_QUEUE_STATISTICS_RECEIVED,
  FETCH_CALL_QUEUE_STATISTICS_REQUEST,
  FETCH_TOTAL_CALLS_IN_QUEUE_REQUEST,
  FETCH_TOTAL_CALLS_IN_QUEUE_RECEIVED,
  FETCH_TOTAL_CALLS_IN_QUEUE_FAILED,
  UPDATE_AGENTS_REQUEST,
  UPDATE_AGENTS_RECEIVED,
  UPDATE_AGENTS_FAILED,
  FETCH_DEVICES_IN_QUEUE_REQUEST,
  FETCH_DEVICES_IN_QUEUE_FAILED,
  FETCH_DEVICES_IN_QUEUE_RECEIVED,
  ADD_DEVICE_NUMBER_IN_QUEUE_REQUEST,
  ADD_DEVICE_NUMBER_IN_QUEUE_RECEIVED,
  ADD_DEVICE_NUMBER_IN_QUEUE_FAILED,
  REMOVE_DEVICE_NUMBER_IN_QUEUE_REQUEST,
  REMOVE_DEVICE_NUMBER_IN_QUEUE_RECEIVED,
  REMOVE_DEVICE_NUMBER_IN_QUEUE_FAILED,
  ADD_CALL_TO_QUEUE,
  UPDATE_STATUS_OF_REGISTERED_AGENTS_QUEUE,
  REMOVE_CALL_FROM_QUEUE,
  CLEAR_CALL_QUEUE_ERRORS,
  UPDATE_CALL_QUEUE_STATISTICS,
} from "redux/actions/callQueue";

const updateCallQueues = (actionID, callQueues, attributes) => {
  const indexOfCallQueue = callQueues.findIndex(
    ({ asterisk_queue_id: id }) => id == actionID
  );
  const updatedCallQueues = [...callQueues];

  updatedCallQueues[indexOfCallQueue] = {
    ...callQueues[indexOfCallQueue],
    ...attributes,
  };

  return updatedCallQueues;
};

const individualCallQueue = {
  isSearching: false,
  isAvailableAgentSearching: false,
  registeredAgents: [],
  isProcessing: true,
  availableAgents: [],
  isAvailableAgentsLoading: true,
  registeredAgentsDevices: [],
  isRegisteredAgentLoading: true,
  isRegisteredAgentSearching: false,
  callQueueStatistic: {},
  isStatisticsLoading: true,
  totalCalls: [],
  isTotalCallsLoading: true,
  totalDevices: [],
  isTotalDevicesLoading: true,
  commitSuccess: "",
};

const defaultCallQueueState = {
  isLoading: true,
  callQueues: [],
  error: "",
  isSubmitting: false,
};

function callQueue(state = defaultCallQueueState, action) {
  switch (action.type) {
    case FETCH_CALL_QUEUES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_CALL_QUEUES_RECEIVED:
      return {
        ...state,
        isLoading: false,
        callQueues: action.data.map((callQueue) => ({
          ...callQueue,
          ...individualCallQueue,
        })),
      };

    case FETCH_CALL_QUEUES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_REGISTERED_AGENTS_REQUEST:
      return {
        ...state,
        error: "",
        callQueues: updateCallQueues(action.id, state.callQueues, {
          commitSuccess: "",
          isProcessing: true,
        }),
      };

    case FETCH_REGISTERED_AGENTS_RECEIVED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isProcessing: false,
          registeredAgents: action.data.registeredAgents,
          commitSuccess: "",
        }),
      };

    case FETCH_REGISTERED_AGENTS_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isProcessing: false,
        }),
        error: action.data.error,
      };

    case FETCH_AVAILABLE_AGENTS_IN_QUEUE_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isAvailableAgentsLoading: true,
          commitSuccess: "",
        }),
        error: "",
      };

    case FETCH_AVAILABLE_AGENTS_IN_QUEUE_RECEIVED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isAvailableAgentsLoading: false,
          availableAgents: action.data.availableAgents,
        }),
      };

    case FETCH_AVAILABLE_AGENTS_IN_QUEUE_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isAvailableAgentsLoading: false,
        }),
        error: action.data.error,
      };

    case FETCH_REGISTERED_DEVICES_IN_QUEUE_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isRegisteredAgentLoading: true,
          commitSuccess: "",
        }),
        error: "",
      };

    case FETCH_REGISTERED_DEVICES_IN_QUEUE_RECEIVED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isRegisteredAgentLoading: false,
          registeredAgentsDevices: action.data.registeredAgents,
        }),
      };

    case FETCH_REGISTERED_DEVICES_IN_QUEUE_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isRegisteredAgentLoading: false,
        }),
        error: action.data.error,
      };

    case CLEAR_CALL_QUEUE_ERRORS:
      return {
        ...state,
        error: "",
      };

    case UPDATE_AGENTS_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        error: "",
        callQueues: updateCallQueues(action.id, state.callQueues, {
          commitSuccess: "",
        }),
      };

    case UPDATE_AGENTS_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          commitSuccess: "message.callQueue.agentManagementUpdatedSuccessfully",
        }),
      };

    case UPDATE_AGENTS_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.data.error,
      };

    case FETCH_CALL_QUEUE_STATISTICS_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isStatisticsLoading: true,
        }),
        error: "",
      };

    case FETCH_CALL_QUEUE_STATISTICS_RECEIVED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.queue_id, state.callQueues, {
          isStatisticsLoading: false,
          callQueueStatistic: action.data,
        }),
      };

    case FETCH_CALL_QUEUE_STATISTICS_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isStatisticsLoading: false,
        }),
        error: action.data.error,
      };

    case FETCH_TOTAL_CALLS_IN_QUEUE_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isTotalCallsLoading: true,
        }),
        error: "",
      };

    case FETCH_TOTAL_CALLS_IN_QUEUE_RECEIVED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isTotalCallsLoading: false,
          totalCalls: action.data.totalCalls,
        }),
      };

    case FETCH_TOTAL_CALLS_IN_QUEUE_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isTotalCallsLoading: false,
        }),
        error: action.data.error,
      };

    case FETCH_DEVICES_IN_QUEUE_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isTotalDevicesLoading: true,
        }),
        error: "",
      };

    case FETCH_DEVICES_IN_QUEUE_RECEIVED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isTotalDevicesLoading: false,
          totalDevices: action.data.totalDevices,
        }),
      };

    case FETCH_DEVICES_IN_QUEUE_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isTotalDevicesLoading: false,
        }),
        error: action.data.error,
      };

    case ADD_DEVICE_NUMBER_IN_QUEUE_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isTotalDevicesLoading: true,
          commitSuccess: "",
        }),
        error: "",
      };

    case ADD_DEVICE_NUMBER_IN_QUEUE_RECEIVED:
      const previousStateInQueue = state.callQueues.find(
        ({ asterisk_queue_id: id }) => id == action.data.id
      );

      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          totalDevices: previousStateInQueue.totalDevices.map(
            ({ number, ...rest }) => {
              if (number == action.data.device) {
                return { ...rest, number, is_registered: true };
              }

              return { ...rest, number };
            }
          ),
          isTotalDevicesLoading: false,
          commitSuccess: "message.callQueue.addedDeviceInQueue",
        }),
      };

    case ADD_DEVICE_NUMBER_IN_QUEUE_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isTotalDevicesLoading: false,
        }),
        error: action.data.error,
      };

    case REMOVE_DEVICE_NUMBER_IN_QUEUE_REQUEST:
      return {
        ...state,
        callQueues: updateCallQueues(action.id, state.callQueues, {
          isTotalDevicesLoading: true,
          commitSuccess: "",
        }),
        error: "",
      };

    case REMOVE_DEVICE_NUMBER_IN_QUEUE_RECEIVED:
      const removePreviousStateInQueue = state.callQueues.find(
        ({ asterisk_queue_id: id }) => id == action.data.id
      );

      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          totalDevices: removePreviousStateInQueue.totalDevices.map(
            ({ number, ...rest }) => {
              if (number == action.data.device) {
                return { ...rest, number, is_registered: false };
              }

              return { ...rest, number };
            }
          ),
          isTotalDevicesLoading: false,
          commitSuccess: "message.callQueue.removeDeviceFromQueue",
        }),
      };

    case REMOVE_DEVICE_NUMBER_IN_QUEUE_FAILED:
      return {
        ...state,
        callQueues: updateCallQueues(action.data.id, state.callQueues, {
          isTotalDevicesLoading: false,
        }),
        error: action.data.error,
      };

    case ADD_CALL_TO_QUEUE:
      const previousState = state.callQueues.find(
        ({ asterisk_queue_id: id }) => id == action.data.queue_id
      );
      const totalCallsInQueue = [...previousState.totalCalls, action.data];
      const uniqueTotalCallsInQueue = [
        ...new Map(
          totalCallsInQueue.map((call) => [call["channel_name"], call])
        ).values(),
      ];

      return {
        ...state,
        callQueues: updateCallQueues(
          Number(action.data.queue_id),
          state.callQueues,
          {
            totalCalls: uniqueTotalCallsInQueue,
            callQueueStatistic: {
              ...previousState.callQueueStatistic,
              total_calls:
                Number(previousState.callQueueStatistic.total_calls ?? 0) + 1,
            },
          }
        ),
      };

    case REMOVE_CALL_FROM_QUEUE:
      const previousStateRemove = state.callQueues.find(
        ({ asterisk_queue_id: id }) => id == action.data.queue_id
      );

      const updatedQueue = previousStateRemove.totalCalls.filter(
        (call) => call.channel !== action.data.channel
      );

      const totalCalls =
        Number(previousStateRemove.callQueueStatistic.total_calls) <= 1
          ? 0
          : Number(previousStateRemove.callQueueStatistic.total_calls) - 1;

      return {
        ...state,
        callQueues: updateCallQueues(action.data.queue_id, state.callQueues, {
          totalCalls: updatedQueue,
          callQueueStatistic: {
            ...previousStateRemove.callQueueStatistic,
            total_calls: totalCalls,
          },
        }),
      };

    case UPDATE_STATUS_OF_REGISTERED_AGENTS_QUEUE:
      const { extensionNumber, status } = action.data;
      const updatedCallQueues = [...state.callQueues];

      const update = updatedCallQueues.map((callQueue) => {
        const registeredAgentIndex = callQueue.registeredAgents.findIndex(
          (element) => element.extension_number == extensionNumber
        );

        if (registeredAgentIndex >= 0) {
          const registeredAgents = [...callQueue.registeredAgents];

          registeredAgents[registeredAgentIndex].status = status;

          return {
            ...callQueue,
            registeredAgents,
          };
        } else {
          return callQueue;
        }
      });

      return {
        ...state,
        callQueues: update,
      };

    case UPDATE_CALL_QUEUE_STATISTICS:
      const {
        missed_calls: missedCalls,
        average_waiting_time_in_seconds: averageWaitingTime,
        queue_id: queueID,
        answered_calls: answerCalls,
      } = action.data;

      const previousStateCallStatistics = state.callQueues.find(
        ({ asterisk_queue_id: id }) => id == queue_id
      );

      return {
        ...state,
        callQueues: updateCallQueues(action.data.queue_id, state.callQueues, {
          callQueueStatistic: {
            ...previousStateCallStatistics.callQueueStatistic,
            missed_calls: missedCalls,
            average_waiting_time_in_seconds: averageWaitingTime,
            queue_id: queueID,
            answered_calls: answerCalls,
          },
        }),
      };

    default:
      return state;
  }
}

export default callQueue;
