import { I18n, I18nReRender } from "react-thunk-i18nify";

import { EMAIL_REGEX } from "constants/regex";

export const MINIMUM_PASSWORD_LENGTH = 8;

export const emailValidation = (value) => {
  if (!value) {
    return (
      <I18nReRender
        render={() => I18n.t("validationErrors.EMPTY_EMAIL_ADDRESS")}
      />
    );
  } else if (EMAIL_REGEX.test(value)) {
    return undefined;
  } else {
    return (
      <I18nReRender
        render={() => I18n.t("validationErrors.INVALID_EMAIL_ADDRESS")}
      />
    );
  }
};

export const validation = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = (
      <I18nReRender
        render={() => I18n.t("validationErrors.INVALID_EMAIL_ADDRESS")}
      />
    );
  }
  if (!values.password) {
    errors.password = (
      <I18nReRender
        render={() => I18n.t("validationErrors.INVALID_PASSWORD")}
      />
    );
  }

  return errors;
};

export const checkPasswordMatch = (values) => {
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = (
      <I18nReRender
        render={() => I18n.t("validationErrors.INVALID_PASSWORD")}
      />
    );
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = (
      <I18nReRender
        render={() => I18n.t("validationErrors.INVALID_PASSWORD")}
      />
    );
  }

  if (values.newPassword != values.confirmPassword) {
    errors.newPassword = (
      <I18nReRender render={() => I18n.t("validationErrors.IDENTICAL")} />
    );

    errors.confirmPassword = (
      <I18nReRender render={() => I18n.t("validationErrors.IDENTICAL")} />
    );
  }

  return errors;
};

export const required = (text) => (value) =>
  value ? undefined : <I18nReRender render={() => I18n.t(text)} />;

export const externalNumberValidation = (value) => {
  if (!value) return I18n.t("validationErrors.REQUIRED");

  // ERROR: Issue #1 - CONSTANT is unable to recognize the regex
  if (value && !/^[\d()\+]+$/i.test(value)) {
    return I18n.t("validationErrors.ONLY_NUMBER_AND_PLUS");
  } else {
    const remainingCharacters = value.substring(1);

    if (remainingCharacters.indexOf("+") >= 0) {
      return I18n.t("validationErrors.ONLY_NUMBER_AND_PLUS");
    }

    return undefined;
  }
};

export const requiredArray = (text) => (value) =>
  value?.length > 0 ? undefined : <I18nReRender render={() => I18n.t(text)} />;

export const validateProfile = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = (
      <I18nReRender
        render={() => I18n.t("validationErrors.EMPTY_FIRST_NAME")}
      />
    );
  }
  if (!values.lastName) {
    errors.lastName = (
      <I18nReRender render={() => I18n.t("validationErrors.EMPTY_LAST_NAME")} />
    );
  }

  return errors;
};

export const validateNewPassword = (values, min) => {
  const errors = {};

  if (values?.currentPassword?.length <= min || !values.currentPassword) {
    errors.currentPassword = (
      <I18nReRender
        render={() =>
          `${I18n.t("validationErrors.SMALL_PASSWORD_LENGTH")} ${min}`
        }
      />
    );
  }

  if (values?.newPassword?.length <= min || !values.newPassword) {
    errors.newPassword = (
      <I18nReRender
        render={() =>
          `${I18n.t("validationErrors.SMALL_PASSWORD_LENGTH")} ${min}`
        }
      />
    );
  }

  if (values?.confirmPassword?.length <= min || !values.confirmPassword) {
    errors.confirmPassword = (
      <I18nReRender
        render={() =>
          `${I18n.t("validationErrors.SMALL_PASSWORD_LENGTH")} ${min}`
        }
      />
    );
  } else if (values?.newPassword != values.confirmPassword) {
    errors.confirmPassword = (
      <I18nReRender render={() => I18n.t("validationErrors.IDENTICAL")} />
    );
  }

  if (values?.currentPassword === values.newPassword) {
    errors.newPassword = (
      <I18nReRender
        render={() => I18n.t("validationErrors.SAME_WITH_CURRENT_PASSWORD")}
      />
    );
  }

  return errors;
};

export const validatePassword = (min) => (value) => {
  if (!value)
    return (
      <I18nReRender
        render={() => `${I18n.t("validationErrors.EMPTY_PASSWORD")}`}
      />
    );

  return value.length >= min ? undefined : (
    <I18nReRender
      render={() =>
        `${I18n.t("validationErrors.SMALL_PASSWORD_LENGTH")} ${min}`
      }
    />
  );
};

export const minValuePassword = (min) => (value) =>
  value?.length >= min ? undefined : (
    <I18nReRender
      render={() =>
        `${I18n.t("validationErrors.SMALL_PASSWORD_LENGTH")} ${min}`
      }
    />
  );

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const validateCallProfile = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  if (!values.description) {
    errors.description = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  if (!values.icon) {
    errors.icon = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  return errors;
};

export const number = (value) =>
  // eslint-disable-next-line no-restricted-globals
  value && isNaN(Number(value))
    ? I18n.t("validationErrors.NOT_A_NUMBER")
    : undefined;

//voicemail box form
export const validateVoicemailBox = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  if (!values.password) {
    errors.password = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  if (!values.maxmsg) {
    errors.maxmsg = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  if (!values.minsecs) {
    errors.minsecs = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  if (!values.maxsecs) {
    errors.maxsecs = (
      <I18nReRender render={() => I18n.t("validationErrors.REQUIRED")} />
    );
  }

  return errors;
};
