import { parseISO } from "date-fns";

import { ELEMENT_TYPE } from "utils/fixedCalendarFormatter";

export const TIME_STAMP = {
  START: "start",
  END: "end",
};

/**
 * Function will return the sorted data based on the start time
 * @param {Array} timeStamps - Array of objects with start and end time
 */
const sortTimeFrames = (timeStamps) =>
  timeStamps.sort((a, b) => {
    const startTimeOfFirstFrame = new Date(a.start);
    const startTimeOfSecondFrame = new Date(b.start);
    const firstTimeFrameStart =
      startTimeOfFirstFrame.getHours() * 60 +
      startTimeOfFirstFrame.getMinutes();
    const secondTimeFrameStart =
      startTimeOfSecondFrame.getHours() * 60 +
      startTimeOfSecondFrame.getMinutes();

    if (firstTimeFrameStart <= secondTimeFrameStart) {
      return -1;
    }

    return 1;
  });

/**
 * Function will return the date in minutes format
 * @param {date} date
 */

export const calculateTotalMinutes = (date) => {
  const parseDate = parseISO(date);
  const hours = parseDate.getHours();
  const minutes = parseDate.getMinutes();

  return hours * 60 + minutes;
};

/**
 * function will return the sorted data based on the start time, auto adjust time add default profile
 * @param {array} timeStamp - Array of objects with start and end time
 * @param {object} selectedDay - Selected day for which data is required
 * @param {*} profileID - Profile id where we have profile id of Work and Off Hours
 * @param {*} edited - Boolean to check if data is edited or not
 * @param {*} editedCallBack - Callback function to call when we need to reset edited flag
 * @param {*} deleteTimeStampCallBack  - Callback function to call when data is deleted
 * @returns
 */
export const timeStampFormatter = (
  timeStamp,
  edited,
  editedCallBack,
  deleteTimeStampCallBack
) => {
  const sortedData = JSON.parse(JSON.stringify(sortTimeFrames(timeStamp)));

  // auto adjust time frame.
  for (let i = 0; i < sortedData.length - 1; i++) {
    let firstTimeFrameStartTime = calculateTotalMinutes(sortedData[i].start);
    let firstTimeFrameEndTime = calculateTotalMinutes(sortedData[i].end);
    let secondTimeFrameStartTime = calculateTotalMinutes(
      sortedData[i + 1].start
    );
    let secondTimeFrameEndTime = calculateTotalMinutes(sortedData[i + 1].end);
    let negativeLoop = false;

    let isSecondTimeFrameLiesBetweenFirstFrame =
      firstTimeFrameStartTime < secondTimeFrameStartTime &&
      firstTimeFrameEndTime > secondTimeFrameStartTime;

    if (
      firstTimeFrameStartTime == secondTimeFrameStartTime &&
      firstTimeFrameEndTime == secondTimeFrameEndTime
    ) {
      deleteOverlappingSameTimeFrame(sortedData, i, deleteTimeStampCallBack);
      if (i > 0) i--;
    } else if (
      firstTimeFrameStartTime <= secondTimeFrameStartTime &&
      firstTimeFrameEndTime > secondTimeFrameEndTime
    ) {
      if (sortedData[i + 1].timeSortingRemaining) {
        addNewTimeFrameIfTimeFrameNeedToDivide(sortedData, i);
      } else {
        updateTimeFrameIfSecondTimeFrameIsWithinFirstTimeFrame(
          sortedData,
          i,
          deleteTimeStampCallBack
        );

        if (i > 0) i--;
      }
    } else if (
      firstTimeFrameEndTime > secondTimeFrameStartTime &&
      secondTimeFrameEndTime > firstTimeFrameEndTime &&
      isSecondTimeFrameLiesBetweenFirstFrame
    ) {
      updateTimeFramesIfSecondStartTimeIsWithinFirstAndEndIsSmaller(
        sortedData,
        i
      );
    }

    isSecondTimeFrameLiesBetweenFirstFrame =
      firstTimeFrameStartTime < secondTimeFrameStartTime &&
      firstTimeFrameEndTime > secondTimeFrameStartTime;

    firstTimeFrameStartTime = calculateTotalMinutes(sortedData[i].start);
    firstTimeFrameEndTime = calculateTotalMinutes(sortedData[i].end);
    secondTimeFrameStartTime = calculateTotalMinutes(sortedData[i + 1].start);
    secondTimeFrameEndTime = calculateTotalMinutes(sortedData[i + 1].end);

    if (
      firstTimeFrameEndTime > secondTimeFrameStartTime ||
      (firstTimeFrameEndTime < secondTimeFrameStartTime &&
        isSecondTimeFrameLiesBetweenFirstFrame)
    ) {
      resetTimeFrames(edited, sortedData, i, editedCallBack);
    }

    const firstTimeFrameProfile = sortedData[i].profileID;
    const secondTimeFrameProfile = sortedData[i + 1].profileID;

    firstTimeFrameStartTime = calculateTotalMinutes(sortedData[i].start);
    firstTimeFrameEndTime = calculateTotalMinutes(sortedData[i].end);
    secondTimeFrameStartTime = calculateTotalMinutes(sortedData[i + 1].start);
    secondTimeFrameEndTime = calculateTotalMinutes(sortedData[i + 1].end);

    if (
      (firstTimeFrameProfile == secondTimeFrameProfile &&
        ((firstTimeFrameStartTime < secondTimeFrameStartTime &&
          firstTimeFrameEndTime > secondTimeFrameStartTime) ||
          firstTimeFrameEndTime === secondTimeFrameStartTime)) ||
      firstTimeFrameStartTime == firstTimeFrameEndTime
    ) {
      removeExtraTimeFrameProfile(sortedData, i, deleteTimeStampCallBack);
      if (i > 0) {
        i--;
      } else {
        negativeLoop = true;
      }
    } else if (secondTimeFrameStartTime == secondTimeFrameEndTime) {
      removeTimeFrameWhenStartAndEndTimeIsSame(
        sortedData,
        i,
        deleteTimeStampCallBack
      );

      if (i > 0) {
        i--;
      } else {
        negativeLoop = true;
      }
    }

    if (sortedData[i]?.elementType) {
      sortedData[i].timeSortingRemaining = false;
    }

    if (sortedData[i + 1]?.elementType) {
      sortedData[i + 1].timeSortingRemaining = false;
    }

    i = negativeLoop ? -1 : i;
  }

  return sortedData;
};

/**
 * Removes a time frame when the start and end times are the same.
 * Deletes the time frame if it is not a newly added element or if the start and end times are identical.
 *
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} i - The index of the time frame in the sorted data array.
 * @param {Function} deleteTimeStampCallBack - A callback function used to delete time frames.
 */
function removeTimeFrameWhenStartAndEndTimeIsSame(
  sortedData,
  i,
  deleteTimeStampCallBack
) {
  if (
    sortedData[i].elementType !== ELEMENT_TYPE.NEWLY_ADDED ||
    firstTimeFrameStartTime == firstTimeFrameEndTime
  ) {
    const { id: deleteTimeFrameID, end, start, profileID } = sortedData[i + 1];

    // Delete the time frame using the provided callback function
    deleteTimeStampCallBack({
      id: deleteTimeFrameID,
      start_time: start,
      end_time: end,
      call_profile_id: profileID,
    });
  }

  // Remove the time frame from the sorted data
  sortedData.splice(i + 1, 1);
}

/**
 * Removes an extra time frame when consecutive time frames have the same profile ID.
 * Deletes the time frame if it is not a newly added element.
 *
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} i - The index of the time frame in the sorted data array.
 * @param {Function} deleteTimeStampCallBack - A callback function used to delete time frames.
 */
function removeExtraTimeFrameProfile(sortedData, i, deleteTimeStampCallBack) {
  if (sortedData[i].elementType !== ELEMENT_TYPE.NEWLY_ADDED) {
    const { id: deleteTimeFrameID, end, start, profileID } = sortedData[i];

    // Delete the time frame using the provided callback function
    deleteTimeStampCallBack({
      id: deleteTimeFrameID,
      start_time: start,
      end_time: end,
      call_profile_id: profileID,
    });
  }

  // Adjust the start time of the next time frame
  sortedData[i + 1].start = sortedData[i].start;

  // Remove the time frame from the sorted data
  sortedData.splice(i, 1);
}

/**
 * Resets the time frames based on the edited type and updates the sorted data accordingly.
 *
 * @param {Object} edited - An object representing the edited time stamp, containing 'id' and 'type' properties.
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} i - The index of the time frame in the sorted data array.
 * @param {Function} editedCallBack - A callback function used to signal that the time frames have been reset.
 */
function resetTimeFrames(edited, sortedData, i, editedCallBack) {
  if (edited.type === TIME_STAMP.START) {
    sortedData[i].end = sortedData[i + 1].start;

    if (sortedData[i].elementType === ELEMENT_TYPE.FETCH_ELEMENT) {
      sortedData[i].elementType = ELEMENT_TYPE.UPDATE_ELEMENT;
    }
  } else if (sortedData[i].id === edited.id && edited.type === TIME_STAMP.END) {
    sortedData[i + 1].start = sortedData[i].end;

    if (sortedData[i + 1].elementType === ELEMENT_TYPE.FETCH_ELEMENT) {
      sortedData[i + 1].elementType = ELEMENT_TYPE.UPDATE_ELEMENT;
    }
  } else {
    if (sortedData[i].elementType === ELEMENT_TYPE.NEWLY_ADDED) {
      sortedData[i + 1].start = sortedData[i].end;
    } else if (sortedData[i + 1].elementType === ELEMENT_TYPE.NEWLY_ADDED) {
      sortedData[i].end = sortedData[i + 1].start;
    }
  }
  editedCallBack();
}

/**
 * Updates the time frames if the start time of the second time frame falls within the duration of the first time frame and the end time of the first time frame is smaller.
 *
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} i - The index of the first time frame in the sorted data array.
 */
function updateTimeFramesIfSecondStartTimeIsWithinFirstAndEndIsSmaller(
  sortedData,
  i
) {
  if (sortedData[i].elementType === ELEMENT_TYPE.NEWLY_ADDED) {
    sortedData[i + 1].start = sortedData[i].end;
  } else if (sortedData[i + 1].elementType === ELEMENT_TYPE.NEWLY_ADDED) {
    sortedData[i].end = sortedData[i + 1].start;
  }

  if (sortedData[i + 1].elementType === ELEMENT_TYPE.FETCH_ELEMENT) {
    sortedData[i + 1].elementType = ELEMENT_TYPE.UPDATE_ELEMENT;
  }
}

/**
 * Deletes the overlapping time frame if two consecutive time frames have the same start and end times.
 *
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} index - The index of the overlapping time frame in the sorted data array.
 * @param {Function} deleteTimeStampCallBack - A callback function used to delete time frames.
 */
function deleteOverlappingSameTimeFrame(
  sortedData,
  index,
  deleteTimeStampCallBack
) {
  // Determine which time frame to delete based on the elementType
  const timeFrameToDelete =
    sortedData[index].elementType !== ELEMENT_TYPE.NEWLY_ADDED
      ? sortedData[index]
      : sortedData[index + 1];

  if (timeFrameToDelete.elementType !== ELEMENT_TYPE.NEWLY_ADDED) {
    // Extract details of the time frame to delete
    const { id: deleteTimeFrameID, end, start, profileID } = timeFrameToDelete;

    // Delete the time frame using the provided callback function
    deleteTimeStampCallBack({
      id: deleteTimeFrameID,
      start_time: start,
      end_time: end,
      call_profile_id: profileID,
    });
  }

  // Remove the time frame from the sorted data
  sortedData.splice(index, 1);
}

/**
 * Inserts a new time frame between the current and next time frames in the sorted data array.
 *
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} i - The index of the current time frame in the sorted data array.
 */
function addNewTimeFrameIfTimeFrameNeedToDivide(sortedData, i) {
  // Insert a new time frame after the current time frame
  sortedData.splice(i + 2, 0, {
    id: Date.now() + Math.floor(Math.random() * 10),
    start: sortedData[i + 1].end,
    end: sortedData[i].end,
    profileID: sortedData[i].profileID,
    title: sortedData[i].title,
    day: sortedData[i].day,
    timeSortingRemaining: false,
    elementType: ELEMENT_TYPE.NEWLY_ADDED,
  });

  // Update the end time of the current time frame
  sortedData[i].end = sortedData[i + 1].start;

  // Check if the next time frame is a fetched element and update its type
  if (sortedData[i + 1].elementType == ELEMENT_TYPE.FETCH_ELEMENT) {
    sortedData[i + 1].elementType = ELEMENT_TYPE.UPDATE_ELEMENT;
  }
}

/**
 * Updates the time frame if the start time of the second time frame falls within the duration of the first time frame.
 * If the second time frame is not a newly added element, it is deleted from the sorted data from original time frame.
 *
 * @param {Array} sortedData - An array of sorted time frames.
 * @param {number} i - The index of the first time frame in the sorted data array.
 * @param {Function} deleteTimeStampCallBack - A callback function used to delete time frames.
 */
function updateTimeFrameIfSecondTimeFrameIsWithinFirstTimeFrame(
  sortedData,
  i,
  deleteTimeStampCallBack
) {
  // Check if the second time frame is not a newly added element
  if (sortedData[i + 1].elementType !== ELEMENT_TYPE.NEWLY_ADDED) {
    // Extract details of the second time frame
    const { id: deleteTimeFrameID, end, start, profileID } = sortedData[i + 1];

    // Delete the second time frame using the provided callback function
    deleteTimeStampCallBack({
      id: deleteTimeFrameID,
      start_time: start,
      end_time: end,
      call_profile_id: profileID,
    });
  }

  // Remove the second time frame from the sorted data
  sortedData.splice(i + 1, 1);
}
