import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";
import Modal from "components/Modal/Modal";

import ConfirmContent from "./ConfirmContent";
import RetryContent from "./RetryContent";

const MODAL_TYPE = {
  ERROR: "error",
  CONFIRM: "confirm",
};

const ConfirmModal = ({
  isLoading,
  deleteModal,
  heading,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
  children,
  isVisible,
  error,
  retryHeader,
  retryContent,
  retryButtonText,
  headerContent,
  containerClassName,
}) => {
  const [modalType, setModalType] = useState(MODAL_TYPE.CONFIRM);

  const loader = isLoading && <MovingDottedLoader />;

  const confirmClassName = deleteModal ? "btn-danger" : "btn-primary";

  const handleOverlayClick = () => {
    if (!isLoading) {
      onCancel();
    }
  };

  const handleCancel = () => {
    onCancel();
    setModalType(null);
  };

  useEffect(() => {
    if (error) {
      setModalType(MODAL_TYPE.ERROR);
    }
  }, [error]);

  const activeModalType =
    modalType == MODAL_TYPE.ERROR ? (
      <RetryContent
        heading={retryHeader}
        content={retryContent}
        isLoading={isLoading}
        onCancel={handleCancel}
        cancelButtonText={cancelButtonText}
        retryButtonText={retryButtonText}
        retryClassName={confirmClassName}
        onConfirm={onConfirm}
        loader={loader}
        error={error}
      />
    ) : (
      <ConfirmContent
        heading={heading}
        content={children}
        isLoading={isLoading}
        onCancel={handleCancel}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        confirmClassName={confirmClassName}
        onConfirm={onConfirm}
        loader={loader}
        headerContent={headerContent}
      />
    );

  return (
    <Modal
      isVisible={isVisible}
      handleOverlayClick={handleOverlayClick}
      className={`custom-modal confirm-modal ${containerClassName} py-4 py-md-5 px-4 px-xxl-5`}
    >
      {activeModalType}
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  isLoading: false,
  deleteModal: false,
  heading: "",
  cancelButtonText: "",
  confirmButtonText: "",
  onCancel: () => {},
  onConfirm: () => {},
  isVisible: false,
  children: <></>,
  error: "",
  retryHeader: "",
  retryContent: <></>,
  retryButtonText: "",
  headerContent: null,
  containerClassName: "",
};

ConfirmModal.propTypes = {
  isLoading: PropTypes.bool,
  deleteModal: PropTypes.bool,
  heading: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.string,
  retryHeader: PropTypes.string,
  retryContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  retryButtonText: PropTypes.string,
  headerContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  containerClassName: PropTypes.string,
};

export default ConfirmModal;
