import { reducer as toastrReducer } from "react-redux-toastr";
import { i18nReducer } from "react-thunk-i18nify";
import { reducer as searchReducer } from "redux-search";

import { combineReducers } from "redux";
import auth from "redux/reducers/auth";
import callHistory from "redux/reducers/callHistory";
import callProfile from "redux/reducers/callProfile";
import callQueue from "redux/reducers/callQueue";
import changePassword from "redux/reducers/changePassword";
import configureQueue from "redux/reducers/configureQueue";
import contact from "redux/reducers/contact";
import note from "redux/reducers/note";
import user from "redux/reducers/user";
import userProfile from "redux/reducers/userProfile";
import voicemail from "redux/reducers/voicemail";
import voicemailBox from "redux/reducers/voicemailBox";

const rootReducer = combineReducers({
  auth,
  callHistory,
  callProfile,
  changePassword,
  configureQueue,
  contact,
  callQueue,
  i18n: i18nReducer,
  note,
  toastr: toastrReducer,
  search: searchReducer,
  user,
  userProfile,
  voicemail,
  voicemailBox,
});

export default rootReducer;
