import cable from "services/socket/cable";

export const CHANNEL_LIST = {
  PBX_USER_STATUS_CHANNEL: "PbxUserStatusChannel",
  PBX_QUEUE_CALLER_STATUS_CHANNEL: "PbxQueueCallerStatusChannel",
  PBX_QUEUE_STATISTICS_CHANNEL: "PbxQueueStatisticsChannel",
  PBX_QUEUE_AGENT_STATUS_CHANNEL: "PbxQueueAgentStatusChannel",
  ACTIVE_CALL_PROFILE_CHANNEL: "ActiveCallProfileChannel",
};

export const ACTIONS_LIST = {
  SUBSCRIBE: "subscribe",
};

export const CONNECTION_STATUS = {
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  ERROR: "error",
};

export const DATA_TYPE = {
  WELCOME: "welcome",
  PING: "ping",
  CONFIRM_SUBSCRIPTION: "confirm_subscription",
  DISCONNECT: "disconnect",
  MESSAGE: "message",
};

class Socket {
  constructor() {
    this.ws = null;
  }

  async setChannel(channel, pbxNumber, cb) {
    try {
      this.ws = await cable.subscribeTo(channel, {
        pbx_number: pbxNumber,
      });
      cb(CONNECTION_STATUS.CONNECTED);
    } catch (error) {
      this.setChannel(channel, pbxNumber, cb);
      throw error;
    }
  }

  getData(cb = () => {}) {
    this.ws.on(DATA_TYPE.MESSAGE, ({ data }) => {
      cb(data);
    });
  }
}

export default Socket;
