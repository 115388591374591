import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";

import { AgentManagementContext } from "context-api/AgentManagementContext";

import { AVAILABLE_AGENTS, RECENTLY_ADDED_AGENT } from "constants/agent";

import { displaySuccess } from "utils/toaster";

import {
  fetchDeviceInQueueRequest,
  updateAgentsRequest,
  silentFetchRegisteredAgentsRequest,
} from "redux/actions/callQueue";

import AvailableAgents from "./AvailableAgents/AvailableAgents";
import ExternalNumber from "./ExternalNumber";
import RegisteredAgents from "./RegisteredAgents/RegisteredAgents";

const AgentManagementSidebar = ({ open, setOpen, queueID, name }) => {
  const toggleClassName = open ? "toggle" : "";
  const toggleOverlay = open ? "d-block" : "d-none";
  const [toggleBar, setToggle] = useState(open);

  const [addExternalNumber, setAddExternalNumber] = useState({
    name: "",
    externalNumber: null,
  });

  const { isSubmitting, callQueues } = useSelector(
    ({ callQueue }) => callQueue
  );

  const { commitSuccess } = callQueues.find(
    ({ asterisk_queue_id: id }) => id == queueID
  );

  const {
    currentUser: { pbx_id: pbxID, user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  const {
    updatedRegisteredAgents,
    deleteRegisteredAgentID,
    deleteExternalNumberID,
    setDeleteRegisteredAgentID,
    setDeleteExternalNumberID,
    callPriority,
    isFormEdited,
    setFormEdited,
    setCallPriority,
  } = useContext(AgentManagementContext);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setToggle(false);
      }, 500);
    } else {
      setToggle(true);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedAgentsAttributes = {
      registered_agents_attributes: updatedRegisteredAgents.map((agent) => {
        const updatedPenalty = callPriority[agent.id].penalty;
        const isPenaltyFieldDirty =
          agent.attributes_are_dirty || callPriority[agent.id].isDirty;

        if (
          agent?.type === AVAILABLE_AGENTS ||
          agent?.type === RECENTLY_ADDED_AGENT
        ) {
          delete agent["id"];
        }

        delete agent["type"];
        delete agent["isDirty"];

        return {
          ...agent,
          penalty: updatedPenalty,
          attributes_are_dirty: isPenaltyFieldDirty,
        };
      }),
      removed_external_agent_ids: deleteExternalNumberID,
      removed_agent_ids: deleteRegisteredAgentID,
    };

    dispatch(updateAgentsRequest(pbxID, queueID, updatedAgentsAttributes));
  };

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
      setOpen(false);
      setFormEdited(false);
      setDeleteRegisteredAgentID([]);
      setDeleteExternalNumberID([]);
      setCallPriority([]);
      dispatch(silentFetchRegisteredAgentsRequest(pbxID, queueID));
      dispatch(fetchDeviceInQueueRequest(userLicenseID, queueID));
    }
  }, [commitSuccess]);

  if (!toggleBar) return null;

  return (
    <>
      <div
        className={`overlay-sidebar position-fixed ${toggleOverlay} hv-100 wv-100`}
      />

      <section
        className={`agent-management-sidebar ${toggleClassName} position-fixed`}
      >
        <form onSubmit={handleSubmit}>
          <div className="agent-management-header d-flex justify-content-between align-items-center p-3">
            <h5 className="mb-0 title fw-bold">
              <Translate value="callQueue.agentManagement.agentManagement" /> -{" "}
              {name}
            </h5>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setOpen(false)}
              className="close-icon fw-normal me-1"
            />
          </div>
          <div className="agent-management-content overflow-auto p-3">
            <div className="agents-content">
              <AvailableAgents queueID={queueID} />
              <ExternalNumber
                setAddExternalNumber={setAddExternalNumber}
                queueID={queueID}
              />
              <RegisteredAgents
                addExternalNumber={addExternalNumber}
                setAddExternalNumber={setAddExternalNumber}
                queueID={queueID}
              />
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-md-end px-3 py-2 submit-container mt-3">
            <button
              className="edit-button px-5 me-md-3 btn border-0 cancel-button d-none d-md-block"
              onClick={() => setOpen(false)}
              type="button"
            >
              <Translate value="common.cancel" />
            </button>
            <button
              type="submit"
              className="save-button btn btn-secondary"
              role="button"
              data-cy="submit"
              disabled={!isFormEdited || isSubmitting}
            >
              <MovingDottedLoader show={isSubmitting} />
              <Translate value="callQueue.agentManagement.saveAgentInformation" />
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

AgentManagementSidebar.defaultProps = {
  open: false,
  setOpen: () => {},
  name: "",
};

AgentManagementSidebar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  queueID: PropTypes.number.isRequired,
  name: PropTypes.string,
};

export default AgentManagementSidebar;
