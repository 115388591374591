import React from "react";
import Skeleton from "react-loading-skeleton";

import PropTypes from "prop-types";

import { WHITE_BASE_COLOR } from "constants/skeletonColor";

const EmptyContentSkeleton = ({ containerClassName }) => (
  <div className="d-flex align-items-center justify-content-center flex-fill h-100 layout-spacing ">
    <article
      className={`empty-content d-flex flex-column justify-content-center align-items-center ms-md-4 h-100 ${containerClassName}`}
    >
      <div className="position-relative">
        <Skeleton circle height={120} width={120} />
        <div className="position-absolute content-block">
          <Skeleton height={100} width={90} baseColor={WHITE_BASE_COLOR} />
        </div>
      </div>

      <div className="mt-4 mb-2 text-center col-10 overflow-hidden">
        <Skeleton height={15} width={400} className="align-self-center mb-0" />
      </div>
      <div className="text-center detail col-10 overflow-hidden">
        <Skeleton height={8} width={250} className="align-self-center mb-0" />
      </div>
    </article>
  </div>
);

EmptyContentSkeleton.defaultProps = {
  containerClassName: "",
};

EmptyContentSkeleton.propTypes = {
  containerClassName: PropTypes.string,
};

export default EmptyContentSkeleton;
