import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import arrayMutators from "final-form-arrays";

import NotFound from "pages/NotFound";

import IncomingCallIndex from "components/CallProfile//Form/IncomingCall/IncomingCallIndex";
import EmptyCallProfileContent from "components/CallProfile/Empty/EmptyCallProfileContent";
import OutgoingCallForm from "components/CallProfile/Form/OutgoingCall/OutgoingCallForm";
import ProfileBasicForm from "components/CallProfile/Form/ProfileBasicForm";
import QueueRegistrationForm from "components/CallProfile/Form/QueueRegistration/QueueRegistrationForm";
import ForbiddenError from "components/Forbidden/ForbiddenError";
import FormWrapper from "components/Form/FormWrapper";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import CallProfileDetailSkeleton from "components/Skeleton/CallProfiles/Detail/CallProfileDetailSkeleton";

import { CallProfileContext } from "context-api/CallProfileContext";

import { PROFILE } from "constants/routes";
import STATUS from "constants/statusCode";

import {
  incomingCallAttributes,
  INCOMING_FORM_TYPE,
  updateWithRemovableDevicesForIncomingCalls,
} from "utils/incomingCallAttributes";
import { incomingCallOptions } from "utils/incomingCallOptions";
import {
  addOutgoingCallAttributes,
  outgoingCallInitialValues,
} from "utils/outgoingCallAttributes";
import {
  editFormAttributes,
  profileQueuesAttributes,
  updateWithRemovableDevicesForCallQueues,
} from "utils/queueRegistrationAttributes";
import { displaySuccess } from "utils/toaster";
import { addSelectedIcon } from "utils/utils";

import {
  fetchCallProfileDetailRequest,
  updateCallProfileRequest,
  clearCallProfileErrors,
  deleteCallProfileRequest,
  fetchCallProfilesRequest,
  fetchAllIncomingSipDevicesRequest,
  fetchAllQueueDevicesRequest,
} from "redux/actions/callProfile";

import DeleteCallProfile from "./DeleteCallProfile";

const EditCallProfileForm = () => {
  const { id } = useParams();

  const [showDeleteModal, setDeleteModal] = useState(false);

  const dispatch = useDispatch();
  const { replace } = useHistory();
  const {
    callProfileDetail: {
      name,
      description,
      icon_json: icon,
      outgoing_call_routings: outgoingCallRoutings,
      incoming_call_routings: incomingCallRoutings,
      call_profile_queues: callProfileQueues,
      is_automatic_queue_registration_enabled:
        isAutomaticQueueRegistrationEnabled,
      is_default: isDefault,
      "used_in_fixed_calendar?": usedInFixedCalendar,
    },
    isProcessing,
    commitError,
    commitSuccess,
    isSubmitting,
    callProfiles,
    isProfileDetailLoading,
    sipDevices,
    isLoading,
    statusCode,
  } = useSelector(({ callProfile }) => callProfile);

  const {
    currentUser: {
      user_license_id: userLicenseID,
      active_call_profile: activeCallProfile,
    },
  } = useSelector(({ auth }) => auth);

  const { setSubmitted } = useContext(CallProfileContext);

  const profileDeleteHandler = () => {
    dispatch(deleteCallProfileRequest(id));
  };

  const showCommitMessage = () => {
    displaySuccess(commitSuccess);
    setDeleteModal(false);
    if (showDeleteModal) {
      replace(PROFILE.INDEX);
    } else {
      dispatch(fetchCallProfilesRequest(userLicenseID));
    }
  };

  useEffect(() => {
    if (commitSuccess) {
      showCommitMessage();
      dispatch(clearCallProfileErrors());
    }
  }, [commitSuccess, commitError]);

  const fetchCallProfileDetail = useMemo(() => {
    dispatch(fetchCallProfileDetailRequest(id));
  }, [id]);

  useEffect(() => {
    fetchCallProfileDetail;
    dispatch(fetchAllIncomingSipDevicesRequest(userLicenseID));

    return () => {
      dispatch(clearCallProfileErrors());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAllQueueDevicesRequest(userLicenseID));
  }, []);

  const initialValues = useMemo(
    () =>
      incomingCallOptions(
        incomingCallRoutings,
        INCOMING_FORM_TYPE.EDIT,
        sipDevices
      ),
    [incomingCallRoutings, sipDevices]
  );

  const initialValuesForQueueRegistration = useMemo(
    () => editFormAttributes(callProfileQueues),
    [id, callProfileQueues]
  );

  const initialValuesForOutGoingCalls = useMemo(
    () => outgoingCallInitialValues(outgoingCallRoutings),
    [outgoingCallRoutings]
  );

  const handleCancel = () => replace(PROFILE.INDEX);

  const onSubmitHandler = ({
    name,
    icon: [brandName, iconName],
    description,
    incomingCalls,
    outgoingCalls,
    call_profile_queues_attributes: callProfileQueuesAttributes = [],
    is_automatic_queue_registration_enabled:
      isAutomaticQueueRegistrationEnabled,
  }) => {
    const updatedQueueAttributes = updateWithRemovableDevicesForCallQueues(
      callProfileQueuesAttributes,
      initialValuesForQueueRegistration,
      isAutomaticQueueRegistrationEnabled
    );

    const updatedIncomingCallsAttributes =
      updateWithRemovableDevicesForIncomingCalls(incomingCalls, initialValues);

    const callProfileUpdateData = {
      call_profile: {
        name: name,
        description: description,
        icon_json: {
          brand_name: brandName,
          icon_name: iconName,
        },
        outgoing_call_routings_attributes:
          addOutgoingCallAttributes(outgoingCalls),
        incoming_call_routings_attributes: incomingCallAttributes(
          updatedIncomingCallsAttributes,
          INCOMING_FORM_TYPE.EDIT
        ),
        call_profile_queues_attributes: profileQueuesAttributes(
          updatedQueueAttributes,
          INCOMING_FORM_TYPE.EDIT
        ),
        is_automatic_queue_registration_enabled:
          isAutomaticQueueRegistrationEnabled,
      },
    };

    dispatch(updateCallProfileRequest(id, callProfileUpdateData));
  };

  const closeModalHandler = () => {
    setDeleteModal(false);
    dispatch(clearCallProfileErrors());
  };

  const translationText =
    activeCallProfile?.name == name
      ? "callProfile.delete.activeProfileCannotBeDeleted"
      : isDefault
      ? "callProfile.delete.defaultProfileCannotBeDeleted"
      : "";

  const confirmationModalTextClassName = usedInFixedCalendar
    ? "text-danger d-block mb-1"
    : "d-none";

  if (isProfileDetailLoading || (isLoading && callProfiles.length === 0)) {
    return <CallProfileDetailSkeleton />;
  }

  if (statusCode == STATUS.UNAUTHORIZED) {
    return <NotFound hideNavigation />;
  }

  if (statusCode == STATUS.FORBIDDEN) {
    return <ForbiddenError hideNavigation />;
  }

  if (callProfiles.length === 0) {
    return <EmptyCallProfileContent />;
  }

  if (!name)
    return (
      <div className="d-flex align-items-center h-100 flex-fill">
        <EmptyCallProfileContent />;
      </div>
    );

  return (
    <div className="call-profile-form flex-fill d-flex flex-column pt-4 pt-md-2 h-100">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="h5 fw-bold ms-1 mb-0">
          <Translate value="callProfile.edit.editProfile" name={name} />
        </h5>
        <DeleteCallProfile
          showTooltip={activeCallProfile?.name == name || isDefault}
          setDeleteModal={setDeleteModal}
          tooltipTranslationText={translationText}
        />
      </div>
      <FormWrapper
        formClassName="p-0 d-flex flex-column justify-content-between h-100"
        submitText="common.save"
        submitClassName="btn btn-primary save-button my-3"
        submitContainerClassName="justify-content-md-end mt-md-3"
        onSubmitHandler={onSubmitHandler}
        isLoading={isSubmitting}
        cancelClassName="btn btn-outline-secondary d-block d-md-none my-md-3"
        handleCancel={handleCancel}
        mutators={{
          ...arrayMutators,
        }}
        submitCallBack={() => setSubmitted(true)}
        initialValues={{
          name,
          description,
          icon: addSelectedIcon(icon),
          incomingCalls: initialValues,
          outgoingCalls: initialValuesForOutGoingCalls,
          is_automatic_queue_registration_enabled:
            isAutomaticQueueRegistrationEnabled,
          call_profile_queues_attributes: initialValuesForQueueRegistration,
        }}
      >
        <ProfileBasicForm isDefault={isDefault} />
        <IncomingCallIndex />
        <OutgoingCallForm />
        <QueueRegistrationForm />
      </FormWrapper>
      <ConfirmModal
        isVisible={showDeleteModal}
        deleteModal
        onCancel={closeModalHandler}
        onConfirm={profileDeleteHandler}
        cancelButtonText="common.cancel"
        confirmButtonText="common.yesImSure"
        isLoading={isProcessing}
        heading="callProfile.delete.areYouSureYouWantToDeleteProfile"
        retryHeader="callProfile.delete.failedToDeleteProfile"
        retryContent={
          <Translate value="callProfile.delete.errorDescription" name={name} />
        }
        retryButtonText="common.retry"
        error={commitError}
      >
        <span className={confirmationModalTextClassName}>
          <Translate
            value="callProfile.delete.thisProfileIsCurrentlyUsedInFixedCalendar"
            name={name}
          />
        </span>
        <Translate value="callProfile.delete.description" name={name} />
      </ConfirmModal>
    </div>
  );
};

export default EditCallProfileForm;
