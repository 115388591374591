import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/js/dist/tooltip";

import NoteMessage from "components/Home/ContactDetail/NoteMessage";

import { isEmpty } from "utils/isEmpty";
import { snakeCaseToKebabCase } from "utils/utils";

import ActiveProfile from "./ActiveProfile";

const AddProfileImage = ({ profilePicture }) => {
  if (profilePicture) {
    return (
      <img
        data-cy="profile-picture"
        src={profilePicture}
        alt="profile picture"
        className="user-icon rounded-circle cover-image"
      />
    );
  }

  return (
    <FontAwesomeIcon data-cy="user-icon" icon={faUser} className="user-icon " />
  );
};

const CurrentTitle = ({ titleCheck, name }) => {
  if (titleCheck) {
    return (
      <div className="d-flex align-item-center mb-1 px-1">
        <p
          data-cy="current-title"
          className="title mb-0 me-2 align-self-center"
        >
          {name?.trim?.()?.substr(0, 1)}{" "}
        </p>
        <hr className="w-100 alphabet-line ms-2" />
      </div>
    );
  }

  return null;
};

const ContactItem = ({
  handleContactDetailSelect,
  name,
  active,
  titleCheck,
  id,
  status,
  note,
  profilePicture,
  activeProfile,
}) => {
  const activeELementClassName =
    active?.name === name && active?.id === id ? "active" : "";

  const activeNameClassName = !isEmpty(activeProfile) ? "active" : "mb-0";

  const userStateClassName = snakeCaseToKebabCase(status) || "unavailable";

  const disabledButtonClassName = Boolean(activeELementClassName)
    ? "disabled"
    : "";

  return (
    <li className="row contact-item-container w-100 px-3 mx-auto mb-3 position-relative">
      <CurrentTitle titleCheck={titleCheck} name={name} />
      <div
        className={` col-12 text-start ${activeELementClassName} ${disabledButtonClassName} contact-item px-0 d-flex align-items-center`}
        onClick={handleContactDetailSelect}
        tabIndex={0}
        type="button"
        data-cy={`contact-item-${id}`}
      >
        <div className="rounded-circle avatar ms-2 me-2 position-relative d-flex align-items-center justify-content-center">
          <AddProfileImage profilePicture={profilePicture} />
          <span
            data-cy="user-status"
            className={`rounded-circle position-absolute user-status ${userStateClassName}`}
          />
        </div>
        <div className="position-relative">
          <p className={`me-2 current-name ${activeNameClassName}`}>
            <span data-cy="contact-name" className="me-1">
              {name}
            </span>
            <NoteMessage text={note} />
          </p>
          <ActiveProfile activeProfile={activeProfile} />
        </div>
      </div>
    </li>
  );
};

AddProfileImage.defaultProps = {
  profilePicture: null,
};

AddProfileImage.propTypes = {
  profilePicture: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

CurrentTitle.defaultProps = {
  titleCheck: false,
};

CurrentTitle.propTypes = {
  titleCheck: PropTypes.bool,
  name: PropTypes.string,
};

ContactItem.defaultProps = {
  handleContactDetailSelect: () => {},
  name: "",
  active: {},
  titleCheck: false,
  id: 0,
  status: "",
  note: "",
  profilePicture: "",
  activeProfile: null,
};

ContactItem.propTypes = {
  handleContactDetailSelect: PropTypes.func,
  name: PropTypes.string,
  active: PropTypes.object,
  titleCheck: PropTypes.bool,
  type: PropTypes.number,
  status: PropTypes.string,
  note: PropTypes.string,
  id: PropTypes.number,
  profilePicture: PropTypes.string,
  activeProfile: PropTypes.shape({}),
};

export default ContactItem;
