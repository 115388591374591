import React from "react";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const GeneralItem = ({ icon, title, content }) => {
  if (!content) return null;

  return (
    <div className="d-flex general-item py-2 px-2">
      <FontAwesomeIcon icon={icon} className="mt-1 icon" />
      <div className="ms-1 ps-1">
        <p className="title mb-0 fw-bold">
          <Translate value={title} />
        </p>
        <p className="content mb-0">{content}</p>
      </div>
    </div>
  );
};

GeneralItem.defaultProps = {
  icon: {},
  title: "",
  content: "",
};

GeneralItem.propTypes = {
  icon: PropTypes.shape({}),
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number,
  ]),
};

export default GeneralItem;
