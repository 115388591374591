import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSortBy, useTable } from "react-table";

import PropTypes from "prop-types";

import EmptyContent from "components/EmptyContent/EmptyContent";
import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import Table from "components/Table/Table";
import TableBody from "components/Table/TableBody";
import TableHeader from "components/Table/TableHeader";

import { CALL_HISTORY_TABLE_HEADER } from "constants/tableHeader";

import { formatCallLogDuration, getCallDuration } from "utils/timeFormatter";

import CallFrom from "./CallFrom";

const CallHistoryTable = ({ callHistories }) => {
  const [tableData, setTableData] = useState([]);
  const { isSearching, isFiltering } = useSelector(
    ({ callHistory }) => callHistory
  );

  const rowData = (cell) => {
    if (cell.column.id == "time") {
      return formatCallLogDuration(cell.value);
    } else if (cell.column.id == "duration") {
      return getCallDuration(cell.value);
    }

    return cell.render("Cell");
  };

  useEffect(() => {
    const callHistory = [];

    callHistories.forEach(
      ({
        call_date_time: dateTime,
        call_duration_in_seconds: callDuration,
        call_from: from,
        call_to: to,
        call_type: callType,
      }) => {
        callHistory.push({
          icon: <CallFrom type={callType} />,
          from: <span>{from}</span>,
          to: to,
          time: new Date(dateTime),
          duration: callDuration,
        });
      }
    );

    setTableData(callHistory);
  }, [callHistories]);

  const tableRowCellClassName = (cell) => {
    if (cell.column.id === "icon") {
      return "cell align-middle cell-icon";
    }

    return "cell align-middle";
  };

  const tableRowClassName = (row) => {
    const { values } = row;
    const iconClassName = values.icon.props.type;

    if (iconClassName === "missed_call") {
      return `${iconClassName.replace("_", "-")} table-row w-auto`;
    }

    return "table-row w-auto";
  };

  const tableInstance = useTable(
    {
      columns: CALL_HISTORY_TABLE_HEADER,
      data: tableData,
      initialState: {
        sortBy: [
          {
            id: "time",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (callHistories.length === 0 && !isSearching && !isFiltering) {
    return (
      <EmptyContent
        title="callHistory.empty.emptyCallHistoryList"
        description="callHistory.empty.description"
      />
    );
  }

  return (
    <div
      className="call-history-table pt-3 px-2 overflow-auto h-100"
      data-cy="call-history-table-data"
    >
      <Table getTableProps={getTableProps}>
        <TableHeader headerGroups={headerGroups} />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          tableRowClassName={tableRowClassName}
          tableRowCellClassName={tableRowCellClassName}
          rowData={rowData}
        />
      </Table>
      <EmptyTableUI
        records={callHistories}
        isSearching={isSearching}
        isFiltering={isFiltering}
        filterTitle="callHistory.empty.callNotFound"
        filterDescription="callHistory.empty.recordOnFilteredDateNotFound"
        searchTitle="callHistory.empty.callNotFound"
        searchDescription="callHistory.empty.searchQueryNotFound"
      />
    </div>
  );
};

CallHistoryTable.defaultProps = {
  callHistories: [],
};

CallHistoryTable.propTypes = {
  callHistories: PropTypes.array,
};

export default CallHistoryTable;
