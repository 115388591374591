import React from "react";
import { Translate } from "react-thunk-i18nify";

import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { FORBIDDEN_BANNER } from "constants/images";

const NavigationButton = ({ hideNavigation, handleNavigation }) => {
  if (hideNavigation) return null;

  return (
    <button className="btn btn-primary mt-2" onClick={handleNavigation}>
      <FontAwesomeIcon icon={faLeftLong} className="me-2" />
      <Translate value="pages.pageNotFound.returnToLoginPage" />
    </button>
  );
};

const ForbiddenError = ({ hideNavigation, handleNavigation }) => (
  <section className="not-found d-flex flex-column justify-content-center align-items-center h-100">
    <img src={FORBIDDEN_BANNER.SOURCE} alt="forbidden page" className="me-5" />
    <h3 className="mt-2 pt-2 mb-0 fw-bold">
      <Translate value="pages.title.pageNotFound" />
    </h3>
    <p className="description my-3">
      <Translate value="pages.pageNotFound.youAreNotAllowed" />
    </p>
    <NavigationButton
      hideNavigation={hideNavigation}
      handleNavigation={handleNavigation}
    />
  </section>
);

ForbiddenError.defaultProps = {
  hideNavigation: false,
  location: { pathname: "" },
  handleNavigation: () => {},
};

ForbiddenError.propTypes = {
  hideNavigation: PropTypes.bool,
  location: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.shape({ pathname: "" }),
  ]),
  handleNavigation: PropTypes.func,
};

NavigationButton.defaultProps = {
  hideNavigation: false,
  handleNavigation: () => {},
};

NavigationButton.propTypes = {
  hideNavigation: PropTypes.bool,
  handleNavigation: PropTypes.func,
};

export default ForbiddenError;
