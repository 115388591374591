import { createSearchAction } from "redux-search";

import { CallHistoryApi } from "internal";

import { getErrors } from "utils/utils";

export const FETCH_CALL_HISTORY_REQUEST = "FETCH_CALL_HISTORY_REQUEST";
export const FETCH_CALL_HISTORY_RECEIVED = "FETCH_CALL_HISTORY_RECEIVED";
export const FETCH_CALL_HISTORY_ERROR = "FETCH_CALL_HISTORY_ERROR";
export const CALL_HISTORY_SEARCH = "CALL_HISTORY_SEARCH";

export const FILTER_CALL_HISTORY_BY_DATE_REQUEST =
  "FILTER_CALL_HISTORY_BY_DATE_REQUEST";
export const FILTER_CALL_HISTORY_BY_DATE_RECEIVED =
  "FILTER_HISTORY_BY_DATE_RECEIVED";
export const FILTER_CALL_HISTORY_BY_DATE_ERROR =
  "FILTER_CALL_HISTORY_BY_DATE_ERROR";

export const searchCallHistory = createSearchAction("callHistories");

const callHistoryReceived = (callHistory) => ({
  type: FETCH_CALL_HISTORY_RECEIVED,
  callHistory,
});

export function callHistorySearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: CALL_HISTORY_SEARCH,
    isSearching,
  };
}

const callHistoryError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_CALL_HISTORY_ERROR,
    error,
  };
};

export const fetchCallHistoryRequest =
  (userLicenseId, type) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_CALL_HISTORY_REQUEST });

      const response = await CallHistoryApi.fetchCallHistory(
        userLicenseId,
        type
      );

      dispatch(callHistoryReceived(response.data));
    } catch (error) {
      dispatch(callHistoryError(error));
    }
  };

const filterCallHistoryByDateReceived = (data) => ({
  type: FILTER_CALL_HISTORY_BY_DATE_RECEIVED,
  data,
});

const filterCallHistoryByDateError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FILTER_CALL_HISTORY_BY_DATE_ERROR,
    error,
  };
};

export const filterCallHistoryByDateRequest =
  (userLicenseId, type, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: FILTER_CALL_HISTORY_BY_DATE_REQUEST });

      const response = await CallHistoryApi.filterByDate(
        userLicenseId,
        type,
        startDate,
        endDate
      );

      dispatch(filterCallHistoryByDateReceived(response.data));
    } catch (error) {
      dispatch(filterCallHistoryByDateError(error));
    }
  };
