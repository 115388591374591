import { useEffect } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-thunk-i18nify";

import FormWrapper from "components/Form/FormWrapper";
import PasswordField from "components/Form/PasswordField/PasswordField";

import { displayError } from "utils/toaster";
import {
  MINIMUM_PASSWORD_LENGTH,
  validateNewPassword,
  validatePassword,
} from "utils/validation";

import { changePasswordRequest } from "redux/actions/changePassword";

const PasswordForm = () => {
  const dispatch = useDispatch();
  const {
    currentUser: { user_license_id: userLicenseId, id: userId },
  } = useSelector(({ auth }) => auth);
  const { error, isLoading } = useSelector(
    ({ changePassword }) => changePassword
  );

  useEffect(() => {
    if (error) {
      displayError(I18n.t(error));
    }
  }, [error]);

  const onSubmitHandler = ({ newPassword, confirmPassword }) => {
    const formData = new FormData();

    formData.append("user_license[user_attributes][id]", userId);
    formData.append("user_license[user_attributes][password]", newPassword);
    formData.append(
      "user_license[user_attributes][password_confirmation]",
      confirmPassword
    );

    dispatch(changePasswordRequest(userLicenseId, formData));
  };

  return (
    <article
      className="password-form col-12 col-md-6"
      data-testid="password-form"
    >
      <FormWrapper
        onSubmitHandler={onSubmitHandler}
        showCancelButton={false}
        isLoading={isLoading}
        validation={(values) =>
          validateNewPassword(values, MINIMUM_PASSWORD_LENGTH)
        }
        submitText="common.save"
        cancelText="common.cancel"
        submitClassName="btn btn-primary save my-3"
        submitContainerClassName="justify-content-md-end mt-md-3"
      >
        <div
          className="mb-4"
          data-cy="currentPasswordContainer"
          data-testid="currentPasswordContainer"
        >
          <Field
            name="currentPassword"
            validate={validatePassword(MINIMUM_PASSWORD_LENGTH)}
            component={PasswordField}
            placeholderTranslation="attributes.password"
            labelTranslation="attributes.password"
          />
        </div>
        <div
          data-cy="newPasswordContainer"
          className="position-relative mb-4"
          data-testid="newPasswordContainer"
        >
          <Field
            name="newPassword"
            validate={validatePassword(MINIMUM_PASSWORD_LENGTH)}
            component={PasswordField}
            placeholderTranslation="attributes.newPassword"
            labelTranslation="attributes.newPassword"
          />
        </div>
        <div
          data-cy="confirmPasswordContainer"
          className="position-relative mb-4"
          data-testid="confirmPasswordContainer"
        >
          <Field
            name="confirmPassword"
            validate={validatePassword(MINIMUM_PASSWORD_LENGTH)}
            component={PasswordField}
            placeholderTranslation="attributes.confirmNewPassword"
            labelTranslation="attributes.confirmNewPassword"
          />
        </div>
      </FormWrapper>
    </article>
  );
};

export default PasswordForm;
