import React from "react";
import Skeleton from "react-loading-skeleton";

import InformationBoxWrapperSkeleton from "components/Skeleton/InformationBoxWrapperSkeleton";

const IncomingCallIndexSkeleton = () => (
  <div className="incoming-call-index mt-2 mb-4 pt-2 pt-md-0">
    <InformationBoxWrapperSkeleton>
      <div className="px-3">
        <div className="outgoing-call-item row py-3">
          <div className="col-md-3 align-self-center">
            <Skeleton height={20} className="w-25" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-2 align-self-center">
                <Skeleton height={20} className="w-50" />
              </div>
              <div className="col-8">
                <Skeleton height={40} className="w-75" />
              </div>
            </div>
          </div>
        </div>
        <div className="outgoing-call-item row py-3">
          <div className="col-md-3 align-self-center">
            <Skeleton height={20} className="w-25" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-2 align-self-center">
                <Skeleton height={20} className="w-50" />
              </div>
              <div className="col-8">
                <Skeleton height={40} className="w-75" />
              </div>
            </div>
          </div>
        </div>
        <div className="outgoing-call-item row py-3">
          <div className="col-md-3 align-self-center">
            <Skeleton height={20} className="w-25" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-2 align-self-center">
                <Skeleton height={20} className="w-50" />
              </div>
              <div className="col-8">
                <Skeleton height={40} className="w-75" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </InformationBoxWrapperSkeleton>
  </div>
);

export default IncomingCallIndexSkeleton;
