import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import ContactListSkeleton from "components/Skeleton/Contacts/ContactList/ContactListSkeleton";

import { dynamicSort } from "utils/sort";

import ContactItem from "./ContactItem";
import EmptyContact from "./EmptyContact";

const ContactListItem = ({
  activeContact,
  handleContactDetailSelect,
  contacts,
}) => {
  const { isLoading, isSearching } = useSelector(({ contact }) => contact);

  const [contactsList, setContactsList] = useState([]);

  const handleAlphabetListing = (item, index) => {
    let checkItem = true;

    contactsList.slice(0, index).map((subItem) => {
      if (item?.name?.substr(0, 1) == subItem?.name?.substr(0, 1)) {
        checkItem = false;
      }
    });

    return checkItem;
  };

  useEffect(() => {
    setContactsList(contacts.sort(dynamicSort("name")));
  }, [contacts]);

  if (isLoading) {
    return <ContactListSkeleton />;
  }

  if (!contacts.length) {
    return (
      <EmptyContact
        searchTitleTranslation="contact.empty.list.contactNotFound"
        searchDescriptionTranslation="contact.empty.list.searchQueryNotFound"
        emptyContactTitleTranslation="contact.empty.list.title"
        emptyContactDescriptionTranslation="contact.empty.list.description"
        isSearching={isSearching}
      />
    );
  }

  return (
    <ul
      className="contact-list mt-0 w-100 h-100 flex-fill ps-0"
      data-cy="contact-list"
    >
      {contactsList.map((item, index) => (
        <ContactItem
          name={item.name}
          key={item.id}
          handleContactDetailSelect={() => handleContactDetailSelect(item)}
          id={item.id}
          status={item?.status}
          active={activeContact}
          titleCheck={handleAlphabetListing(item, index)}
          note={item?.note_description}
          profilePicture={item?.profile_picture?.url}
          activeProfile={item.active_call_profile}
        />
      ))}
    </ul>
  );
};

ContactListItem.defaultProps = {
  activeContact: {},
  handleContactDetailSelect: () => {},
  contacts: [],
};

ContactListItem.propTypes = {
  activeContact: PropTypes.object,
  handleContactDetailSelect: PropTypes.func,
  contacts: PropTypes.array,
};

export default ContactListItem;
