import React from "react";
import { Field, useField } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import OptionSelectDropdown from "components/Dropdown/SelectDropdown/OptionSelectDropdown";

import { useWindowDimensions } from "utils/getDimensions";
import { INCOMING_CALL_TYPE } from "utils/incomingCallAttributes";
import { getRingingDurationOptions } from "utils/incomingCallOptions";
import { required } from "utils/validation";

const ShowAfterText = ({ show }) => {
  if (!show) return null;

  return (
    <p className="show-after-text mb-0 ">
      <Translate value="common.after" />
    </p>
  );
};

const RingingDuration = ({ show, name }) => {
  const nameOftheChooseDevice = name.split(".")[0] + ".selected_option";

  const {
    input: { value: selectedValue },
  } = useField(nameOftheChooseDevice);

  const { width } = useWindowDimensions();

  const showAfterText =
    selectedValue?.value !== INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES ||
    width <= 1200;

  const addClassName =
    selectedValue?.value !== INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES ||
    width <= 1200
      ? "mx-3"
      : "me-3";

  if (!show) return null;

  return (
    <div className="col-xl-3 mb-3">
      <div className="d-flex align-items-center">
        <ShowAfterText show={showAfterText} />
        <Field
          name={name}
          component={OptionSelectDropdown}
          validate={required("validationErrors.REQUIRED")}
          options={getRingingDurationOptions()}
          containerClassName={`fw-normal flex-column justify-content-start ${addClassName} mt-0`}
          activeClassName="selected-call-routing"
          menuClassName="incoming-call-menu py-0"
          className="incoming-call-menu-item"
          errorClassName="align-self-start ms-2"
          disableRemoveSelectedOption
          hideSelectedValue
        />
        <p className=" align-self-center mb-0 px-0">
          <Translate value="timeUnit.sec" />
        </p>
      </div>
    </div>
  );
};

ShowAfterText.propTypes = {
  show: PropTypes.bool.isRequired,
  addSipDeviceClassName: PropTypes.bool.isRequired,
};

RingingDuration.defaultProps = {
  show: false,
  name: "",
};

RingingDuration.propTypes = {
  show: PropTypes.bool,
  name: PropTypes.string,
};

export default RingingDuration;
