import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ContactImage = ({ url }) => {
  if (!url) {
    return (
      <FontAwesomeIcon
        icon={faUser}
        size="2x"
        className="user-icon"
        data-cy="user-icon"
      />
    );
  }

  return (
    <img
      src={url}
      alt="profile"
      data-cy="contact-image"
      className="rounded-circle cover-image"
    />
  );
};

ContactImage.defaultProps = {
  url: "",
};

ContactImage.propTypes = {
  url: PropTypes.string,
};

export default ContactImage;
