import React, { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { I18nReRender, Translate } from "react-thunk-i18nify";

import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flatPicker from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da";
import { english } from "flatpickr/dist/l10n/default";
import PropTypes from "prop-types";

import { useWindowDimensions } from "utils/getDimensions";

import { defaultLanguage } from "locales/root";

const CustomInput = ({ value, inputRef, ...props }) => (
  <I18nReRender
    render={() => (
      <input
        {...props}
        ref={inputRef}
        value={value}
        data-testid="custom-datepicker-input"
      />
    )}
  />
);

const CustomDatePicker = ({ date, setDate, callback }) => {
  const flatPickerRef = useRef();
  const { locale } = useSelector(({ i18n }) => i18n);
  const { width } = useWindowDimensions();

  const placeholderText =
    typeof date == "string" ? (
      <p
        data-testid="custom-datepicker-placeholder"
        className="position-absolute top-50 translate-middle placeholder-text w-100"
      >
        <Translate value="common.selectDate" />
      </p>
    ) : (
      ""
    );

  useEffect(() => {
    flatPickerRef.current.flatpickr.input.type = "button";
  }, []);

  useEffect(() => {
    const currentFlatPickerLanguage =
      defaultLanguage != locale ? Danish : english;

    flatPicker.localize(currentFlatPickerLanguage);
  }, [locale]);

  const position = width <= 767 ? "auto auto" : "auto center";

  return (
    <div
      data-cy="custom-date-picker"
      className="date position-relative d-flex align-items-center my-2 px-2"
    >
      <FontAwesomeIcon
        icon={faCalendar}
        className="icon"
        data-testid="calendar-icon"
      />
      <Flatpickr
        ref={flatPickerRef}
        options={{
          position: position,
          dateFormat: "j-m-Y",
          minDate: "today",
          disableMobile: true,
        }}
        className="date border-0 p-2 w-auto text-center"
        value={date}
        onChange={([date]) => {
          setDate(date);
          callback();
        }}
        render={({ value, ...props }, ref) => (
          <CustomInput value={value} inputRef={ref} {...props} />
        )}
      />
      {placeholderText}
    </div>
  );
};

CustomInput.defaultProps = {
  value: "",
  defaultValue: "",
  inputRef: () => {},
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  defaultValue: PropTypes.string,
  inputRef: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  date: {},
  setDate: () => {},
  callback: () => {},
};

CustomDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  setDate: PropTypes.func,
  callback: PropTypes.func,
};

export default React.memo(CustomDatePicker);
