import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

const ModalHeader = ({ heading, children }) => {
  if (heading) {
    return (
      <p className="heading fw-bold mb-0">
        <Translate value={heading} />
      </p>
    );
  }

  return children;
};

const ConfirmContent = ({
  heading,
  content,
  isLoading,
  onCancel,
  cancelButtonText,
  confirmButtonText,
  confirmClassName,
  onConfirm,
  loader,
  headerContent,
}) => (
  <>
    <ModalHeader heading={heading}>{headerContent}</ModalHeader>
    <p className="py-3 py-md-4 paragraph fw-normal my-0">{content}</p>
    <div className="w-100 d-flex flex-column flex-sm-row justify-content-between align-items-center">
      <button
        className="btn btn-outline-secondary fw-bold px-5 cancel-button mb-2 mb-sm-0"
        disabled={isLoading}
        onClick={onCancel}
        data-cy="cancel-button"
      >
        <Translate value={cancelButtonText} />
      </button>
      <button
        className={`btn confirm-button ${confirmClassName} fw-bold px-5`}
        onClick={onConfirm}
        disabled={isLoading}
        data-cy="confirm-button"
      >
        {loader} <Translate value={confirmButtonText} />
      </button>
    </div>
  </>
);

ModalHeader.defaultProps = {
  heading: "",
  children: null,
};

ModalHeader.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.shape({}),
  ]),
};

ConfirmContent.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmClassName: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  loader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  headerContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

ConfirmContent.defaultProps = {
  isLoading: false,
  cancelButtonText: "",
  confirmButtonText: "",
  confirmClassName: "",
  onCancel: () => {},
  onConfirm: () => {},
  loader: <></>,
  headerContent: null,
};

export default ConfirmContent;
