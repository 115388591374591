import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useSelector } from "react-redux";

import InformationBoxWrapper from "layouts/InformationBoxWrapper/InformationBoxWrapper";

import OutgoingCallFormSkeleton from "components/Skeleton/CallProfiles/Detail/OutgoingCallFormSkeleton";

import { OUTGOING_CALL } from "constants/images";

import { outGoingCallOptions } from "utils/outgoingCallAttributes";

import OutgoingCallItem from "./OutgoingCallItem";

const OutgoingCallForm = () => {
  const { outgoingCallOptions } = useSelector(({ callProfile }) => callProfile);

  const [updatedOutgoingOptions, setUpdatedOutgoingOptions] = useState([]);

  useEffect(() => {
    if (!outgoingCallOptions.length) return setUpdatedOutgoingOptions([]);
    const newUpdatedOptions = [];

    outgoingCallOptions.forEach((option) => {
      newUpdatedOptions.push(outGoingCallOptions(option));
    });
    setUpdatedOutgoingOptions(newUpdatedOptions);
  }, [outgoingCallOptions]);

  if (!updatedOutgoingOptions.length) return <OutgoingCallFormSkeleton />;

  return (
    <article
      className="outgoing-call-form my-4 pt-2 pt-md-0 my-md-0"
      data-cy="outgoing-call"
    >
      <InformationBoxWrapper
        title="common.outgoingCalls"
        titleImageSource={OUTGOING_CALL.SOURCE}
      >
        <div className="px-3">
          <FieldArray name="outgoingCalls">
            {({ fields }) =>
              fields.map((name, index) => (
                <Field
                  key={index}
                  name={name}
                  component={OutgoingCallItem}
                  options={updatedOutgoingOptions}
                />
              ))
            }
          </FieldArray>
        </div>
      </InformationBoxWrapper>
    </article>
  );
};

export default OutgoingCallForm;
