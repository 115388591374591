import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faEllipsisH, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";

import { isEmpty } from "utils/isEmpty";
import { displaySuccess } from "utils/toaster";

import {
  clearProfileErrors,
  profileRemoveRequest,
} from "redux/actions/userProfile";

import DefaultProfileImage from "./DefaultProfileImage";

const ProfileImage = ({
  source,
  handleProfileUpload,
  profilePictureIsEmpty,
  showUploadFeature,
  isImageLoading,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    currentUser: { user_license_id: userLicenseId, id: userId },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  const { profile, isLoading, commitSuccess } = useSelector(
    ({ userProfile }) => userProfile
  );

  useEffect(() => {
    setShowDeleteModal(false);
    if (commitSuccess) {
      displaySuccess(commitSuccess);
    }
  }, [profile]);

  useEffect(() => () => dispatch(clearProfileErrors()), []);

  const handleDeleteConfirm = () => {
    const formData = new FormData();

    formData.append("user_license[user_attributes][id]", userId);
    formData.append("user_license[user_attributes][profile_picture]", null);

    dispatch(profileRemoveRequest(userLicenseId, formData));
  };

  const openModalHandler = () => {
    setShowDeleteModal(true);
  };

  if (
    profilePictureIsEmpty ||
    (isEmpty(profile?.profile_picture) && !isEmpty(profile))
  ) {
    return (
      <DefaultProfileImage
        handleProfileUpload={handleProfileUpload}
        firstName={profile.first_name}
        lastName={profile.last_name}
        showUploadFeature={showUploadFeature}
      />
    );
  }

  if (isImageLoading) {
    return (
      <div className="text-center">
        <Skeleton circle width={130} height={130} />
      </div>
    );
  }

  const showProfileDropdown = showUploadFeature && (
    <div
      data-cy="profile-image-dropdown"
      className="dropdown custom-dropdown rounded-circle position-absolute profile-image-dropdown d-flex align-items-center justify-content-center p-0"
    >
      <button
        data-cy="dropdown-toggle"
        className="btn dropdown-toggle rounded-circle bg-white p-2"
        type="button"
        id="profileDropDown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faEllipsisH} className="dot-icon" />
      </button>
      <ul
        data-cy="dropdown-menu"
        className="dropdown-menu border-0 px-2"
        aria-labelledby="profileDropDown"
      >
        <li className="position-relative">
          <input
            type="file"
            className="invisible position-absolute w-25"
            id="uploadProfile"
            onChange={handleProfileUpload}
          />

          <label className="dropdown-item" htmlFor="uploadProfile">
            <FontAwesomeIcon icon={faPen} className="edit-icon me-2" />
            <Translate value="dropdown.profile.updateProfileImage" />
          </label>
        </li>
        <li className="position-relative">
          <span className="dropdown-item" onClick={openModalHandler}>
            <FontAwesomeIcon icon={faTrash} className="delete-icon" />
            <span>
              <Translate value="dropdown.profile.deleteProfileImage" />
            </span>
          </span>
        </li>
      </ul>
    </div>
  );

  return (
    <div className="profile-image uploaded rounded-pill position-relative d-flex align-items-center justify-content-center">
      <img
        data-cy="profile_image"
        src={source}
        alt="user profile"
        className="uploaded-image rounded-circle"
      />
      {showProfileDropdown}
      <ConfirmModal
        isVisible={showDeleteModal}
        deleteModal
        onCancel={() => setShowDeleteModal(false)}
        cancelButtonText="common.cancel"
        onConfirm={handleDeleteConfirm}
        confirmButtonText="common.yesImSure"
        isLoading={isLoading}
        heading="userProfile.delete.areYouSureYouWantToDeleteProfileImage"
      >
        <Translate value="userProfile.delete.description" />
      </ConfirmModal>
    </div>
  );
};

ProfileImage.defaultProps = {
  profilePictureIsEmpty: true,
  source: "",
  handleProfileUpload: () => {},
  showUploadFeature: true,
  isImageLoading: false,
};

ProfileImage.propTypes = {
  source: PropTypes.string,
  profilePictureIsEmpty: PropTypes.bool,
  handleProfileUpload: PropTypes.func,
  showUploadFeature: PropTypes.bool,
  isImageLoading: PropTypes.bool,
};

export default ProfileImage;
