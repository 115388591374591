import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { faGear } from "@fortawesome/free-solid-svg-icons";

import InformationBoxWrapper from "layouts/InformationBoxWrapper/InformationBoxWrapper";

import FormWrapper from "components/Form/FormWrapper";
import ConfigureVoicemailBoxSkeleton from "components/Skeleton/VoicemailBox/ConfigureVoicemailBoxSkeleton";
import ConfigVoicemailBoxForm from "components/VoicemailBoxes/Form/ConfigVoicemailBoxForm";

import { displaySuccess } from "utils/toaster";
import { validateVoicemailBox } from "utils/validation";

import {
  clearVoicemailBoxErrors,
  fetchVoicemailBoxRequest,
  updateVoicemailBoxRequest,
} from "redux/actions/voicemailBox";

const ConfigureVoicemailBoxes = () => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { isLoading, generalVoicemailBoxes, commitSuccess, isProcessing } =
    useSelector(({ voicemailBox }) => voicemailBox);
  const { extension_number: extensionNumber, cell_number: cellNumber } =
    generalVoicemailBoxes;

  const handleSubmit = (values) => {
    dispatch(updateVoicemailBoxRequest(id, values));
  };

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
      goBack();
    }
  }, [commitSuccess]);

  const handleCancel = () => {
    goBack();
  };

  useEffect(() => {
    dispatch(fetchVoicemailBoxRequest(id));

    return () => {
      dispatch(clearVoicemailBoxErrors());
    };
  }, []);

  if (isLoading) {
    return <ConfigureVoicemailBoxSkeleton />;
  }

  return (
    <div
      role="document"
      className="voicemail-boxes overflow-auto w-100 h-100 mx-0 layout-spacing"
    >
      <article className="configure-voicemail-boxes p-3 bg-white hv-100">
        <FormWrapper
          formClassName="p-0 d-flex flex-column justify-content-between h-100"
          submitText="common.save"
          submitClassName="btn btn-primary save-button my-3"
          submitContainerClassName="justify-content-md-end mt-2 mt-md-3 px-3"
          validation={(values) => validateVoicemailBox(values)}
          onSubmitHandler={handleSubmit}
          handleCancel={handleCancel}
          isLoading={isProcessing}
        >
          <InformationBoxWrapper
            title="voicemailBoxes.configuringVoicemailBoxOf"
            titleIcon={faGear}
            additionalTitleContent={
              <p
                data-cy="cell-number"
                className="text-primary fw-bold mb-0 ms-1"
              >
                {extensionNumber || cellNumber}
              </p>
            }
          >
            <div className="px-2 px-md-5 py-2 d-flex justify-content-between">
              <ConfigVoicemailBoxForm initialValues={generalVoicemailBoxes} />
            </div>
          </InformationBoxWrapper>
        </FormWrapper>
      </article>
    </div>
  );
};

export default ConfigureVoicemailBoxes;
