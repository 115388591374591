import React from "react";
import Skeleton from "react-loading-skeleton";

const ProfileSkeleton = () => (
  <>
    <div className="profile-header p-3 d-flex align-items-center">
      <div className="d-flex justify-content-center justify-content-md-start align-items-center ms-3 ms-sm-0 icon-container">
        <Skeleton circle height={56} width={56} />
      </div>
      <div className="d-flex flex-column text-center text-sm-start justify-content-center mt-3 mt-sm-0 col-sm-8 ms-3">
        <Skeleton
          height={12}
          width={130}
          className="align-self-center mb-0 mt-2"
        />
        <Skeleton height={8} width={90} className="align-self-center mb-0" />
      </div>
    </div>
    <div className="d-flex justify-content-between py-3 ps-4">
      <Skeleton height={20} width={140} className="px-4" />
    </div>
  </>
);

export default ProfileSkeleton;
