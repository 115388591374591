import { ContactApi, UserProfileApi } from "internal";

import { getErrors, setCurrentUser } from "utils/utils";

export const FETCH_PROFILE_DETAIL_REQUEST = "FETCH_PROFILE_DETAIL_REQUEST";
export const FETCH_PROFILE_DETAIL_RECEIVED = "FETCH_PROFILE_DETAIL_RECEIVED";
export const FETCH_PROFILE_DETAIL_ERROR = "FETCH_PROFILE_DETAIL_ERROR";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST ";
export const PROFILE_UPDATE_RECEIVED = "PROFILE_UPDATE_RECEIVED";
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR";

export const PROFILE_PICTURE_UPDATE_REQUEST = "PROFILE_PICTURE_UPDATE_REQUEST";
export const PROFILE_PICTURE_UPDATE_RECEIVED =
  "PROFILE_PICTURE_UPDATE_RECEIVED";
export const PROFILE_PICTURE_UPDATE_ERROR = "PROFILE_PICTURE_UPDATE_ERROR";
export const PROFILE_PICTURE_REMOVE_REQUEST = "PROFILE_PICTURE_REMOVE_REQUEST";
export const PROFILE_PICTURE_REMOVE_RECEIVED =
  "PROFILE_PICTURE_REMOVE_RECEIVED";
export const PROFILE_PICTURE_REMOVE_ERROR = "PROFILE_PICTURE_REMOVE_ERROR";

export const SWITCH_USER_LANGUAGE_REQUEST = "SWITCH_USER_LANGUAGE_REQUEST";
export const SWITCH_USER_LANGUAGE_RECEIVED = "SWITCH_USER_LANGUAGE_RECEIVED";
export const SWITCH_USER_LANGUAGE_FAILED = "SWITCH_USER_LANGUAGE_FAILED";

export const CLEAR_PROFILE_ERRORS = "CLEAR_PROFILE_ERRORS";

const fetchProfileDetailReceived = (profile) => ({
  type: FETCH_PROFILE_DETAIL_RECEIVED,
  profile,
});

const fetchProfileDetailError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_PROFILE_DETAIL_ERROR,
    error,
  };
};

export const fetchProfileDetailRequest = (id, type) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PROFILE_DETAIL_REQUEST });

    const { data } = await ContactApi.fetchContactDetail(id, type);

    dispatch(fetchProfileDetailReceived(data));

    setCurrentUser(data.status, "status");
    setCurrentUser(data.active_call_profile);
    setCurrentUser(
      data.is_automatic_call_profile_switching_enabled,
      "is_automatic_call_profile_switching_enabled"
    );
  } catch (error) {
    dispatch(fetchProfileDetailError(error));
  }
};

export const profileUpdateReceived = (profile) => ({
  type: PROFILE_UPDATE_RECEIVED,
  payload: profile,
});

export const profileUpdateError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: PROFILE_UPDATE_ERROR,
    error,
  };
};

export const clearProfileErrors = () => ({
  type: CLEAR_PROFILE_ERRORS,
});

export const profileUpdateRequest =
  (userLicenseId, attribute) => async (dispatch) => {
    try {
      dispatch({ type: PROFILE_UPDATE_REQUEST });

      const response = await UserProfileApi.updateProfile(
        userLicenseId,
        attribute
      );

      dispatch(profileUpdateReceived(response.data));
    } catch (error) {
      dispatch(profileUpdateError(error));
    }
  };

export const profilePictureUpdateReceived = (profilePicture) => ({
  type: PROFILE_PICTURE_UPDATE_RECEIVED,
  payload: profilePicture,
});

export const profilePictureUpdateError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: PROFILE_PICTURE_UPDATE_ERROR,
    error,
  };
};

export const profilePictureUpdateRequest =
  (userLicenseId, attribute) => async (dispatch) => {
    try {
      dispatch({ type: PROFILE_PICTURE_UPDATE_REQUEST });

      const response = await UserProfileApi.updateProfile(
        userLicenseId,
        attribute
      );

      dispatch(profilePictureUpdateReceived(response.data));
    } catch (error) {
      dispatch(profilePictureUpdateError(error));
    }
  };

export const profileRemoveReceived = (profile) => ({
  type: PROFILE_PICTURE_REMOVE_RECEIVED,
  payload: profile,
});

export const profileRemoveError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: PROFILE_PICTURE_REMOVE_ERROR,
    error,
  };
};

export const profileRemoveRequest =
  (userLicenseId, attribute) => async (dispatch) => {
    try {
      dispatch({ type: PROFILE_PICTURE_REMOVE_REQUEST });

      const response = await UserProfileApi.updateProfile(
        userLicenseId,
        attribute
      );

      dispatch(profileRemoveReceived(response.data));
    } catch (error) {
      dispatch(profileRemoveError(error));
    }
  };

export function switchUserReceived(response) {
  setCurrentUser(response.data.locale, "locale");

  return {
    type: SWITCH_USER_LANGUAGE_RECEIVED,
    user: response.data,
  };
}

export function switchUserFailed(errorData) {
  const { response } = errorData;

  if (response.data.status) {
    getErrors(errorData);
  }

  return {
    type: SWITCH_USER_LANGUAGE_FAILED,
    message: response.data,
  };
}

export const switchUserLanguageRequest = (id, language) => async (dispatch) => {
  try {
    dispatch({ type: SWITCH_USER_LANGUAGE_REQUEST });

    const response = await UserProfileApi.updateLanguage(id, language);

    dispatch(switchUserReceived(response));
  } catch (error) {
    dispatch(switchUserFailed(error));
  }
};
