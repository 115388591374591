import React, { useEffect, useRef } from "react";
import { Translate } from "react-thunk-i18nify";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ActiveSelectValue = ({ activeName }) => {
  if (!activeName) {
    return (
      <div className="ms-1 me-2 position-relative d-flex align-items-center  w-100">
        <p className="name mb-0">
          <Translate value="attributes.selectAnOption" />
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="ms-1 me-1 w-100 position-relative mt-1">
        <p className="name mb-0">{activeName}</p>
      </div>
    </>
  );
};

const DropdownIcon = ({ isDisabled }) => {
  if (isDisabled) return null;

  return <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />;
};

const CallProfileDropdown = ({
  activeValueClassName,
  callProfiles,
  handleUpdateActiveTimeFrameProfile,
  activeName,
  id,
  isDisabled,
  isEditable,
}) => {
  const dropdownButtonRef = useRef(null);
  const dropdownOptionsRef = useRef(null);

  useEffect(() => {
    if (isEditable) return;

    dropdownButtonRef.current.classList.remove("show");
    dropdownOptionsRef.current.classList.remove("show");
  }, [isEditable]);

  const addOptionClassName = (name) => {
    if (activeName !== name) return "";

    return "active-option";
  };

  const disableClassName = isDisabled ? "pe-none" : "";

  return (
    <div className="btn-group custom-dropdown d-flex flex-fill">
      <div
        className={`btn-sm dropdown-toggle text-nowrap position-relative d-flex align-items-center bg-white flex-fill p-1 ${disableClassName}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        ref={dropdownButtonRef}
      >
        <ActiveSelectValue activeName={activeName} />
        <DropdownIcon isDisabled={isDisabled} />
      </div>
      <ul
        className={`dropdown-menu dropdown-menu-end custom-dropdown-menu py-2 mt-1 px-2 border-0 ${activeValueClassName}`}
        ref={dropdownOptionsRef}
      >
        <div className="item-list">
          {callProfiles.map(({ name, ...rest }) => (
            <li key={rest.id}>
              <button
                className={`dropdown-item ${addOptionClassName(
                  name
                )} py-0 px-3 d-flex align-items-center border-0`}
                onClick={() =>
                  handleUpdateActiveTimeFrameProfile({
                    name,
                    timeFrameID: id,
                    ...rest,
                  })
                }
              >
                <span className="mt-1">{name}</span>
              </button>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

ActiveSelectValue.defaultProps = {
  activeName: "",
};

ActiveSelectValue.propTypes = {
  activeName: PropTypes.string,
};

DropdownIcon.defaultProps = {
  isDisabled: false,
};

DropdownIcon.propTypes = {
  isDisabled: PropTypes.bool,
};

CallProfileDropdown.defaultProps = {
  containerClassName: "",
  activeValueClassName: "",
  activeProfileValue: null,
  callProfiles: [{}],
  active: null,
  addIcon: true,
  id: null,
  isDisabled: false,
  isEditable: false,
};

CallProfileDropdown.propTypes = {
  containerClassName: PropTypes.string,
  activeValueClassName: PropTypes.string,
  activeProfileValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.node])
    .isRequired,
  callProfiles: PropTypes.arrayOf(PropTypes.shape({})),
  handleUpdateActiveTimeFrameProfile: PropTypes.func.isRequired,
  activeName: PropTypes.string,
  addIcon: PropTypes.bool,
  id: PropTypes.number,
  isDisabled: PropTypes.bool,
  isEditable: PropTypes.bool,
};

export default CallProfileDropdown;
