import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import {
  faBlenderPhone,
  faHashtag,
  faPenToSquare,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InformationBoxWrapper from "layouts/InformationBoxWrapper/InformationBoxWrapper";

import GeneralVoicemailSkeleton from "components/Skeleton/VoicemailBox/GeneralVoicemailSkeleton";

import { VOICEMAIL_BOXES } from "constants/routes";

import { isEmpty } from "utils/isEmpty";

import GeneralEmptyContent from "./GeneralEmptyContent";
import GeneralItem from "./GeneralItem";

const GeneralVoicemailBoxes = () => {
  const { generalVoicemailBoxes, isLoading } = useSelector(
    ({ voicemailBox }) => voicemailBox
  );

  const { push } = useHistory();

  const {
    email,
    extension_number: extensionNumber,
    maxmsg: maxMsg,
    id,
    cell_number: cellNumber,
  } = generalVoicemailBoxes;

  const activeButtonClassName = isEmpty(generalVoicemailBoxes)
    ? "d-none"
    : "d-none d-md-block";

  const activeMobileEditClassName = isEmpty(generalVoicemailBoxes)
    ? "d-none"
    : "d-md-none";

  const handleConfigureNavigation = () => {
    push(VOICEMAIL_BOXES.addID(VOICEMAIL_BOXES.CONFIG, id));
  };

  if (isLoading) {
    return <GeneralVoicemailSkeleton />;
  }

  return (
    <article className="general-voicemail-boxes p-3 bg-white">
      <InformationBoxWrapper
        title="voicemailBoxes.general.generalVoicemailBoxes"
        titleIcon={faVoicemail}
        titleContent={
          <>
            <button
              data-cy="configure-general-voicemail-box"
              className={`btn btn-primary configure-button fw-bold  ms-md-auto ${activeButtonClassName}`}
              type="button"
              onClick={handleConfigureNavigation}
            >
              <FontAwesomeIcon icon={faPenToSquare} className="me-1" />
              <Translate value="common.configure" />
            </button>
            <span
              className={`bg-secondary rounded-circle edit-icon d-flex align-items-center justify-content-center  ${activeMobileEditClassName}`}
              type="button"
              role="button"
              tabIndex="0"
              onClick={handleConfigureNavigation}
              onKeyPress={(e) => {
                e.code == "Enter" && handleConfigureNavigation();
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} className="text-white" />
            </span>
          </>
        }
        showTooltip
        tooltipContent={
          <Translate
            value="voicemailBoxes.general.voicemailBoxOfYourUserLicense"
            email={email}
          />
        }
      >
        <div
          data-cy="general-voicemail"
          className="px-2 px-md-5 py-2 d-flex justify-content-between flex-wrap"
        >
          <GeneralItem
            icon={faBlenderPhone}
            title="common.extensionNumber"
            content={extensionNumber}
          />
          <GeneralItem
            icon={faBlenderPhone}
            title="common.cellNumber"
            content={cellNumber}
          />
          <GeneralItem
            icon={faVoicemail}
            title="common.emailAddress"
            content={email}
          />
          <GeneralItem
            icon={faHashtag}
            title="voicemailBoxes.general.numberOfAllowedMessages"
            content={maxMsg}
          />
          <GeneralEmptyContent generalVoicemailBoxes={generalVoicemailBoxes} />
        </div>
      </InformationBoxWrapper>
    </article>
  );
};

export default GeneralVoicemailBoxes;
