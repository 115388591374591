import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import AgentImage from "components/Home/QueueDashboard/AgentImage";

import { AGENT_TYPES, STATUS_TYPES } from "constants/agent";

const RegisteredAgent = ({ agent: { type, ...restAgent } }) => {
  if (type == AGENT_TYPES.EXTERNAL_NUMBER) {
    const { name, profile_picture: profilePicture, id } = restAgent;

    return (
      <div
        className="register-agent d-flex justify-content-between my-3 py-2"
        data-cy="registered-agents"
      >
        <div className="d-flex">
          <AgentImage id={id} image={profilePicture?.url} status={null} />
          <p className="mb-0 align-self-center agent-name">{name}</p>
        </div>
        <p className="mb-0 align-self-center text-uppercase agent-status fw-bold offline">
          <Translate value="common.offline" />
        </p>
      </div>
    );
  }

  const { name, status, profile_picture: profilePicture, id } = restAgent;

  const statusClassName = status ? status : "unavailable";

  return (
    <div
      className="register-agent d-flex justify-content-between my-3 pe-1"
      data-cy="registered-agents"
    >
      <div className="d-flex">
        <AgentImage id={id} image={profilePicture?.url} status={status} />
        <p
          className="mb-0 align-self-center 
         agent-name"
        >
          {name}
        </p>
      </div>
      <p
        className={`mb-0 align-self-center text-uppercase agent-status fw-bold ${statusClassName}`}
      >
        {STATUS_TYPES[status]}
      </p>
    </div>
  );
};

RegisteredAgent.defaultProps = {
  agent: {},
};

RegisteredAgent.propTypes = {
  agent: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.oneOf(Object.values(AGENT_TYPES)),
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default RegisteredAgent;
