import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import AutomaticCallProfileToggler from "components/AutomaticCallProfileToggler/AutomaticCallProfileToggler";
import ActiveCallProfileDropdown from "components/Dropdown/ActiveCallProfile/ActiveCallProfileDropdown";
import ProfileDropdown from "components/Dropdown/ProfileDropdown";

import { PBX_USER_BRAND_LOGO } from "constants/images";
import { HOME } from "constants/routes";

import { fetchCallProfilesRequest } from "redux/actions/callProfile";

const TopBar = ({ setToggleMobileMenu }) => {
  const dispatch = useDispatch();

  const { currentUser, isLoggedIn } = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(fetchCallProfilesRequest(currentUser?.user_license_id));
  }, []);

  if (!isLoggedIn) return null;

  return (
    <header className="top-bar w-100 d-flex align-items-center justify-content-between px-3 position-fixed">
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          className="d-none d-md-block d-lg-none hamburger-menu me-3 mt-2 text-primary"
          onClick={() => setToggleMobileMenu((value) => !value)}
        />
        <Link to={HOME.INDEX}>
          <img
            src={PBX_USER_BRAND_LOGO.SOURCE}
            alt="pbx user logo"
            className="d-none d-md-block"
          />
          <img
            src={PBX_USER_BRAND_LOGO.MOBILE.SOURCE}
            alt="pbx user logo"
            className="d-block d-md-none"
          />
        </Link>
      </div>
      <div className="d-flex">
        <div className="d-none d-md-flex flex-column flex-lg-row align-items-lg-center me-2">
          <p className="current-profile">
            <Translate value="common.currentProfile" />:
          </p>
          <ActiveCallProfileDropdown
            addIcon={false}
            containerClassName="d-none d-md-block"
            activeValueClassName="w-auto ps-0"
          />
        </div>

        <div className="d-flex">
          <div className="d-none d-md-block">
            <AutomaticCallProfileToggler />
          </div>

          <ProfileDropdown />
          <FontAwesomeIcon
            icon={faBars}
            size="2x"
            className="d-block d-md-none text-primary ms-3"
            onClick={() => setToggleMobileMenu((value) => !value)}
          />
        </div>
      </div>
    </header>
  );
};

TopBar.defaultProps = {
  setToggleMobileMenu: () => {},
};

TopBar.propTypes = {
  setToggleMobileMenu: PropTypes.func,
};

export default TopBar;
