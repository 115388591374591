import React from "react";
import { Translate } from "react-thunk-i18nify";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const RetryError = ({ error }) => {
  if (error) {
    return (
      <p className="text-danger mt-1">
        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />{" "}
        {error}
      </p>
    );
  }

  return null;
};

const RetryContent = ({
  heading,
  content,
  isLoading,
  onCancel,
  cancelButtonText,
  retryButtonText,
  retryClassName,
  onConfirm,
  loader,
  error,
}) => (
  <>
    <p className="heading fw-bold mb-0">
      <Translate value={heading} />
    </p>
    <p className="py-3 py-md-4 paragraph fw-normal my-0">{content}</p>
    <RetryError error={error} />
    <div className="w-100 d-flex flex-column flex-sm-row justify-content-between align-items-center">
      <button
        className="btn btn-outline-secondary fw-bold px-5 cancel-button mb-2 mb-sm-0"
        disabled={isLoading}
        onClick={onCancel}
      >
        <Translate value={cancelButtonText} />
      </button>
      <button
        className={`btn confirm-button ${retryClassName} fw-bold px-5 `}
        onClick={onConfirm}
        disabled={isLoading}
      >
        {loader} <Translate value={retryButtonText} />
      </button>
    </div>
  </>
);

RetryError.defaultProps = {
  error: "",
};

RetryError.propTypes = {
  error: PropTypes.string,
};

RetryContent.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.node,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  retryButtonText: PropTypes.string,
  retryClassName: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  loader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  error: PropTypes.string,
};

RetryContent.defaultProps = {
  isLoading: false,
  cancelButtonText: "",
  retryButtonText: "",
  retryClassName: "",
  onCancel: () => {},
  onConfirm: () => {},
  loader: <></>,
  error: "",
  heading: "",
  children: <></>,
};

export default RetryContent;
