import { Translate } from "react-thunk-i18nify";

import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Tooltip from "components/CustomTooltip/Tooltip";

import { isEmpty } from "utils/isEmpty";

const TitleContent = ({ children, className }) => {
  if (!children) return null;

  return (
    <div className={`mt-2 mt-md-0 flex-md-fill title-content ${className}`}>
      {children}
    </div>
  );
};

const ToolTipIcon = ({ children, showTooltip }) => {
  if (!showTooltip) return null;

  return (
    <Tooltip title={children}>
      <FontAwesomeIcon icon={faCircleInfo} className="ms-2 tooltip-icon" />
    </Tooltip>
  );
};

const AdditionalTitleContent = ({ children }) => {
  if (!children) return null;

  return <>{children}</>;
};

const IconContainer = ({ icon, imageSource }) => {
  if (!isEmpty(icon)) {
    return <FontAwesomeIcon icon={icon} className="text-white" />;
  }

  return <img src={imageSource} alt="title icon" className="icon" />;
};

const InformationBoxWrapper = ({
  children,
  title,
  titleIcon,
  titleContent,
  showTooltip,
  tooltipContent,
  additionalTitleContent,
  titleImageSource,
  headerClassName,
  titleClassName,
}) => (
  <div className="information-box-wrapper">
    <div
      className={`header d-flex justify-content-between align-items-center py-2 px-3 ${headerClassName}`}
    >
      <div className="d-flex align-items-center flex-fill">
        <span
          data-cy="icon-wrapper"
          className="rounded-circle icon-container d-flex justify-content-center align-items-center "
        >
          <IconContainer icon={titleIcon} imageSource={titleImageSource} />
        </span>
        <p
          data-cy="voicemail-box-title"
          className="mb-0 ps-2 general-voicemail-title fw-bold"
        >
          <Translate value={title} />
        </p>
        <AdditionalTitleContent>
          {additionalTitleContent}
        </AdditionalTitleContent>
        <ToolTipIcon showTooltip={showTooltip}>{tooltipContent}</ToolTipIcon>
      </div>
      <TitleContent className={titleClassName}>{titleContent}</TitleContent>
    </div>
    <div className="content">{children}</div>
  </div>
);

ToolTipIcon.defaultProps = {
  children: <></>,
  showTooltip: false,
};

ToolTipIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showTooltip: PropTypes.bool,
};

TitleContent.defaultProps = {
  children: <></>,
  className: "",
};

TitleContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

IconContainer.defaultProps = {
  icon: {},
  imageSource: {},
};

IconContainer.propTypes = {
  icon: PropTypes.shape({}),
  imageSource: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

AdditionalTitleContent.defaultProps = {
  children: null,
};

AdditionalTitleContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

InformationBoxWrapper.defaultProps = {
  title: "",
  titleContent: null,
  tooltipContent: null,
  showTooltip: false,
  additionalTitleContent: null,
  titleIcon: {},
  titleImageSource: {},
  headerClassName: "",
  titleClassName: "",
};

InformationBoxWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.shape({}),
  titleContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltipContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleImageSource: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  additionalTitleContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  showTooltip: PropTypes.bool,
  headerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
};

export default InformationBoxWrapper;
