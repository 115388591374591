import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";

import { ConfigureQueueContext } from "context-api/ConfigureQueueContext";

import { QUEUE_TYPE } from "constants/queue";

import { displaySuccess } from "utils/toaster";

import { fetchCallQueuesRequest } from "redux/actions/callQueue";
import {
  clearQueueErrors,
  updateQueueInDashboardRequest,
} from "redux/actions/configureQueue";

import AvailableQueues from "./AvailableQueues/AvailableQueues";
import HomeQueues from "./HomeQueues/HomeQueues";

const ConfigureQueueSidebar = ({ open, setOpen }) => {
  const toggleClassName = open ? "toggle" : "";
  const toggleOverlay = open ? "d-block" : "d-none";
  const [toggleBar, setToggle] = useState(open);

  const {
    queuePriority,
    setQueuePriority,
    isFormEdited,
    setFormEdited,
    updatedAvailableQueues,
    setUpdatedAvailableQueues,
    updatedHomeQueues,
    setUpdatedHomeQueues,
  } = useContext(ConfigureQueueContext);

  const { isSubmitting, commitSuccess } = useSelector(
    ({ configureQueue }) => configureQueue
  );
  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setToggle(false);
      }, 500);
    } else {
      setToggle(true);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedHomeAttributes = updatedHomeQueues.map((queue) => {
      const updatedPriority = queuePriority[queue.asterisk_queue_id].weight;

      delete queue["type"];

      return {
        ...queue,
        weight: updatedPriority,
        _destroy: false,
      };
    });

    updatedAvailableQueues.forEach((queue) => {
      if (queue.type == QUEUE_TYPE.HOME_QUEUE) {
        delete queue["type"];

        updatedHomeAttributes.push({
          ...queue,
          _destroy: true,
        });
      }
    });

    const configureQueueAttributes = {
      dashboard_queue: {
        bulk_update_attributes: updatedHomeAttributes,
      },
    };

    dispatch(
      updateQueueInDashboardRequest(userLicenseID, configureQueueAttributes)
    );
  };

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
      setOpen(false);
      setFormEdited(false);
      setUpdatedAvailableQueues([]);
      setUpdatedHomeQueues([]);
      setQueuePriority({});
      dispatch(fetchCallQueuesRequest(userLicenseID));
      dispatch(clearQueueErrors());
    }
  }, [commitSuccess]);

  if (!toggleBar) return null;

  return (
    <>
      <div
        className={`overlay-sidebar position-fixed ${toggleOverlay} hv-100 wv-100`}
      />

      <section
        className={`agent-management-sidebar configure-queue ${toggleClassName} position-fixed`}
      >
        <form onSubmit={handleSubmit}>
          <div className="agent-management-header d-flex justify-content-between align-items-center p-3">
            <h5 className="mb-0 title fw-bold">
              <Translate value="configureQueue.configureQueuePriority" />
            </h5>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => setOpen(false)}
              className="close-icon fw-normal me-1"
            />
          </div>
          <div className="agent-management-content overflow-auto p-3">
            <div className="agents-content">
              <AvailableQueues />
              <HomeQueues />
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-md-end px-3 py-2 submit-container mt-3">
            <button
              className="edit-button px-5 me-md-3 btn border-0 cancel-button d-none d-md-block"
              onClick={() => setOpen(false)}
              type="button"
            >
              <Translate value="common.cancel" />
            </button>
            <button
              type="submit"
              className="save-button btn btn-primary px-5"
              role="button"
              data-cy="submit"
              disabled={!isFormEdited || isSubmitting}
            >
              <MovingDottedLoader show={isSubmitting} />
              <Translate value="configureQueue.saveSetting" />
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

ConfigureQueueSidebar.defaultProps = {
  open: false,
  setOpen: () => {},
};

ConfigureQueueSidebar.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default ConfigureQueueSidebar;
