import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ActiveCallProfileLabel = ({ activeCallProfile }) => {
  if (!activeCallProfile) return null;

  return (
    <span className="badge active-badge position-absolute d-flex align-items-center justify-content-center">
      <Translate value="common.active" />
    </span>
  );
};

const CallProfileListItem = ({
  iconJson: {
    brand_name: brandName,
    icon_name: iconName,
    class: className,
    color,
  },
  title,
  description,
  selectProfileHandler,
  activeProfileId,
  callProfileID,
}) => {
  const {
    currentUser: { active_call_profile: activeCallProfile },
  } = useSelector(({ auth }) => auth);
  const activeStateClassName = activeProfileId ? "active" : "";

  return (
    <div
      className={`call-profile-list-item ${activeStateClassName} d-flex flex-column align-items-center mx-2 my-3 px-4 py-5 p-md-4 position-relative`}
      role="menuitem"
      onClick={selectProfileHandler}
      data-cy={`call-profile-item-${callProfileID}`}
    >
      <ActiveCallProfileLabel
        activeCallProfile={activeCallProfile?.name === title}
      />
      <div className="rounded-circle icon-container d-flex align-items-center justify-content-center mb-2">
        <FontAwesomeIcon
          icon={[brandName, iconName]}
          color={color}
          className={`text-white profile-icon ${className}`}
        />
      </div>
      <p className="fw-bold item-title mt-2 mb-1 text-center">{title}</p>
      <p className="item-description text-center mb-0">{description}</p>
    </div>
  );
};

ActiveCallProfileLabel.defaultProps = {
  activeCallProfile: false,
};

ActiveCallProfileLabel.propTypes = {
  activeCallProfile: PropTypes.bool,
};

CallProfileListItem.defaultProps = {
  iconJson: {},
  title: "",
  description: "",
  selectProfileHandler: () => {},
  activeProfileId: false,
  callProfileID: 0,
};

CallProfileListItem.propTypes = {
  iconJson: PropTypes.shape({
    brand_name: PropTypes.string,
    icon_name: PropTypes.string,
    class: PropTypes.string,
    color: PropTypes.string,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  selectProfileHandler: PropTypes.func,
  activeProfileId: PropTypes.bool,
  callProfileID: PropTypes.number,
};

export default CallProfileListItem;
