import React from "react";
import { useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { FIXED_CALENDAR } from "constants/routes";

const FixedCalendarBanner = () => {
  const history = useHistory();

  const redirectToFixedCalendar = () => {
    history.push(FIXED_CALENDAR.INDEX);
  };

  return (
    <article
      className={`fixed-calendar-banner d-md-flex flex-column flex-lg-row align-items-center justify-content-between my-3 px-2 py-3 py-md-0`}
    >
      <p className="mb-0 banner-text py-2 pe-1 text-center text-md-start">
        <Translate value="callProfile.default.setupTimeSchedulesForSwitchingProfile" />
      </p>
      <div className="d-flex justify-content-center justify-content-md-start ">
        <button
          className="btn btn-primary fixed-banner-button my-2 my-lg-0 text-nowrap"
          onClick={redirectToFixedCalendar}
        >
          <Translate value="callProfile.default.goToFixedCalendar" />
        </button>
      </div>
    </article>
  );
};

export default FixedCalendarBanner;
