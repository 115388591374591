import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import EmptyAgentUI from "components/Home/Empty/EmptyAgentUI";
import SearchBar from "components/SearchBar/SearchBar";
import AgentsSkeleton from "components/Skeleton/CallQueue/Detail/AgentManagementSidebar/AgentsSkeleton";

import { ConfigureQueueContext } from "context-api/ConfigureQueueContext";

import {
  searchInJson,
  SEARCH_KEY_FOR_HOME_CONFIGURE_QUEUE,
} from "utils/searchInJson";

import { fetchHomeQueueRequest } from "redux/actions/configureQueue";

import HomeQueueTable from "./HomeQueueTable";

const HomeQueues = () => {
  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);
  const { homeQueues, isHomeQueueLoading: isLoading } = useSelector(
    ({ configureQueue }) => configureQueue
  );
  const dispatch = useDispatch();

  const { updatedHomeQueues, setUpdatedHomeQueues } = useContext(
    ConfigureQueueContext
  );

  const [initialValues, setInitialValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [isSelectedID, setSelectedID] = useState(false);

  const handleHomeQueuesSearch = (e) => {
    const searchText = e.target.value;

    setInitialValues(
      searchInJson(
        searchText,
        updatedHomeQueues,
        SEARCH_KEY_FOR_HOME_CONFIGURE_QUEUE,
        (value) => setSearching(value)
      )
    );
  };

  useEffect(() => {
    dispatch(fetchHomeQueueRequest(userLicenseID));
  }, []);

  useEffect(() => {
    const queues = homeQueues.map((queue) => {
      if (!queue.weight || queue.weight > homeQueues.length) {
        return {
          ...queue,
          weight: homeQueues.length,
        };
      }

      return queue;
    });

    setInitialValues(queues);

    setUpdatedHomeQueues(queues);
  }, [homeQueues]);

  if (isLoading) {
    return <AgentsSkeleton />;
  }

  if (updatedHomeQueues.length == 0 && !isSearching) {
    return (
      <EmptyAgentUI
        headingTranslation="configureQueue.QueueInHome"
        titleTranslation="configureQueue.empty.emptyHomeQueueList"
        descriptionTranslation="configureQueue.empty.thereIsNoQueueInHomeAtTheMoment"
      />
    );
  }

  return (
    <div className="available-agents">
      <div className="available-agent-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-between ">
        <p className="mb-0 available-agent-title mb-2 mb-sm-0 fw-bold">
          <Translate value="configureQueue.QueueInHome" />
        </p>
        <SearchBar
          onSearchHandler={handleHomeQueuesSearch}
          placeholderText="attributes.searchHomeQueues"
          className="search-bar"
          isSearchDisabled={isSelectedID}
        />
      </div>

      <HomeQueueTable
        homeQueues={initialValues}
        isSearching={isSearching}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

export default HomeQueues;
