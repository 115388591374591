import React from "react";
import { Field } from "react-final-form";
import Select from "react-select";
import { I18n, I18nReRender } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import FormError from "components/Form/FormError";

import { sipDeviceStyle } from "utils/sipDeviceStyle";
import { requiredArray } from "utils/validation";

const SelectInput = ({
  input,
  meta: { touched, error, submitError },
  ...props
}) => {
  //display according to the selected value and the list
  const formatOptionLabel = (option, { context }) =>
    context === "value" ? option.updatedValue : option.label;

  return (
    <>
      <I18nReRender
        render={() => (
          <Select
            {...input}
            {...props}
            isMulti
            menuShouldScrollIntoView
            styles={sipDeviceStyle(touched, error, submitError)}
            noOptionsMessage={() => I18n.t("attributes.noSipDeviceAvailable")}
            formatOptionLabel={formatOptionLabel}
            isSearchable={false}
          />
        )}
      />
      <FormError error={error} submitError={submitError} touched={touched} />
    </>
  );
};

const SipDeviceDropdown = ({
  show,
  name,
  options,
  validateOptions,
  className,
  ...rest
}) => {
  if (!show) return null;

  if (validateOptions) {
    return (
      <div className={`${className} mb-3`}>
        <Field
          name={name}
          component={SelectInput}
          options={options}
          placeholder={I18n.t("attributes.selectDevice")}
          validate={requiredArray("validationErrors.REQUIRED")}
          {...rest}
        />
      </div>
    );
  }

  return (
    <div className={`${className} mb-3`}>
      <Field
        name={name}
        component={SelectInput}
        options={options}
        placeholder={I18n.t("attributes.selectDevice")}
        {...rest}
      />
    </div>
  );
};

SelectInput.propTypes = {
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    submitError: PropTypes.bool,
  }).isRequired,
};

SipDeviceDropdown.defaultProps = {
  show: false,
  name: "",
  options: [],
  validateOptions: true,
  className: "col-xl-6",
};

SipDeviceDropdown.propTypes = {
  show: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.array,
  validateOptions: PropTypes.bool,
  className: PropTypes.string,
};

export default SipDeviceDropdown;
