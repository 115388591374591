export const CONSTANTS = {
  FLEX_STORE: "persist:flexStore",
  BEARER_TOKEN: "bearerToken",
  CURRENT_SELECTED_LANGUAGE: "currentSelectedLanguage",
};

class LocalStorageManager {
  static storage = window.localStorage;

  static get(key) {
    const data = this.storage.getItem(key);

    if (data) return JSON.parse(data);

    return data;
  }

  static set(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  static remove(key) {
    this.storage.removeItem(key);
  }

  static clear() {
    this.storage.clear();
  }
}

export default LocalStorageManager;
