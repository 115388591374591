import da from "locales/da";
import en from "locales/en";

export const defaultLanguage = "en-US";

const locales = {
  "da-DK": da,
  "en-US": en,
};

export default locales;
