import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import Dropdown from "components/Dropdown/Dropdown";
import PbxUserDropdown from "components/Dropdown/PbxUserDropdown";
import DateRangePicker from "components/Form/CustomDatePicker/DateRangePicker";
import SearchBar from "components/SearchBar/SearchBar";

import HasPermission from "hoc/AccessPermission";

import { CALL_HISTORY_KEYWORD } from "constants/callHistory";
import {
  ALL_CALL_HISTORY,
  INCOMING_CALL,
  MISSED_CALL,
  OUTGOING_CALL,
} from "constants/images";
import { CALL_HISTORY } from "constants/routes";
import { ACTION_LIST } from "constants/userPermission";
import { USER_ROLES } from "constants/userRoles";

import { useQuery } from "utils/useQuery";

import { fetchCallHistoryRequest } from "redux/actions/callHistory";

const callHistoryOptions = [
  {
    keyword: CALL_HISTORY_KEYWORD.ALL_CALL_HISTORY,
    searchKey: "all",
    dataTestId: "call-history-call-type-all",
    title: (
      <>
        <img
          src={ALL_CALL_HISTORY.SOURCE}
          alt="all call history"
          className="me-3"
        />
        <span className="me-3">
          <Translate value="dropdown.callHistory.allCallsHistory" />
        </span>
      </>
    ),
  },
  {
    keyword: CALL_HISTORY_KEYWORD.INCOMING_CALLS,
    searchKey: "incoming",
    dataTestId: "call-history-call-type-incoming",
    title: (
      <>
        <img src={INCOMING_CALL.SOURCE} alt="incoming call" className="me-3" />
        <span className="me-3">
          <Translate value="dropdown.callHistory.incomingCalls" />
        </span>
      </>
    ),
  },
  {
    keyword: CALL_HISTORY_KEYWORD.OUTGOING_CALLS,
    searchKey: "outgoing",
    dataTestId: "call-history-call-type-outgoing",
    title: (
      <>
        <img src={OUTGOING_CALL.SOURCE} alt="outgoing call" className="me-3" />
        <span className="me-3">
          <Translate value="dropdown.callHistory.outgoingCalls" />
        </span>
      </>
    ),
  },
  {
    keyword: CALL_HISTORY_KEYWORD.MISSED_CALLS,
    searchKey: "missed_calls",
    dataTestId: "call-history-call-type-missed-calls",
    title: (
      <>
        <img src={MISSED_CALL.SOURCE} alt="missed call" className="me-3" />
        <span className="me-3">
          <Translate value="dropdown.callHistory.missedCalls" />
        </span>
      </>
    ),
  },
];

const TopHeader = ({
  callHistorySearchHandler,
  callHistories,
  updateCallHistoryTableHandler,
  date,
  setDate,
}) => {
  const [activeCallHistory, setActiveCallHistory] = useState(
    CALL_HISTORY_KEYWORD.ALL_CALL_HISTORY
  );

  const {
    currentUser: { user_license_id: userLicenseId, role },
  } = useSelector(({ auth }) => auth);

  const { replace } = useHistory();
  const { pathname } = useLocation();
  const queryResult = useQuery();
  const callType = queryResult?.call_type;
  const pbxUser = queryResult?.pbx_user;
  const dispatch = useDispatch();

  const activeCallHistoryHandler = (callHistory) => {
    const { keyword } = callHistory;

    if (activeCallHistory == keyword) {
      return "active";
    }

    return "";
  };

  const setCallHistoryType = () => {
    let updatedType;

    if (callType) {
      const { keyword } = callHistoryOptions.find(
        (element) => element.searchKey === callType
      );

      updatedType = keyword;
    } else if (role != USER_ROLES.PBX_ADMIN) {
      replace(`${pathname}?call_type=all`);
    }

    setActiveCallHistory(updatedType || CALL_HISTORY_KEYWORD.ALL_CALL_HISTORY);
  };

  const handleCallHistoryUpdate = (updatedType) => {
    const { keyword, searchKey } = updatedType;

    let newPath = `${pathname}?call_type=${searchKey}`;

    if (pbxUser) {
      newPath = `${newPath}&pbx_user=${pbxUser}`;
    }

    setActiveCallHistory(keyword);
    replace(newPath);
    if (pbxUser) {
      dispatch(fetchCallHistoryRequest(pbxUser, searchKey));
    } else {
      dispatch(fetchCallHistoryRequest(userLicenseId, searchKey));
    }
  };

  useEffect(() => {
    setCallHistoryType();
  }, []);

  const handleClearDate = () => {
    if (callType) {
      dispatch(fetchCallHistoryRequest(userLicenseId, callType));
    } else {
      dispatch(fetchCallHistoryRequest(userLicenseId, "all"));
    }
  };

  return (
    <article className="top-header py-3 ps-md-1 pe-md-2 d-flex flex-column flex-md-row justify-content-between">
      <div className="d-flex flex-column flex-lg-row justify-content-between pe-md-0 w-100 mb-3 mb-md-0">
        <div className="d-flex flex-column flex-md-row mb-2 mb-md-0">
          <Dropdown
            active={
              <p className="fw-bold mb-0">
                <Translate
                  value={`dropdown.callHistory.${activeCallHistory}`}
                />
                <span className="ms-1">({callHistories.length})</span>
              </p>
            }
            validActiveClassName={activeCallHistoryHandler}
            options={callHistoryOptions}
            handleDropdownUpdate={handleCallHistoryUpdate}
            handleDropdownDisabled={activeCallHistoryHandler}
            containerClassName=" border-0 custom-button fw-bold"
            activeClassName="px-0 px-sm-2 custom-button border-0"
          />
          <HasPermission perform={ACTION_LIST.PBX_USER_CALL_HISTORY_VIEW}>
            <PbxUserDropdown
              handleSelectedPbxUser={updateCallHistoryTableHandler}
              ownerText={
                <Translate value="dropdown.pbxUsersList.ownCallHistory" />
              }
              queryName="call_type"
              pathIndex={CALL_HISTORY.INDEX}
              initialQueryValue="all"
            />
          </HasPermission>
        </div>

        <div className="d-flex mt-2 mt-lg-0">
          <div className="d-flex flex-column flex-sm-row w-100">
            <p className="filter-dates d-flex align-items-sm-center mb-0 text-nowrap me-2 me-md-0">
              <Translate value="common.filterDates" />
            </p>
            <DateRangePicker
              date={date}
              setDate={setDate}
              onClearDate={handleClearDate}
            />
          </div>

          <SearchBar
            data-testid="search-bar-large-devices"
            onSearchHandler={callHistorySearchHandler}
            placeholderText="attributes.searchAllHistory"
            className="call-history-search-bar ps-0 ps-md-3 d-none d-md-block w-100"
          />
        </div>
      </div>

      <SearchBar
        data-testid="search-bar-mobile"
        onSearchHandler={callHistorySearchHandler}
        placeholderText="attributes.searchAllHistory"
        className="call-history-search-bar table-search-bar ps-md-0 pe-md-2 px-md-3 w-100 d-block d-md-none"
      />
    </article>
  );
};

TopHeader.defaultProps = {
  callHistorySearchHandler: () => {},
  callHistories: [],
  updateCallHistoryTableHandler: () => {},
  date: [],
  setDate: () => {},
};

TopHeader.propTypes = {
  callHistorySearchHandler: PropTypes.func.isRequired,
  callHistories: PropTypes.arrayOf(PropTypes.object),
  updateCallHistoryTableHandler: PropTypes.func,
  setDate: PropTypes.func,
  date: PropTypes.shape([]),
};

export default TopHeader;
