import React from "react";
import { Field } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import OptionSelectDropdown from "components/Dropdown/SelectDropdown/OptionSelectDropdown";

import { required } from "utils/validation";

const OutgoingCallItem = ({
  input: {
    name,
    value: { device_name: deviceName, outgoingCall },
  },
  options,
}) => {
  const additionalOptions = () => {
    if (outgoingCall?.id) {
      return { id: outgoingCall.id };
    }

    return null;
  };

  if (!options.length) return null;

  return (
    <div className="outgoing-call-item row py-3">
      <label className="col-md-5 device-name align-self-center mt-1 mb-2 mb-md-0">
        <Translate
          value="callProfile.outgoing.outgoingCallsFromDeviceDisplay"
          device={deviceName}
        />
        :
      </label>
      <Field
        name={`${name}.outgoingCall`}
        component={OptionSelectDropdown}
        validate={required("validationErrors.REQUIRED")}
        options={options}
        containerClassName="col-md-7 col-lg-5 fw-normal flex-column justify-content-start"
        activeClassName="selected-call-routing px-2"
        placeholderTranslation="attributes.selectAnOption"
        menuClassName="outgoing-call-menu py-0"
        className="outgoing-call-menu-item"
        errorClassName="align-self-start ms-2"
        additionAttributeToOptions={{ ...additionalOptions() }}
        disableRemoveSelectedOption
      />
    </div>
  );
};

OutgoingCallItem.defaultProps = {
  options: [],
  input: {},
};

OutgoingCallItem.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
      device_name: PropTypes.string,
      outgoingCall: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default React.memo(OutgoingCallItem);
