import React from "react";
import Skeleton from "react-loading-skeleton";

const NoteSkeleton = () => (
  <div className="note-container">
    <div className="d-flex justify-content-between py-3 ps-2 align-items-center px-2">
      <Skeleton height={20} width={120} className="px-4" />
      <Skeleton height={30} width={120} className="px-4" />
    </div>
    <div className="note px-2 px-md-3 my-0">
      <Skeleton height={150} width="100%" />
      <div className="my-3 action-container border-start-0 border-end-0 py-3 d-flex justify-content-between flex-column flex-xl-row">
        <div className="d-flex align-items-start align-items-md-center flex-column flex-lg-row gap-2 gap-lg-0">
          <Skeleton height={10} width={80} className="text-center mt-3" />
          <Skeleton height={25} width={80} className="mx-md-3" />
          <Skeleton height={25} width={80} />
        </div>
        <Skeleton height={30} width={100} className="mt-2 mt-md-0" />
      </div>
    </div>
  </div>
);

export default NoteSkeleton;
