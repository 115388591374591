import { AuthApi } from "internal";

import { setKeepMeSignedIn } from "utils/auth";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVED = "LOGIN_RECEIVED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_CLEAR_ERRORS = "LOGIN_CLEAR_ERRORS";

export const LOGOUT = "LOGOUT";
export const SESSION_EXPIRED = "SESSION_EXPIRED";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVED = "RESET_PASSWORD_RECEIVED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_CLEAR_ERRORS = "RESET_PASSWORD_CLEAR_ERRORS";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_RECEIVED = "SET_PASSWORD_RECEIVED";
export const SET_PASSWORD_FAILED = "SET_PASSWORD_FAILED";
export const SET_PASSWORD_CLEAR_ERRORS = "SET_PASSWORD_CLEAR_ERRORS";

export const UPDATE_BEARER_TOKEN_RECEIVED = "UPDATE_BEARER_TOKEN_RECEIVED";

export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

export function loginReceived(data) {
  return {
    type: LOGIN_RECEIVED,
    tokens: data,
  };
}

export function loginFailed(message) {
  return {
    type: LOGIN_FAILED,
    message: message,
  };
}

export function loginClearErrors() {
  return {
    type: LOGIN_CLEAR_ERRORS,
  };
}

export const loginRequest =
  (username, password, keepMeSignedIn) => async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await AuthApi.login(username, password);

      setKeepMeSignedIn(keepMeSignedIn);

      dispatch(loginReceived(response.data));
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

export const updateBearerTokenReceived = (bearerToken) => async (dispatch) =>
  dispatch({
    type: UPDATE_BEARER_TOKEN_RECEIVED,
    tokens: bearerToken,
  });

export const updateBearerTokenFailed = (message) => ({
  type: UPDATE_BEARER_TOKEN_FAILED,
  message,
});

const resetPasswordReceived = (data) => ({
  type: RESET_PASSWORD_RECEIVED,
  tokens: data,
});

const resetPasswordFailed = (message) => ({
  type: RESET_PASSWORD_FAILED,
  message: message,
});

export function resetPasswordClearErrors() {
  return {
    type: RESET_PASSWORD_CLEAR_ERRORS,
  };
}

export const resetPasswordRequest = (username) => (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const response = AuthApi.resetPassword(username);

    dispatch(resetPasswordReceived(response.data));
  } catch (error) {
    dispatch(resetPasswordFailed(error));
  }
};

export function setPasswordReceived(response) {
  return {
    type: SET_PASSWORD_RECEIVED,
    tokens: response?.data,
  };
}

export function setPasswordFailed(message) {
  return {
    type: SET_PASSWORD_FAILED,
    message: message,
  };
}

export const setPasswordRequest =
  (password, passwordConfirmation, resetPasswordToken) => async (dispatch) => {
    dispatch({ type: SET_PASSWORD_REQUEST });
    try {
      const data = await AuthApi.setPassword(
        password,
        passwordConfirmation,
        resetPasswordToken
      );

      dispatch(setPasswordReceived(data));
    } catch (e) {
      dispatch(setPasswordFailed(e));
    }
  };

export const setPasswordClear = () => ({
  type: SET_PASSWORD_CLEAR_ERRORS,
});

export const sessionExpiredRequest = () => (dispatch) => {
  if (dispatch) {
    dispatch({ type: SESSION_EXPIRED });
  } else {
    return { type: SESSION_EXPIRED };
  }
};

export const updateCurrentUser = (user) => ({
  type: UPDATE_CURRENT_USER,
  user,
});

export const logoutRequest = () => (dispatch) => {
  if (dispatch) {
    dispatch({ type: LOGOUT });
  } else {
    return (dispatch) => {
      dispatch({ type: LOGOUT });
    };
  }
};
