import React from "react";

import PropTypes from "prop-types";

import CallQueueEmptyContent from "components/Home/Empty/CallQueueEmptyContent";

import RegisteredAgent from "./RegisteredAgent";

const RegisteredAgentsList = ({ registeredAgents }) => {
  if (registeredAgents.length === 0) {
    return <CallQueueEmptyContent />;
  }

  return registeredAgents.map((agent) => (
    <RegisteredAgent agent={agent} key={agent.id} />
  ));
};

RegisteredAgentsList.defaultProps = {
  registeredAgents: [],
};
RegisteredAgentsList.propTypes = {
  registeredAgents: PropTypes.shape([]),
};

export default RegisteredAgentsList;
