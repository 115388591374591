import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  CONTACT_LIST: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/contacts?filter[type]=:type`,
  CONTACT_DETAIL: `${apiUrlConfig.pbxUserEndPoint()}/contacts/:id?type=:type`,
};

class ContactApi extends AuthenticatedRequest {
  static fetchContacts(userLicenseId, type) {
    const fetchUrl = URLS.CONTACT_LIST.replace(
      ":user_license_id",
      userLicenseId
    );
    const urlWithFilter = fetchUrl.replace(":type", type);

    return this.get(urlWithFilter);
  }
  static fetchContactDetail(id, type) {
    const FETCH_DETAIL_URL = URLS.CONTACT_DETAIL.replace(":id", id).replace(
      ":type",
      type
    );

    return this.get(FETCH_DETAIL_URL);
  }
}

export default ContactApi;
