import React from "react";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import PropTypes from "prop-types";

const FixedCalendarHeader = ({
  handleDateSelect,
  data,
  selectedLocale,
  eventType,
}) => (
  <p
    className="calendar-header mb-0"
    type="button"
    tabIndex={0}
    onClick={() => handleDateSelect(data, eventType)}
  >
    <span className="d-inline-block d-xl-none">
      {format(data.date, "ccc", { locale: selectedLocale })}
    </span>
    <span className="d-none d-xl-inline-block">
      {format(data.date, "cccc", { locale: selectedLocale })}
    </span>
    <span className="ms-2 edit-icon-container rounded-circle d-inline-block">
      <FontAwesomeIcon icon={faPen} className="edit-icon" />
    </span>
  </p>
);

FixedCalendarHeader.defaultProps = {
  handleDateSelect: () => {},
  data: {},
  selectedLocale: {},
  eventType: "",
};

FixedCalendarHeader.propTypes = {
  handleDateSelect: PropTypes.func,
  data: PropTypes.shape({ date: PropTypes.shape({}) }),
  selectedLocale: PropTypes.shape({}),
  eventType: PropTypes.string,
};

export default FixedCalendarHeader;
