import { CallQueueApi } from "internal";

import {
  AVAILABLE_AGENTS,
  CALL_IN_QUEUE_DECISION_TYPES,
} from "constants/agent";

import { getErrors } from "utils/utils";

export const FETCH_CALL_QUEUES_REQUEST = "FETCH_CALL_QUEUES_REQUEST";
export const FETCH_CALL_QUEUES_RECEIVED = "FETCH_CALL_QUEUES_RECEIVED";
export const FETCH_CALL_QUEUES_ERROR = "FETCH_CALL_QUEUES_ERROR";

export const FETCH_REGISTERED_AGENTS_REQUEST =
  "FETCH_REGISTERED_AGENTS_REQUEST";
export const FETCH_REGISTERED_AGENTS_RECEIVED =
  "FETCH_REGISTERED_AGENTS_RECEIVED";
export const FETCH_REGISTERED_AGENTS_FAILED = "FETCH_REGISTERED_AGENTS_FAILED";

export const FETCH_AVAILABLE_AGENTS_IN_QUEUE_REQUEST =
  "FETCH_AVAILABLE_AGENTS_IN_QUEUE_REQUEST";
export const FETCH_AVAILABLE_AGENTS_IN_QUEUE_RECEIVED =
  "FETCH_AVAILABLE_AGENTS_IN_QUEUE_IN_HOME_RECEIVED";
export const FETCH_AVAILABLE_AGENTS_IN_QUEUE_FAILED =
  "FETCH_AVAILABLE_AGENTS_IN_QUEUE_IN_HOME_FAILED";

export const FETCH_REGISTERED_DEVICES_IN_QUEUE_REQUEST =
  "FETCH_REGISTERED_DEVICES_IN_QUEUE_REQUEST";
export const FETCH_REGISTERED_DEVICES_IN_QUEUE_RECEIVED =
  "FETCH_REGISTERED_DEVICES_IN_QUEUE_RECEIVED";
export const FETCH_REGISTERED_DEVICES_IN_QUEUE_FAILED =
  "FETCH_REGISTERED_DEVICES_IN_QUEUE_FAILED";

export const FETCH_CALL_QUEUE_STATISTICS_REQUEST =
  "FETCH_CALL_QUEUE_STATISTICS_REQUEST";
export const FETCH_CALL_QUEUE_STATISTICS_RECEIVED =
  "FETCH_CALL_QUEUE_STATISTICS_RECEIVED";
export const FETCH_CALL_QUEUE_STATISTICS_FAILED =
  "FETCH_CALL_QUEUE_STATISTICS_FAILED";

export const FETCH_TOTAL_CALLS_IN_QUEUE_REQUEST =
  "FETCH_TOTAL_CALLS_IN_QUEUE_REQUEST";
export const FETCH_TOTAL_CALLS_IN_QUEUE_RECEIVED =
  "FETCH_TOTAL_CALLS_IN_QUEUE_RECEIVED";
export const FETCH_TOTAL_CALLS_IN_QUEUE_FAILED =
  "FETCH_TOTAL_CALLS_IN_QUEUE_FAILED";

export const UPDATE_AGENTS_REQUEST = "UPDATE_AGENTS_REQUEST";
export const UPDATE_AGENTS_RECEIVED = "UPDATE_AGENTS_RECEIVED";
export const UPDATE_AGENTS_FAILED = "UPDATE_AGENTS_FAILED";

export const FETCH_DEVICES_IN_QUEUE_REQUEST = "FETCH_DEVICES_IN_QUEUE_REQUEST";
export const FETCH_DEVICES_IN_QUEUE_RECEIVED =
  "FETCH_DEVICES_IN_QUEUE_RECEIVED";
export const FETCH_DEVICES_IN_QUEUE_FAILED = "FETCH_DEVICES_IN_QUEUE_FAILED";

export const ADD_DEVICE_NUMBER_IN_QUEUE_REQUEST =
  "ADD_DEVICE_NUMBER_IN_QUEUE_REQUEST";
export const ADD_DEVICE_NUMBER_IN_QUEUE_RECEIVED =
  "ADD_DEVICE_NUMBER_IN_QUEUE_RECEIVED";
export const ADD_DEVICE_NUMBER_IN_QUEUE_FAILED =
  "ADD_DEVICE_NUMBER_IN_QUEUE_FAILED";

export const REMOVE_DEVICE_NUMBER_IN_QUEUE_REQUEST =
  "REMOVE_DEVICE_NUMBER_IN_QUEUE_REQUEST";
export const REMOVE_DEVICE_NUMBER_IN_QUEUE_RECEIVED =
  "REMOVE_DEVICE_NUMBER_IN_QUEUE_RECEIVED";
export const REMOVE_DEVICE_NUMBER_IN_QUEUE_FAILED =
  "REMOVE_DEVICE_NUMBER_IN_QUEUE_FAILED";

export const REMOVE_CALL_FROM_QUEUE = "REMOVE_CALL_FROM_QUEUE";
export const ADD_CALL_TO_QUEUE = "ADD_CALL_TO_QUEUE";
export const UPDATE_STATUS_OF_REGISTERED_AGENTS_QUEUE =
  "UPDATE_STATUS_OF_REGISTERED_AGENTS_QUEUE";

export const UPDATE_CALL_QUEUE_STATISTICS = "UPDATE_CALL_QUEUE_STATISTICS";

export const CLEAR_CALL_QUEUE_ERRORS = "CLEAR_CALL_QUEUE_ERRORS";

const fetchCallQueuesReceived = (data) => ({
  type: FETCH_CALL_QUEUES_RECEIVED,
  data,
});

const fetchCallQueuesError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_CALL_QUEUES_ERROR,
    error,
  };
};

export const fetchCallQueuesRequest = (userLicenseID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CALL_QUEUES_REQUEST });

    const response = await CallQueueApi.fetchQueueDashboard(userLicenseID);

    dispatch(fetchCallQueuesReceived(response.data));
  } catch (error) {
    dispatch(fetchCallQueuesError(error));
  }
};

const fetchRegisteredAgentsReceived = (data) => ({
  type: FETCH_REGISTERED_AGENTS_RECEIVED,
  data,
});

const fetchRegisteredAgentsFailed = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_REGISTERED_AGENTS_FAILED,
    data: { id, error },
  };
};

export const fetchRegisterAgentsRequest =
  (pbxID, queueID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_REGISTERED_AGENTS_REQUEST, id: queueID });

      const response = await CallQueueApi.fetchRegisterAgents(pbxID, queueID);

      dispatch(
        fetchRegisteredAgentsReceived({
          registeredAgents: response.data,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(fetchRegisteredAgentsFailed(error, queueID));
    }
  };

export const silentFetchRegisteredAgentsRequest =
  (pbxID, queueID) => async (dispatch) => {
    try {
      const response = await CallQueueApi.fetchRegisterAgents(pbxID, queueID);

      dispatch(
        fetchRegisteredAgentsReceived({
          registeredAgents: response.data,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(fetchRegisteredAgentsFailed(error, queueID));
    }
  };

const fetchAvailableAgentsInQueueReceived = (data) => ({
  type: FETCH_AVAILABLE_AGENTS_IN_QUEUE_RECEIVED,
  data,
});

const fetchAvailableAgentsInQueueFailed = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_AVAILABLE_AGENTS_IN_QUEUE_FAILED,
    data: { id, error },
  };
};

export const fetchAvailableAgentsInQueueRequest =
  (pbxID, queueID) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_AVAILABLE_AGENTS_IN_QUEUE_REQUEST,
        id: queueID,
      });

      const response = await CallQueueApi.fetchAvailableAgentsInQueue(
        pbxID,
        queueID
      );

      const data = response.data.map((item, index) => ({
        ...item,
        id: Date.now() + index,
        type: AVAILABLE_AGENTS,
        penalty: 10,
      }));

      dispatch(
        fetchAvailableAgentsInQueueReceived({
          availableAgents: data,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(fetchAvailableAgentsInQueueFailed(error, queueID));
    }
  };

const fetchRegisteredAgentsInQueueReceived = (data) => ({
  type: FETCH_REGISTERED_DEVICES_IN_QUEUE_RECEIVED,
  data,
});

const fetchRegisteredAgentsInQueueFailed = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_REGISTERED_DEVICES_IN_QUEUE_FAILED,
    data: { error, id },
  };
};

export const fetchRegisteredAgentsInQueueRequest =
  (pbxID, queueID) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_REGISTERED_DEVICES_IN_QUEUE_REQUEST,
        id: queueID,
      });

      const response = await CallQueueApi.fetchRegisteredDevicesInQueue(
        pbxID,
        queueID
      );

      dispatch(
        fetchRegisteredAgentsInQueueReceived({
          registeredAgents: response.data,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(fetchRegisteredAgentsInQueueFailed(error, queueID));
    }
  };

const updateAgentsReceived = (data) => ({
  type: UPDATE_AGENTS_RECEIVED,
  data,
});

const updateAgentsFailed = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: UPDATE_AGENTS_FAILED,
    data: { error, id },
  };
};

export const updateAgentsRequest =
  (pbxID, queueID, data) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_AGENTS_REQUEST, id: queueID });

      const response = await CallQueueApi.update(pbxID, queueID, data);

      dispatch(updateAgentsReceived({ data: response.data, id: queueID }));
    } catch (error) {
      dispatch(updateAgentsFailed(error, queueID));
    }
  };

const callQueueStatisticsReceived = (data) => ({
  type: FETCH_CALL_QUEUE_STATISTICS_RECEIVED,
  data,
});

const callQueueStatisticsFailed = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_CALL_QUEUE_STATISTICS_FAILED,
    data: { error, id },
  };
};

export const callQueueStatisticsRequest =
  (pbxID, queueID) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_CALL_QUEUE_STATISTICS_REQUEST,
        id: queueID,
      });

      const { data } = await CallQueueApi.fetchStatistics(pbxID, queueID);

      dispatch(callQueueStatisticsReceived({ ...data, queue_id: queueID }));
    } catch (error) {
      dispatch(callQueueStatisticsFailed(error, queueID));
    }
  };

export const callQueuesStatisticsUpdate =
  (pbxID, queueID) => async (dispatch) => {
    try {
      const { data } = await CallQueueApi.fetchStatistics(pbxID, queueID);

      dispatch(callQueueStatisticsReceived({ ...data, queue_id: queueID }));
    } catch (error) {
      dispatch(callQueueStatisticsFailed(error, queueID));
    }
  };

export const totalCallsInQueueReceived = (data) => ({
  type: FETCH_TOTAL_CALLS_IN_QUEUE_RECEIVED,
  data,
});

export const totalCallsInQueueFailed = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_TOTAL_CALLS_IN_QUEUE_FAILED,
    data: { error, id },
  };
};

export const fetchTotalCallsInQueueRequest =
  (pbxID, queueID) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_TOTAL_CALLS_IN_QUEUE_REQUEST,
        id: queueID,
      });

      const response = await CallQueueApi.fetchTotalCalls(pbxID, queueID);

      dispatch(
        totalCallsInQueueReceived({ totalCalls: response.data, id: queueID })
      );
    } catch (error) {
      dispatch(totalCallsInQueueFailed(error, queueID));
    }
  };

export const fetchDevicesInQueueReceived = (data) => ({
  type: FETCH_DEVICES_IN_QUEUE_RECEIVED,
  data,
});

export const fetchDevicesInQueueError = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_DEVICES_IN_QUEUE_FAILED,
    data: { id, error },
  };
};

export const fetchDeviceInQueueRequest =
  (userLicenseID, queueID) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_DEVICES_IN_QUEUE_REQUEST,
        id: queueID,
      });

      const response = await CallQueueApi.fetchDevicesInQueue(
        userLicenseID,
        queueID
      );

      dispatch(
        fetchDevicesInQueueReceived({
          totalDevices: response.data,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(totalCallsInQueueFailed(error, queueID));
    }
  };

const addDeviceNumberInQueueReceived = (data) => ({
  type: ADD_DEVICE_NUMBER_IN_QUEUE_RECEIVED,
  data,
});

const addDeviceNumberInQueueError = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: ADD_DEVICE_NUMBER_IN_QUEUE_FAILED,
    data: { error, id },
  };
};

export const addDeviceInQueueRequest =
  (pbxID, queueID, number) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_DEVICE_NUMBER_IN_QUEUE_REQUEST,
        id: queueID,
      });

      await CallQueueApi.addDeviceInQueue(pbxID, queueID, number);

      dispatch(
        addDeviceNumberInQueueReceived({
          device: number,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(addDeviceNumberInQueueError(error, queueID));
    }
  };

const removeDeviceNumberInQueueReceived = (data) => ({
  type: REMOVE_DEVICE_NUMBER_IN_QUEUE_RECEIVED,
  data,
});

const removeDeviceNumberInQueueError = (errorData, id) => {
  const error = getErrors(errorData);

  return {
    type: REMOVE_DEVICE_NUMBER_IN_QUEUE_FAILED,
    data: { error, id },
  };
};

export const removeDeviceInQueueRequest =
  (pbxID, queueID, number) => async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_DEVICE_NUMBER_IN_QUEUE_REQUEST,
        id: queueID,
      });

      await CallQueueApi.removeDeviceInQueue(pbxID, queueID, number);

      dispatch(
        removeDeviceNumberInQueueReceived({
          device: number,
          id: queueID,
        })
      );
    } catch (error) {
      dispatch(removeDeviceNumberInQueueError(error, queueID));
    }
  };

export const updateCallInQueue = (data) => {
  if (data.decision_type === CALL_IN_QUEUE_DECISION_TYPES.ADD_TO_QUEUE) {
    return {
      type: ADD_CALL_TO_QUEUE,
      data,
    };
  } else {
    return {
      type: REMOVE_CALL_FROM_QUEUE,
      data,
    };
  }
};

export const updateRegisteredAgentStatusOfOwnUser = (data) => ({
  type: UPDATE_STATUS_OF_REGISTERED_AGENTS_QUEUE,
  data,
});

export const updateCallQueueStatistics = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CALL_QUEUE_STATISTICS,
    data,
  });
};

export const clearCallQueueErrors = () => ({
  type: CLEAR_CALL_QUEUE_ERRORS,
});
