export default {
  attributes: {
    password: "Password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    emailAddress: "Email Address",
    searchContact: "Search Contact",
    firstName: "First Name",
    lastName: "Last Name",
    searchAllHistory: "Search All History",
    profileName: "Profile Name",
    description: "Description",
    icon: "Icon",
    searchCellPhoneNumber: "Search cellphone number",
    pinCode: "Pin Code",
    maximumNumberOfMessagesAllowed: "Maximum Number of Messages allowed",
    minimumNumberOfAMessage: "Minimum length of a message",
    maximumNumberOfAMessage: "Maximum length of a message",
    selectAnOption: "Select an option",
    selectDevice: "Select Device/s",
    noSipDeviceAvailable: "No SIP Device/s Available",
    insertNumber: "Insert number",
    searchAllQueues: "Search All Queues",
    searchAvailableAgents: "Search available agents",
    searchRegisteredAgents: "Search registered agents",
    searchAvailableQueues: "Search available queues",
    searchHomeQueues: "Search home queues",
    externalNumber: "External Number",
    name: "Name",
    number: "Number",
    noProfile: "No Profile",
  },
  auth: {
    signIn: {
      signInToConnectedWithPBX: "Sign in to Stay Connected PBX",
      NotAStayConnectedCustomer: "Not a Stay Connected Customer?",
      forgetPassword: "Forgot Password?",
      keepMeSignedIn: "Keep me signed in",
    },
    forgotPassword: {
      recoverYourPassword: "Recover your password now",
      description:
        "Enter the email associated with your account and we will send an email with instruction to reset your password",
      sendAnInstruction: "Send an instruction",
      signInInstead: "Sign In Instead?",
    },
    emailNotification: {
      checkYourEmail: "Check your email now",
      description:
        "We have sent a password recovery instruction to your email. Please check and work accordingly.",
      changedMyPassword: "I have changed my password, Login Now",
    },
    newPassword: {
      setNewPassword: "Set new password",
      description:
        "Your new password must be different from you previous used password",
      setPassword: "Set Password",
    },
    passwordNotification: {
      congratulationNewPassword: "Congratulations! you have new password now",
      description:
        "You can start using this newly created password from next time. Keep in mind that you should not share this with anyone else",
      okLetLoginNow: "OK! Let’s Login Now",
    },
    logoutModal: {
      whoopsYourSessionHasExpired: "Whoops, your session has expired!",
      description:
        " Your session has expired due to inactivity. Please login again.",
    },
  },
  common: {
    loading: "Loading",
    expireIn: "Expires in",
    saveNote: "Save Note",
    clearNote: "Clear Note",
    updateNote: "Update Note",
    note: "Note",
    contacts: "Contacts",
    logout: "Logout",
    contactInformation: "Contact Information",
    signIn: "Sign In",
    emailAddress: "Email Address",
    error: "Error",
    reload: "Reload Page",
    selectTime: "Select Time",
    selectDate: "Select Date",
    startDate: "Start Date",
    endDate: "End Date",
    time: "Time",
    from: "From",
    listenTo: "Listen To",
    download: "Download",
    sendAsEmail: "Send as Email",
    email: "Email",
    to: "To",
    duration: "Duration",
    cancel: "Cancel",
    yesImSure: "Yes, I am sure",
    success: "Success",
    profile: "Profile",
    userProfile: "User Profile",
    extensionNumber: "Extension Number",
    landlineNumbers: "Landline Numbers",
    mobileNumbers: "Mobile Numbers",
    editProfile: "Edit Profile",
    editYourProfile: "Edit your profile",
    firstName: "First Name",
    lastName: "Last Name",
    send: "Send",
    save: "Save",
    retry: "Retry",
    back: "Back",
    yesterday: "Yesterday",
    today: "Today",
    at: "at",
    filterDates: "Filter Dates",
    password: "Password",
    changePassword: "Change Password",
    save: "Save",
    configure: "Configure",
    cellNumber: "Cell Number",
    currentProfile: "Current Profile",
    outgoingCalls: "Outgoing Calls",
    incomingCalls: "Incoming Calls",
    always: "Always",
    add: "Add",
    after: "After",
    active: "Active",
    available: "available",
    unAvailable: "unAvailable",
    busy: "busy",
    employee: "Employee",
    device: "Device",
    mobile: "Mobile",
    logOn: "Log on",
    external: "External",
    action: "Action",
    callPriority: "Call Priority",
    waitTime: "Wait Time",
    name: "Name",
    number: "Number",
    online: "Online",
    offline: "Offline",
    ringing: "Ringing",
    filterUsers: "Filter Users",
    queueAgentRegistrations: "Queue Agent Registrations",
    queue: "Queue",
    queuePriority: "Queue Priority",
    queues: "Queues",
  },
  contact: {
    // contactlist
    contactList: {
      noSearchResult: "No Search Result Found",
    },
    contactDetail: {
      landlineNumberIndex: "Landline Number %{index}",
      mobileNumberIndex: "Mobile Number %{index}",
    },
    //empty contact
    empty: {
      list: {
        title: "Empty Contacts List",
        description:
          "There is no any contacts here. You can start seeing the contact lists after adding manually or importing from a file. Please check again later",
        searchQueryNotFound:
          "There is no contact in the list that matches what you are searching for",
        contactNotFound: "Contact Not Found",
      },
      detail: {
        title: "Please select a contact from the list to view its details",
        description:
          "You can see details like cell phone numbers, landline numbers, note and other basic details about a contact",
      },
    },
    //note
    note: {
      addYourNoteHere: "Add your note here...",
      expiryTime: "Cannot be in the past",
      removeExpireNote: "Remove Expire date",
      cannotBeBlank: "Cannot be blank",
    },
  },
  dateTime: {
    long: "yyyy-MM-dd HH:mm",
    clockTime: "HH:mm",
  },
  timeUnit: {
    sec: "sec",
    m: "m",
    h: "hr",
    s: "s",
  },
  voicemails: {
    allVoicemails: "All Voicemails",
    searchAllVoicemails: "Search all voicemails",
    empty: {
      voicemailsList: "Empty Voicemail List",
      description:
        "There is no any voicemail here. You can start seeing the contact lists after adding manually or importing from a file. Please check again later",
      voicemailNotFound: "Voicemail Not Found",
      searchQueryNotFound:
        "There is no voicemail in the list that matches what you are searching for",
      recordOnFilteredDateNotFound:
        "There is no voicemail for the date you are searching for",
    },
    download: {
      confirmDownloadingVoicemail: "Confirm Downloading Voicemail?",
      description:
        "You are about to download the voicemail sent by %{voicemail}. If you are sure, please click on the “Yes, I’m sure” button below.",
    },
    sendEmail: {
      confirmSendingVoicemailToEmail: "Confirm sending voicemail to email?",
      description:
        " You are about to send the voicemail from %{voicemail} to your email %{email}. If you are sure, please click on the “Yes, I’m sure” button below.",
      failedToSendEmail: "Failed to send email",
      errorDescription: "Error occurred while sending recording to",
      pleaseTryAgainLater: "Please try again later.",
      sendEmailTo: "Send Email To",
      pleaseSelectEmail:
        "Please select the one of the options for sending email",
      sendEmailManually: "Send email manually",
      defaultVoicemailEmail: "Default Voicemail Email",
      userLicenseEmail: "User license email",
    },
    delete: {
      areYouSureYouWantToDeleteVoicemail:
        "Are you sure you want to delete voicemail?",
      description:
        "You are about to delete the voicemail from %{voicemail}. If you are sure, please click on the “Yes, I’m sure” button below",
    },
  },
  voicemailBoxes: {
    general: {
      numberOfAllowedMessages: "Number of allowed messages",
      generalVoicemailBoxes: "General Voicemail Boxes",
      voicemailBoxOfYourUserLicense:
        "Voicemail Box of your User License %{email}",
      cellPhoneVoicemailBoxes: "Cell Phone Voicemail Boxes",
    },
    empty: {
      cellPhoneNotFound: "Cell Phone Not Found",
      searchQueryNotFound:
        "There is no cell phone in the list that matches what you are searching for",
      emptyCellPhoneList: "Empty Cell Phone List",
      description: "There is no any cell phone here.",
      emptyGeneralVoicemailBoxInformation:
        "Empty General Voicemail Box Information",
      descriptionGeneral: "There is no any general voicemail box here.",
    },
    configuringVoicemailBoxOf: "Configuring voicemail box of",
  },
  callHistory: {
    allCallHistory: "All Call History",
    empty: {
      emptyCallHistoryList: "Empty Call History List",
      description:
        "There is no any call history here. You can start seeing you received or call someone. Please check again later",
      callNotFound: "Call Not Found",
      searchQueryNotFound:
        "There is no call in the call history that matches what you are searching for",
      recordOnFilteredDateNotFound:
        "There is no call in the call history for the date you are searching for",
    },
  },
  dropdown: {
    contactList: {
      all: "All",
      shared: "Shared",
      colleagues: "Colleagues",
    },
    profile: {
      updateProfileImage: "Update Profile Image",
      deleteProfileImage: "Delete profile Image",
      userProfile: "User Profile",
      changePassword: "Change Password",
      voicemailBoxes: "Voicemail Boxes",
      configureQueue: "Configure Queue",
      fixedCalendar: "Fixed Calendar",
    },
    callHistory: {
      allCallsHistory: "All Calls History",
      incomingCalls: "Incoming Calls",
      outgoingCalls: "Outgoing Calls",
      missedCalls: "Missed Calls",
    },
    incomingCalls: {
      giveBusySignal: "Give busy signal",
      initiateCallOnDevices: "Initiate Call on Devices",
      forwardTo: "Forward to",
      goToVoicemail: "Go to voicemail",
      callOnAllDevices: "Call on all devices",
    },
    callPriority: {
      high: "High (%{number})",
      medium: "Medium (%{number})",
      low: "Low (%{number})",
    },
    pbxUsersList: {
      ownVoicemail: "Own Voicemail",
      ownCallHistory: "Own Call History",
      ownVoicemailBox: "Own Voicemail Box",
    },
  },
  error: {
    unableToDownloadVoiceMail: "Unable to download voicemail",
    unableToSendVoiceMail: "Unable to send voicemail",
    unableToDeleteVoiceMail: "Unable to delete voicemail",
    unableToUpdateYourProfileDetail: "Unable to update your profile detail",
    unableToUpdateNote: "Unable to update note",
  },
  callProfile: {
    default: {
      selectProfileFromList:
        "Please select profile from the list to view its detail",
      setupTimeSchedulesForSwitchingProfile:
        "Setup time schedules for switching your profiles when “Switch profiles automatically” is enabled",
      goToFixedCalendar: "Go to fixed calendar",
      yourFixedCalendarProfiles: "Your fixed calendar profiles",
      fixedCalendarHeaderDescription:
        "You can see how your profiles switch according to your fixed calendar here. Your fixed calendar has no restrictions towards editing.",
      youCannotEditTheDefaultProfile: "You cannot edit the default profile",
    },
    edit: {
      editProfile: "Edit %{name}",
    },
    create: {
      createNewProfile: "Create new Profile",
    },
    delete: {
      areYouSureYouWantToDeleteProfile:
        "Are you sure you want to delete this profile?",
      description:
        "You are about to delete the profile %{name}. If you are sure, please click on the “Yes, I’m sure” button below",
      thisProfileIsCurrentlyUsedInFixedCalendar:
        "This profile is currently used in Fixed Calendar",
      failedToDeleteProfile: "Failed to delete profile",
      errorDescription:
        "Error occurred while trying to delete %{name}. Please try again later",
      activeProfileCannotBeDeleted:
        "Active profile cannot be deleted. Please make another profile active to delete this profile.",
      defaultProfileCannotBeDeleted: "Default Profile cannot be deleted.",
    },
    empty: {
      thereAreNoProfilesToShow: "There are no profiles to show,",
      pleaseCreateANewProfile: "please create a new profile",
      outgoing: {
        noOutgoingCallDeviceAvailable: "No outgoing call available",
        noOutgoingCallAvailableDescription:
          "You can add available outgoing call from cell phone and sip device under user license.",
      },
    },
    outgoing: {
      outgoingCallsFromDeviceDisplay: "Outgoing calls from %{device} display",
      mainNumber: "%{mainNumber} - Main Number",
      hiddenNumber: "Hidden Number",
    },
    incomingCalls: {
      initiateCallOnSipDevice: "Initiate call on %{device}",
      chooseAnOption: "Choose an Option",
      chooseAnOptionYouWishToForwardTo:
        "Choose an option you wish to forward to",

      externalNumber: " External Number",
    },
    queueAgentRegistrations: {
      toggleToRegisterQueueAgents:
        "You can control automatic agent registrations in the queue when toggle is enabled",
    },
    selectCurrentProfile: "Select Current Profile",
    timeStampModal: {
      editWeekDay: "Edit %{weekDay}",
      thisIsWhereYouManageYourDailyRoutine:
        "This is where you manage your daily routine.",
      addTimeFrame: "Add time frame",
      chooseProfile: "Choose Profile",
    },
    switchProfileAutomatically: "Switch Profile Automatically",
  },
  callQueue: {
    default: {
      allQueues: "All Queues",
      selectQueueFromList:
        "Please select any queue from left to view it’s detail",
      description:
        "You can see the detail of any queue once you click or select any one of them from the left side.",
      totalCalls: "Total Calls",
      answeredCalls: "Answered Calls",
      missedCalls: "Missed Calls",
      averageCallDuration: "Average Wait Time",
    },
    empty: {
      thereAreNoQueuesToShow: "There are no call queues available to show",
      queueNotFound: "Queue Not Found",
      searchQueryNotFound:
        "There is no queue in the list that matches what you are searching for",
      emptyCallQueueList: "Empty Call Queue List",
      descriptionNoAgent:
        "You can either add agents from the “Manage Agent” or you can add external number. After that you can view the list of registered agents here. ",
      emptyAvailableAgent: "Empty Available Agent List",
      descriptionAvailableAgent: "There is no available agent at the moment.",
      emptyRegisteredAgent: "Empty Registered Agent List",
      descriptionRegisteredAgent: "There is no registered agent at the moment.",
      noAvailableAgent: "No Available Agent Found",
      searchAvailableAgentNotFound:
        "There is no available agent that matches what you are searching for",
      noRegisteredAgents: "No Registered Agents",
      searchRegisteredAgentNotFound:
        "There is no registered agent that matches what you are searching for",
      noCallInQueue: "No Call In Queue",
      descriptionNoCallInQueue:
        "There is no call present in the queue at the moment.",
      noAvailableQueue: "No Available Queue Found",
      searchAvailableQueueNotFound:
        "There is no available queue that matches what you are searching for",
      noHomeQueue: "No Home Queue Found",
      searchHomeQueueNotFound:
        "There is no home queue that matches what you are searching for",
    },
    registeredAgents: "Registered Agents",
    agentManagement: {
      agentManagement: "Agent Management",
      manageAgents: "Manage Agents",
      availableAgents: "Available Agents",
      registeredAgents: "Registered Agents",
      selectedDevices: "Selected Devices",
      addSelectedToRegisteredAgents: "Add selected to registered agents",
      addSelectedAgent: "Add selected agent",
      saveAgentInformation: "Save Agent Information",
      externalNumberTooltipDescription:
        "External Number are those numbers that are not available in PBX and registered to different service provider. You can also add these type of numbers for receiving the call in the queue",
      removeSelectedFromList: "Remove selected from list",
      removeSelectedAgents: "Remove Selected Agents",
      delete: {
        areYouSureYouWantToDelete: "Are you sure you want to delete",
        deleteDescription:
          "You are about to delete above agent from registered agent list. If you are sure, please click on the “Yes, I’m sure” button below",
        areYouSureYouWantToDeleteTheseAgents:
          "Are you sure you want to delete these agents?",
        deleteMultipleAgentDescription:
          "You are about to delete selected agents from registered agent list. If you are sure, please click on the “Yes, I’m sure” button below",
      },
    },
    callInQueue: "Call In Queue",
    youAreNotLoggedIntoQueue:
      "You are not logged into this queue and won’t receive any phone calls",
  },
  userProfile: {
    saveProfileInformation: "Save Profile Information",
    delete: {
      areYouSureYouWantToDeleteProfileImage:
        "Are you sure you want to delete profile image?",
      description:
        "You are about to remove your profile image. If you are sure, please click on the “Yes, I’m sure” button below",
    },
  },
  home: {
    colleagues: "Colleagues",
    returnToQueueDashboard: "Return To Queue Dashboard",
    configureYourDevices: "Configure your Devices",
  },
  languageToggler: {
    en: "EN",
    dk: "DK",
    label: "Language",
  },
  changePassword: {
    congratulations: "Congratulations",
    youHaveANewPasswordNow: "You have a new password now",
    youCanStartUsingPassword:
      "You can start using this newly created password from next time.",
    youShouldNotSharePassword:
      "You should not share this password with anyone.",
  },
  message: {
    auth: {
      incorrectEmailOrPassword: "Incorrect email address or password",
      notExist: "Account does not exist",
      onlyMobileAllowed: "You are only authorized to access Mobile App",
      resetLink:
        "If you have typed an existing mail address you will receive a mail about how to reset your password in a few minutes.",
      passwordChangedSuccessfully: "Password Changed Successfully",
      couldNotSavePassword: "Could Not Save Password",
      yourTokenExpired: "Your Token has expired",
      couldNotProcessThisRequest:
        "Could not process this request, please try again later",
      signIn: "Sign in using your email address",
      unableToSignIn: "Unable to Sign in",
      unauthorized: "You are not authorized to view this page",
    },
    contact: {
      unauthorizedNoteUpdate: "Unauthorized Note Update",
      noteUpdatedSuccessfully: "Note Updated Successfully",
    },
    voicemail: {
      voicemailDownloadedSuccessfully: "Voicemail Downloaded Successfully",
      emailSendSuccessfully: "Email Send Successfully",
      voicemailDeletedSuccessfully: "Voicemail Deleted Successfully",
    },
    voicemailBoxes: {
      voicemailBoxUpdatedSuccessfully: "Voicemail Box Updated Successfully",
    },
    userProfile: {
      profileUpdatedSuccessfully: "Profile Updated Successfully",
      profileRemovedSuccessfully: "Profile Removed Successfully",
      profileImageRemovedSuccessfully: "Profile Image Removed Successfully",
    },
    callProfile: {
      profileDeletedSuccessfully: "Profile Deleted Successfully",
      profileUpdatedSuccessfully: "Profile Updated Successfully",
      profileCreatedSuccessfully: "Profile Created Successfully",
      activeProfileUpdatedSuccessfully: "Active Profile Updated Successfully",
      scheduleProfileUpdatedSuccessfully:
        "Schedule Profile Updated Successfully",
      automaticCallProfileSchedulingEnabled:
        "Automatic Call Profile Scheduling Enabled",
      automaticCallProfileSchedulingDisabled:
        "Automatic Call Profile Scheduling Disabled",
      enablingAutomaticProfileSwitching:
        "Enabling Automatic Profile Switching, it can take few seconds",
    },
    changePassword: {
      passwordChangedSuccessfully: "Password Changed Successfully",
    },
    configureQueue: {
      queueInDashboardUpdatedSuccessfully:
        "Queue In Dashboard Updated Successfully",
    },
    callQueue: {
      agentManagementUpdatedSuccessfully:
        "Agent Management Updated Successfully",
      addedDeviceInQueue: "Added Device In Queue",
      removeDeviceFromQueue: "Remove Device From Queue",
    },
  },
  configureQueue: {
    configureQueuePriority: "Configure Queue Priority",
    saveSetting: "Save Setting",
    availableQueue: "Available Queue",
    QueueInHome: "Queue In Home",
    addSelectedQueueToHomePage: "Add selected queue to home page",
    addSelectedQueue: "Add Selected Queue",
    addToHome: "Add to home",
    selectedQueue: "Selected Queue",
    removeSelectedQueues: "Remove Selected Queues",

    empty: {
      emptyHomeQueueList: "Empty Home Queue List",
      emptyAvailableQueueList: "Empty Available Queue List",
      thereIsNoQueueInHomeAtTheMoment:
        "There is no queue in home at the moment",
      thereIsNoAvailableQueueAtTheMoment:
        "There is no available Queue at the moment",
    },
  },
  pages: {
    title: {
      contacts: "Contacts",
      voicemails: "Voicemails",
      callHistory: "Call History",
      profileManagement: "Profile Management",
      voicemailBoxes: "Voicemail Boxes",
      callQueue: "Call Queue",
      pageNotFound: "Page not found",
      forbiddenPage: "Forbidden Page",
      home: "Home",
    },

    pageNotFound: {
      pageYouWereLookingForNotExist:
        "The page you were looking for does not exist",
      returnToHome: "Return to Home Page",
      youAreNotAllowed: "You are not allowed to access this app",
      returnToLoginPage: "Return to Login Page",
    },
  },
  toasterMessage: {
    networkError: "Network Error",
    processError: "Could not process this request, please try again later.",
    unauthorized: "You are not authorized to perform this action!",
    requestFailed: "Your request has failed, please try again later.",
    session: {
      expired: "Your session has expired",
    },
  },
  validationErrors: {
    EMPTY_EMAIL_ADDRESS: "Email-Address field can't be empty",
    INVALID_EMAIL_ADDRESS: "Invalid Email-Address",
    REQUIRED: "required",
    EMPTY_PASSWORD: "Password field can't be empty",
    INVALID_PASSWORD: "Password should between 8-16 characters",
    SMALL_PASSWORD_LENGTH: "Password should be greater than",
    GREATER_PASSWORD_LENGTH: "Password should be smaller than",
    NON_SIMILAR_PASSWORD_ERROR: "Password doesn't match with each other.",
    IDENTICAL: "Must be identical",
    EMPTY_FIRST_NAME: "First Name field can't be empty",
    EMPTY_LAST_NAME: "Last Name field can't be empty",
    INVALID_PHONE_NUMBER: "Invalid phone number",
    ONLY_NUMBER_AND_PLUS:
      "Numbers starting with “+” or only plain numbers allowed",
    FORWARD_TO_OPTION: "%{deviceName}, Forward to option can't be blank ",
    SAME_WITH_CURRENT_PASSWORD:
      "New password shouldn't be same as current password",
    NOT_A_NUMBER: "Must be a number",
  },
};
