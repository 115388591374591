import { Translate } from "react-thunk-i18nify";

const STATUS_TYPES = {
  online: <Translate value="common.online" />,
  offline: <Translate value="common.offline" />,
  busy: <Translate value="common.Busy" />,
  ringing: <Translate value="common.ringing" />,
  null: <Translate value="common.offline" />,
};

const AGENTS_MANAGEMENT_FORM = {
  REGISTERED_AGENTS: "registeredAgents",
  AVAILABLE_AGENTS: "availableAgents",
};

const AGENT_TYPES = {
  AGENT: "agent",
  EXTERNAL_NUMBER: "external_number",
};

const DEVICE_TYPES = {
  EXTERNAL_NUMBER: "external_number",
  CELL_PHONE: "cell_phone",
  SIP_DEVICE: "sip_device",
};

const CALL_IN_QUEUE_DECISION_TYPES = {
  ADD_TO_QUEUE: "add_to_queue",
  REMOVE_CALL_QUEUE: "remove_from_queue",
};

const AVAILABLE_AGENTS = "availableAgentID";
const RECENTLY_ADDED_AGENT = "recentlyAddedAgent";

const DEVICE_TYPES_LIST = ["cell_phone", "sip_device"];

export {
  STATUS_TYPES,
  AGENT_TYPES,
  AGENTS_MANAGEMENT_FORM,
  DEVICE_TYPES,
  AVAILABLE_AGENTS,
  DEVICE_TYPES_LIST,
  RECENTLY_ADDED_AGENT,
  CALL_IN_QUEUE_DECISION_TYPES,
};
