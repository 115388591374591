import React from "react";

import TableSkeleton from "components/Skeleton/TableSkeleton";

const VoicemailTableSkeleton = () => (
  <div className="voicemails overflow-auto" role="table">
    <TableSkeleton numberOfColumns={6} numberOfRows={6} />
  </div>
);

export default VoicemailTableSkeleton;
