import React from "react";
import Skeleton from "react-loading-skeleton";

const RegisteredAgentSkeleton = () => (
  <div className="register-agent d-flex justify-content-between align-items-center my-3">
    <div className="d-flex align-items-center">
      <Skeleton circle height={35} width={35} className="me-2" />
      <Skeleton
        height={12}
        width={100}
        className="align-self-center mb-0 mt-2"
      />
    </div>
    <Skeleton width={100} height={20} className="align-self-center" />
  </div>
);

export default RegisteredAgentSkeleton;
