import { I18n, Localize, Translate } from "react-thunk-i18nify";

import { format, isToday, isYesterday, subDays, addMinutes } from "date-fns";
import { da, enGB } from "date-fns/locale";

import { store } from "redux/store";

const secondsToMilliSeconds = 1000;

// NOTE: Make sure we use 24 hour format here instead of 12 hour format
export const convertToDuration = (secondsAmount, dateFormat = "mm:ss") => {
  if (!secondsAmount) return "00:00";
  const {
    i18n: { locale },
  } = store.getState();

  const selectedLocale = locale === "da-DK" ? da : enGB;

  const milliSeconds = secondsAmount * secondsToMilliSeconds;

  const d = new Date(milliSeconds);

  const dateWithoutTimezoneDiff = addMinutes(d, d.getTimezoneOffset());

  return format(dateWithoutTimezoneDiff, dateFormat, {
    locale: selectedLocale,
  });
};

export const formatCallLogDuration = (time) => {
  const {
    i18n: { locale },
  } = store.getState();

  const selectedLocale = locale === "da-DK" ? da : enGB;

  const lastSixDays = subDays(new Date(), 7);

  if (isToday(time)) {
    return getDateFormat("today", time, selectedLocale);
  } else if (isYesterday(time)) {
    return getDateFormat("yesterday", time, selectedLocale);
  } else if (lastSixDays.getTime() - time.getTime() < 0) {
    return getWeekDateTimeFormat(time, selectedLocale);
  } else {
    return getYearlyDateFormat(time, selectedLocale);
  }
};

export const getDateFormat = (text, time) => (
  <>
    <span>
      <Translate value={`common.${text}`} />
    </span>
    <span className="mx-1">
      <Translate value="common.at" />
    </span>
    {getClockTime(time)}
  </>
);

export const getWeekDateTimeFormat = (time, locale) => (
  <>
    {/* TODO:  Fix Localize doesn't seems translate the date i.e WEDNESDAY in other language */}
    {format(time, "cccc", { locale: locale })}

    <span className="mx-1">
      <Translate value="common.at" />
    </span>
    {getClockTime(time)}
  </>
);

export const getYearlyDateFormat = (time, locale) => (
  <>
    {/* TODO:  Fix Localize doesn't seems translate the date i.e DEC in other language */}
    {format(time, "dd LLL yyyy", { locale: locale })}
    <span className="mx-1">
      <Translate value="common.at" />
    </span>
    {getClockTime(time)}
  </>
);

export const getClockTime = (time) => (
  <Localize value={time} dateFormat="dateTime.clockTime" />
);

export const getCallDuration = (seconds) => {
  if (seconds === 0) {
    return `0 ${I18n.t("timeUnit.sec")}`;
  } else if (seconds < 60) {
    return convertToDuration(seconds, `s '${I18n.t("timeUnit.sec")}'`);
  } else if (seconds > 60 && seconds < 3600) {
    return convertToDuration(
      seconds,
      `m'${I18n.t("timeUnit.m")}' s'${I18n.t("timeUnit.s")}'`
    );
  } else {
    return convertToDuration(
      seconds,
      `H'${I18n.t("timeUnit.hr")}' m'${I18n.t("timeUnit.m")}': s'${I18n.t(
        "timeUnit.s"
      )}'`
    );
  }
};

export const addHoursAndMinutesInUTC = (date) => {
  const currentDate = new Date(date);
  const convertedToISOStringDate = currentDate.toISOString().split("T")[0];
  const getHour = currentDate.getHours();
  const getMin = currentDate.getMinutes();

  return `${convertedToISOStringDate}T${getHour}:${getMin}:00.000Z`;
};
