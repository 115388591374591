import React from "react";
import Skeleton from "react-loading-skeleton";

import IncomingCallIndexSkeleton from "./IncomingCallIndexSkeleton";
import OutgoingCallFormSkeleton from "./OutgoingCallFormSkeleton";

const CallProfileDetailSkeleton = () => (
  <div className="call-profile-form flex-fill d-flex flex-column ps-3 layout-spacing pt-md-2">
    <Skeleton height={30} width={150} className="mb-3" />
    <div className="row">
      <div className="col-sm-6 col-md-12 col-xl-5 mb-3">
        <Skeleton height={20} width={120} className="mb-2" />
        <Skeleton className="w-100" height={50} />
      </div>
      <div className="col-sm-6 col-md-8 col-xl-5 mb-3">
        <Skeleton height={20} width={120} className="mb-2" />
        <Skeleton className="w-100" height={50} />
      </div>
      <div className="col-sm-6 col-md-4 col-xl-2 mb-3">
        <Skeleton height={20} width={60} className="mb-2" />
        <Skeleton className="w-100" height={50} />
      </div>
    </div>
    <IncomingCallIndexSkeleton />
    <OutgoingCallFormSkeleton />
    <div className="d-flex flex-column flex-md-row justify-content-end mt-4">
      <div className="col-12 col-md-3 d-flex flex-column justify-content-end mb-4">
        <Skeleton height={50} className="w-100 d-inline-block d-md-none" />
        <Skeleton height={50} className="w-100" />
      </div>
    </div>
  </div>
);

export default CallProfileDetailSkeleton;
