import React from "react";

import PropTypes from "prop-types";

import ProfileListItemSkeleton from "./ProfileListItemSkeleton";

const ProfileListSkeleton = ({ containerClassName }) => (
  <div
    className={`call-profile-list bg-white py-3 px-0 h-100 ${containerClassName}`}
  >
    <div className="ps-md-3 mx-auto ms-md-4 mb-3 d-flex align-items-center align-items-sm-start create-button-skeleton" />
    <div className="call-profile-list ps-md-3 bg-white h-100">
      <div className="overflow-auto mh-100">
        <div className="d-flex flex-column align-items-center pe-md-3">
          {Array.from({ length: 5 }, (v, k) => k).map((key) => (
            <ProfileListItemSkeleton key={key} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

ProfileListSkeleton.defaultProps = {
  containerClassName: "",
};

ProfileListSkeleton.propTypes = {
  containerClassName: PropTypes.string,
};

export default ProfileListSkeleton;
