const AUTH = {
  INDEX: "/",
  LOGIN: "/login",
  SET_PASSWORD: "/set-password",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  FORBIDDEN: "/forbidden",
};

const HOME = {
  INDEX: "/home",
};

const VOICEMAIL = {
  INDEX: "/voicemails",
};

const PROFILE = {
  INDEX: "/call-profiles",
  USER_PROFILE: "/user-profile",
  USER_PROFILE_EDIT: "/user-profile/:id",
  EDIT: "/call-profiles/:id/edit",
  CREATE: "/call-profiles/new",
  addID: (url, id) => url.replace(":id", id),
};

const VOICEMAIL_BOXES = {
  INDEX: "/voicemail-boxes",
  CONFIG: "/voicemail-boxes/:id/edit",
  addID: (url, id) => url.replace(":id", id),
};

const CALL_HISTORY = {
  INDEX: "/call-history",
};

const FIXED_CALENDAR = {
  INDEX: "/fixed-calendar",
};

export {
  AUTH,
  VOICEMAIL,
  CALL_HISTORY,
  PROFILE,
  VOICEMAIL_BOXES,
  HOME,
  FIXED_CALENDAR,
};
