import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faXmark, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";
import Modal from "components/Modal/Modal";

import { ELEMENT_TYPE } from "utils/fixedCalendarFormatter";
import { isEmpty } from "utils/isEmpty";
import { addHoursAndMinutesInUTC } from "utils/timeFormatter";
import { timeStampFormatter, TIME_STAMP } from "utils/timeStampFormatter";
import { displaySuccess } from "utils/toaster";

import { updateDailyScheduleCallProfileRequest } from "redux/actions/callProfile";

import TimeFrame from "./TimeFrame";

const DailyScheduleModal = ({
  isVisible,
  handleOverlayClick,
  selectedDay,
  handleCancel,
  calendarData,
}) => {
  const [selectedData, setSelectedData] = useState([]);
  const [deletedData, setDeletedData] = useState([]);
  const [isFormEdited, setFormEdited] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);

  const [date, setDate] = useState("");
  const [edited, setEdited] = useState({
    id: null,
    type: null,
  });
  const timeStampRef = useRef(null);

  const dispatch = useDispatch();

  const {
    isDailyScheduleCallProfileLoading: isSubmitting,
    dailyScheduleCallProfile,
    commitSuccess,
  } = useSelector(({ callProfile }) => callProfile);

  const updateForm = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  useEffect(() => {
    if (calendarData.length == 0) return setSelectedData([]);
    const timeStampOfADay = calendarData.filter(
      ({ day }) => day.toLowerCase() == selectedDay.day.toLowerCase()
    );

    setSelectedData(timeStampOfADay);
    if (!timeStampOfADay.length) return null;

    setDate(timeStampOfADay[0].end.split("T")[0]);
  }, [calendarData]);

  const checkSubmitValidation = useCallback(() => {
    let isDisabled = false;

    selectedData.forEach(({ start, end, title }) => {
      if (isEmpty(start) || isEmpty(end) || !title) {
        isDisabled = true;
      }
    });

    return isDisabled;
  }, [selectedData]);

  const handleUpdateActiveTimeFrameProfile = ({ timeFrameID, name, id }) => {
    updateForm();
    setSelectedData((previous) => {
      const previousData = [...previous];
      const indexOfTimeStamp = previous.findIndex(
        ({ id }) => id == timeFrameID
      );

      previousData[indexOfTimeStamp].title = name;
      previousData[indexOfTimeStamp].profileID = id;

      if (
        previousData[indexOfTimeStamp].elementType == ELEMENT_TYPE.FETCH_ELEMENT
      ) {
        previousData[indexOfTimeStamp].elementType =
          ELEMENT_TYPE.UPDATE_ELEMENT;
      }

      return previousData;
    });
  };

  const constructDefaultStartTime = () => {
    const dateToday = new Date();
    const defaultStartTime = new Date(
      dateToday.getFullYear(),
      dateToday.getMonth(),
      dateToday.getDate(),
      0,
      0,
      0
    );

    return defaultStartTime.toISOString();
  };

  const constructDefaultEndTime = () => {
    const dateToday = new Date();
    const defaultEndTime = new Date(
      dateToday.getFullYear(),
      dateToday.getMonth(),
      dateToday.getDate(),
      0,
      15,
      0
    );

    return defaultEndTime.toISOString();
  };

  const addNewTimeFrameHandler = () => {
    const newTimeFrame = {
      id: Date.now() + Math.floor(Math.random() * 10),
      start: constructDefaultStartTime(),
      end: constructDefaultEndTime(),
      day: selectedDay.day,
      title: "",
      elementType: ELEMENT_TYPE.NEWLY_ADDED,
      timeSortingRemaining: true,
    };

    setSelectedData((previous) => [...previous, newTimeFrame]);
    setUpdatedData((previous) => [...previous, newTimeFrame]);
  };

  const handleDeleteTimeFrame = (id) => {
    updateForm();
    const deleteTimeFrame =
      selectedData.find(({ id: timeFrameID }) => id == timeFrameID) || {};

    if (
      !isEmpty(deleteTimeFrame) &&
      deleteTimeFrame.elementType !== ELEMENT_TYPE.NEWLY_ADDED
    ) {
      const { id: deleteTimeFrameID, end, start, profileID } = deleteTimeFrame;

      setDeletedData((previous) => [
        ...previous,
        {
          id: deleteTimeFrameID,
          start_time: start,
          end_time: end,
          call_profile_id: profileID,
          _destroy: true,
        },
      ]);
    }

    setSelectedData((previous) =>
      previous.filter(({ id: timeFrameID }) => id !== timeFrameID)
    );
  };

  const handleUpdateStartTime = (e, timeFrameID) => {
    if (e.length == 0) return;

    updateForm();

    setEdited({
      id: timeFrameID,
      type: TIME_STAMP.START,
    });

    setSelectedData((previous) => {
      const previousData = [...previous];
      const indexOfTimeStamp = previous.findIndex(
        ({ id }) => id == timeFrameID
      );

      previousData[indexOfTimeStamp].start = new Date(e[0]).toISOString();

      if (
        previousData[indexOfTimeStamp].elementType == ELEMENT_TYPE.FETCH_ELEMENT
      ) {
        previousData[indexOfTimeStamp].elementType =
          ELEMENT_TYPE.UPDATE_ELEMENT;
      }

      return previousData;
    });
  };

  const handleUpdateEndTime = (e, timeFrameID) => {
    if (e.length == 0) return;
    updateForm();

    setEdited({
      id: timeFrameID,
      type: TIME_STAMP.END,
    });

    setSelectedData((previous) => {
      const previousData = [...previous];
      const indexOfTimeStamp = previous.findIndex(
        ({ id }) => id == timeFrameID
      );

      previousData[indexOfTimeStamp].end = new Date(e[0]).toISOString();

      if (
        previousData[indexOfTimeStamp].elementType == ELEMENT_TYPE.FETCH_ELEMENT
      ) {
        previousData[indexOfTimeStamp].elementType =
          ELEMENT_TYPE.UPDATE_ELEMENT;
      }

      return previousData;
    });
  };

  const handleClose = () => {
    handleCancel();
    setFormEdited(false);
    setDeletedData([]);
    setUpdatedData([]);
    setSelectedData([]);
  };

  const updatedDataList = useCallback(() => {
    const sortedData = timeStampFormatter(
      selectedData,
      edited,
      () => setEdited({ id: null, type: null }),
      ({
        id: deleteTimeFrameID,
        start_time: start,
        end_time: end,
        call_profile_id: profileID,
      }) =>
        setDeletedData((previous) => [
          ...previous,
          {
            id: deleteTimeFrameID,
            start_time: start,
            end_time: end,
            call_profile_id: profileID,
            _destroy: true,
          },
        ])
    );

    setUpdatedData(sortedData);
  }, [selectedData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedAttributes = [];

    updatedData.forEach(({ id, profileID, elementType, start, end }) => {
      if (elementType == ELEMENT_TYPE.NEWLY_ADDED) {
        updatedAttributes.push({
          start_time: addHoursAndMinutesInUTC(start),
          end_time: addHoursAndMinutesInUTC(end),
          call_profile_id: profileID,
          _destroy: false,
        });
      } else if (
        elementType == ELEMENT_TYPE.UPDATE_ELEMENT ||
        elementType === ELEMENT_TYPE.FETCH_ELEMENT
      ) {
        updatedAttributes.push({
          start_time: addHoursAndMinutesInUTC(start),
          end_time: addHoursAndMinutesInUTC(end),
          call_profile_id: profileID,
          id,
          _destroy: false,
        });
      }
    });

    const updateTimeStamp = {
      call_profile_daily_schedule_time_frames_attributes:
        updatedAttributes.concat(deletedData),
    };

    const { id } = dailyScheduleCallProfile.find(
      ({ for_day: day }) => day == selectedDay.day
    );

    dispatch(updateDailyScheduleCallProfileRequest(id, updateTimeStamp));
  };

  useEffect(() => {
    if (commitSuccess) {
      handleCancel();
      displaySuccess(commitSuccess);
      setDeletedData([]);
      setUpdatedData([]);
    }
  }, [commitSuccess]);

  useEffect(() => {
    if (checkSubmitValidation() || timeStampRef.current)
      return (timeStampRef.current = false);
    updatedDataList();
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(updatedData);
    timeStampRef.current = true;
  }, [updatedData]);

  return (
    <Modal
      isVisible={isVisible}
      handleOverlayClick={handleOverlayClick}
      className="custom-modal time-stamp-modal px-0 d-flex flex-column"
      positionClassName="time-stamp-position"
    >
      <div className="time-stamp-modal-header p-3 d-flex justify-content-between align-items-center">
        <h5 className="mb-0 fw-bold ps-2">
          <Translate
            value="callProfile.timeStampModal.editWeekDay"
            weekDay={selectedDay.translatedDay}
          />
        </h5>
        <FontAwesomeIcon
          icon={faXmark}
          onClick={handleClose}
          className="close-icon"
        />
      </div>
      <div className="time-stamp-modal-container px-4 py-3 flex-fill time-stamp-list overflow-auto">
        <p className="schedule-description mb-4">
          <Translate value="callProfile.timeStampModal.thisIsWhereYouManageYourDailyRoutine" />
        </p>

        <div className="configure-time-stamp">
          <div className="row gy-4 gx-0 gx-md-4">
            {updatedData.map(
              ({ start, end, title, id, elementType }, index) => (
                <TimeFrame
                  startTime={start}
                  endTime={end}
                  profile={title}
                  id={id}
                  key={index}
                  handleUpdateActiveTimeFrameProfile={
                    handleUpdateActiveTimeFrameProfile
                  }
                  handleDeleteTimeFrame={handleDeleteTimeFrame}
                  handleUpdateStartTime={handleUpdateStartTime}
                  handleUpdateEndTime={handleUpdateEndTime}
                  date={date}
                  elementType={elementType}
                  existingTimeFrames={updatedData}
                  timeFrameCurrentIndex={index}
                />
              )
            )}
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button
              className="btn btn-outline-primary add-time-frame-button px-4"
              onClick={addNewTimeFrameHandler}
              disabled={checkSubmitValidation()}
            >
              <FontAwesomeIcon icon={faClock} className="me-2" />
              <Translate value="callProfile.timeStampModal.addTimeFrame" />
            </button>
          </div>
        </div>
      </div>
      <div className="time-stamp-modal-footer py-3 d-flex justify-content-between px-4">
        <button
          className="btn btn-outline-secondary px-5"
          onClick={handleClose}
        >
          <Translate value="common.cancel" />
        </button>

        <button
          className="btn btn-primary px-5"
          disabled={checkSubmitValidation() || !isFormEdited || isSubmitting}
          onClick={handleSubmit}
        >
          <MovingDottedLoader show={isSubmitting} />
          <Translate value="common.save" />
        </button>
      </div>
    </Modal>
  );
};

DailyScheduleModal.defaultProps = {
  isVisible: false,
  handleOverlayClick: () => {},
  selectedDay: {},
  handleCancel: () => {},
  calendarData: [],
};

DailyScheduleModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOverlayClick: PropTypes.func,
  selectedDay: PropTypes.shape({
    translatedDay: PropTypes.string,
    day: PropTypes.string,
  }),
  handleCancel: PropTypes.func,
  calendarData: PropTypes.shape([]),
};

export default DailyScheduleModal;
