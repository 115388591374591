import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import RegisteredAgentsList from "components/Home/QueueDashboard/CallQueueItem/RegisteredAgents/RegisteredAgent/RegisteredAgentsList";
import RegisteredAgentListSkeleton from "components/Skeleton/CallQueue/RegisteredAgents/RegisteredAgentListSkeleton";

import HasPermission from "hoc/AccessPermission";

import { ACTION_LIST } from "constants/userPermission";

const RegisteredAgents = ({
  registeredAgents,
  isProcessing,
  setToggleAgentManagement,
  queueID,
}) => {
  const handleOpenAgentManagement = () => {
    setToggleAgentManagement((previous) => !previous);
  };

  if (isProcessing) {
    return <RegisteredAgentListSkeleton />;
  }

  return (
    <div
      className="p-3 mt-3 register-agent-container"
      data-cy={`queue-registered-agents-${queueID}`}
    >
      <div className="d-flex justify-content-between flex-wrap">
        <p className="fw-bold register-agent-title mb-0  pb-2 pb-md-0 pt-1">
          <Translate value="callQueue.registeredAgents" />
        </p>
        <HasPermission perform={ACTION_LIST.VIEW_AGENT_MANAGEMENT}>
          <button
            className="btn btn-outline-primary d-flex align-items-center manage-agent-button py-2 py-md-0"
            onClick={handleOpenAgentManagement}
          >
            <Translate value="callQueue.agentManagement.manageAgents" />
          </button>
        </HasPermission>
      </div>
      <RegisteredAgentsList registeredAgents={registeredAgents} />
    </div>
  );
};

RegisteredAgents.defaultProps = {
  registeredAgents: [],
  isProcessing: false,
  setToggleAgentManagement: () => {},
};

RegisteredAgents.propTypes = {
  registeredAgents: PropTypes.shape([]),
  isProcessing: PropTypes.bool,
  setToggleAgentManagement: PropTypes.func,
  queueID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RegisteredAgents;
