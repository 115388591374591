export function dynamicSort(property) {
  let sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property?.substr?.(1);
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property]?.trim?.().localeCompare(a[property]);
    } else {
      return a[property]?.trim?.().localeCompare(b[property]);
    }
  };
}
