import {
  FETCH_PBX_USERS_REQUEST,
  FETCH_PBX_USERS_RECEIVED,
  FETCH_PBX_USERS_ERROR,
  CLEAR_USERS_ERRORS,
} from "redux/actions/user";

const defaultNoteState = {
  isLoading: true,
  pbxUsers: [],
  error: "",
};

function user(state = defaultNoteState, action) {
  switch (action.type) {
    case FETCH_PBX_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case FETCH_PBX_USERS_RECEIVED:
      return {
        ...state,
        isLoading: false,
        pbxUsers: action.pbxUsers,
      };

    case FETCH_PBX_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case CLEAR_USERS_ERRORS:
      return {
        ...state,
        error: "",
      };

    default:
      return state;
  }
}

export default user;
