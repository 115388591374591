import React from "react";
import { Translate } from "react-thunk-i18nify";
import "bootstrap/js/src/collapse";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Modal from "components/Modal/Modal";

import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const ForwardCallModal = ({
  isVisible,
  handleOverlayClick,
  handleCancel,
  handleSubmit,
}) => (
  <Modal
    isVisible={isVisible}
    handleOverlayClick={handleOverlayClick}
    className="custom-modal confirm-modal forward-to-modal p-0"
  >
    <div className="header-container p-3 d-flex justify-content-between align-items-center">
      <h5 className="mb-0 ms-2 ms-md-0">
        <Translate value="callProfile.incomingCalls.chooseAnOptionYouWishToForwardTo" />
      </h5>
      <FontAwesomeIcon
        icon={faXmark}
        onClick={handleCancel}
        className="close-icon me-3 me-md-0"
      />
    </div>
    <DesktopView handleSubmit={handleSubmit} />
    <MobileView handleSubmit={handleSubmit} />
  </Modal>
);

ForwardCallModal.defaultProps = {
  isVisible: false,
  handleOverlayClick: () => {},
};

ForwardCallModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOverlayClick: PropTypes.func,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ForwardCallModal;
