import { UserApi } from "internal";

import { getErrors } from "utils/utils";

export const FETCH_PBX_USERS_REQUEST = "FETCH_PBX_USERS_REQUEST";
export const FETCH_PBX_USERS_RECEIVED = "FETCH_PBX_USERS_RECEIVED";
export const FETCH_PBX_USERS_ERROR = "FETCH_PBX_USERS_ERROR";

export const CLEAR_USERS_ERRORS = "CLEAR_USERS_ERRORS";

export const clearUsersErrors = () => ({
  type: CLEAR_USERS_ERRORS,
});

export const fetchPbxUsersReceived = (pbxUsers) => ({
  type: FETCH_PBX_USERS_RECEIVED,
  pbxUsers,
});

function fetchPbxUsersFailed(errorData) {
  const error = getErrors(errorData);

  return {
    type: FETCH_PBX_USERS_ERROR,
    error,
  };
}

export const fetchPbxUsersRequest = (pbxID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PBX_USERS_REQUEST });

    const response = await UserApi.fetchPbxUsers(pbxID);

    dispatch(fetchPbxUsersReceived(response.data));
  } catch (error) {
    dispatch(fetchPbxUsersFailed(error));
  }
};
