import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { isEmpty } from "utils/isEmpty";

const ActiveProfile = ({ activeProfile }) => {
  if (isEmpty(activeProfile)) return null;

  const {
    name,
    icon_json: { icon_name: iconName, brand_name: brandName },
  } = activeProfile;

  return (
    <span className="active-profile-item">
      <FontAwesomeIcon icon={[brandName, iconName]} /> {name}
    </span>
  );
};

ActiveProfile.defaultProps = {
  activeProfile: {
    name: "",
    icon_json: { icon_name: "", brand_name: "" },
  },
};

ActiveProfile.propTypes = {
  activeProfile: PropTypes.shape({
    name: PropTypes.string,
    icon_json: PropTypes.shape({
      icon_name: PropTypes.string,
      brand_name: PropTypes.string,
    }),
  }),
};

export default ActiveProfile;
