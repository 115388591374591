import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTable, useRowSelect } from "react-table";

import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import EmptyTableHeader from "components/EmptyContent/EmptyTableHeader";
import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import Device from "components/Home/QueueDashboard/Device";
import Employee from "components/Home/QueueDashboard/Employee";
import Table from "components/Table/Table";
import TableCheckBox from "components/Table/TableCheckBox";
import TableHeader from "components/Table/TableHeader";

import { AgentManagementContext } from "context-api/AgentManagementContext";

import { AVAILABLE_AGENTS, DEVICE_TYPES_LIST } from "constants/agent";
import { REGISTERED_AGENTS_TABLE_HEADER } from "constants/tableHeader";

import { isEmpty } from "utils/isEmpty";

import AgentField from "../AgentField";
import CallPriority from "./CallPriority";
import RegisteredAgentSelection from "./RegisteredAgentSelection";

const RegisteredAgentTable = ({
  registeredDevices,
  isSearching,
  setSelectedID,
}) => {
  const [tableData, setTableData] = useState([]);

  const {
    updatedRegisteredAgents,
    setUpdatedRegisteredAgents,
    setUpdatedAvailableAgents,
    setDeleteRegisteredAgentID,
    setDeleteExternalNumberID,
    callPriority,
    setCallPriority,
    isFormEdited,
    setFormEdited,
  } = useContext(AgentManagementContext);

  const updatedTableData = (data) =>
    data.map(
      ({
        name,
        profile_picture: profilePicture,
        number,
        device_type: deviceType,
        device_name: deviceName,
        id,
        ...rest
      }) => ({
        employee: (
          <Employee name={name} profilePicture={profilePicture} hideStatus />
        ),
        device: (
          <Device
            deviceType={deviceType}
            number={number}
            deviceName={deviceName}
          />
        ),
        callPriority: <CallPriority id={id} />,
        action: (
          <FontAwesomeIcon
            icon={faTrashCan}
            onClick={() => singleAgentDeleteHandler(number)}
            className="d-flex mx-auto agent-delete-button"
          />
        ),
        registeredAgent: {
          ...rest,
          name,
          profile_picture: profilePicture,
          number,
          device_type: deviceType,
          device_name: deviceName,
          id,
          penalty: CallPriority?.[id]?.penalty || rest.penalty,
        },
      })
    );

  useEffect(() => {
    if (registeredDevices.length === 0) return setTableData([]);

    setTableData(updatedTableData(registeredDevices));
  }, [registeredDevices]);

  useEffect(() => {
    if (isEmpty(callPriority)) return;

    const sortedData = updatedRegisteredAgents.sort((a, b) => {
      if (callPriority?.[a.id]?.penalty > callPriority?.[b.id]?.penalty) {
        return 1;
      } else if (
        callPriority?.[a.id]?.penalty === callPriority?.[b.id]?.penalty
      ) {
        return a.name.localeCompare(b.name);
      } else {
        return -1;
      }
    });

    setTableData(updatedTableData(sortedData));
  }, [callPriority]);

  const tableInstance = useTable(
    {
      columns: REGISTERED_AGENTS_TABLE_HEADER,
      data: tableData,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableCheckBox {...getToggleAllRowsSelectedProps()} />
          ),

          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <TableCheckBox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const updateCallPriority = (data) => {
    const updatedCallPriority = { ...callPriority };

    data.map(({ penalty, id }) => {
      let title = null;

      if (penalty > data.length) {
        title = data.length;
      } else if (updatedCallPriority?.[id]?.penalty > data.length) {
        title = data.length;
      } else {
        title = penalty;
      }

      updatedCallPriority[id] = {
        penalty: title,
        title: title,
        isDirty: Boolean(updatedCallPriority?.[id]?.isDirty),
      };
    });

    return updatedCallPriority;
  };

  useEffect(() => {
    setTableData(updatedTableData(updatedRegisteredAgents));
    setCallPriority(updateCallPriority(updatedRegisteredAgents));
  }, [updatedRegisteredAgents]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const updateFormEdited = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  useEffect(() => {
    setSelectedID(Boolean(selectedFlatRows.length));
  }, [selectedFlatRows]);

  const handleDeleteRegisteredAgent = () => {
    updateFormEdited();

    selectedFlatRows.map((device) => {
      const { number: deviceNumber } = device.original.registeredAgent;

      setUpdatedRegisteredAgents((previous) =>
        previous.filter(({ number, ...rest }) => {
          if (number != deviceNumber) {
            return {
              ...rest,
              number,
              penalty:
                rest.penalty > updatedRegisteredAgents.length - 1
                  ? updatedRegisteredAgents.length - 1
                  : rest.penalty,
              attributes_are_dirty:
                rest.penalty > updatedRegisteredAgents.length - 1
                  ? true
                  : false,
            };
          }
        })
      );

      const availableAgent = updatedRegisteredAgents.find(
        ({ number }) => number === deviceNumber
      );

      if (availableAgent?.type != AVAILABLE_AGENTS) {
        const { device_type: deviceType, id } = availableAgent;

        if (DEVICE_TYPES_LIST.includes(deviceType)) {
          setDeleteRegisteredAgentID((prev) => [...prev, id]);
        } else {
          setDeleteExternalNumberID((prev) => [...prev, id]);
        }
      }

      setUpdatedAvailableAgents((prev) => [...prev, availableAgent]);
    });
  };

  const singleAgentDeleteHandler = (selectedNumber) => {
    updateFormEdited();

    const newTableData = updatedRegisteredAgents.filter(
      ({ number }) => number !== selectedNumber
    );
    const selectedAgent = updatedRegisteredAgents.find(
      ({ number }) => number == selectedNumber
    );

    if (selectedAgent?.id) {
      const { device_type: deviceType, id } = selectedAgent;

      if (DEVICE_TYPES_LIST.includes(deviceType)) {
        setDeleteRegisteredAgentID((prev) => [...prev, id]);
      } else {
        setDeleteExternalNumberID((prev) => [...prev, id]);
      }
    }

    setUpdatedAvailableAgents((previous) => [...previous, selectedAgent]);
    const registeredAgentsList = [];

    updatedRegisteredAgents.forEach((agent) => {
      if (agent.number !== selectedNumber) {
        registeredAgentsList.push({
          ...agent,
          penalty:
            agent.penalty > updatedRegisteredAgents.length - 1
              ? updatedRegisteredAgents.length - 1
              : agent.penalty,
          attributes_are_dirty:
            agent.penalty > updatedRegisteredAgents.length - 1 ? true : false,
        });
      }
    });

    setUpdatedRegisteredAgents(registeredAgentsList);
    setTableData(updatedTableData(newTableData));
  };

  return (
    <div
      className="registered-agents-table pt-3 overflow-auto"
      key={registeredDevices.length}
    >
      <EmptyTableHeader show={!tableData.length && !isSearching} />
      <Table
        getTableProps={getTableProps}
        className="available-agent-table-list mb-0 px-3"
      >
        <TableHeader
          show={tableData.length}
          headerGroups={headerGroups}
          columnClassName="table-header-contain fw-normal"
        />
        <AgentField
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Table>
      <EmptyTableUI
        records={registeredDevices}
        isSearching={isSearching}
        searchTitle="callQueue.empty.noRegisteredAgents"
        searchDescription="callQueue.empty.searchRegisteredAgentNotFound"
      />
      <RegisteredAgentSelection
        selectedAgents={selectedFlatRows.length}
        handleAgentSelection={handleDeleteRegisteredAgent}
      />
      <hr className="mt-0 separator" />
    </div>
  );
};

RegisteredAgentTable.defaultProps = {
  registeredDevices: [],
  getToggleAllRowsSelectedProps: () => {},
  row: {
    getToggleRowSelectedProps: () => {},
  },
  isSearching: false,
  setSelectedID: () => {},
};

RegisteredAgentTable.propTypes = {
  registeredDevices: PropTypes.array,
  getToggleAllRowsSelectedProps: PropTypes.func,
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
  }),
  isSearching: PropTypes.bool,
  setSelectedID: PropTypes.func,
};

export default RegisteredAgentTable;
