import React from "react";
import { useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { NOT_FOUND } from "constants/images";
import { HOME } from "constants/routes";

const NavigationButton = ({ hideNavigation, handleNavigation }) => {
  if (hideNavigation) return null;

  return (
    <button className="btn btn-primary mt-2" onClick={handleNavigation}>
      <FontAwesomeIcon icon={faLeftLong} className="me-2" />
      <Translate value="pages.pageNotFound.returnToHome" />
    </button>
  );
};

const NotFound = ({ hideNavigation }) => {
  const history = useHistory();

  const handleNavigation = () => {
    history.push(HOME.INDEX);
  };

  return (
    <section className="not-found d-flex flex-column justify-content-center align-items-center h-100 m-auto">
      <img src={NOT_FOUND.SOURCE} alt="page not found" />
      <h4 className="mt-2 pt-2 mb-0 fw-bold">
        <Translate value="pages.title.pageNotFound" />
      </h4>
      <p className="description my-3">
        <Translate value="pages.pageNotFound.pageYouWereLookingForNotExist" />
      </p>
      <NavigationButton
        hideNavigation={hideNavigation}
        handleNavigation={handleNavigation}
      />
    </section>
  );
};

NotFound.defaultProps = {
  hideNavigation: false,
};

NotFound.propTypes = {
  hideNavigation: PropTypes.bool,
};

NavigationButton.defaultProps = {
  hideNavigation: false,
  handleNavigation: () => {},
};

NavigationButton.propTypes = {
  hideNavigation: PropTypes.bool,
  handleNavigation: PropTypes.func,
};

export default NotFound;
