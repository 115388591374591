import React from "react";
import Skeleton from "react-loading-skeleton";

import PropTypes from "prop-types";

import { LIGHT_GREY } from "constants/skeletonColor";

const TableSkeleton = ({ numberOfColumns, numberOfRows, className }) => (
  <table className={`table table-borderless  voice-table ${className}`}>
    <thead className="border-0 table-header py-2">
      <tr>
        {Array.from({ length: numberOfColumns }, (v, k) => k).map((key) => (
          <th
            scope="col"
            key={key}
            className="header-cell fw-bold align-middle"
          >
            <Skeleton height={20} width={90} baseColor={LIGHT_GREY} />
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {Array.from({ length: numberOfRows }, (v, k) => k).map((key) => (
        <tr key={key} className="table-row w-auto">
          {Array.from({ length: numberOfColumns }, (v, k) => k).map(
            (cellKey) => (
              <td key={cellKey} className="cell align-middle">
                <Skeleton height={20} width={140} />
              </td>
            )
          )}
        </tr>
      ))}
    </tbody>
  </table>
);

TableSkeleton.defaultProps = {
  numberOfColumns: 5,
  numberOfRows: 4,
  className: "px-3",
};

TableSkeleton.propTypes = {
  numberOfColumns: PropTypes.number,
  numberOfRows: PropTypes.number,
  className: PropTypes.string,
};

export default TableSkeleton;
