import { createSearchAction } from "redux-search";

import { VoicemailApi } from "internal";

import { downloadAudio } from "utils/audio";
import { getErrors, getServerError } from "utils/utils";

export const FETCH_VOICEMAIL_REQUEST = "FETCH_VOICEMAIL_REQUEST";
export const FETCH_VOICEMAIL_RECEIVED = "FETCH_VOICEMAIL_RECEIVED";
export const FETCH_VOICEMAIL_ERROR = "FETCH_VOICEMAIL_ERROR ";
export const VOICEMAIL_SEARCH = "VOICE_MAIL_SEARCH";

export const VOICEMAIL_DOWNLOAD_REQUEST = "VOICEMAIL_DOWNLOAD_REQUEST";
export const VOICEMAIL_DOWNLOAD_RECEIVED = "VOICEMAIL_DOWNLOAD_RECEIVED";
export const VOICEMAIL_DOWNLOAD_ERROR = "VOICEMAIL_DOWNLOAD_ERROR";

export const VOICEMAIL_SEND_RECORDING_BY_EMAIL_REQUEST =
  "VOICE_MAIL_SEND_RECORDING_BY_EMAIL_REQUEST";
export const VOICEMAIL_SEND_RECORDING_BY_EMAIL_RECEIVED =
  "VOICEMAIL_SEND_RECORDING_BY_EMAIL_RECEIVED";
export const VOICEMAIL_SEND_RECORDING_BY_EMAIL_ERROR =
  "VOICEMAIL_SEND_RECORDING_BY_EMAIL_ERROR";

export const VOICEMAIL_DELETE_REQUEST = "VOICEMAIL_DELETE_REQUEST";
export const VOICEMAIL_DELETE_RECEIVED = "VOICEMAIL_DELETE_RECEIVED";
export const VOICEMAIL_DELETE_ERROR = "VOICEMAIL_DELETE_ERROR";

export const VOICEMAIL_AUDIO_DOWNLOAD_REQUEST =
  "VOICEMAIL_AUDIO_DOWNLOAD_REQUEST";
export const VOICEMAIL_AUDIO_DOWNLOAD_RECEIVED =
  "VOICEMAIL_AUDIO_DOWNLOAD_RECEIVED";
export const VOICEMAIL_AUDIO_DOWNLOAD_ERROR = "VOICEMAIL_AUDIO_DOWNLOAD_ERROR";

export const FILTER_VOICEMAIL_BY_DATE_REQUEST =
  "FILTER_VOICEMAIL_BY_DATE_REQUEST";
export const FILTER_VOICEMAIL_BY_DATE_RECEIVED =
  "FILTER_VOICEMAIL_BY_DATE_RECEIVED";
export const FILTER_VOICEMAIL_BY_DATE_ERROR = "FILTER_VOICEMAIL_BY_DATE_ERROR";

export const CLEAR_VOICEMAIL_ERROR = "CLEAR_VOICEMAIL_ERROR";

export const searchVoicemail = createSearchAction("voicemails");

export const voicemailReceived = (note) => ({
  type: FETCH_VOICEMAIL_RECEIVED,
  payload: note,
});

export const voicemailError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_VOICEMAIL_ERROR,
    error,
  };
};

export function voicemailSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: VOICEMAIL_SEARCH,
    isSearching,
  };
}

export const clearVoicemailError = () => ({
  type: CLEAR_VOICEMAIL_ERROR,
});

export const voicemailRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_VOICEMAIL_REQUEST });

    const response = await VoicemailApi.fetchVoicemails(id);

    dispatch(voicemailReceived(response.data));
  } catch (error) {
    dispatch(voicemailError(error));
  }
};

export const voicemailDownloadError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: VOICEMAIL_DOWNLOAD_ERROR,
    error,
  };
};

export const voicemailDownloadReceived = (data) => ({
  type: VOICEMAIL_DOWNLOAD_RECEIVED,
  payload: data,
});

export const voicemailDownloadRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: VOICEMAIL_DOWNLOAD_REQUEST });

    const response = await VoicemailApi.downloadVoicemail(id);

    downloadAudio(response.data, "voicemail_recording");

    dispatch(voicemailDownloadReceived("success"));
  } catch (error) {
    dispatch(voicemailDownloadError(error));
  }
};

export const voicemailSendReceived = (data) => ({
  type: VOICEMAIL_SEND_RECORDING_BY_EMAIL_RECEIVED,
  payload: data,
});

export const voicemailSendError = (errorData) => {
  const error = getServerError(errorData);

  return {
    type: VOICEMAIL_SEND_RECORDING_BY_EMAIL_ERROR,
    error,
  };
};

export const voicemailSendRequest = (id, email) => async (dispatch) => {
  try {
    dispatch({ type: VOICEMAIL_SEND_RECORDING_BY_EMAIL_REQUEST });
    await VoicemailApi.sendEmail(id, email);

    dispatch(voicemailSendReceived("success"));
  } catch (error) {
    dispatch(voicemailSendError(error));
  }
};

export const voicemailDeleteReceived = (data) => ({
  type: VOICEMAIL_DELETE_RECEIVED,
  payload: data,
});

export const voicemailDeleteError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: VOICEMAIL_DELETE_ERROR,
    error,
  };
};

export const voicemailDeleteRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: VOICEMAIL_DELETE_REQUEST });
    await VoicemailApi.deleteVoicemail(id);

    dispatch(voicemailDeleteReceived(id));
  } catch (error) {
    dispatch(voicemailDeleteError(error));
  }
};

export const voicemailDownloadAudioReceived = (audio) => ({
  type: VOICEMAIL_AUDIO_DOWNLOAD_RECEIVED,
  audio: audio,
});

export const voicemailDownloadAudioError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: VOICEMAIL_AUDIO_DOWNLOAD_ERROR,
    error: error,
  };
};

export const voicemailDownloadAudioRequest =
  (voicemail, format) => async (dispatch) => {
    try {
      dispatch({ type: VOICEMAIL_AUDIO_DOWNLOAD_REQUEST });

      const response = await VoicemailApi.fetchVoicemailAudio(
        voicemail.id,
        format
      );

      dispatch(
        voicemailDownloadAudioReceived({
          id: voicemail.id,
          audio: response.data,
        })
      );
    } catch (error) {
      dispatch(voicemailDownloadAudioError(error));
    }
  };

//filter voicemail
const filterVoicemailByDateReceived = (data) => ({
  type: FILTER_VOICEMAIL_BY_DATE_RECEIVED,
  data,
});

const filterVoicemailByDateError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FILTER_VOICEMAIL_BY_DATE_ERROR,
    error,
  };
};

export const filterVoicemailByDateRequest =
  (userLicenseId, type, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: FILTER_VOICEMAIL_BY_DATE_REQUEST });

      const response = await VoicemailApi.filterByDate(
        userLicenseId,
        type,
        startDate,
        endDate
      );

      dispatch(filterVoicemailByDateReceived(response.data));
    } catch (error) {
      dispatch(filterVoicemailByDateError(error));
    }
  };
