import React from "react";
import Skeleton from "react-loading-skeleton";

import RegisteredAgentSkeleton from "./RegisteredAgentSkeleton";

const RegisteredAgentList = () => (
  <div className="bg-white w-100 p-3 edit-call-queue">
    <div className="d-flex">
      <p className="fw-bold register-agent-title mb-0">
        <Skeleton width={120} height={30} />
      </p>
    </div>
    <RegisteredAgentSkeleton />
    <RegisteredAgentSkeleton />
    <RegisteredAgentSkeleton />
  </div>
);

export default RegisteredAgentList;
