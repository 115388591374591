import {
  CALL_HISTORY_SEARCH,
  FETCH_CALL_HISTORY_ERROR,
  FETCH_CALL_HISTORY_RECEIVED,
  FETCH_CALL_HISTORY_REQUEST,
  FILTER_CALL_HISTORY_BY_DATE_ERROR,
  FILTER_CALL_HISTORY_BY_DATE_RECEIVED,
  FILTER_CALL_HISTORY_BY_DATE_REQUEST,
} from "redux/actions/callHistory";

const defaultState = {
  isLoading: true,
  callHistories: [],
  fetchCallHistoryError: "",
  isSearching: false,
  isProcessing: false,
  isFiltering: false,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CALL_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSearching: false,
        fetchCallHistoryError: "",
      };
    case FETCH_CALL_HISTORY_RECEIVED:
      return {
        ...state,
        isLoading: false,
        callHistories: action.callHistory,
      };
    case FETCH_CALL_HISTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchCallHistoryError: action.error,
      };

    case CALL_HISTORY_SEARCH:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case FILTER_CALL_HISTORY_BY_DATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isFiltering: true,
        fetchCallHistoryError: "",
      };

    case FILTER_CALL_HISTORY_BY_DATE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        callHistories: action.data,
      };
    case FILTER_CALL_HISTORY_BY_DATE_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchCallHistoryError: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
