import React from "react";
import { Translate } from "react-thunk-i18nify";

import { faHistory, faVoicemail } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import { faCalendarTime, faHome } from "constants/customIcon/icons";
import { CALL_HISTORY, HOME, PROFILE, VOICEMAIL } from "constants/routes";

import MenuItem from "./MenuItem";

const menuItem = [
  {
    id: 1,
    title: <Translate value="pages.title.home" />,
    icon: faHome,
    path: HOME.INDEX,
    testID: "menu-item-contacts",
  },
  {
    id: 2,
    title: <Translate value="pages.title.profileManagement" />,
    icon: faCalendarTime,
    path: PROFILE.INDEX,
    testID: "menu-item-profile",
  },
  {
    id: 3,
    title: <Translate value="pages.title.voicemails" />,
    icon: faVoicemail,
    path: VOICEMAIL.INDEX,
    testID: "menu-item-voicemails",
  },
  {
    id: 4,
    title: <Translate value="pages.title.callHistory" />,
    icon: faHistory,
    path: CALL_HISTORY.INDEX,
    testID: "menu-item-call-history",
  },
];

const Menu = ({ hideTitle, closeMobileMenu }) => {
  const showTitle = (title) => {
    if (hideTitle) {
      return "";
    }

    return title;
  };

  return (
    <div className="menu flex-fill pt-1">
      {menuItem.map(({ title, icon, path, id, testID }) => (
        <MenuItem
          key={id}
          title={showTitle(title)}
          icon={icon}
          path={path}
          onMenuItemClick={closeMobileMenu}
          testID={testID}
        />
      ))}
    </div>
  );
};

Menu.defaultProps = {
  hideTitle: false,
  closeMobileMenu: () => {},
};

Menu.propTypes = {
  hideTitle: PropTypes.bool,
  closeMobileMenu: PropTypes.func,
};

export default Menu;
