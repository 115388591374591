import { Field } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import InputField from "components/Form/InputField/InputField";
import PasswordField from "components/Form/PasswordField/PasswordField";

import { composeValidators, number, required } from "utils/validation";

const ConfigVoicemailBoxForm = ({
  initialValues: {
    email,
    maxmsg: maxMsg,
    minsecs: minSecs,
    password,
    maxsecs: maxSecs,
  },
}) => (
  <div data-cy="config-voicemail-box-form" className="w-100">
    <div className="px-2 py-3 config-field row w-100 ms-0">
      <Field
        name="email"
        component={InputField}
        placeholderTranslation="attributes.emailAddress"
        labelTranslation="attributes.emailAddress"
        validate={required("validationErrors.REQUIRED")}
        labelClassName="col-md-4 align-self-center fw-normal mb-0 label"
        inputContainerClassName="col-md-5"
        errorClassName="text-nowrap"
        initialValue={email}
      />
    </div>
    <div className="px-2 py-3 config-field row w-100 ms-0">
      <Field
        name="password"
        component={PasswordField}
        placeholderTranslation="attributes.pinCode"
        labelTranslation="attributes.pinCode"
        validate={required("validationErrors.REQUIRED")}
        labelClassName="col-md-4 align-self-center fw-normal mb-0 label"
        inputContainerClassName="col-md-5"
        errorClassName="text-nowrap"
        initialValue={password}
      />
    </div>
    <div className="px-2 py-3 config-field row w-100 ms-0">
      <Field
        name="maxmsg"
        component={InputField}
        placeholderTranslation="attributes.maximumNumberOfMessagesAllowed"
        labelTranslation="attributes.maximumNumberOfMessagesAllowed"
        validate={composeValidators(
          required("validationErrors.REQUIRED"),
          number
        )}
        labelClassName="col-md-4 align-self-center fw-normal mb-0 label"
        inputContainerClassName="col-10 col-md-2"
        type="number"
        errorClassName="text-nowrap"
        initialValue={maxMsg}
      />
    </div>
    <div className="px-2 py-3 config-field row w-100 ms-0">
      <Field
        name="minsecs"
        component={InputField}
        placeholderTranslation="attributes.minimumNumberOfAMessage"
        labelTranslation="attributes.minimumNumberOfAMessage"
        validate={composeValidators(
          required("validationErrors.REQUIRED"),
          number
        )}
        labelClassName="col-md-4 align-self-center fw-normal mb-0 label"
        inputContainerClassName="col-10 col-md-2"
        type="number"
        errorClassName="text-nowrap"
        initialValue={minSecs}
      />
      <p className="col-2 align-self-center mb-0 time-stamp">
        <Translate value="timeUnit.sec" />
      </p>
    </div>

    <div className="px-2 py-3 config-field row w-100 ms-0">
      <Field
        name="maxsecs"
        component={InputField}
        placeholderTranslation="attributes.maximumNumberOfAMessage"
        labelTranslation="attributes.maximumNumberOfAMessage"
        validate={composeValidators(
          required("validationErrors.REQUIRED"),
          number
        )}
        labelClassName="col-md-4 align-self-center fw-normal mb-0 label"
        inputContainerClassName="col-10 col-md-2"
        type="number"
        errorClassName="text-nowrap"
        initialValue={maxSecs}
      />
      <p className="col-2 align-self-center mb-0 time-stamp">
        <Translate value="timeUnit.sec" />
      </p>
    </div>
  </div>
);

ConfigVoicemailBoxForm.defaultProps = {
  initialValues: {
    email: "",
    maxmsg: "",
    minsecs: "",
    maxsecs: "",
    password: "",
  },
};

ConfigVoicemailBoxForm.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    maxmsg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minsecs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxsecs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    password: PropTypes.string,
  }),
};

export default ConfigVoicemailBoxForm;
