import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { format } from "date-fns";
import { da, enGB } from "date-fns/locale";

import {
  CONFIGURE_FIXED_CALENDAR_FORMAT,
  LANGUAGE_TYPE,
} from "constants/configuration";

import { fixedCalendarFormatter } from "utils/fixedCalendarFormatter";

import { fetchDailyScheduleCallProfileRequest } from "redux/actions/callProfile";

import DailyScheduleModal from "./DailyScheduleModal/DailyScheduleModal";
import FixedCalendarHeader from "./FixedCalendarHeader";

const EVENT_TYPE = {
  EDIT_ICON: "edit icon",
  CALENDAR: "calendar",
};

const FixedCalendar = () => {
  const [isVisible, setVisible] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedDay, setSelectedDay] = useState({
    translatedDay: "",
    day: "",
  });

  const { locale } = useSelector(({ i18n }) => i18n);
  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const { dailyScheduleCallProfile: scheduleData } = useSelector(
    ({ callProfile }) => callProfile
  );

  const selectedLocale = locale === LANGUAGE_TYPE.DANISH ? da : enGB;

  const handleDateSelect = (args, eventType) => {
    if (eventType == EVENT_TYPE.EDIT_ICON) {
      setSelectedDay({
        translatedDay: format(args.date, "EEEE", { locale: selectedLocale }),
        day: format(args.date, "EEEE"),
      });
    } else {
      setSelectedDay({
        translatedDay: format(args.start, "EEEE", { locale: selectedLocale }),
        day: format(args.start, "EEEE"),
      });
    }

    setVisible(true);
    setCalendarData([]);
  };

  useEffect(() => {
    dispatch(fetchDailyScheduleCallProfileRequest(userLicenseID));
  }, []);

  useEffect(() => {
    if (!scheduleData.length) return;
    setCalendarData(fixedCalendarFormatter(scheduleData));
  }, [scheduleData]);

  useEffect(() => {
    if (calendarData.length == 0 && scheduleData.length !== 0) {
      setCalendarData(fixedCalendarFormatter(scheduleData));
    }
  }, [calendarData]);

  return (
    <div className="fixed-calendar pe-md-3 flex-fill h-100 overflow-auto">
      <div className="d-flex flex-column bg-white p-3 ps-md-3 h-auto call-profile-detail pb-3">
        <div className="text-center mt-2 mb-3">
          <p className="calendar-text fw-bold mb-1">
            <Translate value="callProfile.default.yourFixedCalendarProfiles" />:
          </p>
          <p className="calendar-description px-md-3">
            <Translate value="callProfile.default.fixedCalendarHeaderDescription" />
          </p>
        </div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          headerToolbar={false}
          allDaySlot={false}
          scrollTime="00:00"
          locale="en-GB"
          firstDay={1}
          slotLabelFormat={CONFIGURE_FIXED_CALENDAR_FORMAT}
          dayHeaderContent={(args) => (
            <FixedCalendarHeader
              handleDateSelect={handleDateSelect}
              data={args}
              selectedLocale={selectedLocale}
              eventType={EVENT_TYPE.EDIT_ICON}
            />
          )}
          weekends
          contentHeight="auto"
          events={calendarData}
          initialEvents={calendarData}
          select={(e) => handleDateSelect(e, EVENT_TYPE.CALENDAR)}
          slotEventOverlap={false}
        />
      </div>
      <DailyScheduleModal
        isVisible={isVisible}
        handleOverlayClick={() => setVisible(false)}
        handleCancel={() => setVisible(false)}
        calendarData={calendarData}
        selectedDay={selectedDay}
      />
    </div>
  );
};

export default React.memo(FixedCalendar);
