import { setLocale } from "react-thunk-i18nify";

import renderWithProvider from "components/__test__/hoc/withReduxProvider";

import { store } from "redux/store";

const applicationEnvironment = process.env.NODE_ENV;

const setLanguage = (lang) => {
  if (applicationEnvironment == "test") {
    const { store: testStore } = renderWithProvider();

    testStore.dispatch(setLocale(lang));

    const {
      i18n: { locale },
    } = testStore.getState();

    return locale;
  }

  store.dispatch(setLocale(lang));
};

export { setLanguage };
