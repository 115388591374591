import React from "react";
import { actions } from "react-redux-toastr";
import { Translate } from "react-thunk-i18nify";

import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { store } from "redux/store";

const toasterId = "network-error-toastr";

export const displayNetworkErrorToaster = () => {
  store.dispatch(
    actions.add({
      id: toasterId,
      type: "error",
      title: <Translate value="toasterMessage.networkError" />,
      position: "top-right",
      options: {
        timeOut: 3000,
        icon: (
          <FontAwesomeIcon
            className="me-4 toastr-icon mt-0"
            icon={faTimesCircle}
            size="1x"
          />
        ),
        removeOnHover: false,
        closeOnToastrClick: false,
        component: (
          <button
            className="btn btn-warning text-white error-toaster"
            type="button"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon className="mx-2" icon={faSync} />
            <Translate value="common.reload" />
          </button>
        ),
      },
    })
  );
};

export const removeNetworkErrorToaster = () => {
  store.dispatch(actions.remove(toasterId));
};
