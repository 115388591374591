import {
  FETCH_AVAILABLE_QUEUE_REQUEST,
  FETCH_AVAILABLE_QUEUE_RECEIVED,
  FETCH_AVAILABLE_QUEUE_ERROR,
  FETCH_HOME_QUEUE_REQUEST,
  FETCH_HOME_QUEUE_RECEIVED,
  FETCH_HOME_QUEUE_ERROR,
  UPDATE_QUEUE_IN_DASHBOARD_REQUEST,
  UPDATE_QUEUE_IN_DASHBOARD_RECEIVED,
  UPDATE_QUEUE_IN_DASHBOARD_ERROR,
  CLEAR_QUEUE_ERROR_IN_DASHBOARD,
} from "redux/actions/configureQueue";

const defaultState = {
  isHomeQueueLoading: true,
  isAvailableQueueLoading: true,
  isSubmitting: false,
  homeQueues: [],
  availableQueues: [],
  error: "",
  commitSuccess: "",
};

function configureQueue(state = defaultState, action) {
  switch (action.type) {
    case FETCH_HOME_QUEUE_REQUEST:
      return {
        ...state,
        isHomeQueueLoading: true,
        homeQueues: [],
        error: "",
      };

    case FETCH_HOME_QUEUE_RECEIVED:
      return {
        ...state,
        homeQueues: action.data,
        isHomeQueueLoading: false,
      };

    case FETCH_HOME_QUEUE_ERROR:
      return {
        ...state,
        error: action.error,
        isHomeQueueLoading: false,
      };

    case FETCH_AVAILABLE_QUEUE_REQUEST:
      return {
        ...state,
        isAvailableQueueLoading: true,
        availableQueues: [],
        error: "",
      };

    case FETCH_AVAILABLE_QUEUE_RECEIVED:
      return {
        ...state,
        availableQueues: action.data,
        isAvailableQueueLoading: false,
      };

    case FETCH_AVAILABLE_QUEUE_ERROR:
      return {
        ...state,
        error: action.error,
        isAvailableQueueLoading: false,
      };

    case UPDATE_QUEUE_IN_DASHBOARD_REQUEST:
      return {
        ...state,
        error: "",
        commitSuccess: "",
        isSubmitting: true,
      };

    case UPDATE_QUEUE_IN_DASHBOARD_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess:
          "message.configureQueue.queueInDashboardUpdatedSuccessfully",
      };

    case UPDATE_QUEUE_IN_DASHBOARD_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.error,
      };

    case CLEAR_QUEUE_ERROR_IN_DASHBOARD:
      return {
        ...state,
        isHomeQueueLoading: true,
        isAvailableQueueLoading: true,
        isSubmitting: false,
        homeQueues: [],
        availableQueues: [],
        error: "",
        commitSuccess: "",
      };

    default:
      return state;
  }
}

export default configureQueue;
