import React from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import PropTypes from "prop-types";

import InformationBoxWrapper from "layouts/InformationBoxWrapper/InformationBoxWrapper";

import IncomingCallIndexSkeleton from "components/Skeleton/CallProfiles/Detail/IncomingCallIndexSkeleton";

import { INCOMING_CALL } from "constants/images";

import IncomingCallItem from "./IncomingCallItem/IncomingCallItem";

const IncomingCallIndex = ({ initialValue }) => {
  if (!Array.isArray(initialValue)) {
    return <IncomingCallIndexSkeleton />;
  }

  return (
    <article
      className="incoming-call-index mt-2 mb-4 pt-2 pt-md-0"
      data-cy="incoming-call-index"
    >
      <InformationBoxWrapper
        title="common.incomingCalls"
        titleImageSource={INCOMING_CALL.SOURCE}
      >
        <div className="px-3">
          <FieldArray name="incomingCalls">
            {({ fields }) =>
              fields.map((name, index) => (
                <Field key={index} name={name} component={IncomingCallItem} />
              ))
            }
          </FieldArray>
        </div>
      </InformationBoxWrapper>
    </article>
  );
};

IncomingCallIndex.defaultProps = {
  initialValue: [],
};

IncomingCallIndex.propTypes = {
  initialValue: PropTypes.array,
};

export default React.memo(IncomingCallIndex);
