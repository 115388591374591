import React from "react";

import PropTypes from "prop-types";

import EmptyContent from "components/EmptyContent/EmptyContent";

import { isEmpty } from "utils/isEmpty";

const GeneralEmptyContent = ({ generalVoicemailBoxes }) => {
  if (!isEmpty(generalVoicemailBoxes)) return null;

  return (
    <EmptyContent
      title="voicemailBoxes.empty.emptyGeneralVoicemailBoxInformation"
      description="voicemailBoxes.empty.descriptionGeneral"
    />
  );
};

GeneralEmptyContent.defaultProps = {
  generalVoicemailBoxes: {},
};

GeneralEmptyContent.propTypes = {
  generalVoicemailBoxes: PropTypes.shape({}),
};

export default GeneralEmptyContent;
