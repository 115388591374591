import React from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import Modal from "components/Modal/Modal";

import { logoutRequest } from "redux/actions/auth";

const LogoutModal = ({ isVisible }) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  return (
    <Modal
      isVisible={isVisible}
      className="custom-modal session-expired-modal p-4"
      overlayClass="logout-overlay"
    >
      <h5 className="text-center my-3 fw-bold">
        <Translate value="auth.logoutModal.whoopsYourSessionHasExpired" />
      </h5>
      <p className="text-center my-2">
        <Translate value="auth.logoutModal.description" />
      </p>
      <div className="d-flex justify-content-center mt-4 mb-3">
        <button
          className="btn btn-primary logout-button"
          onClick={handleLogout}
        >
          <Translate value="common.logout" />
        </button>
      </div>
    </Modal>
  );
};

LogoutModal.defaultProps = {
  isVisible: false,
};

LogoutModal.propTypes = {
  isVisible: PropTypes.bool,
};

export default LogoutModal;
