import React from "react";
import { I18n, I18nReRender, Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Tooltip from "components/CustomTooltip/Tooltip";
import FormError from "components/Form/FormError";
import InputLabel from "components/Form/InputLabel";

import { TOOLTIP_ALERT } from "constants/images";

const ToolTipIcon = ({ children, showTooltip }) => {
  if (!showTooltip) return null;

  return (
    <Tooltip
      title={children}
      containerClassName="d-inline-block disable-name-tooltip"
    >
      <img
        src={TOOLTIP_ALERT.SOURCE}
        alt="input field disable"
        className="disable-icon ms-1 mb-1"
      />
    </Tooltip>
  );
};

const InputFieldIcon = ({ className, icon }) => {
  if (!icon) return null;

  return (
    <FontAwesomeIcon
      icon={icon}
      className={`position-absolute top-50 input-icon ${className}`}
      data-testid="input-field-icon"
    />
  );
};

const InputField = ({
  input: { name, onBlur, onChange, type, value, ...input },
  meta: { touched, error, submitError },
  placeholderTranslation,
  labelTranslation,
  labelClassName,
  inputFieldIcon,
  inputContainerClassName,
  errorClassName,
  disabled,
  showTooltip,
  tooltipContent,
}) => {
  const checkError = (error, submitError, touched) => {
    if ((error || submitError) && touched) {
      return "error";
    } else if (value) {
      return "active";
    }

    return "";
  };

  return (
    <>
      <I18nReRender
        render={() => (
          <>
            <InputLabel
              name={name}
              className={labelClassName}
              labelTranslation={labelTranslation}
            />
            <ToolTipIcon showTooltip={showTooltip && disabled}>
              <Translate value={tooltipContent} />
            </ToolTipIcon>
            <div
              className={`position-relative mt-1 ${inputContainerClassName}`}
              data-testid="input-field-container"
            >
              <div className="position-relative mt-1 w-100">
                <input
                  {...input}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  type={type}
                  disabled={disabled}
                  className={`form-control w-100  ${checkError(
                    error,
                    submitError,
                    touched
                  )}`}
                  id={name}
                  data-testid={name}
                  aria-describedby={name}
                  placeholder={I18n.t(placeholderTranslation)}
                />
                <InputFieldIcon
                  className={checkError(error, submitError, touched)}
                  icon={inputFieldIcon}
                />
              </div>
              <FormError
                error={error}
                submitError={submitError}
                touched={touched}
                className={errorClassName}
              />
            </div>
          </>
        )}
      />
    </>
  );
};

ToolTipIcon.defaultProps = {
  children: <></>,
  showTooltip: false,
};

ToolTipIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showTooltip: PropTypes.bool,
};

InputFieldIcon.defaultProps = {
  className: "",
  icon: null,
};

InputFieldIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.shape({}),
};

InputField.defaultProps = {
  placeholderTranslation: "",
  labelTranslation: "",
  labelClassName: "fw-bold mb-2",
  inputFieldIcon: {},
  inputContainerClassName: "",
  errorClassName: "",
  disabled: false,
  tooltipContent: "",
  showTooltip: false,
};

InputField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    warning: PropTypes.string,
    submitError: PropTypes.bool,
  }),
  placeholderTranslation: PropTypes.string,
  labelTranslation: PropTypes.string,
  labelClassName: PropTypes.string,
  inputFieldIcon: PropTypes.shape({}),
  inputContainerClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  disabled: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showTooltip: PropTypes.bool,
};

export default InputField;
