import React from "react";
import { Field, useField } from "react-final-form";

import PropTypes from "prop-types";

import OptionSelectDropdown from "components/Dropdown/SelectDropdown/OptionSelectDropdown";

import { INCOMING_CALL_TYPE } from "utils/incomingCallAttributes";
import { getStrategyAfterRingingDuration } from "utils/incomingCallOptions";
import { required } from "utils/validation";

import ForwardTo from "./ForwardTo/ForwardTo";

const StrategyAfterRingingDuration = ({ show, name, deviceName }) => {
  if (!show) return null;

  const {
    input: { value: selectedValue },
  } = useField(`${name}.ringingStrategyAfterRingingDuration`);

  const showForwardTo = selectedValue?.value === INCOMING_CALL_TYPE.FORWARD_TO;

  const containerClassName = showForwardTo ? "col-xl-12" : "col-xl-7";
  const fieldClassName = showForwardTo ? "col-xl-6 mt-0" : "col-12 col-xl-10";
  const rowClassName = showForwardTo
    ? "row align-self-center"
    : "d-flex align-items-center w-100";

  return (
    <div className={`${containerClassName} mb-3`}>
      <div className={rowClassName}>
        <Field
          name={`${name}.ringingStrategyAfterRingingDuration`}
          component={OptionSelectDropdown}
          validate={required("validationErrors.REQUIRED")}
          options={getStrategyAfterRingingDuration()}
          containerClassName={`${fieldClassName} mb-3 fw-normal flex-column justify-content-start`}
          activeClassName="selected-call-routing"
          placeholderTranslation="attributes.selectAnOption"
          menuClassName="incoming-call-menu py-0"
          className="incoming-call-menu-item"
          errorClassName="align-self-start ms-2"
          disableRemoveSelectedOption
          hideSelectedValue
        />
        <ForwardTo
          name={`${name}.ringingStrategyForwardTo`}
          deviceName={deviceName}
          show={showForwardTo}
          additionalClassName="mb-md-3 mt-0"
        />
      </div>
    </div>
  );
};

StrategyAfterRingingDuration.defaultProps = {
  show: false,
  deviceName: "",
  name: "",
};

StrategyAfterRingingDuration.propTypes = {
  show: PropTypes.bool,
  deviceName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
};

export default StrategyAfterRingingDuration;
