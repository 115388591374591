import React from "react";
import { Translate } from "react-thunk-i18nify";

import FixedCalendarBanner from "components/FixedCalendar/FixedCalendarBanner";

import { DEFAULT_PROFILE } from "constants/images";

const DefaultProfile = () => (
  <div className=" pe-md-3 overflow-auto d-none d-md-block flex-fill h-100">
    <div className="d-flex flex-column bg-white p-3 ps-md-3 h-auto call-profile-detail">
      <FixedCalendarBanner />
      <article className="d-flex flex-column align-items-center h-100 flex-fill justify-content-center">
        <figure>
          <img src={DEFAULT_PROFILE.SOURCE} alt="default profile page" />
        </figure>
        <h5 className="h5 fw-bold pt-3 w-100 text-center bg-white">
          <Translate value="callProfile.default.selectProfileFromList" />
        </h5>
      </article>
    </div>
  </div>
);

export default DefaultProfile;
