import React, { useRef, forwardRef, useEffect } from "react";

import PropTypes from "prop-types";

const TableCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        className="form-check-input table-checkbox"
      />
    </div>
  );
});

TableCheckBox.defaultProps = {
  indeterminate: false,
};

TableCheckBox.propTypes = {
  indeterminate: PropTypes.bool,
};

export default TableCheckBox;
