import React, { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";

import { WAIT_TIME } from "constants/images";

import { convertToDuration } from "utils/timeFormatter";

const oneHourInSeconds = 3600;

const WaitingTime = ({ time }) => {
  const [convertedTime, setConvertedTime] = useState(0);
  const timeRef = useRef(null);

  useEffect(() => {
    timeRef.current = Number(time);

    const interval = setInterval(() => {
      if (timeRef.current) {
        setConvertedTime(timeRef.current);
        timeRef.current = null;
      } else {
        setConvertedTime((convertedTime) => convertedTime + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  if (convertedTime === 0)
    return (
      <span>
        <img src={WAIT_TIME.SOURCE} alt="waiting time" className="me-1" />
        --:--
      </span>
    );

  if (convertedTime >= oneHourInSeconds) {
    return (
      <span>
        <img src={WAIT_TIME.SOURCE} alt="waiting time" className="me-1" />
        {convertToDuration(convertedTime, "hh:mm:ss")}
      </span>
    );
  }

  return (
    <span>
      <img src={WAIT_TIME.SOURCE} alt="waiting time" className="me-1" />
      {convertToDuration(convertedTime)}
    </span>
  );
};

WaitingTime.defaultProps = {
  time: 0,
};

WaitingTime.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WaitingTime;
