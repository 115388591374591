import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { EMPTY_CONTACT } from "constants/images";

const Content = ({ title, description }) => (
  <article className="empty-content overflow-auto mh-100 h-100 w-100 d-flex  flex-column justify-content-center align-items-center px-3">
    <figure>
      <img src={EMPTY_CONTACT.SOURCE} alt="non-contact-selected" />
    </figure>
    <h5 className="mt-4 mb-2 text-center title">
      <Translate value={title} />
    </h5>
    <p className="text-center detail">
      <Translate value={description} />
    </p>
  </article>
);

const EmptyContact = ({
  searchTitleTranslation,
  searchDescriptionTranslation,
  emptyContactTitleTranslation,
  emptyContactDescriptionTranslation,
  isSearching,
}) => {
  if (isSearching) {
    return (
      <Content
        title={searchTitleTranslation}
        description={searchDescriptionTranslation}
      />
    );
  }

  return (
    <Content
      title={emptyContactTitleTranslation}
      description={emptyContactDescriptionTranslation}
    />
  );
};

Content.defaultProps = {
  title: "",
  description: "",
};

Content.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

EmptyContact.defaultProps = {
  searchTitleTranslation: "",
  searchDescriptionTranslation: "",
  emptyContactTitleTranslation: "",
  emptyContactDescriptionTranslation: "",
  isSearching: false,
};

EmptyContact.propTypes = {
  searchTitleTranslation: PropTypes.string,
  searchDescriptionTranslation: PropTypes.string,
  emptyContactTitleTranslation: PropTypes.string,
  emptyContactDescriptionTranslation: PropTypes.string,
  isSearching: PropTypes.bool,
};

export default EmptyContact;
