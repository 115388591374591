import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTable, useRowSelect } from "react-table";

import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import EmptyTableHeader from "components/EmptyContent/EmptyTableHeader";
import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import AgentField from "components/Home/QueueDashboard/AgentManagement/AgentField";
import Table from "components/Table/Table";
import TableCheckBox from "components/Table/TableCheckBox";
import TableHeader from "components/Table/TableHeader";

import { ConfigureQueueContext } from "context-api/ConfigureQueueContext";

import { HOME_QUEUE } from "constants/tableHeader";

import { isEmpty } from "utils/isEmpty";

import HomeQueueSelection from "./HomeQueueSelection";
import QueuePriority from "./QueuePriority";

const HomeQueueTable = ({ homeQueues, isSearching, setSelectedID }) => {
  const [tableData, setTableData] = useState([]);

  const {
    setUpdatedHomeQueues,
    updatedHomeQueues,
    queuePriority,
    setQueuePriority,
    setUpdatedAvailableQueues,
    isFormEdited,
    setFormEdited,
  } = useContext(ConfigureQueueContext);

  const updateFormEdited = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  const removeQueueToHomeHandler = (queueID) => {
    updateFormEdited();
    const availableQueue = updatedHomeQueues.find(
      ({ asterisk_queue_id: id }) => id === queueID
    );

    setUpdatedHomeQueues((prev) =>
      prev.filter(({ asterisk_queue_id: id }) => id !== queueID)
    );

    setUpdatedAvailableQueues((prev) => [...prev, availableQueue]);
  };

  const handleUpdateTableData = (data) =>
    data.map(({ asterisk_queue_id: queueID, name }) => ({
      queue: <p className="queue-name mb-0">{name}</p>,
      action: (
        <FontAwesomeIcon
          icon={faTrashCan}
          onClick={() => removeQueueToHomeHandler(queueID)}
          className="d-flex mx-auto agent-delete-button"
        />
      ),
      queuePriority: <QueuePriority id={queueID} />,
      homeQueue: {
        asterisk_queue_id: queueID,
        name,
      },
    }));

  const updateQueuePriority = (data) => {
    const updatedQueuePriority = { ...queuePriority };

    data.map(({ weight, asterisk_queue_id: id }) => {
      let title = null;

      if (weight > data.length) {
        title = data.length;
      } else if (updatedQueuePriority?.[id]?.weight > data.length) {
        title = data.length;
      } else {
        title = weight;
      }

      updatedQueuePriority[id] = {
        weight: title,
        title: title,
      };
    });

    return updatedQueuePriority;
  };

  useEffect(() => {
    if (isEmpty(queuePriority)) return;

    const sortedData = updatedHomeQueues.sort((a, b) => {
      if (
        queuePriority?.[a.asterisk_queue_id]?.weight >
        queuePriority?.[b.asterisk_queue_id]?.weight
      ) {
        return 1;
      } else if (
        queuePriority?.[a.asterisk_queue_id]?.weight ===
        queuePriority?.[b.asterisk_queue_id]?.weight
      ) {
        return a.name.localeCompare(b.name);
      } else {
        return -1;
      }
    });

    setTableData(handleUpdateTableData(sortedData));
  }, [queuePriority]);

  useEffect(() => {
    setTableData(handleUpdateTableData(updatedHomeQueues));
    setQueuePriority(updateQueuePriority(updatedHomeQueues));
  }, [updatedHomeQueues]);

  useEffect(() => {
    if (homeQueues.length === 0) return setTableData([]);
    setTableData(handleUpdateTableData(homeQueues));
  }, [homeQueues]);

  const tableInstance = useTable(
    {
      columns: HOME_QUEUE,
      data: tableData,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "homeQueues",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableCheckBox {...getToggleAllRowsSelectedProps()} />
          ),

          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <TableCheckBox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  useEffect(() => {
    setSelectedID(Boolean(selectedFlatRows.length));
  }, [selectedFlatRows]);

  const handleQueueSelection = () => {
    let newTableData;

    updateFormEdited();

    selectedFlatRows.map((device) => {
      const { asterisk_queue_id: queueID } = device.original.homeQueue;

      newTableData = tableData.filter(
        ({ homeQueue: { asterisk_queue_id: id } }) => id !== queueID
      );

      setUpdatedHomeQueues((prev) =>
        prev.filter(({ asterisk_queue_id: id }) => id !== queueID)
      );

      const availableQueue = updatedHomeQueues.find(
        ({ asterisk_queue_id: id }) => id === queueID
      );

      setUpdatedAvailableQueues((prev) => [...prev, availableQueue]);
    });

    setTableData(newTableData);
  };

  return (
    <div className="home-queue-table pt-3 overflow-auto">
      <EmptyTableHeader show={!tableData.length && !isSearching} />
      <Table
        getTableProps={getTableProps}
        className="available-agent-table-list mb-0 px-3"
      >
        <TableHeader
          headerGroups={headerGroups}
          show={tableData.length}
          columnClassName="table-header-contain fw-normal"
        />
        <AgentField
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Table>
      <EmptyTableUI
        records={homeQueues}
        isSearching={isSearching}
        searchTitle="callQueue.empty.noHomeQueue"
        searchDescription="callQueue.empty.searchHomeQueueNotFound"
      />
      <HomeQueueSelection
        selectedQueues={selectedFlatRows.length}
        handleQueueSelection={handleQueueSelection}
      />
      <hr className="mt-0 separator" />
    </div>
  );
};

HomeQueueTable.defaultProps = {
  homeQueues: [],
  getToggleAllRowsSelectedProps: () => {},
  row: {
    getToggleRowSelectedProps: () => {},
  },
  setUpdatedValueCount: () => {},
  isSearching: false,
  setSelectedID: () => {},
};

HomeQueueTable.propTypes = {
  homeQueues: PropTypes.shape([]),
  getToggleAllRowsSelectedProps: PropTypes.func,
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
  }),
  setUpdatedValueCount: PropTypes.func,
  isSearching: PropTypes.bool,
  setSelectedID: PropTypes.func,
};

export default HomeQueueTable;
