import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { EMPTY_CALL_PROFILE } from "constants/images";
import { PROFILE } from "constants/routes";

const EmptyCallProfileContent = () => (
  <article className="empty-content d-none d-md-block overflow-auto mh-100 h-100 w-100 layout-spacing">
    <div className="p-0 d-flex flex-column align-items-center justify-content-center mx-auto h-100">
      <figure>
        <img src={EMPTY_CALL_PROFILE.SOURCE} alt="call profile content" />
      </figure>
      <h5 className="mt-4 mb-2 text-center title fw-bold">
        <Translate value="callProfile.empty.thereAreNoProfilesToShow" />{" "}
        <Link className="d-inline link" to={PROFILE.CREATE}>
          <Translate value="callProfile.empty.pleaseCreateANewProfile" />
        </Link>
      </h5>
    </div>
  </article>
);

export default EmptyCallProfileContent;
