import React from "react";
import Skeleton from "react-loading-skeleton";

import SearchBarSkeleton from "components/Skeleton/SearchBarSkeleton";
import TableSkeleton from "components/Skeleton/TableSkeleton";

const AgentsSkeleton = () => (
  <div className="available-agents">
    <div className="available-agent-header d-flex align-items-center justify-content-between mb-3">
      <Skeleton width={80} height={30} />
      <SearchBarSkeleton />
    </div>
    <TableSkeleton numberOfRows={2} numberOfColumns={2} className="mb-0" />
    <hr className="mt-0 separator" />
  </div>
);

export default AgentsSkeleton;
