import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import DateRangePicker from "components/Form/CustomDatePicker/DateRangePicker";
import SearchBar from "components/SearchBar/SearchBar";

import { voicemailRequest } from "redux/actions/voicemail";

const TopHeader = ({ handleVoiceSearchHandler, voicemails, date, setDate }) => {
  const dispatch = useDispatch();
  const {
    currentUser: { user_license_id: userLicenseId },
  } = useSelector(({ auth }) => auth);

  const handleClearDate = () => {
    dispatch(voicemailRequest(userLicenseId));
  };

  return (
    <div className="top-header py-3 ps-md-1 pe-md-2 d-flex flex-column flex-md-row justify-content-between">
      <div className="d-flex flex-column flex-lg-row justify-content-between  w-100 mb-3 mb-md-0">
        <div className="d-flex flex-column flex-md-row mb-2 mb-md-0">
          <h5 className="h5 mb-0 align-self-lg-center fw-bold mb-md-2 mb-md-0 ms-md-2">
            <Translate value="voicemails.allVoicemails" /> ({voicemails.length})
          </h5>
        </div>

        <div className="d-flex mt-2 mt-lg-0">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center w-100">
            <p className="filter-dates d-flex align-items-center mb-0 text-nowrap me-2 me-md-0">
              <Translate value="common.filterDates" />
            </p>
            <DateRangePicker
              date={date}
              setDate={setDate}
              onClearDate={handleClearDate}
            />
          </div>

          <SearchBar
            onSearchHandler={handleVoiceSearchHandler}
            placeholderText="voicemails.searchAllVoicemails"
            className="voice-search-bar px-0 px-md-3 d-none d-md-block w-100"
          />
        </div>
      </div>
      <SearchBar
        onSearchHandler={handleVoiceSearchHandler}
        placeholderText="voicemails.searchAllVoicemails"
        className="voice-search-bar table-search-bar ps-md-0 pe-md-2 px-md-3 w-100 d-block d-md-none"
      />
    </div>
  );
};

TopHeader.defaultProps = {
  handleVoiceSearchHandler: () => {},
  voicemails: [],
  updateVoicemailTableHandler: () => {},
  date: [],
  setDate: () => {},
};

TopHeader.propTypes = {
  handleVoiceSearchHandler: PropTypes.func.isRequired,
  voicemails: PropTypes.shape([]),
  updateVoicemailTableHandler: PropTypes.func,
  setDate: PropTypes.func,
  date: PropTypes.shape([]),
};

export default TopHeader;
