import { Translate } from "react-thunk-i18nify";

import { PASSWORD_NOTIFICATION } from "constants/images";

const PasswordNotification = () => (
  <div
    data-cy="congratulations-message-container"
    className="d-flex flex-column justify-content-center password-notification pt-3"
    data-testid="congratulations-message-container"
  >
    <div className="text-center mb-3">
      <h4 className="fw-bold">
        <Translate value="changePassword.congratulations" />!
      </h4>
      <img
        src={PASSWORD_NOTIFICATION.SOURCE}
        alt="successfully changed password"
        className="my-4 py-1"
      />
    </div>
    <p
      className="mb-1 pb-2 title text-center mb-1 fw-bold"
      data-testid="new-password-text"
    >
      <Translate value="changePassword.youHaveANewPasswordNow" />
    </p>
    <p className="message text-center" data-testid="start-using-password-text">
      <Translate value="changePassword.youCanStartUsingPassword" />
      <br className="d-none d-md-block" />
      <Translate value="changePassword.youShouldNotSharePassword" />
    </p>
  </div>
);

export default PasswordNotification;
