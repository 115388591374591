class ApiUrlConfig {
  static endPoint() {
    let basePath;

    switch (process.env.REACT_APP_ENV) {
      case "development":
        basePath = "http://localhost:3000";
        // basePath = "https://mit-dev-api.flexonet.dk";

        break;
      case "staging":
        basePath = "https://mit-dev-api.flexonet.dk";
        break;
      case "production":
        basePath = "https://mit-api.flexonet.dk";
        break;
      default:
        // basePath = 'http://localhost:3000';
        basePath = "https://mit-dev-api.flexonet.dk";

        break;
    }

    return basePath;
  }

  static defaultEndPoint() {
    return ApiUrlConfig.endPoint() + "/api/v1";
  }

  static pbxUserPath() {
    return "/api/pbx_user/v1";
  }

  static pbxUserEndPoint() {
    return ApiUrlConfig.endPoint() + ApiUrlConfig.pbxUserPath();
  }

  static asteriskPath() {
    return "/flexonet_pbx/api/v1";
  }

  static asteriskEndpoint() {
    return ApiUrlConfig.endPoint() + ApiUrlConfig.asteriskPath();
  }
}

export default ApiUrlConfig;
