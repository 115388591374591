import React from "react";
import { Field } from "react-final-form";

import PropTypes from "prop-types";

import IconDropdown from "components/Dropdown/SelectDropdown/IconDropdown/IconDropdown";
import InputField from "components/Form/InputField/InputField";

import { ICON_LIST } from "constants/iconList";

import { required } from "utils/validation";

const ProfileBasicForm = ({ isDefault }) => (
  <div
    className="profile-basic-form row pt-2 mt-1 w-100 align-self-center pe-2 pe-md-0"
    data-cy="profile-basic-form"
  >
    <div className="call-profile-field col-sm-12 col-xl-5 mb-3 px-xl-0 pe-0">
      <Field
        name="name"
        component={InputField}
        placeholderTranslation="attributes.profileName"
        labelTranslation="attributes.profileName"
        validate={required("validationErrors.REQUIRED")}
        disabled={isDefault}
        showTooltip
        tooltipContent="callProfile.default.youCannotEditTheDefaultProfile"
      />
    </div>
    <div className="call-profile-field col-sm-8 col-xl-5 mb-3">
      <Field
        name="description"
        component={InputField}
        placeholderTranslation="attributes.description"
        labelTranslation="attributes.description"
        validate={required("validationErrors.REQUIRED")}
      />
    </div>
    <div className="call-profile-field col-sm-4 col-xl-2 ps-0 ps-sm-3 ps-md-0 pe-0 mt-1">
      <Field
        name="icon"
        component={IconDropdown}
        containerClassName="flex-column justify-content-start"
        activeClassName="px-2 icon-button"
        validate={required("validationErrors.REQUIRED")}
        labelTranslation="attributes.icon"
        placeholderTranslation="attributes.icon"
        options={ICON_LIST}
        errorClassName="align-self-start"
      />
    </div>
  </div>
);

ProfileBasicForm.defaultProps = {
  isDefault: false,
};

ProfileBasicForm.propTypes = {
  isDefault: PropTypes.bool,
};

export default ProfileBasicForm;
