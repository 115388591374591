import React from "react";
import Skeleton from "react-loading-skeleton";

import TableSkeleton from "components/Skeleton/TableSkeleton";

const TotalCallsInQueueSkeleton = () => (
  <div className="total-calls-in-queue px-3 py-4">
    <Skeleton width={120} height={30} />
    <div className="overflow-auto">
      <TableSkeleton numberOfRows={4} numberOfColumns={3} className="px-0" />
    </div>
  </div>
);

export default TotalCallsInQueueSkeleton;
