import { USER_ROLES } from "constants/userRoles";

import { UPDATE_STATUS_CODE_AUTH } from "utils/updateReducer";

import {
  LOGIN_CLEAR_ERRORS,
  LOGIN_FAILED,
  LOGIN_RECEIVED,
  LOGIN_REQUEST,
  LOGOUT,
  RESET_PASSWORD_CLEAR_ERRORS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_RECEIVED,
  RESET_PASSWORD_REQUEST,
  SET_PASSWORD_CLEAR_ERRORS,
  SET_PASSWORD_FAILED,
  SET_PASSWORD_RECEIVED,
  SET_PASSWORD_REQUEST,
  UPDATE_BEARER_TOKEN_RECEIVED,
  SESSION_EXPIRED,
  UPDATE_CURRENT_USER,
} from "redux/actions/auth";

const AUTH_MESSAGES = {
  RESET_PASSWORD_STATUS: "message.auth.resetLink",
  PASSWORD_RESET_ERROR: "toasterMessage.processError",
  LOGIN_400_ERROR: "message.auth.incorrectEmailAddressOrPassword",
  LOGIN_DEFAULT_ERROR: "message.auth.unableToSignIn",
};

const defaultAuthState = {
  accessToken: null,
  refreshToken: null,
  currentUser: {},
  bearerToken: "",
  isLoggedIn: false,
  isLoading: false,
  commitSuccess: "",
  commitError: "",
  fetchError: "",
  sessionExpired: false,
  statusCode: null,
  isPbxAdmin: null,
};

function auth(state = defaultAuthState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: "",
        statusCode: null,
      };

    case LOGIN_RECEIVED: {
      const { tokens } = action;

      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        user,
      } = tokens;

      localStorage.setItem("bearerToken", `${tokenType} ${accessToken}`);

      return {
        ...state,
        accessToken,
        refreshToken,
        currentUser: user,
        bearerToken: `${tokenType} ${accessToken}`,
        isLoggedIn: !!user?.is_active,
        isLoading: false,
        isPbxAdmin: USER_ROLES.PBX_ADMIN == user.role,
      };
    }

    case LOGIN_FAILED: {
      const { response } = action.message;

      if (!response) return { ...state, isLoading: false };

      switch (response?.status) {
        case 400:
          return {
            ...state,
            fetchError: AUTH_MESSAGES.LOGIN_400_ERROR,
            isLoading: false,
          };
        default:
          return {
            ...state,
            commitError: "signIn.message.setPasswordError",
            isLoading: false,
            fetchError: AUTH_MESSAGES.LOGIN_DEFAULT_ERROR,
          };
      }
    }

    case LOGIN_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: "",
        isLoading: false,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitError: "",
        commitSuccess: "",
      };

    case RESET_PASSWORD_RECEIVED: {
      return {
        ...state,
        commitSuccess: AUTH_MESSAGES.RESET_PASSWORD_STATUS,
        isLoading: false,
        commitError: "",
      };
    }

    case RESET_PASSWORD_FAILED: {
      const { response } = action.message;

      switch (response?.status) {
        case 400:
          return {
            ...state,
            commitError: AUTH_MESSAGES.RESET_PASSWORD_STATUS,
            isLoading: false,
            commitSuccess: "",
          };
        default:
          return {
            ...state,
            isLoading: false,
            commitError: AUTH_MESSAGES.PASSWORD_RESET_ERROR,
            commitSuccess: "",
          };
      }
    }

    case RESET_PASSWORD_CLEAR_ERRORS:
      return {
        ...state,
        commitSuccess: "",
        commitError: "",
        isLoading: false,
        fetchError: "",
      };

    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        commitError: "",
        commitSuccess: "",
      };

    case SET_PASSWORD_RECEIVED: {
      return {
        ...state,
        commitSuccess: "signIn.message.passwordChangedSuccessfully",
        isLoading: false,
      };
    }

    case SET_PASSWORD_FAILED: {
      const { response } = action.message;

      if (!response)
        return {
          ...state,
          isLoading: false,
          commitError: "signIn.message.setPasswordError404",
        };

      switch (response?.status) {
        case 400:
          return {
            ...state,
            commitError: "signIn.message.setPasswordError400",
            isLoading: false,
          };
        case 404:
          return {
            ...state,
            commitError: "signIn.message.setPasswordError404",
            isLoading: false,
          };
        default:
          return {
            ...state,
            commitError: "signIn.message.setPasswordError",
            isLoading: false,
          };
      }
    }

    case SET_PASSWORD_CLEAR_ERRORS:
      return {
        ...state,
        commitError: "",
        commitSuccess: "",
        fetchError: "",
        isLoading: false,
        fetchError: "",
      };

    case SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      };

    case LOGOUT:
      localStorage.removeItem("bearerToken");
      localStorage.removeItem("keep-me-signed-in");
      // we are reloading because WebSocket wouldn't completely close the connection otherwise and gives us various issues
      window.location.reload();

      return {
        ...state,
        accessToken: null,
        currentUser: null,
        refreshToken: null,
        bearerToken: null,
        isLoggedIn: false,
        isLoading: false,
        sessionExpired: false,
        loginError: "",
        statusCode: null,
      };

    case UPDATE_BEARER_TOKEN_RECEIVED: {
      const { tokens } = action;
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        user,
      } = tokens;

      localStorage.setItem("bearerToken", `${tokenType} ${accessToken}`);

      return {
        ...state,
        accessToken,
        refreshToken,
        currentUser: user,
        bearerToken: `${tokenType} ${accessToken}`,
        isLoggedIn: !!user?.is_active,
        isLoading: false,
      };
    }

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
      };

    case UPDATE_STATUS_CODE_AUTH:
      return {
        ...state,
        statusCode: action.statusCode,
      };

    default:
      return state;
  }
}

export default auth;
