import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import PbxUserDropdown from "components/Dropdown/PbxUserDropdown";
import VoicemailBoxesSkeleton from "components/Skeleton/VoicemailBox/VoicemailBoxesSkeleton";
import CellPhoneVoicemailBoxes from "components/VoicemailBoxes/CellPhones/CellPhoneVoicemailBoxes";
import GeneralVoicemailBoxes from "components/VoicemailBoxes/General/GeneralVoicemailBoxes";

import HasPermission from "hoc/AccessPermission";

import { ACTION_LIST } from "constants/userPermission";

import { useQuery } from "utils/useQuery";

import { fetchPbxUsersRequest } from "redux/actions/user";
import {
  clearVoicemailBoxErrors,
  fetchVoicemailBoxCellPhonesRequest,
  fetchVoicemailBoxRequest,
} from "redux/actions/voicemailBox";

const VoicemailBoxes = () => {
  const dispatch = useDispatch();
  const {
    currentUser: { asterisk_voicemail_box_id: voicemailBoxId, pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);

  const {
    currentUser: { user_license_id: userLicenseID },
    isPbxAdmin,
  } = useSelector(({ auth }) => auth);
  const { pbxUsers } = useSelector(({ user }) => user);

  const { pbx_user: pbxUser } = useQuery();

  const { isLoading } = useSelector(({ voicemailBox }) => voicemailBox);

  const [loading, setLoading] = useState(true);

  const handleUpdatePbxUser = ({ id, asteriskVoicemailBoxId }) => {
    dispatch(fetchVoicemailBoxCellPhonesRequest(id));
    dispatch(fetchVoicemailBoxRequest(asteriskVoicemailBoxId));
  };

  useEffect(() => {
    if (isPbxAdmin) dispatch(fetchPbxUsersRequest(pbxID));

    if (userLicenseID == pbxUser || !pbxUser) {
      dispatch(fetchVoicemailBoxRequest(voicemailBoxId));
      dispatch(fetchVoicemailBoxCellPhonesRequest(userLicenseID));
    }

    return () => {
      dispatch(clearVoicemailBoxErrors());
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (pbxUsers.length == 0 || userLicenseID == pbxUser || !pbxUser) return;

    const { asterisk_voicemail_box_id: asteriskVoicemailBoxID, id } =
      pbxUsers.find(({ id }) => id == pbxUser);

    dispatch(fetchVoicemailBoxRequest(asteriskVoicemailBoxID));
    dispatch(fetchVoicemailBoxCellPhonesRequest(id));
  }, [pbxUsers]);

  if (isLoading && loading) {
    return <VoicemailBoxesSkeleton />;
  }

  return (
    <div
      role="document"
      className="voicemail-boxes overflow-auto w-100 h-100 mx-0 layout-spacing"
    >
      <div className="bg-white px-0 h-100">
        <div className="p-4 d-flex flex-column flex-md-row align-items-md-center title-container">
          <h5
            data-cy="voicemail-boxes-title"
            className="h5 mb-2 mb-md-0 title fw-bold"
          >
            <Translate value="pages.title.voicemailBoxes" />
          </h5>
          <HasPermission perform={ACTION_LIST.PBX_USER_VOICEMAIL_BOX_VIEW}>
            <PbxUserDropdown
              handleSelectedPbxUser={handleUpdatePbxUser}
              ownerText={
                <Translate value="dropdown.pbxUsersList.ownVoicemailBox" />
              }
              containerClassName="light-background"
              isFetchRequestDisabled
            />
          </HasPermission>
        </div>
        <GeneralVoicemailBoxes />
        <CellPhoneVoicemailBoxes />
      </div>
    </div>
  );
};

export default VoicemailBoxes;
