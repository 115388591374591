import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import EmptyAgentUI from "components/Home/Empty/EmptyAgentUI";
import SearchBar from "components/SearchBar/SearchBar";
import AgentsSkeleton from "components/Skeleton/CallQueue/Detail/AgentManagementSidebar/AgentsSkeleton";

import { AgentManagementContext } from "context-api/AgentManagementContext";

import { DEVICE_TYPES, RECENTLY_ADDED_AGENT } from "constants/agent";

import {
  searchInJson,
  SEARCH_KEY_FOR_REGISTERED_AGENT,
} from "utils/searchInJson";

import { fetchRegisteredAgentsInQueueRequest } from "redux/actions/callQueue";

import RegisteredAgentTable from "./RegisteredAgentTable";

const RegisteredAgents = ({
  addExternalNumber,
  setAddExternalNumber,
  queueID,
}) => {
  const {
    currentUser: { pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);
  const { callQueues } = useSelector(({ callQueue }) => callQueue);

  const { registeredAgentsDevices, isRegisteredAgentLoading: isLoading } =
    callQueues.find(({ asterisk_queue_id: id }) => id == queueID);

  const {
    setUpdatedRegisteredAgents,
    updatedRegisteredAgents,
    callPriority,
    isFormEdited,
    setFormEdited,
  } = useContext(AgentManagementContext);

  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [isSelectedID, setSelectedID] = useState(false);

  const handleRegisteredAgentsSearch = (e) => {
    const searchText = e.target.value;

    setInitialValues(
      searchInJson(
        searchText,
        updatedRegisteredAgents,
        SEARCH_KEY_FOR_REGISTERED_AGENT,
        (value) => setSearching(value)
      )
    );
  };

  useEffect(() => {
    setInitialValues(registeredAgentsDevices);
  }, [registeredAgentsDevices]);

  useEffect(() => {
    if (addExternalNumber?.name !== "") {
      const uniqueID = Date.now();

      if (!isFormEdited) {
        setFormEdited(true);
      }

      setUpdatedRegisteredAgents((previous) => [
        ...previous,
        {
          id: uniqueID,
          name: addExternalNumber?.name,
          number: addExternalNumber?.externalNumber,
          device_type: DEVICE_TYPES.EXTERNAL_NUMBER,
          type: RECENTLY_ADDED_AGENT,
          penalty:
            callPriority?.[queueID]?.penalty ||
            updatedRegisteredAgents.length + 1,
        },
      ]);
      setAddExternalNumber({ externalNumber: "", name: "" });
    }
  }, [addExternalNumber]);

  useEffect(() => {
    setUpdatedRegisteredAgents(
      registeredAgentsDevices.map((agent) => {
        if (!agent.penalty || agent.penalty > registeredAgentsDevices.length) {
          return {
            ...agent,
            penalty: registeredAgentsDevices.length,
            attributes_are_dirty: true,
          };
        }

        return { ...agent, attributes_are_dirty: false };
      })
    );
  }, [registeredAgentsDevices]);

  useEffect(() => {
    dispatch(fetchRegisteredAgentsInQueueRequest(pbxID, queueID));
  }, []);

  if (isLoading) {
    return <AgentsSkeleton />;
  }

  if (updatedRegisteredAgents.length == 0 && !isSearching) {
    return (
      <EmptyAgentUI
        headingTranslation="callQueue.agentManagement.registeredAgents"
        titleTranslation="callQueue.empty.emptyRegisteredAgent"
        descriptionTranslation="callQueue.empty.descriptionRegisteredAgent"
      />
    );
  }

  return (
    <div className="registered-agents">
      <div className="available-agent-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-between ">
        <p className="mb-0 available-agent-title mb-2 mb-sm-0 fw-bold">
          <Translate value="callQueue.agentManagement.registeredAgents" />
        </p>
        <SearchBar
          onSearchHandler={handleRegisteredAgentsSearch}
          placeholderText="attributes.searchRegisteredAgents"
          className="search-bar"
          isSearchDisabled={isSelectedID}
        />
      </div>
      <RegisteredAgentTable
        registeredDevices={initialValues}
        isSearching={isSearching}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

RegisteredAgents.defaultProps = {
  addExternalNumber: {},
  setAddExternalNumber: () => {},
};

RegisteredAgents.propTypes = {
  addExternalNumber: PropTypes.shape({
    name: PropTypes.string,
    externalNumber: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({}),
    ]),
  }),
  setAddExternalNumber: PropTypes.func,
  queueID: PropTypes.number.isRequired,
};

export default RegisteredAgents;
