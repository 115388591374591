import React, { useMemo } from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import SelectDropdown from "components/Dropdown/SelectDropdown/SelectDropdown";

const OptionSelectDropdown = ({
  input: { onChange, value: selectedOption, onBlur, name },
  meta: { touched, error, submitError },
  options,
  className,
  containerClassName,
  activeClassName,
  labelTranslation,
  placeholderTranslation,
  menuClassName,
  errorClassName,
  additionAttributeToOptions,
  disableRemoveSelectedOption,
  hideSelectedValue,
}) => {
  const onValueSelectHandler = ({ value, label, ...rest }) => {
    if (selectedOption.value == value) {
      if (disableRemoveSelectedOption) return;
      onChange({ target: { value: "" } });
    } else {
      onChange({
        target: {
          value: {
            value,
            label,
            ...rest,
            ...additionAttributeToOptions,
          },
        },
      });
    }
  };

  const activeElementClassName = useMemo(
    () => (selectedValue) =>
      selectedOption.value == selectedValue ? "active" : "",
    [selectedOption]
  );

  return (
    <>
      <SelectDropdown
        key={name}
        input={{ value: selectedOption.value, onBlur, name }}
        meta={{ touched, error, submitError }}
        containerClassName={containerClassName}
        activeClassName={activeClassName}
        labelTranslation={labelTranslation}
        menuClassName={menuClassName}
        errorClassName={errorClassName}
        activeElement={
          <>
            <span className="px-2">
              {selectedOption.label || (
                <Translate value={placeholderTranslation} />
              )}
            </span>
          </>
        }
      >
        {options.map(({ value, label, ...rest }, index) => {
          if (value === selectedOption.value && hideSelectedValue) return null;

          return (
            <li key={index}>
              <button
                data-field-name={`option-item-${name}-${index}`}
                type="button"
                className={`dropdown-item ${className} ${activeElementClassName(
                  value
                )} py-0 px-3 d-flex justify-content-between align-items-center option-item`}
                onClick={() => onValueSelectHandler({ value, label, ...rest })}
              >
                {label}
              </button>
            </li>
          );
        })}
      </SelectDropdown>
    </>
  );
};

OptionSelectDropdown.defaultProps = {
  options: [],
  className: "",
  containerClassName: "px-3",
  activeClassName: "",
  labelTranslation: "",
  placeholderTranslation: "",
  menuClassName: "",
  errorClassName: "",
  additionAttributeToOptions: {},
  disableRemoveSelectedOption: false,
  hideSelectedValue: false,
};

OptionSelectDropdown.propTypes = {
  options: PropTypes.array,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
    ]),
    onBlur: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    submitError: PropTypes.bool,
  }),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  labelTranslation: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  menuClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  additionAttributeToOptions: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  disableRemoveSelectedOption: PropTypes.bool,
  hideSelectedValue: PropTypes.bool,
};

export default OptionSelectDropdown;
