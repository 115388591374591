import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTable, useRowSelect } from "react-table";

import PropTypes from "prop-types";

import EmptyTableHeader from "components/EmptyContent/EmptyTableHeader";
import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import Device from "components/Home/QueueDashboard/Device";
import Employee from "components/Home/QueueDashboard/Employee";
import Table from "components/Table/Table";
import TableCheckBox from "components/Table/TableCheckBox";
import TableHeader from "components/Table/TableHeader";

import { AgentManagementContext } from "context-api/AgentManagementContext";

import { AVAILABLE_AGENTS, DEVICE_TYPES_LIST } from "constants/agent";
import { AVAILABLE_AGENTS_TABLE_HEADER } from "constants/tableHeader";

import AgentField from "../AgentField";
import AvailableAgentSelection from "./AvailableAgentSelection";

const AvailableAgentTable = ({
  availableAgents,
  isSearching,
  setSelectedID,
}) => {
  const [tableData, setTableData] = useState([]);

  const {
    setUpdatedRegisteredAgents,
    setUpdatedAvailableAgents,
    updatedAvailableAgents,
    isFormEdited,
    setFormEdited,
    setDeleteRegisteredAgentID,
    setDeleteExternalNumberID,
  } = useContext(AgentManagementContext);

  const updateFormEdited = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  const handleUpdateTableData = (data) =>
    data.map(
      ({
        name,
        profile_picture: profilePicture,
        number,
        device_type: deviceType,
        device_name: deviceName,
        ...rest
      }) => ({
        employee: (
          <Employee name={name} profilePicture={profilePicture} hideStatus />
        ),
        device: (
          <Device
            deviceType={deviceType}
            number={number}
            deviceName={deviceName}
          />
        ),
        availableAgent: {
          ...rest,
          name,
          profile_picture: profilePicture,
          number,
          device_type: deviceType,
          device_name: deviceName,
        },
      })
    );

  useEffect(() => {
    if (availableAgents.length === 0) return setTableData([]);
    setTableData(handleUpdateTableData(availableAgents));
  }, [availableAgents]);

  useEffect(() => {
    setTableData(handleUpdateTableData(updatedAvailableAgents));
  }, [updatedAvailableAgents]);

  const tableInstance = useTable(
    {
      columns: AVAILABLE_AGENTS_TABLE_HEADER,
      data: tableData,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "availableAgents",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableCheckBox {...getToggleAllRowsSelectedProps()} />
          ),

          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <TableCheckBox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  useEffect(() => {
    setSelectedID(Boolean(selectedFlatRows.length));
  }, [selectedFlatRows]);

  const handleAgentSelection = () => {
    let newTableData;

    updateFormEdited();

    selectedFlatRows.map((device) => {
      const { number: deviceNumber } = device.original.availableAgent;

      newTableData = tableData.filter(
        ({ availableAgent: { number } }) => number !== deviceNumber
      );

      setUpdatedAvailableAgents((prev) =>
        prev.filter(({ number }) => number !== deviceNumber)
      );
      const registeredAgent = updatedAvailableAgents.find(
        ({ number }) => number === deviceNumber
      );

      if (registeredAgent?.type != AVAILABLE_AGENTS) {
        const { device_type: deviceType, id } = registeredAgent;

        if (DEVICE_TYPES_LIST.includes(deviceType)) {
          setDeleteRegisteredAgentID((prev) => [...prev, id]);
          setDeleteRegisteredAgentID((prev) => {
            const filterData = prev.filter((agentID) => agentID !== id);

            return filterData;
          });
        } else {
          setDeleteExternalNumberID((prev) => {
            const filterData = prev.filter((agentID) => agentID !== id);

            return filterData;
          });
        }
      }

      setUpdatedRegisteredAgents((prev) => [
        ...prev,
        { ...registeredAgent, penalty: prev.length + 1 },
      ]);
    });

    setTableData(newTableData);
  };

  return (
    <div className="available-agents-table pt-3 overflow-auto">
      <EmptyTableHeader show={!tableData.length && isSearching} />
      <Table
        getTableProps={getTableProps}
        className="available-agent-table-list mb-0 px-3"
      >
        <TableHeader
          headerGroups={headerGroups}
          show={tableData.length}
          columnClassName="table-header-contain fw-normal"
        />
        <AgentField
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Table>
      <EmptyTableUI
        records={availableAgents}
        isSearching={isSearching}
        searchTitle="callQueue.empty.noAvailableAgent"
        searchDescription="callQueue.empty.searchAvailableAgentNotFound"
      />
      <AvailableAgentSelection
        selectedAgents={selectedFlatRows.length}
        handleAgentSelection={handleAgentSelection}
      />
      <hr className="mt-0 separator" />
    </div>
  );
};

AvailableAgentTable.defaultProps = {
  availableAgents: [],
  isSearching: false,
  getToggleAllRowsSelectedProps: () => {},
  row: {
    getToggleRowSelectedProps: () => {},
  },
  setUpdatedValueCount: () => {},
  setSelectedID: () => {},
};

AvailableAgentTable.propTypes = {
  availableAgents: PropTypes.shape([]),
  isSearching: PropTypes.bool,
  getToggleAllRowsSelectedProps: PropTypes.func,
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
  }),
  setUpdatedValueCount: PropTypes.func,
  setSelectedID: PropTypes.func,
};

export default AvailableAgentTable;
