export function sipDeviceStyle(touched, error, submitError) {
  return {
    control: (provided, state) => ({
      ...provided,
      border:
        touched && (error || submitError)
          ? "0.0625em solid #E35D6A"
          : state.isFocused
          ? "0.0625em solid #ced4da"
          : "0.0625em solid #ced4da",
      height:
        state.getValue().length == 2
          ? "auto"
          : state.getValue().length > 2
          ? "4.5em"
          : "2.5em",
      scrollSnapAlign: "center",
      boxShadow: "null",
      "&:hover": {
        borderColor: "#ced4da",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5em 0.5em",
      borderBottom: "0.0625em solid #f8f9fa",
      backgroundColor: "null",
      color: state.isSelected ? "##0c1e4e" : "#707070",
      fontFamily: "Acumin, sans-serif",
      cursor: "pointer",
      fontSize: "0.875em",
      "&:focus": {
        backgroundColor: "unset",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height:
        state.getValue().length == 2
          ? "4em"
          : state.getValue().length > 2
          ? "4.3em"
          : "2.3em",
      overflow: state.getValue().length > 1 ? "auto" : "initial",
      scrollSnapAlign: "center",
      display: "flex",
      alignItems: "start",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "null",
      height: "1.875em",
      border: state.isFocused
        ? "0.0625em solid #E35D6A"
        : "0.0625em solid #75B798",
      boxSizing: "border-box",
      borderRadius: "0.5em",
      cursor: "pointer",
      "&:hover": {
        border: "0.0625em solid #E35D6A",
        ".css-xb97g8": {
          backgroundColor: "#E35D6A",
        },
      },
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#707070",
      fontFamily: "Neutrif, sans-serif",
      fontWeight: "400",
      fontSize: "0.875em",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: touched && (error || submitError) ? "#E35D6A" : "#707070",
      fontFamily: "Neutrif, sans-serif",
      fontWeight: "400",
      fontSize: "0.875em",
      alignSelf: "center",
      marginTop: "-1em",
    }),
    input: (provided) => ({
      ...provided,
      alignSelf: "center",
      height: "2.1em",
      "&::after": {
        display: "none",
      },
    }),
  };
}
