import axios from "axios";

import errorHandlerInterceptor from "services/requests/interceptor";

export const DEFAULT_HEADERS = {
  Accept: "application/json",
};

export const FORM_DATA_HEADERS = {
  "Content-Type": "multipart/form-data",
};

// Intercept the response to perform any additional tasks.
axios.interceptors.response.use(null, errorHandlerInterceptor);

class BaseRequest {
  static headers(headers) {
    return { headers: headers || DEFAULT_HEADERS };
  }

  static get(url, fileDownload = false) {
    return axios.get(url, this.headers(fileDownload));
  }

  static post(url, data) {
    return axios.post(url, data, this.headers());
  }

  static patch(url, data, isMultiPart = false) {
    if (isMultiPart) {
      return axios.patch(url, data, this.headers(FORM_DATA_HEADERS));
    }

    return axios.patch(url, data, this.headers());
  }

  static delete(url) {
    return axios.delete(url, this.headers());
  }
}

export default BaseRequest;
