import {
  VOICEMAIL_BOX_FETCH_REQUEST,
  VOICEMAIL_BOX_FETCH_RECEIVED,
  VOICEMAIL_BOX_FETCH_FAILED,
  VOICEMAIL_BOX_FETCH_CELL_PHONES_REQUEST,
  VOICEMAIL_BOX_FETCH_CELL_PHONES_RECEIVED,
  VOICEMAIL_BOX_FETCH_CELL_PHONES_FAILED,
  VOICEMAIL_BOX_CLEAR_ERRORS,
  VOICEMAIL_BOX_CELL_PHONE_SEARCH,
  VOICEMAIL_BOX_UPDATE_REQUEST,
  VOICEMAIL_BOX_UPDATE_RECEIVED,
  VOICEMAIL_BOX_UPDATE_FAILED,
} from "redux/actions/voicemailBox";

const defaultState = {
  generalVoicemailBoxes: {},
  fetchError: "",
  isLoading: true,
  isCellPhoneLoading: true,
  isProcessing: false,
  commitError: "",
  commitSuccess: false,
  cellPhones: [],
  isSearching: false,
};

const voicemailBox = (state = defaultState, action) => {
  switch (action.type) {
    case VOICEMAIL_BOX_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        fetchError: null,
        generalVoicemailBoxes: {},
      };

    case VOICEMAIL_BOX_FETCH_RECEIVED:
      return {
        ...state,
        generalVoicemailBoxes: action.voicemailBox,
        isLoading: false,
        fetchError: null,
      };

    case VOICEMAIL_BOX_FETCH_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isLoading: false,
      };

    case VOICEMAIL_BOX_FETCH_CELL_PHONES_REQUEST:
      return {
        ...state,
        isCellPhoneLoading: true,
        fetchError: null,
      };

    case VOICEMAIL_BOX_FETCH_CELL_PHONES_RECEIVED:
      return {
        ...state,
        cellPhones: action.cellPhones,
        isCellPhoneLoading: false,
        fetchError: null,
      };

    case VOICEMAIL_BOX_FETCH_CELL_PHONES_FAILED:
      return {
        ...state,
        fetchError: action.message,
        isCellPhoneLoading: false,
      };

    case VOICEMAIL_BOX_CELL_PHONE_SEARCH:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case VOICEMAIL_BOX_UPDATE_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitError: null,
        commitSuccess: false,
      };

    case VOICEMAIL_BOX_UPDATE_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        commitError: null,
        commitSuccess: "message.voicemailBoxes.voicemailBoxUpdatedSuccessfully",
      };

    case VOICEMAIL_BOX_UPDATE_FAILED:
      return {
        ...state,
        commitError: action.message,
        isProcessing: false,
      };

    case VOICEMAIL_BOX_CLEAR_ERRORS:
      return {
        ...state,
        fetchError: null,
        commitError: "",
        commitSuccess: "",
      };

    default:
      return state;
  }
};

export default voicemailBox;
