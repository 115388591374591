import React from "react";
import { Translate } from "react-thunk-i18nify";

const NewCustomerLink = () => {
  const addCustomerLink = () => {
    if (process.env.REACT_APP_ENV === "production") {
      return "http://dev.stayc.dk/";
    }

    return "http://stayc.dk/";
  };

  return (
    <a
      className="new-customer-link my-2 mb-3 py-1 link fw-bold"
      href={addCustomerLink()}
      target="_blank"
      data-testid="customer-link"
    >
      <Translate value="auth.signIn.NotAStayConnectedCustomer" />
    </a>
  );
};

export default NewCustomerLink;
