import React from "react";

import AlphabetTextSkeleton from "./AlphabetTextSkeleton";
import IndividualContactSkeleton from "./IndividualContactSkeleton";

const ContactListSkeleton = () => (
  <div className="d-flex flex-column align-items-between">
    <div className="col-10 overflow-hidden">
      <AlphabetTextSkeleton />
      <IndividualContactSkeleton />
      <IndividualContactSkeleton />
    </div>
    <div className="col-10 overflow-hidden">
      <AlphabetTextSkeleton />
      <IndividualContactSkeleton />
      <IndividualContactSkeleton />
      <IndividualContactSkeleton />
    </div>
    <div className="col-10 overflow-hidden">
      <AlphabetTextSkeleton />
      <IndividualContactSkeleton />
      <IndividualContactSkeleton />
      <IndividualContactSkeleton />
    </div>
  </div>
);

export default ContactListSkeleton;
