import React from "react";
import { I18n, I18nReRender } from "react-thunk-i18nify";

const translateText = (key) => <I18nReRender render={() => I18n.t(key)} />;

export const CALL_HISTORY_TABLE_HEADER = [
  {
    Header: "",
    accessor: "icon", // accessor is the "key" in the data
    disableSortBy: true,
  },
  {
    Header: translateText("common.from"),
    accessor: "from", // accessor is the "key" in the data
    disableSortBy: true,
  },
  {
    Header: translateText("common.to"),
    accessor: "to",
    disableSortBy: true,
  },
  {
    Header: translateText("common.time"),
    accessor: "time",
    sortType: "datetime",
    sortDescFirst: true,
  },
  {
    Header: translateText("common.duration"),
    accessor: "duration",
    sortType: "basic",
  },
];

export const VOICEMAIL_TABLE_HEADER = [
  {
    Header: translateText("common.time"),
    accessor: "time", // accessor is the "key" in the data
    sortType: "datetime",
    manualSortBy: true,
  },
  {
    Header: translateText("common.from"),
    accessor: "from",
    sortType: "basic",
  },
  {
    Header: translateText("common.listenTo"),
    accessor: "listen",
    disableSortBy: true,
  },
  {
    Header: translateText("common.download"),
    accessor: "download",
    disableSortBy: true,
  },
  {
    Header: translateText("common.sendAsEmail"),
    accessor: "email",
    disableSortBy: true,
  },
  {
    Header: " ",
    accessor: "delete",
    disableSortBy: true,
  },
];

export const VOICEMAIL_BOXES_CELL_PHONE_TABLE_HEADER = [
  {
    Header: translateText("common.cellNumber"),
    accessor: "cellNumber", // accessor is the "key" in the data
  },
  {
    Header: translateText("common.emailAddress"),
    accessor: "emailAddress",
  },
  {
    Header: translateText("voicemailBoxes.general.numberOfAllowedMessages"),
    accessor: "numberOfAllowedMessages",
  },
  {
    Header: "",
    accessor: "edit",
  },
];

export const AVAILABLE_AGENTS_TABLE_HEADER = [
  {
    Header: translateText("common.employee"),
    accessor: "employee",
  },
  {
    Header: translateText("common.device"),
    accessor: "device",
  },
];

export const REGISTERED_AGENTS_TABLE_HEADER = [
  {
    Header: translateText("common.employee"),
    accessor: "employee",
  },
  {
    Header: translateText("common.device"),
    accessor: "device",
  },
  {
    Header: translateText("common.callPriority"),
    accessor: "callPriority",
  },
  {
    Header: translateText("common.action"),
    accessor: "action",
  },
];

export const TOTAL_CALLS_IN_CALL_QUEUE = [
  {
    Header: translateText("common.number"),
    accessor: "number",
  },
  {
    Header: translateText("common.name"),
    accessor: "name",
    disableSortBy: true,
  },
  {
    Header: translateText("common.waitTime"),
    accessor: "waitTime",
  },
];

export const AVAILABLE_QUEUE = [
  {
    Header: translateText("common.queue"),
    accessor: "queue",
  },
  {
    Header: translateText("common.action"),
    accessor: "action",
  },
];

export const HOME_QUEUE = [
  {
    Header: translateText("common.queue"),
    accessor: "queue",
  },
  {
    Header: translateText("common.queuePriority"),
    accessor: "queuePriority",
  },
  {
    Header: translateText("common.action"),
    accessor: "action",
  },
];
