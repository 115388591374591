import {
  CLEAR_NOTE_ERROR,
  FETCH_NOTE_RECEIVED,
  NOTE_UPDATE_ERROR,
  NOTE_UPDATE_RECEIVED,
  NOTE_UPDATE_REQUEST,
} from "redux/actions/note";

const defaultNoteState = {
  isLoading: false,
  note: {},
  error: "",
  commitSuccess: "",
};

function note(state = defaultNoteState, action) {
  switch (action.type) {
    case NOTE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        commitSuccess: "",
        note: "",
      };
    case NOTE_UPDATE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        note: action.payload,
        commitSuccess: "message.contact.noteUpdatedSuccessfully",
      };

    case NOTE_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case CLEAR_NOTE_ERROR:
      return {
        ...state,
        error: "",
        commitSuccess: "",
      };

    case FETCH_NOTE_RECEIVED:
      return {
        ...state,
        error: "",
        commitSuccess: "",
        note: action.note,
      };

    default:
      return state;
  }
}

export default note;
