import {
  CLEAR_VOICEMAIL_ERROR,
  FETCH_VOICEMAIL_ERROR,
  FETCH_VOICEMAIL_RECEIVED,
  FETCH_VOICEMAIL_REQUEST,
  FILTER_VOICEMAIL_BY_DATE_ERROR,
  FILTER_VOICEMAIL_BY_DATE_RECEIVED,
  FILTER_VOICEMAIL_BY_DATE_REQUEST,
  VOICEMAIL_AUDIO_DOWNLOAD_ERROR,
  VOICEMAIL_AUDIO_DOWNLOAD_RECEIVED,
  VOICEMAIL_AUDIO_DOWNLOAD_REQUEST,
  VOICEMAIL_DELETE_ERROR,
  VOICEMAIL_DELETE_RECEIVED,
  VOICEMAIL_DELETE_REQUEST,
  VOICEMAIL_DOWNLOAD_ERROR,
  VOICEMAIL_DOWNLOAD_RECEIVED,
  VOICEMAIL_DOWNLOAD_REQUEST,
  VOICEMAIL_SEARCH,
  VOICEMAIL_SEND_RECORDING_BY_EMAIL_ERROR,
  VOICEMAIL_SEND_RECORDING_BY_EMAIL_RECEIVED,
  VOICEMAIL_SEND_RECORDING_BY_EMAIL_REQUEST,
} from "redux/actions/voicemail";

const defaultVoicemailState = {
  isLoading: true,
  voicemails: [],
  error: "",
  isSearching: false,
  isProcessing: false,
  isFiltering: false,
  commitError: "",
  commitSuccess: "",
  audioFile: {},
};

function voicemails(state = defaultVoicemailState, action) {
  switch (action.type) {
    case FETCH_VOICEMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        isFiltering: false,
        isSearching: false,
        error: "",
      };
    case FETCH_VOICEMAIL_RECEIVED:
      return {
        ...state,
        isLoading: false,
        voicemails: action.payload,
      };
    case FETCH_VOICEMAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case VOICEMAIL_SEARCH:
      return {
        ...state,
        isSearching: action.isSearching,
      };

    case VOICEMAIL_DOWNLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
        isSearching: false,
        commitError: "",
        commitSuccess: "",
      };
    case VOICEMAIL_DOWNLOAD_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        commitSuccess: "message.voicemail.voicemailDownloadedSuccessfully",
      };
    case VOICEMAIL_DOWNLOAD_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };
    case VOICEMAIL_SEND_RECORDING_BY_EMAIL_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitError: "",
        commitSuccess: "",
      };
    case VOICEMAIL_SEND_RECORDING_BY_EMAIL_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        commitSuccess: "message.voicemail.emailSendSuccessfully",
      };

    case VOICEMAIL_SEND_RECORDING_BY_EMAIL_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case VOICEMAIL_DELETE_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitError: "",
        commitSuccess: "",
      };
    case VOICEMAIL_DELETE_RECEIVED:
      const newVoicemails = state.voicemails.filter(
        (item) => item.id !== action.payload
      );

      return {
        ...state,
        isProcessing: false,
        commitSuccess: "message.voicemail.voicemailDeletedSuccessfully",
        voicemails: newVoicemails,
      };
    case VOICEMAIL_DELETE_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case VOICEMAIL_AUDIO_DOWNLOAD_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitError: "",
        commitSuccess: "",
        audioFile: {},
      };

    case VOICEMAIL_AUDIO_DOWNLOAD_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        audioFile: action.audio,
      };

    case VOICEMAIL_AUDIO_DOWNLOAD_ERROR:
      return {
        ...state,
        isProcessing: false,
        audio: "",
      };

    case FILTER_VOICEMAIL_BY_DATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isFiltering: true,
        error: "",
      };

    case FILTER_VOICEMAIL_BY_DATE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        voicemails: action.data,
      };

    case FILTER_VOICEMAIL_BY_DATE_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchCallHistoryError: action.error,
      };

    case CLEAR_VOICEMAIL_ERROR:
      return {
        ...state,
        commitError: "",
        commitSuccess: "",
        isLoading: false,
      };

    default:
      return state;
  }
}

export default voicemails;
