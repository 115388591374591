import React, { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { I18nReRender, Translate } from "react-thunk-i18nify";

import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flatPicker from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da.js";
import { english } from "flatpickr/dist/l10n/default";
import PropTypes from "prop-types";

import { defaultLanguage } from "locales/root";

const CustomInput = ({ value, inputRef, ...props }) => (
  <I18nReRender
    render={() => (
      <input
        {...props}
        ref={inputRef}
        value={value}
        data-testid="date-range-input"
      />
    )}
  />
);

const DateRangePicker = ({ date, setDate, onClearDate, filterCallBack }) => {
  const flatPickerRef = useRef();
  const { locale } = useSelector(({ i18n }) => i18n);

  const handleClearDate = () => {
    setDate("");
    onClearDate();
  };

  useEffect(() => {
    flatPickerRef.current.flatpickr.input.type = "button";
  }, []);

  useEffect(() => {
    const currentFlatPickerLanguage =
      defaultLanguage != locale ? Danish : english;

    flatPicker.localize(currentFlatPickerLanguage);
  }, [locale]);

  useEffect(() => {
    if (Array.isArray(date)) {
      filterCallBack();
    }
  }, [date]);

  const placeholderClassName = !Array.isArray(date) ? "" : "d-none";
  const clearDateClassName = date ? "" : "d-none";

  return (
    <div
      className="range-container date range position-relative d-flex align-items-center mx-md-2 px-2 py-2"
      data-testid="date-range-picker"
    >
      <FontAwesomeIcon icon={faCalendar} className="icon" />
      <Flatpickr
        ref={flatPickerRef}
        options={{
          position: "auto auto",
          mode: "range",
          maxDate: new Date(),
          dateFormat: "F j, Y",
        }}
        className="date range border-0 p-2 w-auto text-center"
        value={date}
        onChange={(e) => {
          setDate(e);
        }}
        render={({ value, ...props }, ref) => (
          <CustomInput value={value} inputRef={ref} {...props} />
        )}
      />
      <FontAwesomeIcon
        icon={faTimesCircle}
        className={`icon close ${clearDateClassName}`}
        data-testid="date-range-picker-close-icon"
        onClick={handleClearDate}
        data-cy="date-range-close"
      />
      <p
        className={`position-absolute placeholder-text range ${placeholderClassName} w-100 mb-0 mt-1`}
      >
        <Translate value="common.startDate" />
        <span className="mx-1">-</span>
        <Translate value="common.endDate" />
      </p>
    </div>
  );
};

CustomInput.defaultProps = {
  value: "",
  defaultValue: "",
  inputRef: () => {},
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  defaultValue: PropTypes.string,
  inputRef: PropTypes.func,
};

DateRangePicker.defaultProps = {
  date: "",
  setDate: () => {},
  filterCallBack: () => {},
  onClearDate: () => {},
};

DateRangePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setDate: PropTypes.func,
  filterCallBack: () => {},
  onClearDate: () => {},
};

export default React.memo(DateRangePicker);
