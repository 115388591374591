import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-thunk-i18nify";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { INDEX_MODES, reduxSearch, SearchApi } from "redux-search";

import customResourceSelector from "utils/search";

import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "redux/reducers/root";

import locales, { defaultLanguage } from "locales/root";

const persistConfig = {
  key: "pbxStore",
  storage,
  whitelist: ["auth", "i18n"], // eg: ["auth","i18n"] will be presists
};

const applicationEnvironment = process.env.NODE_ENV;

const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== "production",
});

// prefix matching (eg "c", "ca", "cat" match "cat")
const prefixSearchApi = new SearchApi({
  indexMode: INDEX_MODES.PREFIXES,
});

// Compose :reduxSearch with other store enhancers
// Compose :reduxSearch with other store enhancers
const enhancer = compose(
  applyMiddleware(thunk, logger),
  composeWithDevTools(
    reduxSearch({
      resourceIndexes: {
        contacts: ["name"],
        callHistories: ["call_from", "call_to"],
        voicemails: ["sender_number"],
        cellPhones: ["cell_number"],
      },
      resourceSelector: (resourceName, state) =>
        customResourceSelector(resourceName, state), // redux-search
      searchApi: prefixSearchApi,
    })
  )
);

let appStore = null;
let appPersistor = null;

const pReducer = persistReducer(persistConfig, rootReducer);

const setupJestStore = (preloadedState) =>
  createStore(pReducer, preloadedState, applyMiddleware(thunk));

if (applicationEnvironment !== "test") {
  appStore = createStore(pReducer, enhancer);
  appPersistor = persistStore(appStore);

  appStore.dispatch(loadTranslations(locales));

  appStore.dispatch(setLocale(defaultLanguage));
}

const store = appStore;

syncTranslationWithStore(store);

const persistor = appPersistor;

export { persistor, store, setupJestStore };
