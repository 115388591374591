import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

const RegisteredAgentSelection = ({ selectedAgents, handleAgentSelection }) => {
  if (selectedAgents === 0) return null;

  return (
    <div className="selected-employee d-flex justify-content-between align-items-center p-2 flex-wrap">
      <p className="mb-0 number-of-selected-employee">
        <Translate value="configureQueue.selectedQueue" />
        <b className="count-of-employee ms-1">{selectedAgents}</b>
      </p>
      <button
        onClick={handleAgentSelection}
        className="btn btn-danger fw-normal d-none d-md-flex align-items-center"
        type="button"
      >
        <Translate value="callQueue.agentManagement.removeSelectedFromList" />
      </button>
      <button
        onClick={handleAgentSelection}
        className="btn btn-danger fw-normal d-flex d-md-none align-items-center"
        type="button"
      >
        <Translate value="configureQueue.removeSelectedQueues" />
      </button>
    </div>
  );
};

RegisteredAgentSelection.defaultProps = {
  selectedAgents: 0,
  handleAgentSelection: () => {},
};

RegisteredAgentSelection.propTypes = {
  selectedAgents: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleAgentSelection: PropTypes.func,
};

export default RegisteredAgentSelection;
