export default {
  attributes: {
    password: "kodeord",
    newPassword: "Ny adgangskode",
    confirmNewPassword: "Bekræft ny adgangskode",
    emailAddress: "E-mailadresse",
    searchContact: "Søg kontaktpersoner",
    firstName: "Fornavn",
    lastName: "Efternavn",
    searchAllHistory: "Søg i hele historik",
    profileName: "Profilnavn",
    description: "Beskrivelse",
    icon: "Ikon",
    searchCellPhoneNumber: "Søg mobiltelefonnummer",
    pinCode: "Pinkode",
    maximumNumberOfMessagesAllowed: "Maksimalt antal meddelelser tilladt",
    minimumNumberOfAMessage: "Minimumlængde af en besked",
    maximumNumberOfAMessage: "Maksimal længde af en besked",
    selectAnOption: "Vælg en mulighed",
    selectDevice: "Vælg enhed/er",
    noSipDeviceAvailable: "Ingen SIP-enhed/-er tilgængelig",
    insertNumber: "Indsæt nummer",
    searchAllQueues: "Søg i alle køer",
    searchAvailableAgents: "Søg efter tilgængelige agenter",
    searchRegisteredAgents: "Søg registrerede agenter",
    searchAvailableQueues: "Søg efter tilgængelige køer",
    searchHomeQueues: "Søg hjemmekøer",
    externalNumber: "Eksternt nummer",
    name: "navn",
    number: "Nummer",
    noProfile: "Ingen profil",
  },
  auth: {
    signIn: {
      signInToConnectedWithPBX: "Log ind for at Stay Connected PBX",
      NotAStayConnectedCustomer: "Ikke en Stay Connected-kunde?",
      forgetPassword: "Glemt kodeord?",
      keepMeSignedIn: "Hold mig logget ind",
    },
    forgotPassword: {
      recoverYourPassword: "Gendan din adgangskode nu",
      description:
        "Indtast den e-mail, der er knyttet til din konto, og vi sender en e-mail med instruktioner om at nulstille din adgangskode",
      sendAnInstruction: "Send en instruktion",
      signInInstead: "Log ind i stedet?",
    },
    emailNotification: {
      checkYourEmail: "Tjek din e-mail nu",
      description:
        "Vi har sendt en instruktion til gendannelse af adgangskode til din e-mail. Tjek venligst og arbejd derefter.",
      changedMyPassword: "Jeg har ændret min adgangskode, Log ind nu",
    },
    newPassword: {
      setNewPassword: "Indstil ny adgangskode",
      description:
        "Din nye adgangskode skal være forskellig fra din tidligere brugte adgangskode",
      setPassword: "Indstil adgangskode",
    },
    passwordNotification: {
      congratulationNewPassword: "Tillykke! du har ny adgangskode nu",
      description:
        "Du kan begynde at bruge denne nyoprettede adgangskode fra næste gang. Husk, at du ikke bør dele dette med andre",
      okLetLoginNow: "OKAY! Lad os logge ind nu",
    },
    logoutModal: {
      whoopsYourSessionHasExpired: "Hov, din session er udløbet!",
      description:
        "Din session er udløbet på grund af inaktivitet. Log venligst på igen.",
    },
  },
  common: {
    loading: "Indlæser",
    expireIn: "Udløber om",
    saveNote: "Gem note",
    clearNote: "Slet note",
    updateNote: "Opdater note",
    note: "Bemærk",
    contacts: "Kontaktpersoner",
    logout: "Log ud",
    contactInformation: "Kontakt information",
    signIn: "Log ind",
    emailAddress: "E-mailadresse",
    error: "Fejl",
    reload: "Genindlæs siden",
    selectTime: "Vælg Tid",
    selectDate: "Vælg dato",
    startDate: "Startdato",
    endDate: "Slutdato",
    time: "Tid",
    to: "til",
    duration: "Varighed",
    from: "Fra",
    cancel: "Afbestille",
    listenTo: "Lyt",
    download: "Hent",
    sendAsEmail: "Send som e-mail",
    email: "E-mail",
    success: "Succes",
    profile: "Profil",
    userProfile: "Brugerprofil",
    extensionNumber: "Lokalnyummer",
    landlineNumbers: "Fastnetnumre",
    mobileNumbers: "Mobilnumre",
    editProfile: "Rediger profil",
    editYourProfile: "Rediger din profil",
    yesImSure: "Ja, det er jeg sikker på",
    send: "Sende",
    save: "Gemme",
    retry: "Prøve igen",
    yesterday: "I går",
    today: "I dag",
    at: "på",
    filterDates: "Afgræns efter datoer",
    password: "adgangskode",
    changePassword: "Skift adgangskode",
    save: "Gemme",
    configure: "Konfigurer",
    cellNumber: "Mobilnummer",
    currentProfile: "Nuværende profil",
    outgoingCalls: "Udgående opkald",
    incomingCalls: "Indgående opkald",
    always: "Vil",
    add: "Tilføje",
    after: "Efter",
    active: "Aktiv",
    available: "ledig",
    unAvailable: "utilgængelig",
    busy: "travl",
    employee: "Ansat",
    device: "Apparat",
    mobile: "Mobil",
    logOn: "Log på",
    external: "Ekstern",
    action: "Handling",
    callPriority: "Opkaldsprioritet",
    waitTime: "Ventetid",
    name: "navn",
    number: "Nummer",
    online: "Online",
    offline: "Offline",
    ringing: "Ringer",
    filterUsers: "Filtrer brugere",
    queueAgentRegistrations: "Profilstyret ind- og udmelding af køer",
    queuePriority: "Køprioritet",
    queue: "Kø",
    queues: "Køer",
  },
  contact: {
    // contactlist
    contactList: {
      noSearchResult: "Intet søgeresultat fundet",
    },
    contactDetail: {
      landlineNumberIndex: "Fastnetnummer %{index}",
      mobileNumberIndex: "Mobilnummer %{index}",
    },
    //empty contact
    empty: {
      list: {
        title: "Tøm kontaktliste",
        description:
          "Der er ingen kontakter her. Du kan begynde at se kontaktlisterne efter at have tilføjet manuelt eller importeret fra en fil. Tjek venligst igen senere",
        searchQueryNotFound:
          "Der er ingen kontakt på listen, der matcher det, du søger efter",
        contactNotFound: "Kontakt ikke fundet",
      },
      detail: {
        title: "Vælg venligst en kontakt fra listen for at se dens detaljer",
        description:
          "Du kan se detaljer som mobiltelefonnumre, fastnetnumre, note og andre grundlæggende detaljer om en kontakt",
      },
    },
    //note
    note: {
      addYourNoteHere: "Skriv din note her...",
      expiryTime: "Kan ikke være i fortiden",
      removeExpireNote: "Fjern udløbsdato",
      cannotBeBlank: "Kan ikke være blank",
    },
  },
  dateTime: {
    long: "yyyy-MM-dd hh:mm",
    clockTime: "hh:mm",
  },
  timeUnit: {
    sec: "s",
    m: "m",
    h: "t",
    s: "s",
  },
  voicemails: {
    allVoicemails: "Alle telefonsvarere",
    searchAllVoicemails: "Søg i alle telefonsvarere",
    empty: {
      voicemailsList: "Tom telefonsvarerliste",
      description:
        "Der er ingen telefonsvarerbeskeder her. Du kan begynde at se dine beskeder, når nogen har lagt en besked. Tjek venligst igen senere",
      voicemailNotFound: "Voicemail ikke fundet",
      searchQueryNotFound:
        "Der er ingen telefonsvarer på listen, der matcher det, du søger efter",
      recordOnFilteredDateNotFound:
        "Der er ingen telefonsvarer til den dato, du søger efter",
    },
    download: {
      confirmDownloadingVoicemail:
        "Skal du bekræfte, at du henter telefonsvarer?",
      description:
        "Du er ved at hente den telefonsvarer, der sendes af %{voicemail}.Hvis du er sikker, skal du klikke på “Ja, det er jeg sikker på” knappen nedenfor.",
    },
    sendEmail: {
      confirmSendingVoicemailToEmail:
        "Skal du bekræfte afsendelse af telefonsvarer til e-mail?",
      description:
        "Du er ved at sende telefonsvareren fra %{voicemail} til din mail %{email}.Hvis du er sikker, skal du klikke på “Ja, det er jeg sikker på” knappen nedenfor.",
      failedToSendEmail: "Kunne ikke sende e-mail",
      errorDescription: "Der opstod en fejl under afsendelse af optagelse til.",
      pleaseTryAgainLater: "Prøv igen senere.",
      sendEmailTo: "Send e-mail til",
      pleaseSelectEmail:
        "Vælg venligst en af ​​mulighederne for at sende e-mail",
      sendEmailManually: "Send e-mail manuelt",
      defaultVoicemailEmail: "Standard e-mail til telefonsvarer",
      userLicenseEmail: "Brugerlicens e-mail",
    },
    delete: {
      areYouSureYouWantToDeleteVoicemail:
        "Er du sikker på, at du vil slette talebeskeder?",
      description:
        "Du er ved at slette telefonsvareren fra %{voicemail}. Hvis du er sikker, skal du klikke på “Ja, det er jeg sikker på” knappen nedenfor.",
    },
  },
  voicemailBoxes: {
    general: {
      numberOfAllowedMessages: "Antal tilladte beskeder",
      generalVoicemailBoxes: "Generelle telefonsvarere",
      voicemailBoxOfYourUserLicense:
        "Telefonsvarere i din brugerlicens %{email}",
      cellPhoneVoicemailBoxes: "Mobiltelefonsvarere",
    },
    empty: {
      cellPhoneNotFound: "Mobiltelefon ikke fundet",
      searchQueryNotFound:
        "Der er ingen mobiltelefon på listen, der matcher det, du søger efter",
      emptyCellPhoneList: "Tom mobiltelefonliste",
      description: "Der er ingen mobiltelefon her.",
      emptyGeneralVoicemailBoxInformation:
        "Listen over generelle telefonsvarere er tom",
      descriptionGeneral: "Der er ingen generel telefonsvarere her.",
    },
    configuringVoicemailBoxOf: "Konfiguration af telefonsvarere af",
  },
  callHistory: {
    allCallHistory: "Al opkaldshistorik",

    empty: {
      emptyCallHistoryList: "Tom liste over opkaldshistorik",
      description:
        "Der er ingen kald at vise i opkaldshistorikken. Du vil se ind- og udgående kald, når du har brugt din telefon. Tjek venligst igen senere",
      callNotFound: "Opkald ikke fundet",
      searchQueryNotFound:
        "Der er ingen opkald i opkaldshistorikken, der matcher det, du søger efter",
      recordOnFilteredDateNotFound:
        "Der er ingen opkald i opkaldshistorikken for den dato, du søger efter",
    },
  },
  dropdown: {
    contactList: {
      all: "Alle",
      shared: "Delte",
      colleagues: "Kollegaer",
    },
    profile: {
      updateProfileImage: "Opdater profilbillede",
      deleteProfileImage: "Slet profilbillede",
      userProfile: "Brugerprofil",
      changePassword: "Ændre adgangskode",
      voicemailBoxes: "Voicemail bokse",
      configureQueue: "Konfigurer kø",
      fixedCalendar: "kalender",
    },
    callHistory: {
      allCallsHistory: "Historik for alle kald",
      incomingCalls: "Indgående opkald",
      outgoingCalls: "Udgående opkald",
      missedCalls: "Ubesvarede opkald",
    },
    incomingCalls: {
      giveBusySignal: "Send optagetsignal",
      initiateCallOnDevices: "Ring på",
      forwardTo: "Viderestil",
      goToVoicemail: "Gå til Voicemail",
      callOnAllDevices: "Ring på alle enheder",
    },
    callPriority: {
      high: "Høj (%{number})",
      medium: "Medium (%{number})",
      low: "Lav (%{number})",
    },
    pbxUsersList: {
      ownVoicemail: "Egen telefonsvarer",
      ownCallHistory: "Egen opkaldshistorik",
      ownVoicemailBox: "Egen telefonsvarerboks",
    },
  },
  callProfile: {
    default: {
      selectProfileFromList:
        "Vælg venligst en profil fra listen, for at se detaljerne",
      setupTimeSchedulesForSwitchingProfile:
        "Indstil tidsplaner for automatisk profilstyring, når  “Skift profiler automatisk” er aktiveret",
      goToFixedCalendar: "Gå til kalender",
      yourFixedCalendarProfiles: "Dine kalenderprofiler",
      fixedCalendarHeaderDescription:
        "Du kan se, hvordan dine profiler skifter efter din kalender her. Din kalender har ingen begrænsninger for redigering.",
      youCannotEditTheDefaultProfile:
        "Du kan ikke redigere standardprofilnavnet",
    },
    edit: {
      editProfile: " Rediger %{name}",
    },
    create: {
      createNewProfile: "Opret ny profil",
    },
    delete: {
      areYouSureYouWantToDeleteProfile:
        "Er du sikker på, at du vil slette profilen?",
      description:
        "Du er ved at slette profilen %{name}. Hvis du er sikker, så klik venligst på  “Ja, det er jeg sikker på” knappen nedenfor.",
      fixedCalendarDescription: "Denne profil bruges i øjeblikket i kalemderem",
      failedToDeleteProfile: "Profilen kunne ikke slettes",
      errorDescription:
        "Der opstod en fejl under forsøg på at slette %{name}. Prøv igen senere",
      activeProfileCannotBeDeleted:
        "En aktiv profil kan ikke slettes. Vælg en anden profil, før du forsøger at sletter denne igen.",
      defaultProfileCannotBeDeleted: "Standardprofilen kan ikke slettes.",
      youCannotDeleteTheDefaultProfile:
        "Standardprofil i kalender kan ikke slettes.",
    },
    empty: {
      thereAreNoProfilesToShow: "Der er ingen profiler at vise,",
      pleaseCreateANewProfile: "opret en ny profil",
      outgoing: {
        noOutgoingCallDeviceAvailable:
          "Ingen udgående opkaldsenhed tilgængelig",
        noOutgoingCallAvailableDescription:
          "Du kan tilføje tilgængelige udgående opkald fra telefoner under brugerlicens.",
      },
    },
    outgoing: {
      outgoingCallsFromDeviceDisplay: "Udgående nummer for %{device} er altid:",
      mainNumber: "%{mainNumber} -  Hovednummer",
      hiddenNumber: "Skjult nummer",
    },
    incomingCalls: {
      initiateCallOnSipDevice: "Start opkald på %{device}",
      chooseAnOption: "Vælg en destination",
      chooseAnOptionYouWishToForwardTo:
        "Vælg en destination, du ønsker at videresende til",
      externalNumber: "Eksternt nummer",
    },
    queueAgentRegistrations: {
      toggleToRegisterQueueAgents:
        "Du kan kontrollere automatiske agentregistreringer i køen, når skifte er aktiveret",
    },
    selectCurrentProfile: "Vælg Aktuel profil",
    timeStampModal: {
      editWeekDay: "Redigere %{weekDay}",
      thisIsWhereYouManageYourDailyRoutine:
        "Det er her, du styrer din daglige rutine.",
      addTimeFrame: "Tilføj tidsramme",
      chooseProfile: "Vælg Profil",
    },
    switchProfileAutomatically: "Skift profil automatisk",
  },
  callQueue: {
    default: {
      allQueues: "Alle køer",
      selectQueueFromList:
        "Vælg venligst en hvilken som helst kø fra venstre for at se dens detaljer",
      description:
        "Du kan se detaljerne i enhver kø, når du klikker på eller vælger en af ​​dem fra venstre side.",
      totalCalls: "Opkald i dag",
      answeredCalls: "Besvarede opkald",
      missedCalls: "Ubesvarede opkald",
      averageCallDuration: "Gennemsnitlig ventetid",
    },
    empty: {
      thereAreNoQueuesToShow: "Der er ingen tilgængelige opkaldskøer at vise",
      queueNotFound: "Kø ikke fundet",
      searchQueryNotFound:
        "Der er ingen kø på listen, der matcher det, du søger efter",
      emptyCallQueueList: "Tøm opkaldskøliste",
      descriptionNoAgent:
        "Du kan enten tilføje agenter fra “Administrer agent”, eller du kan tilføje eksternt nummer. Derefter kan du se listen over registrerede agenter her.",
      emptyAvailableAgent: "Tøm liste over tilgængelige agenter",
      descriptionAvailableAgent: "Der er ingen ledig agent i øjeblikket.",
      emptyRegisteredAgent: "Tøm liste over registrerede agenter",
      descriptionRegisteredAgent:
        "Der er ingen registreret agent i øjeblikket.",
      noAvailableAgent: "Ingen tilgængelig agent fundet",
      searchAvailableAgentNotFound:
        "Der er ingen tilgængelig agent, der matcher det, du søger efter",
      noRegisteredAgents: "Ingen registrerede agenter",
      searchRegisteredAgentNotFound:
        "Der er ingen registreret agent, der matcher det, du søger efter",
      noCallInQueue: "Ingen kald i kø",
      descriptionNoCallInQueue: "Der er ingen kald i køen i øjeblikket.",
      noAvailableQueue: "Ingen tilgængelig kø fundet",
      searchAvailableQueueNotFound:
        "Der er ingen ledig kø, der matcher det, du søger efter",
      noHomeQueue: "Ingen hjemmekø fundet",
      searchHomeQueueNotFound:
        "Der er ingen hjemmekø, der matcher det, du søger efter",
    },
    registeredAgents: "Indmeldte brugere",
    agentManagement: {
      agentManagement: "Administration af brugere",
      manageAgents: "Administrer helpdesk-brugere",
      availableAgents: "Tilgængelige brugere",
      registeredAgents: "Registrerede brugere",
      selectedDevices: "Udvalgte enheder",
      addSelectedToRegisteredAgents: "Tilføj valgte til registrerede brugere",
      addSelectedAgent: "Tilføj valgt bruger",
      saveAgentInformation: "Gem agentoplysninger",
      externalNumberTooltipDescription:
        "Eksternt nummer er de numre, der ikke er tilgængelige i PBX og registreret hos en anden tjenesteudbyder. Du kan også tilføje denne type numre til at modtage opkaldet i køen",
      removeSelectedFromList: "Fjern valgte fra listen",
      removeSelectedAgents: "Fjern udvalgte brugere",
      delete: {
        areYouSureYouWantToDelete: "Er du sikker på, at du vil slette",
        deleteDescription:
          "Du er ved at slette ovenstående bruger fra listen over registrerede brugere. Hvis du er sikker, så klik venligst på  “Ja, det er jeg sikker på” knappen nedenfor.",
        areYouSureYouWantToDeleteTheseAgents:
          "Er du sikker på, at du vil slette disse brugere?",
        deleteMultipleAgentDescription:
          "Du er ved at slette udvalgte brugere fra listen over registrerede brugere. Hvis du er sikker, så klik venligst på  “Ja, det er jeg sikker på” knappen nedenfor.w",
      },
    },
    callInQueue: "Ring i kø",
    youAreNotLoggedIntoQueue:
      "Du er ikke logget ind i denne kø og vil ikke modtage telefonopkald",
  },
  userProfile: {
    saveProfileInformation: "Gem profiloplysninger",

    delete: {
      areYouSureYouWantToDeleteProfileImage:
        "Er du sikker på, at du vil slette profilbillede?",
      description:
        "Du er ved at fjerne dit profilbillede. Hvis du er sikker, så klik venligst på “Ja, det er jeg sikker på” button below",
    },
  },
  home: {
    colleagues: "Kollegaer",
    returnToQueueDashboard: "Tilbage til Kø-dashboard",
    configureYourDevices: "Konfigurer dine enheder",
  },
  configureQueue: {
    configureQueuePriority: "Konfigurer køprioritet",
    saveSetting: "Gem indstilling",
    availableQueue: "Tilgængelig kø",
    QueueInHome: "Kø i hjemmet",
    addSelectedQueueToHomePage: "Tilføj den valgte kø til startsiden",
    addSelectedQueue: "Tilføj valgt kø",
    addToHome: "Tilføj til hjemmet",
    selectedQueue: "Valgt kø",
    removeSelectedQueues: "Fjern valgte køer",

    empty: {
      emptyHomeQueueList: "Tøm hjemmekøliste",
      emptyAvailableQueueList: "Tøm ledig køliste",
      thereIsNoQueueInHomeAtTheMoment: "Der er ingen kø i hjemmet i øjeblikket",
      thereIsNoAvailableQueueAtTheMoment: "Der er ingen ledig kø i øjeblikket",
    },
  },
  changePassword: {
    congratulations: "Tillykke",
    youHaveANewPasswordNow: "Du har nu en ny adgangskode",
    youCanStartUsingPassword:
      "du kan begynde at bruge denne nyoprettede adgangskode fra næste gang.",
    youShouldNotSharePassword: "Du bør ikke dele denne adgangskode med nogen.",
  },
  error: {
    unableToDownloadVoicemail: "Talebeskeden kan ikke hentes",
    unableToSendVoicemail: "Talebeskeder kan ikke sendes",
    unableToDeleteVoicemail: "Kan ikke slette voicemail",
    unableToUpdateYourProfileDetail: "Kan ikke opdatere dine profildetaljer",
    unableToUpdateNote: "Noten kunne ikke opdateres",
  },
  languageToggler: {
    en: "EN",
    dk: "DK",
    label: "Sprog",
  },
  message: {
    auth: {
      incorrectEmailOrPassword: "Forkert e-mailadresse eller kodeord",
      notExist: "Kontoen eksisterer ikke",
      onlyMobileAllowed:
        "Du har kun tilladelse til at få adgang til mobilappen",
      resetLink:
        "Hvis du har indtastet en eksisterende e-mailadresse, vil du om kort tid modtage en mail omkring nulstilling af dit kodeord.",
      passwordChangedSuccessfully: "Kodeordet er opdateret",
      couldNotSavePassword: "Kodeordet blev ikke gemt",
      yourTokenExpired: "Din session er udløbet",
      couldNotProcessThisRequest:
        "Din forespørgsel kunne ikke behandles, venligst prøv igen senere.",
      signIn: "Log ind ved at bruge en e-mailadresse",
      unableToSignIn: "Kan ikke logge ind",
      unauthorized: "Du har ikke tilladelse til at se denne side",
    },
    contact: {
      unauthorizedNoteUpdate: "Uautoriseret noteopdatering",
      noteUpdatedSuccessfully: "Note blev opdateret",
    },
    voicemailBoxes: {
      voicemailBoxUpdatedSuccessfully: "Voicemail-boks blev opdateret",
    },
    voicemail: {
      voicemailDownloadedSuccessfully: "Talebeskeden blev hentet",
      emailSendSuccessfully: "E-mail Send succesfuldt",
      voicemailDeletedSuccessfully: "Talebeskeden blev slettet",
    },
    userProfile: {
      profileUpdatedSuccessfully: "Profiloplysningerne er opdateret",
      profileRemovedSuccessfully: "Profilen blev fjernet",
      profileImageRemovedSuccessfully: "Profilbilledet blev fjernet",
    },
    callProfile: {
      profileDeletedSuccessfully: "Profilen blev slettet",
      profileUpdatedSuccessfully: "Profilen blev opdateret",
      profileCreatedSuccessfully: "Profil oprettet med succes",
      activeProfileUpdatedSuccessfully: "Aktiv profil blev opdateret",
      scheduleProfileUpdatedSuccessfully: "Planlæg profil blev opdateret",
      automaticCallProfileSchedulingEnabled:
        "Automatisk opkaldsprofilplanlægning aktiveret",
      automaticCallProfileSchedulingDisabled:
        "Automatisk opkaldsprofilplanlægning deaktiveret",
      enablingAutomaticProfileSwitching:
        "Aktivering af automatisk profilskift kan tage nogle få sekunder",
    },
    changePassword: {
      passwordChangedSuccessfully: "Adgangskoden blev ændret",
    },
    configureQueue: {
      queueInDashboardUpdatedSuccessfully: "Kø i Dashboard blev opdateret",
    },
    callQueue: {
      agentManagementUpdatedSuccessfully: "Agentadministration blev opdateret",
      addedDeviceInQueue: "Tilføjet enhed i kø",
      removeDeviceFromQueue: "Fjern enhed fra kø",
    },
  },
  pages: {
    title: {
      contacts: "Kontakter",
      voicemails: "Telefonsvarere",
      callHistory: "Opkaldshistorik",
      profileManagement: "Profilstyring",
      voicemailBoxes: "Voicemail bokse",
      callQueue: "Opkaldskø",
      pageNotFound: "Siden blev ikke fundet",
      home: "Home",
    },
    pageNotFound: {
      pageYouWereLookingForNotExist:
        "The page you were looking for does not exist",
      returnToHome: "Return to Home Page",
      youAreNotAllowed: "Du har ikke tilladelse til at få adgang til denne app",
      returnToLoginPage: "Return to Login Page",
    },
  },
  toasterMessage: {
    networkError: "Netværksfejl",
    processError:
      "Din forespørgsel kunne ikke behandles, venligst prøv igen senere.",
    unauthorized: "Du er ikke autoriseret til at udføre denne handling!",
    requestFailed: "Din anmodning mislykkedes, prøv venligst igen senere.",
    session: {
      expired: "Din session er udløbet",
    },
  },
  validationErrors: {
    EMPTY_EMAIL_ADDRESS: "E-mailadresse-feltet er tomt",
    INVALID_EMAIL_ADDRESS: "Ugyldig e-mailadresse",
    REQUIRED: "påkrævet",
    EMPTY_PASSWORD: "Adgangskodefeltet må ikke være tomt",
    INVALID_PASSWORD: "Adgangskoden skal være mellem 8-16 tegn",
    SMALL_PASSWORD_LENGTH: "Adgangskoden skal være større end",
    GREATER_PASSWORD_LENGTH: "Adgangskoden skal være mindre end",
    NON_SIMILAR_PASSWORD_ERROR:
      "Adgangskoden stemmer ikke overens med hinanden.",
    INVALID_PHONE_NUMBER: "Ugyldigt telefonnummer",
    FORWARD_TO_OPTION:
      "%{deviceName}, Valgmuligheden Videresend til kan ikke være tom",
    IDENTICAL: "Skal være identisk",
    EMPTY_FIRST_NAME: "Feltet Fornavn må ikke være tomt",
    ONLY_NUMBER_AND_PLUS:
      "Tal der starter med “+” eller kun almindelige tal tilladt",
    EMPTY_LAST_NAME: "Efternavnsfeltet må ikke være tomt",
    SAME_WITH_CURRENT_PASSWORD:
      "Ny adgangskode bør ikke være den samme som den nuværende adgangskode",
    NOT_A_NUMBER: "Skal være et tal",
  },
};
