import React, { useContext, useEffect, useMemo } from "react";
import { I18n } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import ForwardCallModal from "components/Modal/ForwardToModal/ForwardCallModal";

import { CallProfileContext } from "context-api/CallProfileContext";

import { displayError } from "utils/toaster";

const ForwardToField = ({
  input: { onChange },
  meta,
  showModal,
  toggleModal,
  deviceName,
}) => {
  const handleSubmit = ({ external_number, external_number_name }) => {
    onChange({ target: { value: { external_number, external_number_name } } });
    toggleModal(false);
  };

  const { isSubmitted, setSubmitted } = useContext(CallProfileContext);

  const [touched, error, submitError] = useMemo(
    () => [meta.touched, meta.error, meta.submitError],
    [meta.touched, meta.error, meta.submitError]
  );

  useEffect(() => {
    if ((error || submitError) && touched && isSubmitted) {
      const errorMessage = I18n.t("validationErrors.FORWARD_TO_OPTION", {
        deviceName: deviceName,
      });

      displayError(errorMessage);
      setSubmitted(false);
    }
  }, [error, touched, submitError]);

  return (
    <ForwardCallModal
      isVisible={showModal}
      handleOverlayClick={() => toggleModal(false)}
      handleCancel={() => toggleModal(false)}
      handleSubmit={handleSubmit}
    />
  );
};

ForwardToField.defaultProps = {
  showModal: false,
  toggleModal: () => {},
};

ForwardToField.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  deviceName: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    submitError: PropTypes.bool,
  }).isRequired,
};

export default React.memo(ForwardToField);
