import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  VOICEMAILS: `${apiUrlConfig.asteriskEndpoint()}/user_licenses/:user_license_id/voicemails`,
  DOWNLOAD_VOICEMAIL: `${apiUrlConfig.asteriskEndpoint()}/voicemails/:id/download_recording`,
  SEND_EMAIL: `${apiUrlConfig.asteriskEndpoint()}/voicemails/:id/send_recording_by_email?recipient_email=:email`,
  DELETE_VOICEMAIL: `${apiUrlConfig.asteriskEndpoint()}/voicemails/:id`,
  VOICEMAIL_AUDIO: `${apiUrlConfig.asteriskEndpoint()}/voicemails/:id/audio_recording?format=:format`,
  FILTER_BY_DATE: `${apiUrlConfig.asteriskEndpoint()}/user_licenses/:user_license_id/voicemails?filter[type]=:type&filter[received_date][start_date]=:start_date&filter[received_date][end_date]=:end_date`,
};

class VoicemailApi extends AuthenticatedRequest {
  static fetchVoicemails(userLicenseId) {
    const GET_URL = URLS.VOICEMAILS.replace(":user_license_id", userLicenseId);

    return this.get(GET_URL);
  }

  static downloadVoicemail(id) {
    const DOWNLOAD_URL = URLS.DOWNLOAD_VOICEMAIL.replace(":id", id);

    return this.get(DOWNLOAD_URL);
  }

  static sendEmail(id, email) {
    const SEND_EMAIL_URL = URLS.SEND_EMAIL.replace(":id", id).replace(
      ":email",
      email
    );

    return this.get(SEND_EMAIL_URL);
  }

  static deleteVoicemail(id) {
    const DELETE_URL = URLS.DELETE_VOICEMAIL.replace(":id", id);

    return this.delete(DELETE_URL);
  }

  static fetchVoicemailAudio(id, format = "base64") {
    const FETCH_AUDIO_URL = URLS.VOICEMAIL_AUDIO.replace(":id", id).replace(
      ":format",
      format
    );

    return this.get(FETCH_AUDIO_URL);
  }

  static filterByDate(userLicenseId, type, startDate, endDate) {
    const FILTER_BY_DATE_URL = URLS.FILTER_BY_DATE.replace(
      ":user_license_id",
      userLicenseId
    )
      .replace(":type", type)
      .replace(":start_date", startDate)
      .replace(":end_date", endDate);

    return this.get(FILTER_BY_DATE_URL);
  }
}

export default VoicemailApi;
