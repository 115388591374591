import {
  CALENDAR_TIME,
  PLUS,
  PRESENTATION,
  SNOOZE,
  USER_TIME,
  MOBILE_SIGNAL_OUT,
  PHONE_OFFICE,
  TOOLTIP,
  AGENT_MOBILE,
  HOME,
  CONFIGURE_QUEUE,
  INFORMATION_ICON,
} from "./path";

const BRAND_NAME = "pbx-user";

//FontAwesome icon has a base size of 512 where as one of attribute of viewport always change.
//example: viewport: 420 512 where 420 change often and 512 is base size.
const BASE_SIZE = 512;

// replace 32, 32 with your SVG view box
// e000 is the unicode point which represents this custom icon. Increment this value for other icons
// replace 'CALENDAR_TIME' with your single-path SVG
// brand name of svg in prefix

const prefixID = "e00";

export const faCalendarTime = {
  prefix: BRAND_NAME,
  iconName: "calendar-time",
  icon: [481, BASE_SIZE, [], prefixID + 1, CALENDAR_TIME],
};

export const faSnooze = {
  prefix: BRAND_NAME,
  iconName: "snooze",
  icon: [450, BASE_SIZE, [], prefixID + 2, SNOOZE],
};

export const faUserTime = {
  prefix: BRAND_NAME,
  iconName: "user-time",
  icon: [450, BASE_SIZE, [], prefixID + 3, USER_TIME],
};

export const faPresentation = {
  prefix: BRAND_NAME,
  iconName: "presentation",
  icon: [BASE_SIZE, 457, [], prefixID + 4, PRESENTATION],
};

export const faCirclePlus = {
  prefix: BRAND_NAME,
  iconName: "circle-plus",
  icon: [BASE_SIZE, BASE_SIZE, [], prefixID + 5, PLUS],
};

export const faMobileSignalOut = {
  prefix: BRAND_NAME,
  iconName: "mobile-signal-out",
  icon: [513, BASE_SIZE, [], prefixID + 6, MOBILE_SIGNAL_OUT],
};

export const faPhoneOffice = {
  prefix: BRAND_NAME,
  iconName: "phone-office",
  icon: [513, 456, [], prefixID + 7, PHONE_OFFICE],
};

export const faToolTip = {
  prefix: BRAND_NAME,
  iconName: "tool-tip",
  icon: [BASE_SIZE, BASE_SIZE, [], prefixID + 8, TOOLTIP],
};

export const faAgentMobile = {
  prefix: BRAND_NAME,
  iconName: "agent-mobile",
  icon: [352, BASE_SIZE, [], prefixID + 9, AGENT_MOBILE],
};

export const faHome = {
  prefix: BRAND_NAME,
  iconName: "home",
  icon: [BASE_SIZE, 562, [], prefixID + 10, HOME],
};

export const faConfigureQueue = {
  prefix: BRAND_NAME,
  iconName: "configure-queue",
  icon: [BASE_SIZE, 475, [], prefixID + 11, CONFIGURE_QUEUE],
};

export const faInformationIcon = {
  prefix: BRAND_NAME,
  iconName: "information-icon",
  icon: [BASE_SIZE, BASE_SIZE, [], prefixID + 12, INFORMATION_ICON],
};
