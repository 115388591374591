import React, { useEffect, useState } from "react";
import { Field, useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faUsersGear } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import InformationBoxWrapper from "layouts/InformationBoxWrapper/InformationBoxWrapper";

import { INFOGRAPHIC_QUEUE } from "constants/images";

import { formatQueueDevicesOption } from "utils/incomingCallOptions";

import QueueRegistrationItem from "./QueueRegistrationItem";

const FieldArrayView = ({ isQueueEnabled, options }) => {
  if (!isQueueEnabled) {
    return (
      <section className="d-flex flex-column justify-content-center align-items-center m-3">
        <img src={INFOGRAPHIC_QUEUE.SOURCE} alt="infographic queue" />
        <p className="text-center fw-bold mx-3 mb-3">
          <Translate value="callProfile.queueAgentRegistrations.toggleToRegisterQueueAgents" />
        </p>
      </section>
    );
  }

  return (
    <div className="px-3">
      <FieldArray name="call_profile_queues_attributes">
        {({ fields }) =>
          fields.map((name, index) => (
            <Field
              key={index}
              name={name}
              component={QueueRegistrationItem}
              options={options}
            />
          ))
        }
      </FieldArray>
    </div>
  );
};

const QueueRegistrationForm = () => {
  const fieldName = "is_automatic_queue_registration_enabled";

  const {
    input: { value: isAutomaticQueueRegistrationEnabled },
  } = useField(fieldName);

  const { callQueueDevices } = useSelector(({ callProfile }) => callProfile);

  const [updatedOptions, setUpdatedOptions] = useState([]);

  useEffect(() => {
    if (!callQueueDevices.length) return setUpdatedOptions([]);
    const options = formatQueueDevicesOption(callQueueDevices);

    setUpdatedOptions(options);
  }, [callQueueDevices]);

  return (
    <article className="incoming-call-index my-4 pt-2 pt-md-0 mt-4">
      <InformationBoxWrapper
        title="common.queueAgentRegistrations"
        titleIcon={faUsersGear}
        additionalTitleContent={
          <div
            className="form-check form-switch automatic-call-profile-toggler d-flex align-items-center ps-0 ms-auto"
            type="button"
          >
            <Field
              className="form-check-input ms-2"
              name={fieldName}
              component="input"
              type="checkbox"
              role="switch"
              data-cy={fieldName}
            />
          </div>
        }
      >
        <FieldArrayView
          options={updatedOptions}
          isQueueEnabled={isAutomaticQueueRegistrationEnabled}
        />
      </InformationBoxWrapper>
    </article>
  );
};

FieldArrayView.propTypes = {
  isQueueEnabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default QueueRegistrationForm;
