import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProfileHeader from "components/ProfileHeader/ProfileHeader";
import ProfileHeaderSkeleton from "components/Skeleton/UserProfile/ProfileHeaderSkeleton";
import ProfileSkeleton from "components/Skeleton/UserProfile/UserProfileSkeleton";
import ProfileDetail from "components/UserProfile/ProfileDetail";

import { fetchProfileDetailRequest } from "redux/actions/userProfile";

const UserProfile = () => {
  const dispatch = useDispatch();
  const {
    currentUser: { user_license_id: userLicenseId },
  } = useSelector(({ auth }) => auth);

  const [loading, setLoading] = useState(true);

  const { isLoading, isProfilePictureLoading } = useSelector(
    ({ userProfile }) => userProfile
  );

  useEffect(() => {
    dispatch(fetchProfileDetailRequest(userLicenseId, "colleague"));
    setLoading(false);
  }, []);

  if (isProfilePictureLoading) {
    return (
      <div className="layout-spacing overflow-auto h-100 mh-100">
        <div
          className="bg-white w-100 h-100 d-flex flex-column justify-content-between flex-fill mx-0 py-3"
          role="document"
        >
          <ProfileHeaderSkeleton />
          <div className="px-4 bg-white pb-3">
            <ProfileDetail />
          </div>
        </div>
      </div>
    );
  }

  if (isLoading || loading) {
    return <ProfileSkeleton />;
  }

  return (
    <div className="layout-spacing overflow-auto h-100 mh-100">
      <div
        className="bg-white w-100 h-100 d-flex flex-column justify-content-between flex-fill mx-0 pt-3"
        role="document"
      >
        <div className="px-4 bg-white">
          <ProfileHeader
            title="common.userProfile"
            showEditProfileButton
            isImageLoading={isProfilePictureLoading}
          />
        </div>
        <div data-cy="profile-detail" className="px-4 bg-white pb-3">
          <ProfileDetail />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
