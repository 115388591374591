import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "bootstrap/js/dist/dropdown";
import { Translate } from "react-thunk-i18nify";

import {
  faChevronDown,
  faKey,
  faSignOutAlt,
  faUser,
  faInbox,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfigureQueueSidebar from "components/ConfigureQueue/ConfigureQueueSidebar";

import { AutomaticCallProfileContext } from "context-api/AutomaticCallProfileContext";
import ConfigureQueueProvider from "context-api/ConfigureQueueContext";

import { faConfigureQueue } from "constants/customIcon/icons";
import {
  AUTH,
  HOME,
  PROFILE,
  VOICEMAIL_BOXES,
  FIXED_CALENDAR,
} from "constants/routes";

import { setCurrentUser, snakeCaseToKebabCase } from "utils/utils";

import { CHANNEL_LIST } from "services/Socket";
import cable from "services/socket/cable";

import { logoutRequest } from "redux/actions/auth";
import { updateRegisteredAgentStatusOfOwnUser } from "redux/actions/callQueue";
import { updateContactStatus } from "redux/actions/contact";
import { fetchProfileDetailRequest } from "redux/actions/userProfile";

const DROPDOWN_ITEM = {
  PROFILE: "profile",
  PASSWORD: "password",
  LOGOUT: "logout",
  CONFIGURE_QUEUE: "configure queue",
  VOICEMAIL_BOXES: "voicemail boxes",
  FIXED_CALENDAR: "fixed calendar",
};

const LIST = [
  {
    id: 1,
    label: "dropdown.profile.userProfile",
    name: DROPDOWN_ITEM.PROFILE,
    icon: faUser,
  },
  {
    id: 2,
    label: "dropdown.profile.changePassword",
    name: DROPDOWN_ITEM.PASSWORD,
    icon: faKey,
  },
  {
    id: 3,
    label: "dropdown.profile.voicemailBoxes",
    name: DROPDOWN_ITEM.VOICEMAIL_BOXES,
    icon: faInbox,
  },
  {
    id: 4,
    label: "dropdown.profile.configureQueue",
    name: DROPDOWN_ITEM.CONFIGURE_QUEUE,
    icon: faConfigureQueue,
  },
  {
    id: 5,
    label: "dropdown.profile.fixedCalendar",
    name: DROPDOWN_ITEM.FIXED_CALENDAR,
    icon: faCalendar,
  },
  {
    id: 6,
    label: "common.logout",
    name: DROPDOWN_ITEM.LOGOUT,
    icon: faSignOutAlt,
  },
];

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { currentUser, bearerToken } = useSelector(({ auth }) => auth);
  const {
    profile: { profile_picture: profilePicture },
  } = useSelector(({ userProfile }) => userProfile);

  const { setAutomaticCallProfile, setShowFixedCalendar } = useContext(
    AutomaticCallProfileContext
  );

  const [openConfigQueue, setOpenConfigQueue] = useState(false);

  const [subscription, setSubscription] = useState();

  const handleItemSelection = (selectedItem) => {
    switch (selectedItem) {
      case DROPDOWN_ITEM.PROFILE:
        push(PROFILE.USER_PROFILE);
        break;

      case DROPDOWN_ITEM.PASSWORD:
        push(AUTH.CHANGE_PASSWORD);
        break;

      case DROPDOWN_ITEM.LOGOUT:
        dispatch(logoutRequest());
        break;

      case DROPDOWN_ITEM.VOICEMAIL_BOXES:
        push(VOICEMAIL_BOXES.INDEX);
        break;

      case DROPDOWN_ITEM.CONFIGURE_QUEUE:
        setOpenConfigQueue(true);
        break;

      case DROPDOWN_ITEM.FIXED_CALENDAR:
        push(FIXED_CALENDAR.INDEX);
        setShowFixedCalendar(true);
        break;

      default:
        return "";
    }
  };

  useEffect(() => {
    if (window.Cypress) return null;

    const connectToChannel = async () => {
      const subscription = await cable.subscribeTo(
        CHANNEL_LIST.PBX_USER_STATUS_CHANNEL,
        {
          pbx_number: currentUser.pbx_number,
        }
      );

      setSubscription(subscription);
    };

    if (!subscription) {
      connectToChannel();
    } else {
      subscription.on("message", ({ data }) => {
        const { extension_number: extensionNumber, status } = data;
        const { identifier } = subscription;

        if (identifier == CHANNEL_LIST.PBX_USER_STATUS_CHANNEL) {
          if (extensionNumber == currentUser.extension_number) {
            setCurrentUser(status, "status");
          }
          dispatch(updateContactStatus({ extensionNumber, status }));

          if (pathname == HOME.INDEX) {
            dispatch(
              updateRegisteredAgentStatusOfOwnUser({
                extensionNumber,
                status,
              })
            );
          }
        }
      });
    }
  }, [subscription, bearerToken]);

  useEffect(() => {
    dispatch(
      fetchProfileDetailRequest(currentUser?.user_license_id, "colleague")
    );
  }, []);

  useEffect(() => {
    setAutomaticCallProfile(
      currentUser.is_automatic_call_profile_switching_enabled
    );
  }, []);

  const userStateClassName =
    snakeCaseToKebabCase(currentUser?.status) || "unavailable";

  const addProfilePicture = profilePicture?.url ? (
    <img
      src={profilePicture.url}
      alt="profile"
      className="rounded-circle cover-image"
    />
  ) : (
    <FontAwesomeIcon icon={faUser} className="user-icon" />
  );

  return (
    <div className="btn-group profile-dropdown d-flex align-items-center">
      <div
        className=" btn-sm dropdown-toggle profile-button text-nowrap border-0 position-relative d-flex align-items-center px-1"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-cy="profile-dropdown"
      >
        <div className="rounded-circle avatar ms-1 me-2 position-relative d-flex align-items-center justify-content-center">
          {addProfilePicture}
          <span
            className={`rounded-circle position-absolute user-status ${userStateClassName}`}
          />
        </div>
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
      </div>
      <ul className="dropdown-menu profile-dropdown-menu py-2 mt-2 px-2 border-0">
        {LIST.map(({ label, name, icon, id }) => (
          <li key={id}>
            <button
              className="dropdown-item py-0 px-3 logout-button d-flex align-items-center border-0"
              onClick={() => handleItemSelection(name)}
              data-cy={name}
            >
              <FontAwesomeIcon icon={icon} className="me-3 profile-icon" />
              <span className="mt-1">
                <Translate value={label} />
              </span>
            </button>
          </li>
        ))}
      </ul>
      <ConfigureQueueProvider>
        <ConfigureQueueSidebar
          open={openConfigQueue}
          setOpen={setOpenConfigQueue}
        />
      </ConfigureQueueProvider>
    </div>
  );
};

export default React.memo(ProfileDropdown);
