import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  FETCH_PBX_USERS: `${apiUrlConfig.defaultEndPoint()}/pbxes/:pbx_id/users`,
};

class UserApi extends AuthenticatedRequest {
  /**
   *
   * @param {init} pbxID - id of pbx user
   * @returns
   */
  static fetchPbxUsers(pbxID) {
    const FETCH_PBX_USERS = URLS.FETCH_PBX_USERS.replace(":pbx_id", pbxID);

    return this.get(FETCH_PBX_USERS);
  }
}

export default UserApi;
