import { useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { PROFILE } from "constants/routes";

import ProfileImageContainer from "./ProfileImageContainer";

const ProfileHeader = ({ title, showEditProfileButton, isImageLoading }) => {
  const { push } = useHistory();

  const handleEditProfileNavigation = () => {
    push(PROFILE.addID(PROFILE.USER_PROFILE_EDIT, "edit"));
  };

  const showEditButton = showEditProfileButton ? (
    <button
      data-cy="edit-profile"
      className="btn btn-primary edit-profile fw-bold px-3 mt-3 mt-md-0"
      onClick={handleEditProfileNavigation}
    >
      <Translate value="common.editProfile" />
    </button>
  ) : (
    <div className="invisible button-replacement" />
  );

  const hideMobileView = showEditProfileButton ? "d-none d-md-block" : "";

  return (
    <div
      className="profile-header d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-md-between pb-4 flex-fill"
      role="heading"
    >
      <h5 data-cy="profile-header" className={`h5 ${hideMobileView} fw-bold`}>
        <Translate value={title} />
      </h5>
      <ProfileImageContainer
        showEditProfileButton={showEditProfileButton}
        isImageLoading={isImageLoading}
      />
      {showEditButton}
    </div>
  );
};

ProfileHeader.defaultProps = {
  title: "",
  showEditProfileButton: false,
  isImageLoading: false,
};

ProfileHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showEditProfileButton: PropTypes.bool,
  isImageLoading: PropTypes.bool.isRequired,
};

export default ProfileHeader;
