import { createCable } from "@anycable/web";

import { AuthApi } from "internal";

import SocketUrl from "configs/socketUrlConfig";

import { updateBearerTokenReceived } from "redux/actions/auth";
import { store } from "redux/store";

let checkMultipleCall = false;
const timeStampForAnotherRequest = 3000;

export default createCable(SocketUrl.endPoint(), {
  tokenRefresher: async (transport) => {
    const {
      auth: { refreshToken },
    } = store.getState();

    if (checkMultipleCall) {
      setTimeout(() => {
        checkMultipleCall = false;
      }, timeStampForAnotherRequest);

      return null;
    }

    checkMultipleCall = true;

    const response = await AuthApi.refreshBearerToken(refreshToken);

    store.dispatch(updateBearerTokenReceived(response.data));

    transport.setURL(SocketUrl.baseEndPoint(response.data.access_token));
  },
});
