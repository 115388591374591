import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import Note from "components/Note/Note";
import RecordDetail, {
  RECORD_TYPE,
} from "components/RecordDetail/RecordDetail";

import { isEmpty } from "utils/isEmpty";

import { fetchCallProfilesRequest } from "redux/actions/callProfile";

const ProfileDetail = () => {
  const { profile } = useSelector(({ userProfile }) => userProfile);

  const [contactItems, setContactItems] = useState([]);
  const [text, setText] = useState("");

  const dispatch = useDispatch();
  const { currentUser } = useSelector(({ auth }) => auth);

  const { user_license_id: userLicenseID, pbx_number: pbxNumber } = currentUser;

  const addDetailChildren = (keyword, value) => {
    if (!value || value.length == 0) return [];
    let newChildren = [];

    value.forEach((item, index) => {
      newChildren.push({
        label: <Translate value={keyword} index={index + 1} />,
        value: item,
      });
    });

    return newChildren;
  };

  useEffect(() => {
    dispatch(fetchCallProfilesRequest(userLicenseID));
  }, []);

  useEffect(() => {
    if (isEmpty(profile)) return;

    const updatedContactItem = [
      {
        id: 1,
        label: <Translate value="common.extensionNumber" />,
        value: `${profile?.extension_number || ""} (${pbxNumber})`,
        children: [],
        type: RECORD_TYPE.DEFAULT,
      },
      {
        id: 2,
        label: <Translate value="common.Profile" />,
        value: null,
        children: [],
        type: RECORD_TYPE.PROFILE,
      },
      {
        id: 3,
        label: <Translate value="common.landlineNumbers" />,
        value: "",
        children: addDetailChildren(
          "contact.contactDetail.landlineNumberIndex",
          profile?.landline_numbers
        ),
        type: RECORD_TYPE.DEFAULT,
      },
      {
        id: 4,
        label: <Translate value="common.mobileNumbers" />,
        value: "",
        children: addDetailChildren(
          "contact.contactDetail.mobileNumberIndex",
          profile?.cell_phone_numbers || [profile?.cell_number]
        ),
        type: RECORD_TYPE.DEFAULT,
      },
      {
        id: 5,
        label: <Translate value="common.emailAddress" />,
        value: profile?.email || "",
        type: RECORD_TYPE.DEFAULT,
      },
      {
        id: 6,
        label: <Translate value="common.note" />,
        value: profile?.description || "",
        type: RECORD_TYPE.DEFAULT,
      },
    ];

    setContactItems(updatedContactItem);
  }, [profile]);

  return (
    <div role="document">
      <div className="profile-detail record-detail pt-3 d-flex flex-column justify-content-between bg-white">
        <div className="row mx-auto w-100 pb-3">
          {contactItems.map(({ label, value, children, id, type }) => (
            <RecordDetail
              label={label}
              value={value}
              children={children}
              key={id}
              type={type}
            />
          ))}
        </div>

        <Note text={text} setText={setText} note={profile.note} />
      </div>
    </div>
  );
};

export default ProfileDetail;
