import { CallProfileApi } from "internal";

import { displayError } from "utils/toaster";
import { getErrors, getServerError, setCurrentUser } from "utils/utils";

export const FETCH_CALL_PROFILES_REQUEST = "FETCH_CALL_PROFILES_REQUEST";
export const FETCH_CALL_PROFILES_RECEIVED = "FETCH_CALL_PROFILES_RECEIVED";
export const FETCH_CALL_PROFILES_ERROR = "FETCH_CALL_PROFILES_ERROR";

export const FETCH_CALL_PROFILE_DETAIL_REQUEST =
  "FETCH_CALL_PROFILE_DETAIL_REQUEST";
export const FETCH_CALL_PROFILE_DETAIL_RECEIVED =
  "FETCH_CALL_PROFILE_DETAIL_RECEIVED";
export const FETCH_CALL_PROFILE_DETAIL_ERROR =
  "FETCH_CALL_PROFILE_DETAIL_ERROR";

export const UPDATE_CALL_PROFILE_REQUEST = "UPDATE_CALL_PROFILE_REQUEST";
export const UPDATE_CALL_PROFILE_RECEIVED = "UPDATE_CALL_PROFILE_RECEIVED";
export const UPDATE_CALL_PROFILE_ERROR = "UPDATE_CALL_PROFILE_ERROR";

export const CREATE_CALL_PROFILE_REQUEST = "CREATE_CALL_PROFILE_REQUEST";
export const CREATE_CALL_PROFILE_RECEIVED = "CREATE_CALL_PROFILE_RECEIVED";
export const CREATE_CALL_PROFILE_ERROR = "CREATE_CALL_PROFILE_ERROR";

export const DELETE_CALL_PROFILE_REQUEST = "DELETE_CALL_PROFILE_REQUEST";
export const DELETE_CALL_PROFILE_RECEIVED = "DELETE_CALL_PROFILE_RECEIVED";
export const DELETE_CALL_PROFILE_ERROR = "DELETE_CALL_PROFILE_ERROR";

export const FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_REQUEST =
  "FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_REQUEST";
export const FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_RECEIVED =
  "FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_RECEIVED";
export const FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_ERROR =
  "FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_ERROR";

export const FETCH_OUTGOING_CALL_OPTIONS_REQUEST =
  "FETCH_OUTGOING_CALL_OPTIONS_REQUEST";
export const FETCH_OUTGOING_CALL_OPTIONS_RECEIVED =
  "FETCH_OUTGOING_CALL_OPTIONS_RECEIVED";
export const FETCH_OUTGOING_CALL_OPTIONS_ERROR =
  "FETCH_OUTGOING_CALL_OPTIONS_ERROR";

export const FETCH_ALL_INCOMING_AVAILABLE_DEVICES_REQUEST =
  "FETCH_ALL_INCOMING_AVAILABLE_DEVICES_REQUEST";
export const FETCH_ALL_INCOMING_AVAILABLE_DEVICES_RECEIVED =
  "FETCH_ALL_INCOMING_AVAILABLE_DEVICES_RECEIVED";
export const FETCH_ALL_INCOMING_AVAILABLE_DEVICES_ERROR =
  "FETCH_ALL_INCOMING_AVAILABLE_DEVICES_ERROR";

export const FETCH_INCOMING_AVAILABLE_SIP_DEVICES_REQUEST =
  "FETCH_INCOMING_AVAILABLE_SIP_DEVICES_REQUEST";
export const FETCH_INCOMING_AVAILABLE_SIP_DEVICES_RECEIVED =
  "FETCH_INCOMING_AVAILABLE_SIP_DEVICES_RECEIVED";
export const FETCH_INCOMING_AVAILABLE_SIP_DEVICES_ERROR =
  "FETCH_INCOMING_AVAILABLE_SIP_DEVICES_ERROR";

export const UPDATE_ACTIVE_CALL_PROFILE_REQUEST =
  "UPDATE_ACTIVE_CALL_PROFILE_REQUEST";
export const UPDATE_ACTIVE_CALL_PROFILE_RECEIVED =
  "UPDATE_ACTIVE_CALL_PROFILE_RECEIVED";
export const UPDATE_ACTIVE_CALL_PROFILE_ERROR =
  "UPDATE_ACTIVE_CALL_PROFILE_ERROR";

export const FETCH_DAILY_SCHEDULE_CALL_PROFILE_REQUEST =
  "FETCH_DAILY_SCHEDULE_CALL_PROFILE_REQUEST";
export const FETCH_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED =
  "FETCH_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED";
export const FETCH_DAILY_SCHEDULE_CALL_PROFILE_ERROR =
  "FETCH_DAILY_SCHEDULE_CALL_PROFILE_ERROR";

export const UPDATE_DAILY_SCHEDULE_CALL_PROFILE_REQUEST =
  "UPDATE_DAILY_SCHEDULE_CALL_PROFILE_REQUEST";
export const UPDATE_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED =
  "UPDATE_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED";
export const UPDATE_DAILY_SCHEDULE_CALL_PROFILE_ERROR =
  "UPDATE_DAILY_SCHEDULE_CALL_PROFILE_ERROR";

export const UPDATE_AUTOMATIC_CALL_PROFILE_REQUEST =
  "UPDATE_AUTOMATIC_CALL_PROFILE_REQUEST";
export const UPDATE_AUTOMATIC_CALL_PROFILE_RECEIVED =
  "UPDATE_AUTOMATIC_CALL_PROFILE_RECEIVED";
export const UPDATE_AUTOMATIC_CALL_PROFILE_ERROR =
  "UPDATE_AUTOMATIC_CALL_PROFILE_ERROR";

export const CLEAR_CALL_PROFILES_ERRORS = "CLEAR_CALL_PROFILES_ERRORS";

export const FETCH_ALL_QUEUES_REQUEST = "FETCH_ALL_QUEUES_REQUEST";
export const FETCH_ALL_QUEUES_RECEIVED = "FETCH_ALL_QUEUES_RECEIVED";
export const FETCH_ALL_QUEUES_FAILED = "FETCH_ALL_QUEUES_FAILED";

export const FETCH_ALL_QUEUE_DEVICES_REQUEST =
  "FETCH_ALL_QUEUE_DEVICES_REQUEST";
export const FETCH_ALL_QUEUE_DEVICES_RECEIVED =
  "FETCH_ALL_QUEUE_DEVICES_RECEIVED";
export const FETCH_ALL_QUEUE_DEVICES_FAILED = "FETCH_ALL_QUEUE_DEVICES_FAILED";

const fetchCallProfilesReceived = (data) => ({
  type: FETCH_CALL_PROFILES_RECEIVED,
  data,
});

const fetchCallProfilesError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_CALL_PROFILES_ERROR,
    error,
  };
};

export const fetchCallProfilesRequest = (userLicenseID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CALL_PROFILES_REQUEST });
    const response = await CallProfileApi.fetchAll(userLicenseID);

    dispatch(fetchCallProfilesReceived(response.data));
  } catch (error) {
    dispatch(fetchCallProfilesError(error));
  }
};

export const clearCallProfileErrors = () => ({
  type: CLEAR_CALL_PROFILES_ERRORS,
});

// api to get profile detail
const fetchCallProfileDetailReceived = (data) => ({
  type: FETCH_CALL_PROFILE_DETAIL_RECEIVED,
  data,
});

const fetchCallProfileDetailError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_CALL_PROFILE_DETAIL_ERROR,
    error,
  };
};

export const fetchCallProfileDetailRequest =
  (callProfileID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_CALL_PROFILE_DETAIL_REQUEST });

      const response = await CallProfileApi.detail(callProfileID);

      dispatch(fetchCallProfileDetailReceived(response.data));
    } catch (error) {
      dispatch(fetchCallProfileDetailError(error));
    }
  };

// api to update profile detail
const updateCallProfileReceived = (data) => ({
  type: UPDATE_CALL_PROFILE_RECEIVED,
  data,
});

const updateCallProfileError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: UPDATE_CALL_PROFILE_ERROR,
    error,
  };
};

export const updateCallProfileRequest =
  (callProfileID, data) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CALL_PROFILE_REQUEST });

      const response = await CallProfileApi.update(callProfileID, data);

      dispatch(updateCallProfileReceived(response.data));
    } catch (error) {
      dispatch(updateCallProfileError(error));
    }
  };

// create a new profile
const createCallProfileReceived = (data) => ({
  type: CREATE_CALL_PROFILE_RECEIVED,
  data,
});

const createCallProfileError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: CREATE_CALL_PROFILE_ERROR,
    error,
  };
};

export const createCallProfileRequest =
  (userLicenseID, data) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_CALL_PROFILE_REQUEST });

      const response = await CallProfileApi.create(userLicenseID, data);

      dispatch(createCallProfileReceived(response.data));
    } catch (error) {
      dispatch(createCallProfileError(error));
    }
  };

// api to delete  call profile
export const deleteCallProfileReceived = (data) => ({
  type: DELETE_CALL_PROFILE_RECEIVED,
  data,
});

export const deleteCallProfileError = (errorData) => {
  const error = getServerError(errorData);

  return {
    type: DELETE_CALL_PROFILE_ERROR,
    error,
  };
};

export const deleteCallProfileRequest = (callProfileID) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CALL_PROFILE_REQUEST });

    const response = await CallProfileApi.deleteProfile(callProfileID);

    dispatch(deleteCallProfileReceived(response.data));
  } catch (error) {
    dispatch(deleteCallProfileError(error));
  }
};

// fetch all available outgoing devices
const fetchAllOutgoingAvailableDevicesReceived = (data) => ({
  type: FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_RECEIVED,
  data,
});

const fetchAllOutgoingAvailableDevicesError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_ERROR,
    error,
  };
};

export const fetchAllOutgoingAvailableDevicesRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_REQUEST });

      const response = await CallProfileApi.fetchOutgoingAvailableDevices(
        userLicenseID
      );

      dispatch(fetchAllOutgoingAvailableDevicesReceived(response.data));
    } catch (error) {
      dispatch(fetchAllOutgoingAvailableDevicesError(error));
    }
  };

// fetch all outgoing call options
const fetchOutgoingCallOptionsReceived = (data) => ({
  type: FETCH_OUTGOING_CALL_OPTIONS_RECEIVED,
  data,
});

const fetchOutgoingCallOptionsError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_OUTGOING_CALL_OPTIONS_ERROR,
    error,
  };
};

export const fetchOutgoingCallOptionsRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_OUTGOING_CALL_OPTIONS_REQUEST });

      const response = await CallProfileApi.fetchOutgoingCallOptions(
        userLicenseID
      );

      dispatch(fetchOutgoingCallOptionsReceived(response.data));
    } catch (error) {
      dispatch(fetchOutgoingCallOptionsError(error));
    }
  };

//update active profile
const updateActiveCallProfileReceived = (data) => ({
  type: UPDATE_ACTIVE_CALL_PROFILE_RECEIVED,
  data,
});

const updateActiveCallProfileError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: UPDATE_ACTIVE_CALL_PROFILE_ERROR,
    error,
  };
};

export const updateActiveCallProfileRequest =
  (callProfileID, activeProfile) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ACTIVE_CALL_PROFILE_REQUEST });

      const response = await CallProfileApi.updateActiveProfile(
        callProfileID,
        activeProfile
      );

      dispatch(updateActiveCallProfileReceived(response.data));
      setCurrentUser(response.data);
    } catch (error) {
      dispatch(updateActiveCallProfileError(error));
    }
  };

// fetch all available incoming devices
const fetchAllIncomingAvailableDevicesReceived = (data) => ({
  type: FETCH_ALL_INCOMING_AVAILABLE_DEVICES_RECEIVED,
  data,
});

const fetchAllIncomingAvailableDevicesError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_ALL_INCOMING_AVAILABLE_DEVICES_ERROR,
    error,
  };
};

export const fetchAllIncomingAvailableDevicesRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_INCOMING_AVAILABLE_DEVICES_REQUEST });

      const response = await CallProfileApi.fetchIncomingAvailableDevices(
        userLicenseID
      );

      dispatch(fetchAllIncomingAvailableDevicesReceived(response.data));
    } catch (error) {
      dispatch(fetchAllIncomingAvailableDevicesError(error));
    }
  };

// fetch all incoming sip devices
const fetchAllIncomingSipDevicesReceived = (data) => ({
  type: FETCH_INCOMING_AVAILABLE_SIP_DEVICES_RECEIVED,
  data,
});

const fetchAllIncomingSipDevicesError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_INCOMING_AVAILABLE_SIP_DEVICES_ERROR,
    error,
  };
};

export const fetchAllIncomingSipDevicesRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_INCOMING_AVAILABLE_SIP_DEVICES_REQUEST });

      const response = await CallProfileApi.fetchIncomingAvailableSipDevices(
        userLicenseID
      );

      dispatch(fetchAllIncomingSipDevicesReceived(response.data));
    } catch (error) {
      dispatch(fetchAllIncomingSipDevicesError(error));
    }
  };

// fetch daily schedule call profile
const fetchDailyScheduleCallProfileReceived = (data) => ({
  type: FETCH_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED,
  data,
});

const fetchDailyScheduleCallProfileError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_DAILY_SCHEDULE_CALL_PROFILE_ERROR,
    error,
  };
};

export const fetchDailyScheduleCallProfileRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_DAILY_SCHEDULE_CALL_PROFILE_REQUEST });

      const response = await CallProfileApi.fetchDailyScheduleCallProfile(
        userLicenseID
      );

      dispatch(fetchDailyScheduleCallProfileReceived(response.data));
    } catch (error) {
      dispatch(fetchDailyScheduleCallProfileError(error));
    }
  };

// update daily schedule call profile
const updateDailyScheduleCallProfileReceived = (data) => ({
  type: UPDATE_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED,
  data,
});

const updateDailyScheduleCallProfileError = (errorData) => {
  const errors = errorData.response.data;

  displayError(errors);

  return {
    type: UPDATE_DAILY_SCHEDULE_CALL_PROFILE_ERROR,
    error,
  };
};

export const updateDailyScheduleCallProfileRequest =
  (callProfileID, payload) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_DAILY_SCHEDULE_CALL_PROFILE_REQUEST });

      const response = await CallProfileApi.updateScheduleCallProfile(
        callProfileID,
        payload
      );

      dispatch(updateDailyScheduleCallProfileReceived(response.data));
    } catch (error) {
      dispatch(updateDailyScheduleCallProfileError(error));
    }
  };

// update call profile schedule
const updateAutomaticCallProfileReceived = (payload, callback) => {
  callback(payload.data);

  return {
    type: UPDATE_AUTOMATIC_CALL_PROFILE_RECEIVED,
    payload,
  };
};

const updateAutomaticCallProfileError = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: UPDATE_AUTOMATIC_CALL_PROFILE_ERROR,
    error,
  };
};

export const updateAutomaticCallProfileRequest =
  (userLicenseID, isActiveCallProfile, callback) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_AUTOMATIC_CALL_PROFILE_REQUEST });

      const response = await CallProfileApi.updateAutomaticCallProfile(
        userLicenseID,
        isActiveCallProfile
      );

      dispatch(
        updateAutomaticCallProfileReceived(
          {
            data: response.data,
            isActive: isActiveCallProfile,
          },
          callback
        )
      );
      setCurrentUser(response.data);
    } catch (error) {
      dispatch(updateAutomaticCallProfileError(error));
    }
  };

const fetchAllQueuesReceived = (data) => ({
  type: FETCH_ALL_QUEUES_RECEIVED,
  data,
});

const fetchAllQueuesFailed = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_ALL_QUEUES_FAILED,
    error,
  };
};

export const fetchAllQueuesRequest = (pbxID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_QUEUES_REQUEST });

    const response = await CallProfileApi.fetchAllQueues(pbxID);

    dispatch(fetchAllQueuesReceived(response.data));
  } catch (error) {
    dispatch(fetchAllQueuesFailed(error));
  }
};

const fetchAllQueueDevicesReceived = (data) => ({
  type: FETCH_ALL_QUEUE_DEVICES_RECEIVED,
  data,
});

const fetchAllQueueDevicesFailed = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_ALL_QUEUE_DEVICES_FAILED,
    error,
  };
};

export const fetchAllQueueDevicesRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_ALL_QUEUE_DEVICES_REQUEST });

      const response = await CallProfileApi.fetchAllQueueDevices(userLicenseID);

      dispatch(fetchAllQueueDevicesReceived(response.data));
    } catch (error) {
      dispatch(fetchAllQueueDevicesFailed(error));
    }
  };
