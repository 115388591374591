import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  faCalendarTime,
  faPresentation,
  faSnooze,
  faUserTime,
  faCirclePlus,
  faPhoneOffice,
  faMobileSignalOut,
  faToolTip,
  faAgentMobile,
  faHome,
  faConfigureQueue,
  faInformationIcon,
} from "constants/customIcon/icons";

// add new icons to lib
library.add(
  faCalendarTime,
  faPresentation,
  faSnooze,
  faUserTime,
  faCirclePlus,
  fas,
  fab,
  far,
  faPhoneOffice,
  faMobileSignalOut,
  faToolTip,
  faAgentMobile,
  faHome,
  faConfigureQueue,
  faInformationIcon
);

// Look for changes of icons
dom.watch();
