import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  UPDATE_PROFILE: `${apiUrlConfig.defaultEndPoint()}/user_licenses/:user_license_id?profile_update=true`,
  UPDATE_LANGUAGE: `${apiUrlConfig.defaultEndPoint()}/users/:id/switch_locale`,
};

class UserProfileApi extends AuthenticatedRequest {
  static updateProfile(userLicenseId, attribute) {
    const UPDATE_URL = URLS.UPDATE_PROFILE.replace(
      ":user_license_id",
      userLicenseId
    );

    return this.patch(UPDATE_URL, attribute, true);
  }

  /**
   *
   * @param {init} id - id of current user
   * @param {string} language -  language to switch to
   * @returns
   */
  static updateLanguage(id, language) {
    const UPDATED_URL = URLS.UPDATE_LANGUAGE.replace(":id", id);

    return this.patch(UPDATED_URL, { locale: language });
  }
}

export default UserProfileApi;
