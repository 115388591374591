import { I18n, Translate } from "react-thunk-i18nify";

const OUTGOING_CALL_TYPE = {
  HIDDEN_NUMBER: "hidden_number",
  MAIN_DID_NUMBER: "main_did_number",
  ANONYMOUS: "ANONYMOUS",
};

const FORM_TYPE = {
  EDIT: "edit",
  CREATE: "create",
};

/**
 * Function will return the attributes required for outgoing call attributes.
 * @param {array} outgoingCalls - list of outgoing devices
 * @returns
 */
const addOutgoingCallAttributes = (outgoingCalls) =>
  outgoingCalls.map(({ call_routing_device, device_name, outgoingCall }) => {
    const { value, label, type, ...rest } = outgoingCall;
    let updatedLabel = null;

    switch (type) {
      case OUTGOING_CALL_TYPE.HIDDEN_NUMBER:
        updatedLabel = value;
        break;

      case OUTGOING_CALL_TYPE.MAIN_DID_NUMBER:
        const {
          props: { value: translateString, mainNumber },
        } = label;

        updatedLabel = I18n.t(translateString, { mainNumber: mainNumber });
        break;

      default:
        if (value == OUTGOING_CALL_TYPE.ANONYMOUS) {
          updatedLabel = value;
        } else {
          updatedLabel = label;
        }
    }

    if (rest?.id || outgoingCall.id) {
      return {
        caller_id_to_display: updatedLabel,
        caller_id_value: value,
        call_routing_device_attributes: call_routing_device,
        device_name,
        id: outgoingCall.id || rest.id,
      };
    }

    return {
      caller_id_to_display: updatedLabel,
      caller_id_value: value,
      call_routing_device_attributes: call_routing_device,
      device_name,
    };
  });

/**
 *
 * @param {string} type - the type of the outgoing device option such as hidden_number, main_did_number
 * @param {number|string} caller_id_to_display - the label of the outgoing device option
 * @param {number\string} caller_id_value - the value of the outgoing device option
 * @returns
 */

const outGoingCallOptions = ({
  type,
  caller_id_to_display: label,
  caller_id_value: value,
}) => {
  switch (type) {
    case OUTGOING_CALL_TYPE.HIDDEN_NUMBER:
      return {
        value,
        label: <Translate value="callProfile.outgoing.hiddenNumber" />,
        type: OUTGOING_CALL_TYPE.HIDDEN_NUMBER,
      };
    case OUTGOING_CALL_TYPE.MAIN_DID_NUMBER:
      return {
        value,
        label: (
          <Translate
            value="callProfile.outgoing.mainNumber"
            mainNumber={value}
          />
        ),
        type: OUTGOING_CALL_TYPE.MAIN_DID_NUMBER,
      };

    default:
      return {
        value,
        label,
      };
  }
};

/**
 * Initial values of outgoing calls list
 * @param {array} outgoingCalls - list of outgoing devices
 * @returns
 */
const outgoingCallInitialValues = (outgoingCalls) => {
  if (!outgoingCalls) return [];

  return outgoingCalls.map(({ call_routing_device, device_name, ...rest }) => {
    if (rest?.id) {
      const { caller_id_value: value, caller_id_to_display: label, id } = rest;

      const updatedLabel =
        label == OUTGOING_CALL_TYPE.ANONYMOUS ? (
          <Translate value="callProfile.outgoing.hiddenNumber" />
        ) : (
          label
        );

      return {
        outgoingCall: {
          value,
          label: updatedLabel,
          id,
        },
        call_routing_device,
        device_name,
      };
    }

    return {
      outgoingCall: null,
      call_routing_device,
      device_name,
    };
  });
};

export {
  addOutgoingCallAttributes,
  outGoingCallOptions,
  OUTGOING_CALL_TYPE,
  FORM_TYPE,
  outgoingCallInitialValues,
};
