import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import EmptyAgentUI from "components/Home/Empty/EmptyAgentUI";
import SearchBar from "components/SearchBar/SearchBar";
import AgentsSkeleton from "components/Skeleton/CallQueue/Detail/AgentManagementSidebar/AgentsSkeleton";

import { AgentManagementContext } from "context-api/AgentManagementContext";

import {
  searchInJson,
  SEARCH_KEY_FOR_AVAILABLE_AGENT,
} from "utils/searchInJson";

import { fetchAvailableAgentsInQueueRequest } from "redux/actions/callQueue";

import AvailableAgentTable from "./AvailableAgentTable";

const AvailableAgents = ({ queueID }) => {
  const { callQueues } = useSelector(({ callQueue }) => callQueue);

  const { availableAgents, isAvailableAgentsLoading: isLoading } =
    callQueues.find(({ asterisk_queue_id: id }) => id == queueID);

  const {
    currentUser: { pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [isSelectedID, setSelectedID] = useState(false);

  const { updatedAvailableAgents, setUpdatedAvailableAgents } = useContext(
    AgentManagementContext
  );

  const handleAvailableAgentsSearch = (e) => {
    const searchText = e.target.value;

    setInitialValues(
      searchInJson(
        searchText,
        updatedAvailableAgents,
        SEARCH_KEY_FOR_AVAILABLE_AGENT,
        (value) => setSearching(value)
      )
    );
  };

  useEffect(() => {
    dispatch(fetchAvailableAgentsInQueueRequest(pbxID, queueID));
  }, [queueID]);

  useEffect(() => {
    setInitialValues(availableAgents);
    setUpdatedAvailableAgents(availableAgents);
  }, [availableAgents]);

  if (isLoading) {
    return <AgentsSkeleton />;
  }

  if (updatedAvailableAgents.length == 0 && !isSearching) {
    return <EmptyAgentUI />;
  }

  return (
    <div className="available-agents">
      <div className="available-agent-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-between ">
        <p className="mb-0 available-agent-title mb-2 mb-sm-0 fw-bold">
          <Translate value="callQueue.agentManagement.availableAgents" />
        </p>
        <SearchBar
          onSearchHandler={handleAvailableAgentsSearch}
          placeholderText="attributes.searchAvailableAgents"
          className="search-bar"
          isSearchDisabled={isSelectedID}
        />
      </div>
      <AvailableAgentTable
        availableAgents={initialValues}
        isSearching={isSearching}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

AvailableAgents.propTypes = {
  queueID: PropTypes.number.isRequired,
};

export default AvailableAgents;
