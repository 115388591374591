import React from "react";
import { useField } from "react-final-form";

import PropTypes from "prop-types";

import SipDeviceDropdown from "../IncomingCall/IncomingCallItem/SipDeviceDropdown";

const QueueRegistrationItem = ({ input: { name }, options }) => {
  const {
    input: { value },
  } = useField(name);

  if (!options?.length) return null;

  return (
    <div className="incoming-call-item row py-3 gx-0 gx-md-2">
      <label className="col-md-5 device-name align-self-center mt-1 mb-2 mb-md-0">
        {value.name}:
      </label>
      <SipDeviceDropdown
        show
        name={`${name}.call_profile_queue_devices_attributes`}
        options={options}
        validateOptions={false}
        className="col-md-7 col-xxl-6"
        id={name.replace("[", "_").replace("]", "_")}
      />
    </div>
  );
};

QueueRegistrationItem.defaultProps = {
  options: [],
  input: {},
};

QueueRegistrationItem.propTypes = {
  name: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  value: PropTypes.shape({
    name: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

export default QueueRegistrationItem;
