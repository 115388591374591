import React, { useContext, useCallback } from "react";

import PropTypes from "prop-types";

import Dropdown from "components/Dropdown/Dropdown";

import { ConfigureQueueContext } from "context-api/ConfigureQueueContext";

const QueuePriority = ({ id }) => {
  const {
    queuePriority,
    setQueuePriority,
    isFormEdited,
    setFormEdited,
    updatedHomeQueues,
    setUpdatedHomeQueues,
  } = useContext(ConfigureQueueContext);

  const priorityOptions = updatedHomeQueues.map((_, index) => ({
    keyword: index + 1,
    title: index + 1,
  }));

  const updateForm = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  const handleUpdateCallPriority = ({ title, keyword }) => {
    updateForm();
    setQueuePriority((previous) => ({
      ...previous,
      [id]: {
        weight: keyword,
        title,
      },
    }));

    setUpdatedHomeQueues((prev) => {
      const copiedHomeQueue = [...prev];

      return copiedHomeQueue.map(({ asterisk_queue_id: queueID, ...rest }) => {
        if (queueID == id) {
          return {
            ...rest,
            asterisk_queue_id: queueID,
            weight: keyword,
          };
        }

        return {
          ...rest,
          asterisk_queue_id: queueID,
        };
      });
    });
  };

  return (
    <Dropdown
      active={queuePriority[id]?.title}
      options={priorityOptions}
      handleDropdownUpdate={handleUpdateCallPriority}
      containerClassName="call-priority me-2"
      activeClassName="px-0 px-sm-2 call-priority-dropdown"
    />
  );
};

QueuePriority.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default QueuePriority;
