import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { endOfDay, startOfMonth } from "date-fns";

import EmptyContent from "components/EmptyContent/EmptyContent";
import VoicemailSkeleton from "components/Skeleton/Voicemails/VoicemailSkeleton";
import VoicemailTableSkeleton from "components/Skeleton/Voicemails/VoicemailTableSkeleton";
import VoicemailTable from "components/Voicemail/Table/VoicemailTable";
import TopHeader from "components/Voicemail/TopHeader";

import filterSearchResults from "utils/filterSearchResults";
import { useQuery } from "utils/useQuery";

import {
  clearVoicemailError,
  filterVoicemailByDateRequest,
  searchVoicemail,
  voicemailRequest,
  voicemailSearchRequest,
} from "redux/actions/voicemail";

const Voicemail = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const { pbx_user: pbxUser } = useQuery();

  const { isLoading, voicemails, isSearching, isFiltering } = useSelector(
    ({ voicemail }) => voicemail
  );
  const {
    currentUser: { user_license_id: userLicenseID },
    isPbxAdmin,
  } = useSelector(({ auth }) => auth);
  const search = useSelector(({ search }) => search);

  const [searchText, setSearchText] = useState(null);

  const handleVoiceSearchHandler = (e) => {
    dispatch(searchVoicemail(e.target.value));

    setSearchText(e.target.value);
  };

  const updatedVoicemails = isSearching || isFiltering ? data : voicemails;

  const fetchSearchResults = useCallback(() => {
    dispatch(voicemailSearchRequest(searchText));
    const result = filterSearchResults({
      resource: voicemails,
      searchResult: search.voicemails,
    });

    if (searchText === "") {
      setSearchText(null);
    }

    setData(result);
  }, [search.voicemails]);

  const filterByDate = () => {
    if (date.length <= 1) return;
    const [startDate, end] = date;

    const endDate = endOfDay(end);

    const startDateUnixFormat = Math.floor(startDate.getTime() / 1000);
    const endDateUnixFormat = Math.floor(endDate.getTime() / 1000);

    dispatch(
      filterVoicemailByDateRequest(
        pbxUser || userLicenseID,
        "all",
        startDateUnixFormat,
        endDateUnixFormat
      )
    );
  };

  useEffect(() => {
    fetchSearchResults();
  }, [search.voicemails]);

  useEffect(() => {
    setDate([startOfMonth(new Date()), new Date()]);
  }, []);

  useEffect(() => {
    if (date.length === 0) return;
    filterByDate();
  }, [date]);

  useEffect(
    () => () => {
      dispatch(clearVoicemailError());
    },
    []
  );

  useEffect(() => {
    if (!isLoading && loading) {
      setLoading(false);
    }
  }, [isLoading]);

  if (isLoading && loading) {
    return <VoicemailSkeleton />;
  }

  if (
    updatedVoicemails.length === 0 &&
    !isPbxAdmin &&
    date.length === 0 &&
    !isLoading
  ) {
    return (
      <EmptyContent
        title="voicemails.empty.voicemailsList"
        description="voicemails.empty.description"
      />
    );
  }

  if (updatedVoicemails.length === 0 && !isSearching && !isFiltering) {
    return (
      <div
        role="document"
        className="row overflow-auto w-100 h-100 mx-0 layout-spacing"
      >
        <div className="voicemails px-2 card border-0 px-0">
          <TopHeader
            handleVoiceSearchHandler={handleVoiceSearchHandler}
            voicemails={updatedVoicemails}
            updateVoicemailTableHandler={({ id }) =>
              dispatch(voicemailRequest(id))
            }
          />
          {isLoading ? (
            <VoicemailTableSkeleton />
          ) : (
            <EmptyContent
              title="voicemails.empty.voicemailsList"
              description="voicemails.empty.description"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div role="document" className="w-100 h-100 mx-0 layout-spacing">
      <div className="voicemails px-2 card border-0 px-0 h-100">
        <TopHeader
          handleVoiceSearchHandler={handleVoiceSearchHandler}
          voicemails={updatedVoicemails}
          date={date}
          setDate={setDate}
        />
        <VoicemailTable voicemails={updatedVoicemails} />
      </div>
    </div>
  );
};

export default Voicemail;
