import axios from "axios";

import { AuthApi } from "internal";

import { AUTH, PROFILE } from "constants/routes";

import { getKeepMeSignedIn } from "utils/auth";
import {
  displayNetworkErrorToaster,
  removeNetworkErrorToaster,
} from "utils/networkToaster";
import updateStatusReducer, { REDUCER_TYPE } from "utils/updateReducer";

import {
  sessionExpiredRequest,
  updateBearerTokenReceived,
} from "redux/actions/auth";
import { store } from "redux/store";

export const UPDATE_STATUS_CODE = "UPDATE_STATUS_CODE";

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

/**
 * Interceptor to catch Unauthenticated responses.
 *
 * @param {*} err - Response error
 */

const handleSessionExpired = () => {
  const translationItem = JSON.parse(
    localStorage.getItem("persist:pbxStore")
  )?.i18n;

  localStorage.clear();
  localStorage.setItem(
    "persist:pbxStore",
    JSON.stringify({ i18n: translationItem })
  );

  store.dispatch(sessionExpiredRequest());
};

const refreshBearerToken = async (err) => {
  const originalRequest = err.config;

  if (!originalRequest._retry) {
    const {
      auth: { refreshToken },
    } = store.getState();

    if (isRefreshing) {
      // when refresh token is already added, it will push over failedQueue list
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })

        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;

          return axios(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    // when token expired for the first time

    return new Promise(async function (resolve, reject) {
      try {
        const response = await AuthApi.refreshBearerToken(refreshToken);

        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

        store.dispatch(updateBearerTokenReceived(response.data));

        processQueue(null, response.data.access_token);
        resolve(axios(originalRequest));
      } catch (error) {
        processQueue(err, null);
        reject(err);
      } finally {
        isRefreshing = false;
      }
    });
  }
};

const errorHandlerInterceptor = (err) => {
  if (err.message === "Network Error") {
    removeNetworkErrorToaster();
    displayNetworkErrorToaster();
  } else if (
    err.response &&
    err.response.status === 401 &&
    window.location.pathname !== AUTH.LOGIN
  ) {
    if (getKeepMeSignedIn() == "false" || !getKeepMeSignedIn()) {
      handleSessionExpired();
    } else {
      return refreshBearerToken(err);
    }
  } else if (err.response && err.response.status === 404) {
    const pathname = window.location.pathname;
    const reducerType = pathname.includes(PROFILE.INDEX)
      ? REDUCER_TYPE.CALL_PROFILE
      : null;

    store.dispatch(updateStatusReducer(err.response.status, reducerType));

    return Promise.reject(err);
  } else if (err.response && err.response.status === 403) {
    const pathname = window.location.pathname;
    const reducerType = pathname.includes(AUTH.LOGIN)
      ? REDUCER_TYPE.AUTH
      : pathname.includes(PROFILE.INDEX)
      ? REDUCER_TYPE.CALL_PROFILE
      : null;

    store.dispatch(updateStatusReducer(err.response.status, reducerType));
  } else {
    throw err;
  }
};

export default errorHandlerInterceptor;
