import React from "react";

import PropTypes from "prop-types";

const EmptyTableHeader = ({ show }) => {
  if (!show) return null;

  return <div className="empty-content-header" />;
};

EmptyTableHeader.defaultProps = {
  show: false,
};

EmptyTableHeader.propTypes = {
  show: PropTypes.bool,
};

export default EmptyTableHeader;
