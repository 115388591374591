import React from "react";
import Skeleton from "react-loading-skeleton";

import InformationBoxWrapperSkeleton from "components/Skeleton/InformationBoxWrapperSkeleton";

const ConfigureVoicemailBoxSkeleton = () => (
  <div
    role="document"
    className="voicemail-boxes overflow-auto w-100 h-100 mx-0 layout-spacing"
  >
    <article className="configure-voicemail-boxes p-3 bg-white hv-100">
      <InformationBoxWrapperSkeleton>
        <div className="px-2 px-md-3 py-2 d-flex justify-content-between flex-wrap">
          <div className="w-100">
            <div className="px-4 py-3 config-field row w-100 ms-0">
              <div className="col-md-4  align-self-center mb-2 mb-md-0">
                <Skeleton height={20} width={100} />
              </div>
              <div className="col-md-5">
                <Skeleton height={50} className="w-100" />
              </div>
            </div>
            <div className="px-4 py-3 config-field row w-100 ms-0">
              <div className="col-md-4  align-self-center  mb-2 mb-md-0">
                <Skeleton height={20} width={80} />
              </div>
              <div className="col-md-5">
                <Skeleton height={50} className="w-100" />
              </div>
            </div>
            <div className="px-4 py-3 config-field row w-100 ms-0">
              <div className="col-md-4  align-self-center  mb-2 mb-md-0">
                <Skeleton height={20} className="w-75" />
              </div>
              <div className="col-md-2">
                <Skeleton height={50} className="w-100" />
              </div>
            </div>
            <div className="px-4 py-3 config-field row w-100 ms-0">
              <div className="col-md-4  align-self-center  mb-2 mb-md-0">
                <Skeleton height={20} className="w-75" />
              </div>
              <div className="col-md-2">
                <Skeleton height={50} className="w-100" />
              </div>
            </div>
            <div className="px-4 py-3 config-field row w-100 ms-0">
              <div className="col-md-4  align-self-center  mb-2 mb-md-0">
                <Skeleton height={20} className="w-75" />
              </div>
              <div className="col-md-2">
                <Skeleton height={50} className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </InformationBoxWrapperSkeleton>
      <div className="d-flex flex-column flex-md-row justify-content-end mt-md-3">
        <div className="mb-3 col-12 col-md-1 mx-md-2">
          <Skeleton height={55} className="w-100" />
        </div>
        <div className="mb-3 col-12 col-md-2">
          <Skeleton height={55} className="w-100" />
        </div>
      </div>
    </article>
  </div>
);

export default ConfigureVoicemailBoxSkeleton;
