import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import ContactInformationSkeleton from "./ContactInformationSkeleton";
import ProfileSkeleton from "./ProfileSkeleton";

const ContactDetailSkeleton = ({ containerClassName }) => {
  const { contactDetailLoading } = useSelector(({ contact }) => contact);

  const activeProfileClassNames = contactDetailLoading
    ? "active"
    : "d-none d-md-block";

  return (
    <div
      className={`contact-detail ${activeProfileClassNames} mh-100 h-100 p-0 ${containerClassName}`}
    >
      <div className="h-100  ms-md-4 mt-5 pt-5 pt-md-0 mt-md-0 pe-md-3">
        <Skeleton
          width={250}
          height={35}
          className="mb-2 d-none d-md-inline-block"
        />
        <ProfileSkeleton />
        <ContactInformationSkeleton />
      </div>
    </div>
  );
};

ContactDetailSkeleton.defaultProps = {
  containerClassName:
    " col-12 col-md-7 col-lg-8 col-xxl-9 p-0 my-0 overflow-auto",
  showReturnToDashboardSkeleton: false,
};

ContactDetailSkeleton.propTypes = {
  containerClassName: PropTypes.string,
  showReturnToDashboardSkeleton: PropTypes.bool,
};

export default ContactDetailSkeleton;
