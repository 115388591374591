import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faCheck, faPen, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addMinutes, subMinutes } from "date-fns";
import PropTypes from "prop-types";

import { ELEMENT_TYPE } from "utils/fixedCalendarFormatter";
import { calculateTotalMinutes } from "utils/timeStampFormatter";

import CallProfileDropdown from "./CallProfileDropdown";

const TimeFrame = ({
  startTime,
  endTime,
  profile,
  id,
  handleUpdateActiveTimeFrameProfile,
  handleDeleteTimeFrame,
  handleUpdateStartTime,
  handleUpdateEndTime,
  date,
  elementType,
  timeFrameCurrentIndex,
  existingTimeFrames,
}) => {
  const { callProfiles } = useSelector(({ callProfile }) => callProfile);

  const [editAble, setEditAble] = useState(false);
  const [updatedStartTime, setStartTime] = useState({
    time: null,
    data: null,
  });

  const [updatedEndTime, setEndTime] = useState({
    time: null,
    data: null,
  });

  const [updatedProfile, setProfile] = useState({
    name: null,
    data: null,
  });

  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    if (!editAble) {
      if (updatedStartTime.data) {
        handleUpdateStartTime(updatedStartTime.data, id);
        setStartTime((prev) => ({ ...prev, data: null }));
      }

      if (updatedEndTime.data) {
        handleUpdateEndTime(updatedEndTime.data, id);
        setEndTime((prev) => ({ ...prev, data: null }));
      }

      if (updatedProfile.data) {
        handleUpdateActiveTimeFrameProfile(updatedProfile.data);
        setProfile((prev) => ({ ...prev, data: null }));
      }
    }
  }, [editAble]);

  useEffect(() => {
    if (startTime) {
      setStartTime((prev) => ({ ...prev, time: startTime }));
    }
  }, [startTime]);

  useEffect(() => {
    if (endTime) {
      setEndTime((prev) => ({ ...prev, time: endTime }));
    }
  }, [endTime]);

  useEffect(() => {
    if (profile) {
      setProfile((prev) => ({ ...prev, name: profile }));
    }
  }, [profile]);

  const handleUpdateProfile = (e) => {
    setProfile({ name: e.name, data: e });
  };

  useEffect(() => {
    if (elementType === ELEMENT_TYPE.NEWLY_ADDED && !profile) {
      setEditAble(true);
    }
  }, [elementType]);

  const validateAttributes = (endTime, startTime) => {
    const calculatedUpdatedEndTime = calculateTotalMinutes(endTime);
    const calculatedUpdatedStartTime = calculateTotalMinutes(startTime);

    const updatedValidationErrors = [];

    if (!updatedProfile?.name) {
      updatedValidationErrors.push("Call Profile can't be blank");
    }

    // Overlapping time frames examples for existing time frame let's say 10 - 18 with work profile:
    // 9 - 11 => time frame overlaps with existing time frame's start time
    // 6 - 20 OR 10 - 18 => time frame overshadows existing time frame
    // 17 - 19 => time frame overlaps with existing time frame's end time
    // 12 - 18 with work profile again => overshadowed by existing time frame
    const overlappingTimeFrame = existingTimeFrames.find(
      (
        {
          start: existingStartTime,
          end: existingEndTime,
          title: existingCallProfile,
        },
        index
      ) => {
        const calculatedExistingStartTime =
          calculateTotalMinutes(existingStartTime);
        const calculatedExistingEndTime =
          calculateTotalMinutes(existingEndTime);

        const overlapsExistingStartTime =
          calculatedUpdatedStartTime < calculatedExistingStartTime &&
          calculatedUpdatedEndTime > calculatedExistingStartTime;
        const overShadowsExistingTimeFrame =
          calculatedUpdatedStartTime <= calculatedExistingStartTime &&
          calculatedUpdatedEndTime >= calculatedExistingEndTime;
        const overlapsExistingEndTime =
          calculatedUpdatedStartTime < calculatedExistingEndTime &&
          calculatedUpdatedEndTime > calculatedExistingEndTime;
        const overshadowedByExistingTimeFrame =
          existingCallProfile === updatedProfile.name &&
          calculatedUpdatedStartTime > calculatedExistingStartTime &&
          calculatedUpdatedEndTime < calculatedExistingEndTime;

        return (
          (overlapsExistingStartTime ||
            overShadowsExistingTimeFrame ||
            overlapsExistingEndTime ||
            overshadowedByExistingTimeFrame) &&
          index != timeFrameCurrentIndex
        );
      }
    );

    if (overlappingTimeFrame) {
      updatedValidationErrors.push(
        "Call profile already exists during that start and end time"
      );
    }

    if (calculatedUpdatedEndTime < calculatedUpdatedStartTime) {
      updatedValidationErrors.push("Start time can't be greater than end time");
    }

    if (updatedValidationErrors.length > 0) {
      setValidationErrors(updatedValidationErrors);
    } else {
      setEditAble(!editAble);
      setValidationErrors([]);
    }
  };

  const handleTimeFrameUpdate = (endTime, startTime) => {
    if (!editAble) {
      setEditAble(!editAble);

      return;
    }

    validateAttributes(endTime, startTime);
  };

  const icon = editAble ? faCheck : faPen;

  const minStartTime = updatedStartTime.time
    ? `${date}T${updatedStartTime.time.split("T")[1]}`
    : {};
  const maxEndTime = updatedEndTime.time
    ? `${date}T${updatedEndTime.time.split("T")[1]}`
    : {};

  const start = startTime ? new Date(updatedStartTime.time) : null;
  const end = endTime ? new Date(updatedEndTime.time) : null;

  const maxStartTimeProps = !profile
    ? {}
    : { maxTime: subMinutes(new Date(maxEndTime), 1) };
  const minEndTimeProps = !profile
    ? {}
    : { minTime: addMinutes(new Date(minStartTime), 1) };

  const validationErrorsContent = validationErrors.length > 0 && (
    <div className="text-danger mt-2 ps-0">{validationErrors.join(", ")}</div>
  );

  return (
    <>
      <div className="col-12 col-md-5 ps-md-0">
        <div className="time-stamp-item p-2 h-100 d-flex align-items-center">
          <div className="start-time d-flex align-items-center flex-fill">
            <p className="mb-0 me-2 choose-profile">
              <Translate value="common.from" />
            </p>
            <Flatpickr
              options={{
                enableTime: true,
                position: "auto center",
                dateFormat: "H : i",
                altFormat: "H : i",
                noCalendar: true,
                disableMobile: "true",
                time_24hr: true,
                minuteIncrement: 1,
                ...maxStartTimeProps,
              }}
              className="text-center setup-time border-0"
              value={start}
              placeholder="00 : 00"
              onClose={(selectedTimes) => {
                setStartTime({
                  time: new Date(selectedTimes[0]).toISOString(),
                  data: selectedTimes,
                });
              }}
              disabled={!editAble}
            />
          </div>
          <div className="end-time d-flex align-items-center flex-fill">
            <p className="mb-0 me-2 choose-profile">
              <Translate value="common.to" />
            </p>
            <Flatpickr
              options={{
                enableTime: true,
                position: "auto center",
                dateFormat: "H : i",
                altFormat: "H : i",
                noCalendar: true,
                disableMobile: true,
                time_24hr: true,
                minuteIncrement: 1,
                ...minEndTimeProps,
              }}
              className="text-center setup-time border-0"
              placeholder="00 : 15"
              value={end}
              onClose={(selectedTimes) => {
                setEndTime({
                  time: new Date(selectedTimes[0]).toISOString(),
                  data: selectedTimes,
                });
              }}
              disabled={!editAble}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-7">
        <div className="d-flex align-items-center w-100">
          <div className="time-stamp-item p-2 d-flex align-items-center flex-fill">
            <p className="mb-0 me-2 choose-profile text-nowrap">
              <Translate value="callProfile.timeStampModal.chooseProfile" />
            </p>
            <CallProfileDropdown
              callProfiles={callProfiles}
              activeName={updatedProfile.name}
              id={id}
              handleUpdateActiveTimeFrameProfile={handleUpdateProfile}
              isDisabled={!editAble}
              isEditable={editAble}
            />
          </div>
          <div className="d-flex ps-2 align-items-center">
            <button
              className="btn rounded-circle bg-primary p-1 d-flex justify-content-center align-items-center edit-icon border-0"
              type="button"
              onClick={() =>
                handleTimeFrameUpdate(
                  updatedEndTime.time,
                  updatedStartTime.time
                )
              }
            >
              <FontAwesomeIcon icon={icon} className="text-white" />
            </button>
            <button
              className="btn rounded-circle bg-danger p-1 d-flex justify-content-center align-items-center edit-icon ms-2 border-0"
              onClick={() => handleDeleteTimeFrame(id)}
            >
              <FontAwesomeIcon icon={faTimes} className="text-white" />
            </button>
          </div>
        </div>
      </div>

      {validationErrorsContent}
    </>
  );
};

TimeFrame.defaultProps = {
  startTime: {},
  endTime: {},
  profile: "",
  id: "",
  handleUpdateActiveTimeFrameProfile: () => {},
  handleDeleteTimeFrame: () => {},
  handleUpdateStartTime: () => {},
  handleUpdateEndTime: () => {},
  date: "",
  existingTimeFrames: [],
};

TimeFrame.propTypes = {
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  profile: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleUpdateActiveTimeFrameProfile: PropTypes.func,
  handleUpdateStartTime: PropTypes.func,
  handleUpdateEndTime: PropTypes.func,
  handleDeleteTimeFrame: PropTypes.func,
  date: PropTypes.string,
  elementType: PropTypes.string.isRequired,
  timeFrameCurrentIndex: PropTypes.number.isRequired,
  existingTimeFrames: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TimeFrame;
