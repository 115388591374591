import React from "react";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { snakeCaseToKebabCase } from "utils/utils";

const AgentImage = ({ image, id, status, hideStatus }) => {
  const userStateClassName = snakeCaseToKebabCase(status || "unavailable");
  const hideStatusClassName = hideStatus ? "d-none" : "";

  if (image) {
    return (
      <div className="rounded-circle avatar me-2 position-relative d-flex align-items-center justify-content-center">
        <img src={image} alt={`agent ${id}`} className="rounded-circle" />
        <span
          className={`rounded-circle position-absolute user-status ${userStateClassName} ${hideStatusClassName}`}
        />
      </div>
    );
  }

  return (
    <div className="rounded-circle avatar me-2 position-relative d-flex align-items-center justify-content-center">
      <FontAwesomeIcon icon={faUser} className="user-icon" />
      <span
        className={`rounded-circle position-absolute user-status ${userStateClassName} ${hideStatusClassName}`}
      />
    </div>
  );
};

AgentImage.defaultProps = {
  image: "",
  id: 0,
  status: "",
  hideStatus: false,
};

AgentImage.propTypes = {
  image: PropTypes.string,
  id: PropTypes.number,
  status: PropTypes.string,
  hideStatus: PropTypes.bool,
};

export default AgentImage;
