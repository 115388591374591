import React from "react";

import TableSkeleton from "components/Skeleton/TableSkeleton";

const CallHistoryTableSkeleton = () => (
  <div className="call-history-table-container overflow-auto" role="table">
    <TableSkeleton numberOfColumns={4} numberOfRows={5} />
  </div>
);

export default CallHistoryTableSkeleton;
