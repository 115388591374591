export const UPDATE_STATUS_CODE_CALL_QUEUE = "UPDATE_STATUS_CODE_CALL_QUEUE";
export const UPDATE_STATUS_CODE_CALL_PROFILE =
  "UPDATE_STATUS_CODE_CALL_PROFILE";
export const UPDATE_STATUS_CODE_AUTH = "UPDATE_STATUS_CODE_AUTH";

export const REDUCER_TYPE = {
  CALL_PROFILE: "callProfile",
  AUTH: "auth",
};

const updateStatusReducer = (statusCode, reducerType) => (dispatch) => {
  switch (reducerType) {
    case REDUCER_TYPE.CALL_PROFILE:
      if (dispatch) {
        dispatch({
          type: UPDATE_STATUS_CODE_CALL_PROFILE,
          statusCode,
        });
      } else {
        return {
          type: UPDATE_STATUS_CODE_CALL_PROFILE,
          statusCode,
        };
      }
      break;

    case REDUCER_TYPE.AUTH:
      if (dispatch) {
        dispatch({
          type: UPDATE_STATUS_CODE_AUTH,
          statusCode,
        });
      } else {
        return {
          type: UPDATE_STATUS_CODE_AUTH,
          statusCode,
        };
      }
      break;

    default:
      return null;
  }
};

export default updateStatusReducer;
