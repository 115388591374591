import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { BRAND_LOGO } from "constants/images";
import { AUTH, HOME } from "constants/routes";

const LoginWrapper = ({ children }) => {
  const { isLoggedIn } = useSelector(({ auth }) => auth);

  const path = isLoggedIn ? `${HOME.INDEX}?type=all` : AUTH.INDEX;

  return (
    <main
      className="login-container h-100 w-100 py-5"
      data-testid="login-wrapper"
    >
      <section className="container h-100 w-100 d-flex flex-column justify-content-center">
        <header
          className="w-100 d-flex flex-column align-items-center justify-content-between mb-2 mb-xl-4"
          role="heading"
        >
          <Link to={path}>
            <figure>
              <img
                src={BRAND_LOGO.SOURCE}
                alt="brand-logo"
                className="text-center"
              />
            </figure>
          </Link>
        </header>
        <div
          className="card d-flex justify-content-center align-items-center px-3 px-sm-5 py-4 mx-md-auto col-md-10 col-lg-8 col-xl-6 border-0"
          role="form"
        >
          {children}
        </div>
      </section>
    </main>
  );
};

LoginWrapper.defaultProps = {
  children: <></>,
};

LoginWrapper.propTypes = {
  children: PropTypes.node,
};

export default LoginWrapper;
