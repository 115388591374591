import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { faAgentMobile } from "constants/customIcon/icons";
import { faPhoneOffice } from "constants/customIcon/icons";

const ConfigureDevice = ({
  deviceType,
  isRegistered,
  number,
  deviceName,
  handleToggleChange,
  isSipDevice,
  queueID,
}) => {
  const icon = isSipDevice ? faPhoneOffice : faAgentMobile;

  const [isActive, setActive] = useState(isRegistered);

  const handleChange = (e) => {
    handleToggleChange({
      deviceType,
      isRegistered: e.target.checked,
      number,
      deviceName,
    });
    setActive(e.target.checked);
  };

  useEffect(() => {
    setActive(isRegistered);
  }, [isRegistered]);

  return (
    <li className="device-item my-2 p-2">
      <div className="form-switch ps-0 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center flex-fill device-number">
          <FontAwesomeIcon icon={icon} className="me-1 device-icon" />
          <label
            className="form-check-label ms-1 text-nowrap me-2 flex-fill"
            htmlFor={number + queueID}
          >
            {number}
          </label>
        </div>

        <input
          className="form-check-input device-toggler ms-4 mt-0"
          type="checkbox"
          role="switch"
          checked={isActive}
          defaultChecked={isRegistered}
          id={number + queueID}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </li>
  );
};

ConfigureDevice.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  deviceName: PropTypes.string.isRequired,
  handleToggleChange: PropTypes.func.isRequired,
  isSipDevice: PropTypes.bool.isRequired,
  queueID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ConfigureDevice;
