import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

const InputLabel = ({ name, className, labelTranslation }) => {
  if (!labelTranslation) return null;

  return (
    <label
      htmlFor={name}
      className={`label ${className}`}
      data-testid="input-label"
    >
      <Translate value={labelTranslation} />
    </label>
  );
};

InputLabel.defaultProps = {
  name: "",
  className: "fw-bold mb-0",
  labelTranslation: "",
};

InputLabel.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  labelTranslation: PropTypes.string,
};

export default InputLabel;
