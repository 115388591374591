import React from "react";

import PropTypes from "prop-types";

import { INCOMING_CALL, MISSED_CALL, OUTGOING_CALL } from "constants/images";

const CallFrom = ({ type }) => {
  const missedCall = <img src={MISSED_CALL.SOURCE} alt="missed call" />;
  const outGoingCall = <img src={OUTGOING_CALL.SOURCE} alt="outgoing call" />;
  const incomingCall = <img src={INCOMING_CALL.SOURCE} alt="incoming call" />;

  const callIcon = (type) => {
    switch (type) {
      case "missed_call":
        return (
          <span className="missed-call" data-testid="missed-call-icon">
            {missedCall}
          </span>
        );
      case "incoming":
        return (
          <span className="incoming-call" data-testid="incoming-call-icon">
            {incomingCall}
          </span>
        );
      default:
        return (
          <span className="outgoing-call" data-testid="outgoing-call-icon">
            {outGoingCall}
          </span>
        );
    }
  };

  return callIcon(type);
};

CallFrom.defaultProps = {
  type: "",
};

CallFrom.propTypes = {
  type: PropTypes.string,
};

export default CallFrom;
