import { createSearchAction } from "redux-search";

import { ContactApi } from "internal";

import { getErrors } from "utils/utils";

export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_RECEIVED = "FETCH_CONTACTS_RECEIVED";
export const FETCH_CONTACTS_ERROR = "FETCH_CONTACTS_ERROR";

export const FETCH_CONTACT_DETAIL_REQUEST = "FETCH_CONTACT_DETAIL_REQUEST";
export const FETCH_CONTACT_DETAIL_RECEIVED = "FETCH_CONTACT_DETAIL_RECEIVED";
export const FETCH_CONTACT_DETAIL_ERROR = "FETCH_CONTACT_DETAIL_ERROR";

export const CLEAR_CONTACTS_ERRORS = " CLEAR-CONTACTS_ERRORS";
export const CONTACT_DETAIL_CLEAR = "CONTACT_DETAIL_CLEAR";
export const CONTACT_LIST_SEARCH = "CONTACT_LIST_SEARCH";

export const UPDATE_CONTACT_STATUS = "UPDATE_CONTACT_STATUS";

export const searchContact = createSearchAction("contacts");

export function fetchContactsReceived(contacts) {
  return {
    type: FETCH_CONTACTS_RECEIVED,
    contacts,
  };
}

function fetchContactsError(errorData) {
  const error = getErrors(errorData);

  return {
    type: FETCH_CONTACTS_ERROR,
    error,
  };
}

export function contactsSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: CONTACT_LIST_SEARCH,
    isSearching,
  };
}

export const fetchContactsRequest =
  (user_license_id, type) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_CONTACTS_REQUEST });

      const response = await ContactApi.fetchContacts(user_license_id, type);

      dispatch(fetchContactsReceived(response.data));
    } catch (error) {
      dispatch(fetchContactsError(error));
    }
  };

function contactDetailReceived(data) {
  return {
    type: FETCH_CONTACT_DETAIL_RECEIVED,
    data: data,
  };
}

function contactDetailError(errorData) {
  const error = getErrors(errorData);

  return {
    type: FETCH_CONTACT_DETAIL_ERROR,
    error,
  };
}

export function contactDetailClear() {
  return {
    type: CONTACT_DETAIL_CLEAR,
  };
}

export const contactDetailRequest = (id, type) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CONTACT_DETAIL_REQUEST });

    const response = await ContactApi.fetchContactDetail(id, type);

    dispatch(contactDetailReceived(response.data));
  } catch (error) {
    dispatch(contactDetailError(error));
  }
};

export const updateContactStatus = (status) => ({
  type: UPDATE_CONTACT_STATUS,
  status,
});
