import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import EmptyAgentUI from "components/Home/Empty/EmptyAgentUI";
import SearchBar from "components/SearchBar/SearchBar";
import AgentsSkeleton from "components/Skeleton/CallQueue/Detail/AgentManagementSidebar/AgentsSkeleton";

import { ConfigureQueueContext } from "context-api/ConfigureQueueContext";

import {
  searchInJson,
  SEARCH_KEY_FOR_AVAILABLE_CONFIGURE_QUEUE,
} from "utils/searchInJson";

import { fetchAvailableQueueRequest } from "redux/actions/configureQueue";

import AvailableQueueTable from "./AvailableQueueTable";

const AvailableQueues = () => {
  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);
  const { availableQueues, isAvailableQueueLoading: isLoading } = useSelector(
    ({ configureQueue }) => configureQueue
  );
  const dispatch = useDispatch();

  const { updatedAvailableQueues, setUpdatedAvailableQueues } = useContext(
    ConfigureQueueContext
  );

  const [initialValues, setInitialValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const [isSelectedID, setSelectedID] = useState(false);

  const handleAvailableQueuesSearch = (e) => {
    const searchText = e.target.value;

    setInitialValues(
      searchInJson(
        searchText,
        updatedAvailableQueues,
        SEARCH_KEY_FOR_AVAILABLE_CONFIGURE_QUEUE,
        (value) => setSearching(value)
      )
    );
  };

  useEffect(() => {
    dispatch(fetchAvailableQueueRequest(userLicenseID));
  }, []);

  useEffect(() => {
    setInitialValues(availableQueues);
    setUpdatedAvailableQueues(availableQueues);
  }, [availableQueues]);

  if (isLoading) {
    return <AgentsSkeleton />;
  }

  if (updatedAvailableQueues.length == 0 && !isSearching) {
    return (
      <EmptyAgentUI
        headingTranslation="configureQueue.availableQueue"
        titleTranslation="configureQueue.empty.emptyAvailableQueueList"
        descriptionTranslation="configureQueue.empty.thereIsNoAvailableQueueAtTheMoment"
      />
    );
  }

  return (
    <div className="available-agents">
      <div className="available-agent-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-between ">
        <p className="mb-0 available-agent-title mb-2 mb-sm-0 fw-bold">
          <Translate value="configureQueue.availableQueue" />
        </p>
        <SearchBar
          onSearchHandler={handleAvailableQueuesSearch}
          placeholderText="attributes.searchAvailableQueues"
          className="search-bar"
          isSearchDisabled={isSelectedID}
        />
      </div>
      <AvailableQueueTable
        availableQueues={initialValues}
        isSearching={isSearching}
        setSelectedID={setSelectedID}
      />
    </div>
  );
};

export default AvailableQueues;
