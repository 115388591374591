import React from "react";
import { Field, useField } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import OptionSelectDropdown from "components/Dropdown/SelectDropdown/OptionSelectDropdown";

import { useWindowDimensions } from "utils/getDimensions";
import { INCOMING_CALL_TYPE } from "utils/incomingCallAttributes";
import { required } from "utils/validation";

import ForwardTo from "./ForwardTo/ForwardTo";
import RingingDuration from "./RingingDuration";
import SipDeviceDropdown from "./SipDeviceDropdown";
import StrategyAfterRingingDuration from "./StrategyAfterRingingDuration";

const IncomingRingingText = ({ show, className }) => {
  if (!show) return null;

  return (
    <p className={`mb-0 d-none d-md-block h-50 after ${className}`}>
      <Translate value="common.after" />
    </p>
  );
};

const IncomingCallItem = ({
  input: {
    name,
    value: { device_name: deviceName, options },
  },
}) => {
  const {
    input: { value: selectedValue },
  } = useField(name);

  const {
    meta: { error, touched },
  } = useField(`${name}.selected_sip_device`);

  const {
    meta: {
      touched: ringingStrategyAfterRingingDuration,
      error: afterRingingFieldError,
    },
    input: { value: ringingStrategyValue },
  } = useField(`${name}.ringingStrategyAfterRingingDuration`);

  const { width } = useWindowDimensions();

  const showSipDevice =
    selectedValue?.selected_option?.value ===
    INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES;

  const showForwardToField =
    selectedValue?.selected_option?.value === INCOMING_CALL_TYPE.FORWARD_TO;

  const showStrategy =
    selectedValue?.selected_option?.value ===
      INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES ||
    selectedValue?.selected_option?.value ===
      INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES;

  const showAfterDescription =
    selectedValue?.selected_option?.value ===
      INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES && width > 1200;

  const sipDeviceLength = selectedValue?.selected_sip_device?.length || 0;

  const ringingStrategyWhenWithZeroSipDevice =
    sipDeviceLength === 0 &&
    ringingStrategyAfterRingingDuration &&
    afterRingingFieldError;

  const ringingStrategyAfterOneDeviceSelection =
    sipDeviceLength === 1 &&
    ringingStrategyAfterRingingDuration &&
    afterRingingFieldError;

  const ringingStrategyAfterMultipleDeviceSelection =
    sipDeviceLength > 1 &&
    ringingStrategyAfterRingingDuration &&
    afterRingingFieldError;

  const ringingStrategyValueValue =
    ringingStrategyValue?.value === INCOMING_CALL_TYPE.FORWARD_TO;

  const afterTextContainerClassName = () => {
    if (
      ringingStrategyValueValue &&
      sipDeviceLength === 0 &&
      typeof error !== "undefined" &&
      touched
    ) {
      return "forward-to-text-with-sip-error";
    }

    if (ringingStrategyValueValue && sipDeviceLength === 1) {
      return "forward-to-text-with-single-device";
    }

    if (ringingStrategyValueValue && sipDeviceLength > 1) {
      return "forward-to-text-with-multiple-device";
    }

    if (ringingStrategyValueValue) {
      return "forward-to-text";
    }

    if (ringingStrategyWhenWithZeroSipDevice) {
      return "after-ringing-error";
    }

    if (ringingStrategyAfterOneDeviceSelection) {
      return "after-ringing-error-with-device";
    }

    if (ringingStrategyAfterMultipleDeviceSelection) {
      return "after-ringing-error-with-multiple-device";
    }

    if (
      sipDeviceLength > 1 ||
      (sipDeviceLength === 0 && typeof error !== "undefined" && touched)
    ) {
      return "value";
    }

    return "default-view";
  };

  return (
    <div className="incoming-call-item row py-3 gx-0 gx-md-2">
      <label className="col-6 col-md-3 device-name mt-2 mb-2 mb-md-0 ps-0 ps-md-3">
        {deviceName}
      </label>
      <p className="col-6 col-md-2 col-xxl-1 align-self-center mb-0 text-end d-block d-md-none pe-1">
        <Translate value="common.always" />
      </p>
      <div className="col-md-9 d-flex">
        <div className="me-md-4">
          <p className="d-none d-md-block h-50 mt-2 mb-0">
            <Translate value="common.always" />
          </p>

          <IncomingRingingText
            show={showAfterDescription}
            className={afterTextContainerClassName()}
          />
        </div>
        <div className="row w-100 flex-fill">
          <Field
            name={`${name}.selected_option`}
            component={OptionSelectDropdown}
            validate={required("validationErrors.REQUIRED")}
            options={options}
            containerClassName="col-xl-6 mb-3 fw-normal flex-column justify-content-start"
            activeClassName="selected-call-routing px-2"
            placeholderTranslation="attributes.selectAnOption"
            menuClassName="incoming-call-menu py-0"
            className="incoming-call-menu-item"
            errorClassName="align-self-start ms-2"
            disableRemoveSelectedOption
            hideSelectedValue
          />
          <SipDeviceDropdown
            show={showSipDevice}
            name={`${name}.selected_sip_device`}
            options={selectedValue?.sipDevices}
            validateOptions
          />
          <ForwardTo
            show={showForwardToField}
            name={`${name}.forwardTo`}
            deviceName={deviceName}
          />
          <RingingDuration
            show={showStrategy}
            name={`${name}.ringing_duration`}
            isSipDevice={showSipDevice}
          />
          <StrategyAfterRingingDuration
            name={name}
            show={showStrategy}
            deviceName={deviceName}
          />
        </div>
      </div>
    </div>
  );
};

IncomingRingingText.propTypes = {
  show: PropTypes.bool,
  isSipDevice: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

IncomingCallItem.defaultProps = {
  value: {},
  className: "",
};

IncomingCallItem.propTypes = {
  deviceName: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.shape({
      device_name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      options: PropTypes.array,
    }),
  }),
};

export default IncomingCallItem;
