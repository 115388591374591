import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Translate, I18n } from "react-thunk-i18nify";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import FormWrapper from "components/Form/FormWrapper";
import InputField from "components/Form/InputField/InputField";
import PasswordField from "components/Form/PasswordField/PasswordField";

import { AUTH } from "constants/routes";

import { displayError } from "utils/toaster";
import {
  composeValidators,
  emailValidation,
  minValuePassword,
  required,
  validation,
} from "utils/validation";

import { loginRequest, resetPasswordClearErrors } from "redux/actions/auth";

const LoginForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [keepMeSignedIn, setSignedIn] = useState(true);

  const onSubmit = ({ username, password }) => {
    dispatch(loginRequest(username, password, keepMeSignedIn));
  };

  const { isLoading, fetchError } = useSelector(({ auth }) => auth);

  const handleNavigation = () => {
    history.push(AUTH.FORGOT_PASSWORD);
  };

  useEffect(() => {
    if (fetchError && !isLoading) {
      displayError(I18n.t(fetchError));
    }
  }, [fetchError]);

  useEffect(() => () => dispatch(resetPasswordClearErrors()), []);

  return (
    <>
      <div className="w-100 h-100 mt-2" data-testid="login-form">
        <h4 className="mb-4 pb-2 login-header fw-bold">
          <Translate value="auth.signIn.signInToConnectedWithPBX" />
        </h4>
      </div>
      <FormWrapper
        onSubmitHandler={onSubmit}
        validation={(values) => validation(values)}
        isLoading={isLoading}
        showCancelButton={false}
        submitClassName="btn btn-primary w-100 sign-in-button my-3"
        submitText="common.signIn"
        formClassName="p-0"
      >
        <div className="mb-3 w-100 position-relative">
          <Field
            name="username"
            validate={composeValidators(emailValidation)}
            component={InputField}
            placeholderTranslation="attributes.emailAddress"
            labelTranslation="attributes.emailAddress"
            inputFieldIcon={faEnvelope}
          />
        </div>
        <div className="mb-3 w-100 position-relative">
          <Field
            name="password"
            validate={composeValidators(
              required("validationErrors.INVALID_PASSWORD"),
              minValuePassword(8)
            )}
            component={PasswordField}
            placeholderTranslation="attributes.password"
            labelTranslation="attributes.password"
          />
        </div>

        <div className="w-100 d-flex flex-column flex-sm-row justify-content-between mb-2 pb-2 mx-1 align-items-sm-center">
          <div className="form-check mt-1 mt-sm-0 ps-3">
            <input
              type="checkbox"
              className="form-check-input checked me-2 mb-1"
              id="flexCheckDefault"
              data-cy="keepMeSignedIn"
              data-testid="keep-me-signed-in"
              checked={keepMeSignedIn}
              value={keepMeSignedIn}
              onClick={() => setSignedIn(!keepMeSignedIn)}
            />

            <label
              className="form-check-label keep-me-signed-in"
              htmlFor="flexCheckDefault"
            >
              <Translate value="auth.signIn.keepMeSignedIn" />
            </label>
          </div>
          <a
            className="forgot-password mt-2 mt-sm-0 link"
            type="button"
            onClick={handleNavigation}
            data-testid="forgot-password-link"
          >
            <Translate value="auth.signIn.forgetPassword" />
          </a>
        </div>
      </FormWrapper>
    </>
  );
};

export default LoginForm;
