import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  CHANGE_PASSWORD: `${apiUrlConfig.defaultEndPoint()}/user_licenses/:user_license_id?profile_update=true`,
};

class ChangePasswordApi extends AuthenticatedRequest {
  static updatePassword(userLicenseId, attributes) {
    const UPDATE_URL = URLS.CHANGE_PASSWORD.replace(
      ":user_license_id",
      userLicenseId
    );

    return this.patch(UPDATE_URL, attributes, true);
  }
}

export default ChangePasswordApi;
