import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { NO_CALL_QUEUE } from "constants/images";

const NoCallQueueContent = ({
  titleTranslation,
  descriptionTranslate,
  descriptionClassName,
  queueID,
}) => (
  <article
    className="empty-content no-call-queue overflow-auto d-flex align-items-center px-2 py-2"
    data-cy={`no-call-queue-content-${queueID}`}
  >
    <img
      src={NO_CALL_QUEUE.SOURCE}
      alt="no call queue in the list"
      className="me-2"
    />
    <div>
      <p className="title mb-0 fw-bold">
        <Translate value={titleTranslation} />
      </p>
      <p className={`${descriptionClassName} no-agent-detail mb-0`}>
        <Translate value={descriptionTranslate} />
      </p>
    </div>
  </article>
);

NoCallQueueContent.defaultProps = {
  titleTranslation: "callQueue.empty.noRegisteredAgents",
  descriptionTranslate: "callQueue.empty.descriptionNoAgent",
  descriptionClassName: "col-md-10 col-lg-8 col-xl-6",
};

NoCallQueueContent.propTypes = {
  titleTranslation: PropTypes.string,
  descriptionTranslate: PropTypes.string,
  descriptionClassName: PropTypes.string,
  queueID: PropTypes.number.isRequired,
};

export default NoCallQueueContent;
