import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import AgentManagementSidebar from "components/Home/QueueDashboard/AgentManagement/AgentManagementSidebar";
import CallQueueStatistics from "components/Home/QueueDashboard/CallQueueItem/CallQueueStatistics/CallQueueStatistics";
import RegisteredAgents from "components/Home/QueueDashboard/CallQueueItem/RegisteredAgents/RegisteredAgents";
import TotalCallsTable from "components/Home/QueueDashboard/CallQueueItem/TotalCallsInQueue/TotalCallsTable";

import HasPermission from "hoc/AccessPermission";

import AgentManagementProvider from "context-api/AgentManagementContext";

import { ACTION_LIST } from "constants/userPermission";

import { displaySuccess } from "utils/toaster";

import {
  fetchDeviceInQueueRequest,
  fetchRegisterAgentsRequest,
  fetchRegisteredAgentsInQueueRequest,
  silentFetchRegisteredAgentsRequest,
} from "redux/actions/callQueue";

import ConfigureDevices from "./ConfigureDevices/ConfigureDevices";

const CallQueueItem = ({ id, name }) => {
  const dispatch = useDispatch();

  const [openAgentManagement, setToggleAgentManagement] = useState(false);

  const {
    currentUser: { pbx_id: pbxID, user_license_id: userLicenseID },
    isPbxAdmin,
  } = useSelector(({ auth }) => auth);

  const { callQueues } = useSelector(({ callQueue }) => callQueue);
  const {
    registeredAgents,
    isProcessing,
    totalDevices,
    isTotalDevicesLoading,
    commitSuccess,
  } = callQueues.find(({ asterisk_queue_id: queueID }) => queueID == id);

  useEffect(() => {
    dispatch(fetchRegisterAgentsRequest(pbxID, id));
    dispatch(fetchRegisteredAgentsInQueueRequest(pbxID, id));
    dispatch(fetchDeviceInQueueRequest(userLicenseID, id));
  }, []);

  useEffect(() => {
    if (isPbxAdmin) return;
    if (!commitSuccess) return;

    displaySuccess(commitSuccess);
    dispatch(silentFetchRegisteredAgentsRequest(pbxID, id));
    dispatch(fetchDeviceInQueueRequest(userLicenseID, id));
  }, [commitSuccess]);

  return (
    <div
      className="call-queue-item col-12 col-lg-6"
      data-cy={`call-queue-item-${id}`}
    >
      <div className="bg-white w-100 edit-call-queue-item">
        <div className="call-queue-title p-3 d-flex justify-content-between align-items-center">
          <p className="queue-name fw-bold mb-0" data-cy={`queue-name-${id}`}>
            {name}
          </p>
          <ConfigureDevices
            totalDevices={totalDevices}
            queueID={id}
            isTotalDevicesLoading={isTotalDevicesLoading}
          />
        </div>
        <CallQueueStatistics queueID={id} />
        <TotalCallsTable queueID={id} />
        <RegisteredAgents
          registeredAgents={registeredAgents}
          isProcessing={isProcessing}
          setToggleAgentManagement={setToggleAgentManagement}
          queueID={id}
        />
        <HasPermission perform={ACTION_LIST.VIEW_AGENT_MANAGEMENT}>
          <AgentManagementProvider>
            <AgentManagementSidebar
              open={openAgentManagement}
              setOpen={setToggleAgentManagement}
              queueID={id}
              name={name}
            />
          </AgentManagementProvider>
        </HasPermission>
      </div>
    </div>
  );
};

CallQueueItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default CallQueueItem;
