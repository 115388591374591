import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import AutomaticCallProfileToggler from "components/AutomaticCallProfileToggler/AutomaticCallProfileToggler";
import ActiveCallProfileDropdown from "components/Dropdown/ActiveCallProfile/ActiveCallProfileDropdown";
import LanguageToggler from "components/LanguageToggler/LanguageToggler";
import Menu from "components/Menu/Menu";

import { PBX_USER_BRAND_LOGO } from "constants/images";
import { HOME } from "constants/routes";

const Sidebar = ({ toggleMobileMenu, setToggleMobileMenu }) => {
  const [collapse, setCollapse] = useState(true);
  const [showText, setShowText] = useState(false);

  const icon = collapse ? faArrowRight : faArrowLeft;
  const showMenu = toggleMobileMenu ? "mobile-toggle" : "";
  const showCloseIcon = toggleMobileMenu ? "d-block" : "d-none";
  const collapseSideBar = collapse ? "collapsed" : "";

  const handleCollapse = () => {
    setCollapse((val) => !val);
    if (showText) {
      setTimeout(() => {
        setShowText(false);
      }, 400);
    } else {
      setShowText(true);
    }
  };

  const closeMobileMenu = () => {
    setToggleMobileMenu(false);
  };

  useEffect(() => {
    if (toggleMobileMenu) {
      setCollapse(false);
      setShowText(true);
    } else if (!collapse) {
      setTimeout(() => {
        setCollapse(true);
        setShowText(false);
      }, 350);
    }
  }, [toggleMobileMenu]);

  return (
    <aside
      className={`sidebar-container ${collapseSideBar} ${showMenu} mh-100 w-100 h-100 `}
    >
      <div className=" sidebar d-flex flex-column justify-content-between h-100 pe-3 py-4 overflow-auto">
        <div className="d-flex align-items-center d-lg-none mb-3 ms-2">
          <FontAwesomeIcon
            icon={faTimes}
            className={`close mb-0 me-2 mt-2 ${showCloseIcon}`}
            size="2x"
            onClick={closeMobileMenu}
          />
          <Link to={HOME.INDEX}>
            <img
              src={PBX_USER_BRAND_LOGO.SOURCE}
              alt="brand-logo"
              className="mb-0 ms-2"
            />
          </Link>
        </div>
        <Menu
          hideTitle={collapse || !showText}
          closeMobileMenu={closeMobileMenu}
        />

        <div className="flex-fill d-flex flex-column justify-content-end ps-2 mb-3">
          <ActiveCallProfileDropdown
            containerClassName="d-block d-md-none justify-content-center mb-4 dropup"
            activeValueClassName="w-100 sidebar-active-call-profile"
            callback={closeMobileMenu}
            showActiveProfileTitle
          />
          <div className="my-2 d-block d-md-none">
            <AutomaticCallProfileToggler />
          </div>
          <div onClick={closeMobileMenu} role="button">
            <LanguageToggler showText={showText} />
          </div>
        </div>
      </div>
      <button
        className="btn collapse-button position-absolute p-0 d-none d-lg-block"
        onClick={handleCollapse}
      >
        <FontAwesomeIcon icon={icon} className="arrow-icon text-center" />
      </button>
    </aside>
  );
};

Sidebar.defaultProps = {
  toggleMobileMenu: false,
  setToggleMobileMenu: () => {},
};

Sidebar.propTypes = {
  toggleMobileMenu: PropTypes.bool,
  setToggleMobileMenu: PropTypes.func,
};

export default Sidebar;
