import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActivePasswordStatus from "components/ChangePassword/ActivePasswordStatus";
import PasswordTitle from "components/ChangePassword/PasswordTitle";

import { clearChangePasswordError } from "redux/actions/changePassword";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { commitSuccess } = useSelector(({ changePassword }) => changePassword);

  useEffect(() => () => dispatch(clearChangePasswordError()), []);

  return (
    <div
      className="change-password flex-fill h-100 layout-spacing overflow-auto"
      role="document"
    >
      <div className="card border-0 h-100 p-0">
        <div className="p-4 pb-0 bg-white">
          <PasswordTitle commitSuccess={commitSuccess} />
        </div>

        <div className="row flex-fill bg-white p-4 m-0">
          <ActivePasswordStatus commitSuccess={commitSuccess} />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
