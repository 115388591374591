import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { I18n, I18nReRender, Translate } from "react-thunk-i18nify";

import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatISO } from "date-fns";
import PropTypes from "prop-types";

import CustomDatePicker from "components/Form/CustomDatePicker/CustomDatePicker";
import TimePicker from "components/Form/TimePicker/TimePicker";
import MovingDottedLoader from "components/Loader/MovingDottedLoader";

import { displaySuccess } from "utils/toaster";

import {
  clearNoteError,
  fetchNoteReceived,
  noteUpdateRequest,
} from "redux/actions/note";

const ShowErrorMessage = ({ show, isTimeNotAdded }) => {
  if (!show) return null;

  if (!isTimeNotAdded) {
    return (
      <p className="error-time mb-0 mx-0 mx-md-2">
        <Translate value="contact.note.expiryTime" />
      </p>
    );
  }

  return (
    <p className="error-time mb-0 mx-0 mx-md-2">
      <Translate value="contact.note.cannotBeBlank" />
    </p>
  );
};

const ErrorPlacement = ({ show }) => {
  if (!show) return null;

  return <div className="error-placement mb-0 invisible d-none d-lg-block" />;
};

const Note = ({ text, setText, note }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timeError, setTimeError] = useState(false);
  const [checkNoteExpire, setNoteExpire] = useState(false);
  const [isNoteEdited, setNoteEdited] = useState(false);
  const [isClearing, setClearing] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const textFieldRef = useRef(null);

  const {
    note: updatedNote,
    commitSuccess,
    isLoading,
    error,
  } = useSelector(({ note }) => note);

  const handleUpdateNote = async (date, time) => {
    if (date && !time) return setTimeError(true);
    setLoading(true);

    let updatedDate = "";
    let updatedTime = "";

    if (date) {
      updatedDate = formatISO(date).split("T");
      updatedDate = updatedDate[0];
    }

    if (time) {
      updatedTime = formatISO(time).split("T");

      updatedTime = `${updatedDate}T${updatedTime[1]}`;
    }

    const data = {
      description: text,
      expiry_date: updatedDate,
      expiry_time: updatedTime,
    };

    dispatch(noteUpdateRequest(note.id, data));
  };

  useEffect(() => {
    if (note) {
      dispatch(fetchNoteReceived(note));
    }
  }, [note]);

  const handleClearDateAndTime = () => {
    setTime("");
    setDate("");
    setNoteEdited(true);
  };

  const checkNoteUpdate = (condition, firstValue, secondValue) => {
    if (condition) {
      return firstValue;
    }

    return secondValue;
  };

  const handleTextBoxClear = () => {
    textFieldRef.current.value = "";
    setText("");
    setDate("");
    setTime("");

    if (updatedNote) {
      if (
        updatedNote.description === text ||
        (updatedNote.description && text)
      ) {
        setClearing(true);

        const data = {
          description: "",
          expiry_date: "",
          expiry_time: "",
        };

        dispatch(noteUpdateRequest(note.id, data));
      }
    }
  };

  const validateSelectedDateTime = (time, date) => {
    if (!time) return;
    let updatedDate = "";
    let updatedTime = "";

    if (date) {
      updatedDate = formatISO(date).split("T");
      updatedDate = updatedDate[0];
    }

    updatedTime = formatISO(time).split("T");
    updatedTime = `${updatedDate}T${updatedTime[1]}`;

    updatedTime = new Date(updatedTime).toUTCString();

    if (
      new Date(updatedTime).getTime() > Date.now() ||
      !updatedDate ||
      !updatedTime
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setTimeError(validateSelectedDateTime(time, date));
  }, [time, date]);

  useEffect(() => {
    if (updatedNote) {
      setText(updatedNote.description);
      if (updatedNote?.expiry_date) {
        setDate(new Date(updatedNote.expiry_date));
      }
      if (updatedNote?.expiry_time) {
        setTime(new Date(updatedNote.expiry_time));
      }
    }

    if (checkNoteExpire) return setNoteExpire(false);

    if (commitSuccess) {
      setNoteEdited(false);
      displaySuccess(commitSuccess);
      setClearing(false);
      setLoading(false);
    }
  }, [updatedNote, commitSuccess]);

  useEffect(() => {
    if (error) {
      setClearing(false);
      setText(updatedNote.description);
    }
  }, [error]);

  useEffect(() => () => dispatch(clearNoteError()), []);

  const handleNoteChanges = (e) => {
    setText(e.target.value);
    setNoteEdited(true);
  };

  const disabledNote = () => {
    if (timeError || isLoading) return true;

    if (
      text === updatedNote?.description &&
      time?.getTime?.() == new Date(updatedNote?.expiry_time)?.getTime?.() &&
      date?.getTime?.() == new Date(updatedNote?.expiry_date)?.getTime?.()
    )
      return true;

    if (!isNoteEdited) return true;

    if (!text) return true;

    return false;
  };

  const clearTextClassName = isClearing ? "me-3" : "";
  const saveContainerClassName = !timeError ? "align-items-xl-center" : "";
  const expiryTimeClassName = date || time ? "" : "d-none";

  return (
    <div className="note-container">
      <div>
        <div className="d-flex justify-content-between align-items-center py-2 pe-2 title-container">
          <label
            data-cy="note-title"
            htmlFor="notepad"
            className="form-label note-title mx-3 mb-0 fw-bold"
          >
            <Translate value="common.note" />
          </label>
          <button
            data-cy="clear-note-button"
            className={`btn clear-note-button ${checkNoteUpdate(
              updatedNote?.description || text,
              "clear",
              ""
            )} border-0 p-2 d-flex  align-items-center me-3`}
            onClick={handleTextBoxClear}
            disabled={!text}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              className={`me-1 ${checkNoteUpdate(
                updatedNote?.description || text,
                "clear",
                ""
              )}`}
            />{" "}
            <span className={`text-nowrap ${clearTextClassName}`}>
              <Translate value="common.clearNote" />
            </span>
            <MovingDottedLoader show={isClearing} />
          </button>
        </div>

        <div className="note my-0">
          <div className="px-md-4">
            <I18nReRender
              render={() => (
                <textarea
                  defaultValue={updatedNote?.description}
                  className="form-control note-pad border-0"
                  id="notepad"
                  data-cy="user-notepad"
                  rows="4"
                  ref={textFieldRef}
                  onChange={handleNoteChanges}
                  placeholder={I18n.t("contact.note.addYourNoteHere")}
                />
              )}
            />
          </div>

          <div
            className={`action-container border-start-0 border-end-0 border-bottom-0 d-flex justify-content-between ${saveContainerClassName} flex-column flex-xl-row px-md-4 py-2`}
          >
            <div className="d-flex align-items-lg-center flex-column flex-lg-row gap-2 gap-lg-0">
              <div>
                <p className="mb-0 expire-in fw-normal">
                  <Translate value="common.expireIn" />
                </p>
                <ErrorPlacement show={timeError} />
              </div>
              <div className="d-flex flex-wrap">
                <div className="ms-0 ms-md-2 mx-2">
                  <CustomDatePicker
                    date={date}
                    setDate={setDate}
                    callback={() => setNoteEdited(true)}
                  />
                </div>
                <div className="my-2 me-2">
                  <div className="mx-0 mx-md-2">
                    <TimePicker
                      time={time}
                      setTime={setTime}
                      callback={() => setNoteEdited(true)}
                      isDisabled={!date}
                    />
                  </div>
                  <ShowErrorMessage
                    show={timeError}
                    isTimeNotAdded={Boolean(date && !time)}
                  />
                </div>
                <button
                  data-cy="remove-expiry-time"
                  className={`btn btn-outline-danger remove-expiry-time ${expiryTimeClassName} my-2 ms-2 ms-sm-0`}
                  onClick={handleClearDateAndTime}
                  disabled={isLoading}
                >
                  <Translate value="contact.note.removeExpireNote" />
                </button>
              </div>
            </div>

            <button
              data-cy="submit-note-button"
              className={`btn btn-primary save-note-button ${checkNoteUpdate(
                updatedNote?.description,
                "update",
                ""
              )} border-0 mt-3 mt-xl-0 px-5`}
              disabled={disabledNote()}
              onClick={() => handleUpdateNote(date, time)}
            >
              <MovingDottedLoader show={isLoading && loading} />
              <Translate
                value={`common.${checkNoteUpdate(
                  updatedNote?.description,

                  "updateNote",
                  "saveNote"
                )}`}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPlacement.propTypes = {
  show: PropTypes.bool.isRequired,
};

ShowErrorMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  isTimeNotAdded: PropTypes.bool.isRequired,
};

Note.defaultProps = {
  text: "",
  setText: () => {},
  note: {},
};

Note.propTypes = {
  text: PropTypes.string,
  setText: PropTypes.func,
  note: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    expiry_date: PropTypes.string,
    expiry_time: PropTypes.string,
  }),
};

export default Note;
