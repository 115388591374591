import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTable, useRowSelect } from "react-table";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import EmptyTableHeader from "components/EmptyContent/EmptyTableHeader";
import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import AgentField from "components/Home/QueueDashboard/AgentManagement/AgentField";
import Table from "components/Table/Table";
import TableCheckBox from "components/Table/TableCheckBox";
import TableHeader from "components/Table/TableHeader";

import { ConfigureQueueContext } from "context-api/ConfigureQueueContext";

import { AVAILABLE_QUEUE } from "constants/tableHeader";

import AvailableQueueSelection from "./AvailableQueueSelection";

const AvailableQueueTable = ({
  availableQueues,
  isSearching,
  setSelectedID,
}) => {
  const [tableData, setTableData] = useState([]);

  const {
    setUpdatedHomeQueues,
    updatedAvailableQueues,
    setUpdatedAvailableQueues,
    isFormEdited,
    setFormEdited,
  } = useContext(ConfigureQueueContext);

  const updateFormEdited = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  const addQueueToHomeHandler = (queueID) => {
    updateFormEdited();
    const homeQueue = updatedAvailableQueues.find(
      ({ asterisk_queue_id: id }) => id === queueID
    );

    setUpdatedAvailableQueues((prev) =>
      prev.filter(({ asterisk_queue_id: id }) => id !== queueID)
    );

    setUpdatedHomeQueues((prev) => [
      ...prev,
      { ...homeQueue, weight: prev.length + 1 },
    ]);
  };

  const handleUpdateTableData = (data) =>
    data.map(({ asterisk_queue_id: queueID, name }) => ({
      queue: <p className="queue-name mb-0">{name}</p>,
      action: (
        <button
          className="btn btn-outline-primary add-to-home-button"
          type="button"
          onClick={() => addQueueToHomeHandler(queueID)}
        >
          <Translate value="configureQueue.addToHome" />
        </button>
      ),
      availableQueue: {
        asterisk_queue_id: queueID,
        name,
      },
    }));

  useEffect(() => {
    if (availableQueues.length === 0) return setTableData([]);
    setTableData(handleUpdateTableData(availableQueues));
  }, [availableQueues]);

  useEffect(() => {
    setTableData(handleUpdateTableData(updatedAvailableQueues));
  }, [updatedAvailableQueues]);

  const tableInstance = useTable(
    {
      columns: AVAILABLE_QUEUE,
      data: tableData,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "availableQueues",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <TableCheckBox {...getToggleAllRowsSelectedProps()} />
          ),

          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <TableCheckBox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  useEffect(() => {
    setSelectedID(Boolean(selectedFlatRows.length));
  }, [selectedFlatRows]);

  const handleQueueSelection = () => {
    let newTableData;

    updateFormEdited();

    selectedFlatRows.map((device) => {
      const { asterisk_queue_id: queueID } = device.original.availableQueue;

      newTableData = tableData.filter(
        ({ availableQueue: { asterisk_queue_id: id } }) => id !== queueID
      );

      setUpdatedAvailableQueues((prev) =>
        prev.filter(({ asterisk_queue_id: id }) => id !== queueID)
      );

      const homeQueue = updatedAvailableQueues.find(
        ({ asterisk_queue_id: id }) => id === queueID
      );

      setUpdatedHomeQueues((prev) => [
        ...prev,
        { ...homeQueue, weight: prev.length + 1 },
      ]);
    });

    setTableData(newTableData);
  };

  return (
    <div className="available-queue-table pt-3 overflow-auto">
      <EmptyTableHeader show={!tableData.length && !isSearching} />
      <Table
        getTableProps={getTableProps}
        className="available-agent-table-list mb-0 px-3"
      >
        <TableHeader
          headerGroups={headerGroups}
          show={tableData.length}
          columnClassName="table-header-contain fw-normal"
        />
        <AgentField
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Table>
      <EmptyTableUI
        records={availableQueues}
        isSearching={isSearching}
        searchTitle="callQueue.empty.noAvailableQueue"
        searchDescription="callQueue.empty.searchAvailableQueueNotFound"
      />
      <AvailableQueueSelection
        selectedQueues={selectedFlatRows.length}
        handleQueueSelection={handleQueueSelection}
      />
      <hr className="mt-0 separator" />
    </div>
  );
};

AvailableQueueTable.defaultProps = {
  availableQueues: [],
  getToggleAllRowsSelectedProps: () => {},
  row: {
    getToggleRowSelectedProps: () => {},
  },
  setUpdatedValueCount: () => {},
  isSearching: false,
  setSelectedID: () => {},
};

AvailableQueueTable.propTypes = {
  availableQueues: PropTypes.shape([]),
  getToggleAllRowsSelectedProps: PropTypes.func,
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func,
  }),
  setUpdatedValueCount: PropTypes.func,
  isSearching: PropTypes.bool,
  setSelectedID: PropTypes.func,
};

export default AvailableQueueTable;
