import React from "react";
import Skeleton from "react-loading-skeleton";

import ProfileHeaderSkeleton from "./ProfileHeaderSkeleton";

const EditProfileSkeleton = () => (
  <div className="layout-spacing overflow-auto h-100 mh-100">
    <div className="d-flex flex-column bg-white pb-4 h-100">
      <div className="bg-white px-4 d-flex flex-fill pt-3">
        <ProfileHeaderSkeleton />
      </div>
      <div className="flex-fill bg-white px-4 w-100">
        <div className="row justify-content-between">
          <div className="mb-3 col-12 col-md-6">
            <Skeleton height={25} width={120} className="mb-3" />
            <Skeleton className="w-100" height={50} />
          </div>

          <div className="mb-3 col-12 col-md-6">
            <Skeleton height={25} width={120} className="mb-3" />
            <Skeleton className="w-100" height={50} />
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-end mt-md-3">
            <div className="mb-3 col-12 col-md-1 mx-md-2">
              <Skeleton height={55} className="w-100" />
            </div>
            <div className="mb-3 col-12 col-md-2">
              <Skeleton height={55} className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EditProfileSkeleton;
