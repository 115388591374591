import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { faVoicemail } from "@fortawesome/free-solid-svg-icons";

import InformationBoxWrapper from "layouts/InformationBoxWrapper/InformationBoxWrapper";

import SearchBar from "components/SearchBar/SearchBar";
import CellPhoneVoicemailBoxSkeleton from "components/Skeleton/VoicemailBox/CellPhoneVoicemailBoxSkeleton";

import filterSearchResults from "utils/filterSearchResults";

import {
  searchVoicemailBoxesCellPhone,
  voicemailBoxesSearchRequest,
} from "redux/actions/voicemailBox";

import CellPhoneTable from "./CellPhoneTable";

const CellPhoneVoicemailBoxes = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(null);
  const [data, setData] = useState([]);

  const { cellPhones, isSearching, isCellPhoneLoading } = useSelector(
    ({ voicemailBox }) => voicemailBox
  );

  const search = useSelector(({ search }) => search);

  const updatedCellPhones = isSearching ? data : cellPhones;
  const searchBarClassName = !cellPhones.length && !isSearching ? "d-none" : "";

  const handleVoiceBoxSearch = (e) => {
    dispatch(searchVoicemailBoxesCellPhone(e.target.value));

    setSearchText(e.target.value);
  };

  const fetchSearchResults = useCallback(() => {
    dispatch(voicemailBoxesSearchRequest(searchText));
    const result = filterSearchResults({
      resource: cellPhones,
      searchResult: search.cellPhones,
    });

    if (searchText === "") {
      setSearchText(null);
    }

    setData(result);
  }, [search.cellPhones]);

  useEffect(() => {
    fetchSearchResults();
  }, [search.cellPhones]);

  if (isCellPhoneLoading) {
    return <CellPhoneVoicemailBoxSkeleton />;
  }

  return (
    <article className="cell-phone-voicemail-boxes py-3 px-3 bg-white">
      <InformationBoxWrapper
        title="voicemailBoxes.general.cellPhoneVoicemailBoxes"
        titleIcon={faVoicemail}
        headerClassName="flex-wrap"
        titleClassName="information-search"
        titleContent={
          <SearchBar
            onSearchHandler={handleVoiceBoxSearch}
            placeholderText="attributes.searchCellPhoneNumber"
            className={`cell-number-search-bar px-0 ps-md-3 ms-md-auto ${searchBarClassName}`}
          />
        }
      >
        <CellPhoneTable cellPhones={updatedCellPhones} />
      </InformationBoxWrapper>
    </article>
  );
};

export default CellPhoneVoicemailBoxes;
