import React from "react";
import Skeleton from "react-loading-skeleton";

import PropTypes from "prop-types";

import { LIGHT_GREY } from "constants/skeletonColor";

const TitleContent = ({ children }) => {
  if (!children) return null;

  return (
    <div className="mt-2 mt-md-0 d-flex align-items-center">{children}</div>
  );
};

const ToolTipIcon = ({ showTooltip }) => {
  if (!showTooltip) return null;

  return <Skeleton circle width={20} height={20} baseColor={LIGHT_GREY} />;
};

const InformationBoxWrapperSkeleton = ({
  children,
  titleContent,
  showTooltip,
}) => (
  <div className="information-box-wrapper">
    <div className="header d-flex justify-content-between align-items-center p-2 flex-wrap">
      <div className="d-flex align-items-center px-2">
        <Skeleton
          circle
          width={30}
          height={30}
          className="mt-2 mt-md-0"
          baseColor={LIGHT_GREY}
        />
        <Skeleton
          width={120}
          height={20}
          className="mx-2 mb-0"
          baseColor={LIGHT_GREY}
        />
        <ToolTipIcon showTooltip={showTooltip} baseColor={LIGHT_GREY} />
      </div>
      <TitleContent>{titleContent}</TitleContent>
    </div>
    <div className="content">{children}</div>
  </div>
);

ToolTipIcon.defaultProps = {
  children: null,
  showTooltip: false,
};

ToolTipIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showTooltip: PropTypes.bool,
};

TitleContent.defaultProps = {
  children: null,
};

TitleContent.propTypes = {
  children: PropTypes.node,
};

InformationBoxWrapperSkeleton.defaultProps = {
  title: "",
  titleContent: null,
  tooltipContent: null,
  showTooltip: false,
};

InformationBoxWrapperSkeleton.propTypes = {
  children: PropTypes.node.isRequired,
  titleContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showTooltip: PropTypes.bool,
};

export default InformationBoxWrapperSkeleton;
