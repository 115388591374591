import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { isEmpty } from "utils/isEmpty";
import { displayError, displaySuccess } from "utils/toaster";

import {
  clearProfileErrors,
  profilePictureUpdateRequest,
} from "redux/actions/userProfile";

import ProfileImage from "./ProfileImage";

const ProfileImageContainer = ({ showEditProfileButton, isImageLoading }) => {
  const dispatch = useDispatch();

  const {
    contactDetailData: {
      debtor_name: debtorName,
      profile_picture: profilePicture,
    },
  } = useSelector(({ contact }) => contact);
  const { profile, error, commitSuccess } = useSelector(
    ({ userProfile }) => userProfile
  );
  const {
    currentUser: { user_license_id: userLicenseId, id: userId },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
    } else if (error) {
      displayError(error);
    }
  }, [profile, error]);

  useEffect(() => () => dispatch(clearProfileErrors()), []);

  const handleProfileUpload = (e) => {
    const formData = new FormData();

    formData.append("user_license[user_attributes][id]", userId);
    formData.append(
      "user_license[user_attributes][profile_picture]",
      e.target.files[0]
    );

    dispatch(profilePictureUpdateRequest(userLicenseId, formData));
  };

  const showUserDetail = showEditProfileButton ? (
    <div className="mt-2">
      <h4 data-cy="profile_name" className="mb-0 text-center fw-bold name">
        {profile.name}
      </h4>
      <h5 data-cy="debtor_name" className="text-center">
        {debtorName || profile.debtor_name}
      </h5>
    </div>
  ) : (
    ""
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <ProfileImage
        source={profile?.profile_picture?.url || profilePicture?.url}
        handleProfileUpload={handleProfileUpload}
        profilePictureIsEmpty={isEmpty(profilePicture) && isEmpty(profile)}
        showUploadFeature={showEditProfileButton}
        isImageLoading={isImageLoading}
      />
      {showUserDetail}
    </div>
  );
};

ProfileImageContainer.defaultProps = {
  showEditProfileButton: false,
  isImageLoading: false,
};

ProfileImageContainer.propTypes = {
  showEditProfileButton: PropTypes.bool,
  isImageLoading: PropTypes.bool,
};

export default ProfileImageContainer;
