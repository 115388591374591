import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import SelectDropdown from "components/Dropdown/SelectDropdown/SelectDropdown";

import ActiveSelectValue from "./ActiveSelectValue";

const IconDropdown = ({
  input: { onChange, value, onBlur, name },
  meta: { touched, error, submitError },
  options,
  className,
  containerClassName,
  activeClassName,
  labelTranslation,
  placeholderTranslation,
  errorClassName,
}) => {
  const getClassName = useMemo(
    () => (error, submitError, touched) => {
      if ((error || submitError) && touched) {
        return "error text-danger";
      } else if (value) {
        return "active";
      }

      return "";
    },
    [error, submitError, touched]
  );

  const checkActiveSelectedIcon = useMemo(
    () => (brandName, iconName) =>
      value[0] == brandName && value[1] == iconName,
    [value]
  );

  const onChangeHandler = (brandName, iconName) => {
    if (value[0] == brandName && value[1] == iconName) {
      onChange({ target: { value: "" } });
    } else {
      onChange({ target: { value: [brandName, iconName] } });
    }
  };

  const activeElementClassName = useMemo(
    () => (brandName, iconName) =>
      checkActiveSelectedIcon(brandName, iconName) ? "active fw-bold" : "",
    [value]
  );

  return (
    <>
      <SelectDropdown
        input={{ value, onBlur, name }}
        meta={{ touched, error, submitError }}
        containerClassName={containerClassName}
        activeClassName={activeClassName}
        labelTranslation={labelTranslation}
        menuClassName="icon-dropdown py-2"
        errorClassName={errorClassName}
        activeElement={
          <>
            <ActiveSelectValue
              checkError={() => getClassName(error, submitError, touched)}
              placeholderTranslation={placeholderTranslation}
              value={value}
            />
          </>
        }
      >
        {options.map(({ brand_name: brandName, icon_name: iconName, id }) => (
          <li key={id}>
            <button
              type="button"
              className={`dropdown-item ${className} ${activeElementClassName(
                brandName,
                iconName
              )} py-0 px-3 d-flex justify-content-between align-items-center`}
              onClick={() => onChangeHandler(brandName, iconName)}
              data-cy={`${name}-${iconName}`}
            >
              <input
                type="checkbox"
                className="form-check-input"
                checked={checkActiveSelectedIcon(brandName, iconName)}
                onChange={() => {}}
              />
              <FontAwesomeIcon
                icon={[brandName, iconName]}
                className="ms-2 profile-icon"
              />
            </button>
          </li>
        ))}
      </SelectDropdown>
    </>
  );
};

IconDropdown.defaultProps = {
  options: [],
  className: "",
  containerClassName: "px-3",
  activeClassName: "fw-bold",
  labelTranslation: "",
  placeholderTranslation: "",
  errorClassName: "",
};

IconDropdown.propTypes = {
  options: PropTypes.array,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
    ]),
    onBlur: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    submitError: PropTypes.bool,
  }),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  labelTranslation: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default IconDropdown;
