import React from "react";

import PropTypes from "prop-types";

const MovingDottedLoader = ({ show }) => {
  if (!show) return null;

  return (
    <span
      data-testid="moving-dotted-loader"
      className="moving-loading-dots w-100 text-center me-2"
    >
      <span className="dot d-inline-block" />
      <span className=" dot d-inline-block mx-1" />
      <span className="dot d-inline-block" />
    </span>
  );
};

MovingDottedLoader.defaultProps = {
  show: true,
};

MovingDottedLoader.propTypes = {
  show: PropTypes.bool,
};

export default MovingDottedLoader;
