import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { CONTACT_NOT_SELECTED } from "constants/images";

const CallQueueEmptyContent = ({
  titleTranslation,
  descriptionTranslate,
  descriptionClassName,
}) => (
  <article className="empty-content overflow-auto d-flex  flex-column justify-content-center align-items-center px-3 mt-2">
    <figure>
      <img src={CONTACT_NOT_SELECTED.SOURCE} alt="no registered agent" />
    </figure>
    <h5 className="mt-4 mb-2 text-center title">
      <Translate value={titleTranslation} />
    </h5>
    <p className={`text-center ${descriptionClassName} no-agent-detail`}>
      <Translate value={descriptionTranslate} />
    </p>
  </article>
);

CallQueueEmptyContent.defaultProps = {
  titleTranslation: "callQueue.empty.noRegisteredAgents",
  descriptionTranslate: "callQueue.empty.descriptionNoAgent",
  descriptionClassName: "",
};

CallQueueEmptyContent.propTypes = {
  titleTranslation: PropTypes.string,
  descriptionTranslate: PropTypes.string,
  descriptionClassName: PropTypes.string,
};

export default CallQueueEmptyContent;
