import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import ProfileWrapper from "layouts/ProfileWrapper/ProfileWrapper";

import DefaultProfile from "components/CallProfile/Default/DefaultProfile";
import EmptyCallProfileContent from "components/CallProfile/Empty/EmptyCallProfileContent";
import FixedCalendarBanner from "components/FixedCalendar/FixedCalendarBanner";
import EmptyContentSkeleton from "components/Skeleton/EmptyContentSkeleton";

import { AutomaticCallProfileContext } from "context-api/AutomaticCallProfileContext";

const DefaultCallProfile = () => {
  const { isLoading, callProfiles } = useSelector(
    ({ callProfile }) => callProfile
  );

  const { showFixedCalendar, setShowFixedCalendar } = useContext(
    AutomaticCallProfileContext
  );

  useEffect(() => {
    () => {
      setShowFixedCalendar(false);
    };
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="d-block w-100 d-md-none">
          <FixedCalendarBanner />
        </div>
        <ProfileWrapper>
          <div className=" pe-md-3 overflow-auto d-none d-md-block flex-fill h-100">
            <div className="d-flex flex-column bg-white p-3 ps-md-3 h-auto call-profile-detail">
              <EmptyContentSkeleton />
            </div>
          </div>
        </ProfileWrapper>
      </>
    );
  }

  if (!callProfiles.length) {
    return <EmptyCallProfileContent />;
  }

  return (
    <>
      <div className="d-block w-100 d-md-none">
        <FixedCalendarBanner />
      </div>
      <ProfileWrapper showFixedCalender={showFixedCalendar}>
        <DefaultProfile />
      </ProfileWrapper>
    </>
  );
};

export default DefaultCallProfile;
