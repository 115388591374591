// search key for all resources
export const SEARCH_KEY_FOR_REGISTERED_AGENT = ["name", "number"];
export const SEARCH_KEY_FOR_AVAILABLE_AGENT = ["name", "number"];
export const SEARCH_KEY_FOR_HOME_CONFIGURE_QUEUE = ["name"];
export const SEARCH_KEY_FOR_AVAILABLE_CONFIGURE_QUEUE = ["name"];

/**
 *
 * @param {string} searchText - Text to search in json
 * @param {*} data - Json to search in
 * @param {*} searchKeys - Keys to search in json
 * @param {*} searchCallBack - Callback to execute when search is done
 * @returns
 */

export const searchInJson = (
  searchText = "",
  data,
  searchKeys = [],
  searchCallBack = () => {}
) => {
  if (searchText) {
    searchCallBack(true);
  } else {
    searchCallBack(false);

    return data;
  }

  const updatedSearchData = [];

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < searchKeys.length; j++) {
      const currentSearchValue = data[i][searchKeys[j]].toLowerCase();

      if (
        currentSearchValue.includes(searchText.toLowerCase()) &&
        currentSearchValue.charAt(0) == searchText.toLowerCase().charAt(0)
      ) {
        updatedSearchData.push(data[i]);
        break;
      }
    }
  }

  return updatedSearchData;
};
