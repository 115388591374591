import React from "react";
import { I18n, I18nReRender } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import InputLabel from "components/Form/InputLabel";

const ErrorMessage = ({ error }) => {
  if (!error) return null;

  return (
    <p data-testid="error-message" className="error-message fw-bold ms-1">
      {error}
    </p>
  );
};

const CustomInputField = ({
  error,
  className,
  onChange,
  onBlur,
  value,
  name,
  placeholderTranslation,
  labelTranslation,
  labelClassName,
}) => {
  const checkError = (error) => {
    if (error) {
      return "error";
    }

    return "";
  };

  return (
    <div className="custom-input-field">
      <I18nReRender
        render={() => (
          <>
            <InputLabel
              name={name}
              className={labelClassName}
              labelTranslation={labelTranslation}
            />
            <input
              className={`${className} ${checkError(error)}`}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder={I18n.t(placeholderTranslation)}
              name={name}
              id={name}
              data-testid={name}
            />
            <ErrorMessage error={error} />
          </>
        )}
      />
    </div>
  );
};

ErrorMessage.defaultProps = {
  error: "",
};

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

CustomInputField.defaultProps = {
  error: "",
  className: "form-control w-100",
  onChange: () => {},
  onBlur: () => {},
  value: null,
  placeholderTranslation: "",
  name: "",
  labelTranslation: "",
  labelClassName: "fw-bold mb-2 ms-1",
};

CustomInputField.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ]),
  placeholderTranslation: PropTypes.string,
  name: PropTypes.string,
  labelTranslation: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default CustomInputField;
