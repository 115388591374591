import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTable } from "react-table";

import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import EmptyContent from "components/EmptyContent/EmptyContent";
import EmptyTableUI from "components/EmptyContent/EmptyTableUI";
import Table from "components/Table/Table";
import TableBody from "components/Table/TableBody";
import TableHeader from "components/Table/TableHeader";

import { VOICEMAIL_BOXES } from "constants/routes";
import { VOICEMAIL_BOXES_CELL_PHONE_TABLE_HEADER } from "constants/tableHeader";

const CellPhoneTable = ({ cellPhones }) => {
  const [tableData, setTableData] = useState([]);

  const { isSearching } = useSelector(({ voicemailBox }) => voicemailBox);

  const { push } = useHistory();

  const handleConfigureNavigation = (id) => {
    push(VOICEMAIL_BOXES.addID(VOICEMAIL_BOXES.CONFIG, id));
  };

  useEffect(() => {
    if (!cellPhones.length) return setTableData([]);
    const newTableData = [];

    cellPhones.forEach(({ email, cell_number: cellNumber, maxmsg, id }) => {
      newTableData.push({
        emailAddress: email,
        cellNumber,
        numberOfAllowedMessages: maxmsg,
        edit: (
          <FontAwesomeIcon
            data-cy="cellphone-voicemail-edit-icon"
            icon={faPenToSquare}
            className="edit-icon"
            onClick={() => handleConfigureNavigation(id)}
          />
        ),
      });
    });

    setTableData(newTableData);
  }, [cellPhones]);

  const tableInstance = useTable({
    columns: VOICEMAIL_BOXES_CELL_PHONE_TABLE_HEADER,
    data: tableData,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (cellPhones.length === 0 && !isSearching)
    return (
      <EmptyContent
        title="voicemailBoxes.empty.emptyCellPhoneList"
        description="voicemailBoxes.empty.description"
      />
    );

  return (
    <div data-cy="cellphone-table" className="cell-phone-table overflow-auto">
      <Table getTableProps={getTableProps}>
        <TableHeader
          headerGroups={headerGroups}
          className="mx-2"
          columnClassName="text-center fw-bold px-3"
        />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
          tableRowClassName={() => "cell-phone-table-row"}
        />
      </Table>
      <EmptyTableUI
        records={cellPhones}
        isSearching={isSearching}
        searchTitle="voicemailBoxes.empty.cellPhoneNotFound"
        searchDescription="voicemailBoxes.empty.searchQueryNotFound"
      />
    </div>
  );
};

CellPhoneTable.defaultProps = {
  cellPhones: [],
};

CellPhoneTable.propTypes = {
  cellPhones: PropTypes.array.isRequired,
};

export default CellPhoneTable;
