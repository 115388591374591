import React, { useState } from "react";
import { Field, useField } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { required } from "utils/validation";

import ForwardToField from "./ForwardToField";

const ForwardToValue = ({ value, onClearForwardTo, handleForwardToClick }) => {
  if (value) {
    return (
      <span
        className="badge forward-to-value fw-normal p-2 d-flex align-items-center"
        type="button"
        tabIndex={0}
        onClick={onClearForwardTo}
        onKeyPress={(e) => {
          e.code == "Enter" && onClearForwardTo();
        }}
      >
        {value}
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="close-icon ms-2 me-1"
        />
      </span>
    );
  }

  return (
    <button
      className="btn btn-link forward-to-button align-self-center px-0"
      onClick={handleForwardToClick}
      type="button"
    >
      <Translate value="callProfile.incomingCalls.chooseAnOption" />
    </button>
  );
};

const ForwardTo = ({ show, name, deviceName, additionalClassName }) => {
  const [showModal, toggleModal] = useState(false);
  const {
    input: { value, onChange },
  } = useField(name);

  const forwardContainerClassName = !value ? "my-3" : " align-self-center";

  if (!show) return null;

  return (
    <div
      className={`col-xl-6 ${forwardContainerClassName} ${additionalClassName}`}
    >
      <ForwardToValue
        value={value.external_number}
        onClearForwardTo={() => onChange({ target: { value: "" } })}
        handleForwardToClick={() => toggleModal(true)}
      />

      <Field
        key={name}
        component={ForwardToField}
        name={name}
        showModal={showModal}
        toggleModal={toggleModal}
        validate={required("validationErrors.REQUIRED")}
        deviceName={deviceName}
      />
    </div>
  );
};

ForwardToValue.defaultProps = {
  value: "",
  onClearForwardTo: () => {},
  handleForwardToClick: () => {},
};

ForwardToValue.propTypes = {
  value: PropTypes.string,
  onClearForwardTo: PropTypes.func,
  handleForwardToClick: PropTypes.func,
};

ForwardTo.defaultProps = {
  additionalClassName: "mt-2 mt-md-0 mb-md-3",
};

ForwardTo.propTypes = {
  show: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  deviceName: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  additionalClassName: PropTypes.string,
};

export default ForwardTo;
