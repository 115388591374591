import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import ContactList from "components/Home/ContactList/ContactList";
import HomeDetail from "components/Home/HomeDetail";

import {
  clearCallQueueErrors,
  fetchCallQueuesRequest,
} from "redux/actions/callQueue";

const TAB = {
  COLLEAGUE: "colleague",
  QUEUE: "queue",
};

const Home = () => {
  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);

  const { contactDetailData, contactDetailLoading } = useSelector(
    ({ contact }) => contact
  );
  const { isLoading } = useSelector(({ callQueue }) => callQueue);

  const [activeContact, setActiveContact] = useState({});
  const [activeTab, setActiveTab] = useState(TAB.COLLEAGUE);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCallQueuesRequest(userLicenseID));

    return () => {
      dispatch(clearCallQueueErrors());
    };
  }, []);

  const colleagueClassName =
    activeTab === TAB.COLLEAGUE ? "" : "d-none d-md-block";

  const queueDashboardClassName =
    activeTab === TAB.QUEUE ? "" : "d-none d-md-flex";

  const activeTabInColleagueClassName =
    activeTab === TAB.COLLEAGUE ? "active" : "";
  const activeTabInQueueClassName = activeTab === TAB.QUEUE ? "active" : "";

  return (
    <>
      <div className="d-flex d-md-none home-tab-container ps-3">
        <button
          className={`home-tab ${activeTabInColleagueClassName}`}
          onClick={() => setActiveTab(TAB.COLLEAGUE)}
        >
          <Translate value="home.colleagues" />
        </button>

        <button
          className={`home-tab ms-2 ${activeTabInQueueClassName}`}
          onClick={() => setActiveTab(TAB.QUEUE)}
        >
          <Translate value="common.queues" />
        </button>
      </div>
      <div
        className="row w-100 h-100 flex-fill mx-0 p-2 pe-md-0 home-tab-container"
        role="document"
      >
        <ContactList
          activeContact={activeContact}
          setActiveContact={setActiveContact}
          containerClassName={colleagueClassName}
        />
        <div className="mh-100 p-0 col-12 col-md-7 col-lg-8 col-xxl-9 pe-md-2 my-0 overflow-auto flex-fill">
          <HomeDetail
            contactDetailLoading={contactDetailLoading}
            contactDetailData={contactDetailData}
            setActiveContact={setActiveContact}
            isLoading={isLoading}
            queueClassName={queueDashboardClassName}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
