import React from "react";

import PropTypes from "prop-types";

import ActiveCallProfileDropdown from "components/Dropdown/ActiveCallProfile/ActiveCallProfileDropdown";

export const RECORD_TYPE = {
  DEFAULT: "default",
  PROFILE: "profile",
};

const RecordDetail = ({ label, value, children, type }) => {
  if (RECORD_TYPE.PROFILE === type)
    return (
      <>
        <p className="col-12 col-sm-6 detail-header fw-bold align-self-center">
          {label}
        </p>
        <ActiveCallProfileDropdown
          isUserProfile
          containerClassName="col-12 col-sm-6 detail-value ps-1 mb-3"
        />
      </>
    );

  if (!value && children.length == 0) return null;

  if (children.length) {
    return (
      <>
        <p className="col-12 detail-header fw-bold align-self-center">
          {label}
        </p>
        {children.map(({ label, value }, index) => (
          <div className="col-12 row" key={index}>
            <p className="col-12 col-sm-6 detail-sub-title ps-4 align-self-center">
              {label}
            </p>
            <p className="col-12 col-sm-6 detail-value ps-4 align-self-center">
              {value}
            </p>
          </div>
        ))}
      </>
    );
  }

  if (!value) return null;

  return (
    <>
      <p className="col-12 col-sm-6 detail-header fw-bold align-self-center">
        {label}
      </p>
      <p className="col-12 col-sm-6 detail-value align-self-center">{value}</p>
    </>
  );
};

RecordDetail.defaultProps = {
  label: <></>,
  value: "",
  children: [],
  type: RECORD_TYPE.DEFAULT,
};

RecordDetail.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  children: PropTypes.array,
  type: PropTypes.oneOf([RECORD_TYPE.DEFAULT, RECORD_TYPE.PROFILE]),
};

export default RecordDetail;
