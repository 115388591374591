import { createSearchAction } from "redux-search";

import { VoicemailBoxApi } from "internal";

import { getErrors } from "utils/utils";

export const VOICEMAIL_BOX_FETCH_REQUEST = "VOICEMAIL_BOX_FETCH_REQUEST";
export const VOICEMAIL_BOX_FETCH_RECEIVED = "VOICEMAIL_BOX_FETCH_RECEIVED";
export const VOICEMAIL_BOX_FETCH_FAILED = "VOICEMAIL_BOX_FETCH_FAILED";

export const VOICEMAIL_BOX_FETCH_CELL_PHONES_REQUEST =
  "VOICEMAIL_BOX_FETCH_CELL_PHONES_REQUEST";
export const VOICEMAIL_BOX_FETCH_CELL_PHONES_RECEIVED =
  "VOICEMAIL_BOX_FETCH_CELL_PHONES_RECEIVED";
export const VOICEMAIL_BOX_FETCH_CELL_PHONES_FAILED =
  "VOICEMAIL_BOX_FETCH_CELL_PHONES_FAILED";

export const VOICEMAIL_BOX_CELL_PHONE_SEARCH =
  "VOICEMAIL_BOX_CELL_PHONE_SEARCH";

export const VOICEMAIL_BOX_UPDATE_REQUEST = "VOICEMAIL_BOX_UPDATE_REQUEST";
export const VOICEMAIL_BOX_UPDATE_RECEIVED = "VOICEMAIL_BOX_UPDATE_RECEIVED";
export const VOICEMAIL_BOX_UPDATE_FAILED = "VOICEMAIL_BOX_UPDATE_FAILED";

export const VOICEMAIL_BOX_CLEAR_ERRORS = "VOICEMAIL_BOX_CLEAR_ERRORS";

export const searchVoicemailBoxesCellPhone = createSearchAction("cellPhones");

export const clearVoicemailBoxErrors = () => ({
  type: VOICEMAIL_BOX_CLEAR_ERRORS,
});

export function voicemailBoxesSearchRequest(value) {
  const isSearching = Boolean(value);

  return {
    type: VOICEMAIL_BOX_CELL_PHONE_SEARCH,
    isSearching,
  };
}

export function voicemailBoxFetchReceived(response) {
  return {
    type: VOICEMAIL_BOX_FETCH_RECEIVED,
    voicemailBox: response.data,
  };
}

export function voicemailBoxFetchFailed(errorData) {
  const message = getErrors(errorData);

  return {
    type: VOICEMAIL_BOX_FETCH_FAILED,
    message,
  };
}

export const fetchVoicemailBoxRequest =
  (voicemailBoxID) => async (dispatch) => {
    try {
      dispatch({ type: VOICEMAIL_BOX_FETCH_REQUEST });

      const response = await VoicemailBoxApi.fetch(voicemailBoxID);

      dispatch(voicemailBoxFetchReceived(response));
    } catch (error) {
      dispatch(voicemailBoxFetchFailed(error));
    }
  };

// fetch cell phone for voicemail box
function fetchCellPhonesReceived(response) {
  return {
    type: VOICEMAIL_BOX_FETCH_CELL_PHONES_RECEIVED,
    cellPhones: response.data,
  };
}

function fetchCellPhonesError(errorData) {
  const message = getErrors(errorData);

  return {
    type: VOICEMAIL_BOX_FETCH_CELL_PHONES_FAILED,
    message,
  };
}

export const fetchVoicemailBoxCellPhonesRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: VOICEMAIL_BOX_FETCH_CELL_PHONES_REQUEST });
      const response = await VoicemailBoxApi.fetchCellPhones(userLicenseID);

      dispatch(fetchCellPhonesReceived(response));
    } catch (error) {
      dispatch(fetchCellPhonesError(error));
    }
  };

// update voicemail box
export function voicemailBoxUpdateReceived(response) {
  return {
    type: VOICEMAIL_BOX_UPDATE_RECEIVED,
    voicemailBox: response.data,
  };
}

export function voicemailBoxUpdateFailed(errorData) {
  const message = getErrors(errorData);

  return {
    type: VOICEMAIL_BOX_UPDATE_FAILED,
    message,
  };
}

export const updateVoicemailBoxRequest =
  (voicemailBoxID, attributes) => async (dispatch) => {
    try {
      dispatch({ type: VOICEMAIL_BOX_UPDATE_REQUEST });

      const response = await VoicemailBoxApi.update(voicemailBoxID, attributes);

      dispatch(voicemailBoxUpdateReceived(response));
    } catch (error) {
      dispatch(voicemailBoxUpdateFailed(error));
    }
  };
