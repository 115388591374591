import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { AutomaticCallProfileContext } from "context-api/AutomaticCallProfileContext";

import { displaySuccess } from "utils/toaster";
import { setCurrentUser } from "utils/utils";

import { CHANNEL_LIST } from "services/Socket";
import cable from "services/socket/cable";

import {
  clearCallProfileErrors,
  updateActiveCallProfileRequest,
} from "redux/actions/callProfile";

import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const AddProfileIcon = ({ addIcon, icon }) => {
  if (!addIcon) {
    return null;
  }

  return <FontAwesomeIcon icon={icon} />;
};

const AddProfileTitle = ({ show, children }) => {
  if (!show) return null;

  return <>{children}</>;
};

const ActiveSelectValue = ({ active, addIcon, showActiveProfileTitle }) => {
  const { isAutomaticCallProfileEnable } = useContext(
    AutomaticCallProfileContext
  );

  if (isAutomaticCallProfileEnable && !active?.name) {
    return (
      <p className="ms-1 me-2 position-relative d-flex align-items-center mb-0 w-100 name">
        <Translate value="attributes.noProfile" />
      </p>
    );
  }

  if (!active?.name) {
    return (
      <p className="ms-1 me-2 position-relative d-flex align-items-center mb-0 w-100 name">
        <Translate value="attributes.selectAnOption" />
      </p>
    );
  }

  const {
    icon_json: { brand_name: brandName, icon_name: iconName },
    name,
  } = active;

  const activeNameClassName = addIcon ? "ms-3" : "";

  return (
    <div
      data-cy="active-call-profile"
      className="ms-1 me-2 position-relative d-flex align-items-center w-100 position-relative"
    >
      <AddProfileIcon addIcon={addIcon} icon={[brandName, iconName]} />
      <span className={`name ${activeNameClassName}`}>{name}</span>
      <AddProfileTitle show={showActiveProfileTitle}>
        <span className="position-absolute active-profile-title">
          <Translate value="common.currentProfile" />
        </span>
      </AddProfileTitle>
    </div>
  );
};

const ActiveCallProfileDropdown = ({
  containerClassName,
  addIcon,
  activeValueClassName,
  callback,
  showActiveProfileTitle,
  isUserProfile,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { callProfiles, commitSuccess } = useSelector(
    ({ callProfile }) => callProfile
  );

  const { currentUser, bearerToken } = useSelector(({ auth }) => auth);

  const [subscription, setSubscription] = useState();

  useEffect(() => () => dispatch(clearCallProfileErrors()), []);

  useEffect(() => {
    if (commitSuccess) {
      displaySuccess(commitSuccess);
      dispatch(clearCallProfileErrors());
    }
  }, [commitSuccess]);

  useEffect(() => {
    if (window.Cypress) return null;

    const connectToChannel = async () => {
      const subscription = await cable.subscribeTo(
        CHANNEL_LIST.ACTIVE_CALL_PROFILE_CHANNEL,
        {
          user_license_extension_number: currentUser.extension_number,
          pbx_ref_number: currentUser.pbx_number,
        }
      );

      setSubscription(subscription);
    };

    if (!subscription) {
      connectToChannel();
    } else {
      subscription.on("message", ({ data }) => {
        const { identifier } = subscription;

        if (identifier == CHANNEL_LIST.ACTIVE_CALL_PROFILE_CHANNEL) {
          setCurrentUser(data, "active_call_profile");
        }
      });
    }
  }, [subscription, bearerToken]);

  const handleActiveCallProfileSelection = (activeProfile) => {
    dispatch(updateActiveCallProfileRequest(activeProfile.id, activeProfile));
    if (id == activeProfile.id) {
      dispatch(fetchCallProfileDetailRequest(id));
    }
    callback();
  };

  return (
    <>
      <DesktopView
        containerClassName={containerClassName}
        activeValueClassName={activeValueClassName}
        activeProfileValue={
          <ActiveSelectValue
            addIcon={addIcon}
            active={currentUser?.active_call_profile}
            showActiveProfileTitle={showActiveProfileTitle}
          />
        }
        callProfiles={callProfiles}
        active={currentUser?.active_call_profile}
        handleActiveCallProfileSelection={handleActiveCallProfileSelection}
        isUserProfile={isUserProfile}
      />
      <MobileView
        containerClassName={containerClassName}
        activeValueClassName={activeValueClassName}
        addIcon={addIcon}
        active={currentUser?.active_call_profile}
        callProfiles={callProfiles}
        handleActiveCallProfileSelection={handleActiveCallProfileSelection}
        isUserProfile={isUserProfile}
      />
    </>
  );
};

AddProfileTitle.defaultProps = {
  show: false,
  children: null,
};
AddProfileTitle.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.node]),
};

ActiveSelectValue.defaultProps = {
  active: null,
  addIcon: true,
  showActiveProfileTitle: false,
};

ActiveSelectValue.propTypes = {
  active: PropTypes.object,
  addIcon: PropTypes.bool,
  showActiveProfileTitle: PropTypes.bool,
};

AddProfileIcon.defaultProps = {
  addIcon: true,
  icon: [],
};

AddProfileIcon.propTypes = {
  addIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ActiveCallProfileDropdown.defaultProps = {
  containerClassName: "",
  addIcon: true,
  activeValueClassName: "col-12 col-md-6 col-lg-8",
  callback: () => {},
  showActiveProfileTitle: false,
  isUserProfile: false,
};

ActiveCallProfileDropdown.propTypes = {
  containerClassName: PropTypes.string,
  activeValueClassName: PropTypes.string,
  addIcon: PropTypes.bool,
  callback: PropTypes.func,
  showActiveProfileTitle: PropTypes.bool,
  isUserProfile: PropTypes.bool,
};

export default ActiveCallProfileDropdown;
