import React from "react";
import Skeleton from "react-loading-skeleton";

import InformationBoxWrapperSkeleton from "components/Skeleton/InformationBoxWrapperSkeleton";

const OutgoingCallFormSkeleton = () => (
  <div className="outgoing-call-form mt-3">
    <InformationBoxWrapperSkeleton>
      <div className="px-3">
        <div className="outgoing-call-item row py-3">
          <div className="col-md-5 align-self-center">
            <Skeleton height={20} className="w-75" />
          </div>
          <div className="col-md-5">
            <Skeleton height={40} width="100" />
          </div>
        </div>
        <div className="outgoing-call-item row py-3">
          <div className="col-md-5 align-self-center">
            <Skeleton height={20} className="w-75" />
          </div>
          <div className="col-md-5">
            <Skeleton height={40} width="100" />
          </div>
        </div>
        <div className="outgoing-call-item row py-3">
          <div className="col-md-5 align-self-center">
            <Skeleton height={20} className="w-75" />
          </div>
          <div className="col-md-5">
            <Skeleton height={40} width="100" />
          </div>
        </div>
      </div>
    </InformationBoxWrapperSkeleton>
  </div>
);

export default OutgoingCallFormSkeleton;
