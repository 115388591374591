import React, { createContext, useState } from "react";

import PropTypes from "prop-types";

export const ConfigureQueueContext = createContext(null);

const ConfigureQueueProvider = ({ children }) => {
  const [updatedHomeQueues, setUpdatedHomeQueues] = useState([]);
  const [updatedAvailableQueues, setUpdatedAvailableQueues] = useState([]);
  const [queuePriority, setQueuePriority] = useState({});
  const [isFormEdited, setFormEdited] = useState(false);

  return (
    <ConfigureQueueContext.Provider
      value={{
        updatedHomeQueues,
        setUpdatedHomeQueues,
        updatedAvailableQueues,
        setUpdatedAvailableQueues,
        queuePriority,
        setQueuePriority,
        isFormEdited,
        setFormEdited,
      }}
    >
      {children}
    </ConfigureQueueContext.Provider>
  );
};

ConfigureQueueProvider.defaultProps = {
  children: null,
};

ConfigureQueueProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
};

export default ConfigureQueueProvider;
