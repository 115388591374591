import React from "react";
import Skeleton from "react-loading-skeleton";

import NoteSkeleton from "./NoteSkeleton";
import ProfileHeaderSkeleton from "./ProfileHeaderSkeleton";

const UserProfileSkeleton = () => (
  <div className="layout-spacing overflow-auto h-100 mh-100 ">
    <div className="bg-white py-3 h-100 d-flex flex-column justify-content-between">
      <div className="w-100 bg-white">
        <ProfileHeaderSkeleton showEditProfile />
      </div>
      <div className="px-4 pb-3 bg-white">
        <div className="profile-detail">
          <div className="d-flex flex-column flex-sm-row w-100 align-items-sm-center justify-content-start my-2">
            <div className="flex-fill px-2">
              <Skeleton
                height={15}
                width={150}
                className="detail-header flex-fill"
              />
            </div>
            <div className="flex-fill">
              <Skeleton
                height={12}
                width={200}
                className=" detail-value flex-fill mt-2"
              />
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row w-100 align-items-sm-center justify-content-start my-2">
            <div className="flex-fill px-2">
              <Skeleton
                height={15}
                width={150}
                className="detail-header flex-fill"
              />
            </div>
            <div className="flex-fill">
              <Skeleton
                height={12}
                width={200}
                className=" detail-value flex-fill mt-2"
              />
            </div>
          </div>
          <NoteSkeleton />
        </div>
      </div>
    </div>
  </div>
);

export default UserProfileSkeleton;
