import React from "react";
import Skeleton from "react-loading-skeleton";

import InformationBoxWrapperSkeleton from "components/Skeleton/InformationBoxWrapperSkeleton";

import { LIGHT_GREY } from "constants/skeletonColor";

const GeneralVoicemailSkeleton = () => (
  <article className="general-voicemail-boxes p-3 bg-white">
    <InformationBoxWrapperSkeleton
      title="voicemailBoxes.general.generalVoicemailBoxes"
      titleContent={
        <>
          <Skeleton
            width={90}
            height={30}
            baseColor={LIGHT_GREY}
            className="d-none d-md-inline-block mt-2"
          />{" "}
          <Skeleton
            circle
            width={20}
            height={20}
            baseColor={LIGHT_GREY}
            className="d-inline-block d-md-none"
          />{" "}
        </>
      }
      showTooltip
    >
      <div className="px-2 px-md-3 py-2 d-flex justify-content-between flex-wrap">
        <div className="d-flex general-item py-2">
          <Skeleton width={30} height={20} />
          <div className="ms-1 ps-1">
            <Skeleton width={120} height={25} className="mb-2" />
            <Skeleton width={120} height={25} />
          </div>
        </div>
        <div className="d-flex general-item py-2">
          <Skeleton width={30} height={20} />
          <div className="ms-1 ps-1">
            <Skeleton width={120} height={25} className="mb-2" />
            <Skeleton width={120} height={25} />
          </div>
        </div>
        <div className="d-flex general-item py-2">
          <Skeleton width={30} height={20} />
          <div className="ms-1 ps-1">
            <Skeleton width={140} height={25} className="mb-2" />
            <Skeleton width={120} height={25} />
          </div>
        </div>
      </div>
    </InformationBoxWrapperSkeleton>
  </article>
);

export default GeneralVoicemailSkeleton;
