import { UPDATE_STATUS_CODE_CALL_PROFILE } from "utils/updateReducer";

import {
  CLEAR_CALL_PROFILES_ERRORS,
  FETCH_CALL_PROFILE_DETAIL_ERROR,
  FETCH_CALL_PROFILE_DETAIL_RECEIVED,
  FETCH_CALL_PROFILE_DETAIL_REQUEST,
  FETCH_CALL_PROFILES_ERROR,
  FETCH_CALL_PROFILES_RECEIVED,
  FETCH_CALL_PROFILES_REQUEST,
  UPDATE_CALL_PROFILE_ERROR,
  CREATE_CALL_PROFILE_REQUEST,
  CREATE_CALL_PROFILE_RECEIVED,
  CREATE_CALL_PROFILE_ERROR,
  DELETE_CALL_PROFILE_REQUEST,
  DELETE_CALL_PROFILE_RECEIVED,
  DELETE_CALL_PROFILE_ERROR,
  UPDATE_CALL_PROFILE_RECEIVED,
  UPDATE_CALL_PROFILE_REQUEST,
  FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_REQUEST,
  FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_RECEIVED,
  FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_ERROR,
  FETCH_OUTGOING_CALL_OPTIONS_REQUEST,
  FETCH_OUTGOING_CALL_OPTIONS_RECEIVED,
  FETCH_OUTGOING_CALL_OPTIONS_ERROR,
  UPDATE_ACTIVE_CALL_PROFILE_REQUEST,
  UPDATE_ACTIVE_CALL_PROFILE_RECEIVED,
  UPDATE_ACTIVE_CALL_PROFILE_ERROR,
  FETCH_ALL_INCOMING_AVAILABLE_DEVICES_REQUEST,
  FETCH_ALL_INCOMING_AVAILABLE_DEVICES_RECEIVED,
  FETCH_ALL_INCOMING_AVAILABLE_DEVICES_ERROR,
  FETCH_INCOMING_AVAILABLE_SIP_DEVICES_ERROR,
  FETCH_INCOMING_AVAILABLE_SIP_DEVICES_REQUEST,
  FETCH_INCOMING_AVAILABLE_SIP_DEVICES_RECEIVED,
  FETCH_DAILY_SCHEDULE_CALL_PROFILE_REQUEST,
  FETCH_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED,
  FETCH_DAILY_SCHEDULE_CALL_PROFILE_ERROR,
  UPDATE_DAILY_SCHEDULE_CALL_PROFILE_REQUEST,
  UPDATE_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED,
  UPDATE_DAILY_SCHEDULE_CALL_PROFILE_ERROR,
  UPDATE_AUTOMATIC_CALL_PROFILE_REQUEST,
  UPDATE_AUTOMATIC_CALL_PROFILE_RECEIVED,
  UPDATE_AUTOMATIC_CALL_PROFILE_ERROR,
  FETCH_ALL_QUEUES_REQUEST,
  FETCH_ALL_QUEUES_RECEIVED,
  FETCH_ALL_QUEUES_FAILED,
  FETCH_ALL_QUEUE_DEVICES_REQUEST,
  FETCH_ALL_QUEUE_DEVICES_RECEIVED,
  FETCH_ALL_QUEUE_DEVICES_FAILED,
} from "redux/actions/callProfile";

const defaultProfileState = {
  error: "",
  callProfiles: [],
  isLoading: true,
  isProcessing: false,
  isSubmitting: false,
  isActiveCallProfileUpdating: false,
  isProfileDetailLoading: true,
  isCallQueuesLoading: true,
  callProfileDetail: {},
  commitError: "",
  commitSuccess: "",
  outgoingCallDevices: [],
  outgoingCallOptions: [],
  incomingCallDevices: [],
  sipDevices: [],
  callProfileQueues: [],
  callQueueDevices: [],
  statusCode: null,
  dailyScheduleCallProfile: [],
  isDailyScheduleCallProfileLoading: true,
  automaticCallProfile: {},
};

const reducer = (state = defaultProfileState, action) => {
  switch (action.type) {
    case FETCH_CALL_PROFILES_REQUEST:
      return {
        ...state,
        error: "",
        isLoading: true,
      };

    case FETCH_CALL_PROFILES_RECEIVED:
      return {
        ...state,
        callProfiles: action.data,
        isLoading: false,
      };

    case FETCH_CALL_PROFILES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case FETCH_CALL_PROFILE_DETAIL_REQUEST:
      return {
        ...state,
        error: "",
        isProfileDetailLoading: true,
        statusCode: null,
        callProfileDetail: {},
      };

    case FETCH_CALL_PROFILE_DETAIL_RECEIVED:
      return {
        ...state,
        isProfileDetailLoading: false,
        callProfileDetail: action.data,
      };

    case FETCH_CALL_PROFILE_DETAIL_ERROR:
      return {
        ...state,
        isProfileDetailLoading: false,
        error: action.error,
      };

    case UPDATE_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        commitError: "",
      };

    case UPDATE_CALL_PROFILE_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess: "message.callProfile.profileUpdatedSuccessfully",
        callProfileDetail: action.data,
      };

    case UPDATE_CALL_PROFILE_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.error,
      };

    case CREATE_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        commitError: "",
      };

    case CREATE_CALL_PROFILE_RECEIVED:
      return {
        ...state,
        isSubmitting: false,
        commitSuccess: "message.callProfile.profileCreatedSuccessfully",
        callProfileDetail: action.data,
      };

    case CREATE_CALL_PROFILE_ERROR:
      return {
        ...state,
        isSubmitting: false,
        commitError: action.error,
      };

    case DELETE_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case DELETE_CALL_PROFILE_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        callProfileDetail: {},
        commitSuccess: "message.callProfile.profileDeletedSuccessfully",
      };

    case DELETE_CALL_PROFILE_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case CLEAR_CALL_PROFILES_ERRORS:
      return {
        ...state,
        error: "",
        commitError: "",
        commitSuccess: "",
        statusCode: null,
      };

    case FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        outgoingCallDevices: action.data,
      };

    case FETCH_ALL_OUTGOING_AVAILABLE_DEVICES_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case FETCH_ALL_INCOMING_AVAILABLE_DEVICES_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case FETCH_ALL_INCOMING_AVAILABLE_DEVICES_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        incomingCallDevices: action.data,
      };

    case FETCH_ALL_INCOMING_AVAILABLE_DEVICES_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case FETCH_OUTGOING_CALL_OPTIONS_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case FETCH_OUTGOING_CALL_OPTIONS_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        outgoingCallOptions: action.data,
      };

    case FETCH_OUTGOING_CALL_OPTIONS_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case UPDATE_ACTIVE_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isActiveCallProfileUpdating: true,
        commitSuccess: "",
        commitError: "",
      };

    case UPDATE_ACTIVE_CALL_PROFILE_RECEIVED:
      return {
        ...state,
        isActiveCallProfileUpdating: false,
        commitSuccess: "message.callProfile.activeProfileUpdatedSuccessfully",
      };

    case UPDATE_ACTIVE_CALL_PROFILE_ERROR:
      return {
        ...state,
        isActiveCallProfileUpdating: false,
        commitError: action.error,
      };

    case FETCH_INCOMING_AVAILABLE_SIP_DEVICES_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case FETCH_INCOMING_AVAILABLE_SIP_DEVICES_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        sipDevices: action.data,
      };

    case FETCH_INCOMING_AVAILABLE_SIP_DEVICES_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case UPDATE_STATUS_CODE_CALL_PROFILE:
      return {
        ...state,
        statusCode: action.statusCode,
      };

    case FETCH_DAILY_SCHEDULE_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isDailyScheduleCallProfileLoading: true,
        commitError: "",
        commitSuccess: "",
      };
    case FETCH_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED:
      return {
        ...state,
        isDailyScheduleCallProfileLoading: false,
        dailyScheduleCallProfile: action.data,
      };

    case FETCH_DAILY_SCHEDULE_CALL_PROFILE_ERROR:
      return {
        ...state,
        isDailyScheduleCallProfileLoading: false,
        commitError: action.error,
      };

    case UPDATE_DAILY_SCHEDULE_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isDailyScheduleCallProfileLoading: true,
        commitError: "",
        commitSuccess: "",
      };

    case UPDATE_DAILY_SCHEDULE_CALL_PROFILE_RECEIVED:
      const indexOfSelectedCallProfile =
        state.dailyScheduleCallProfile.findIndex(
          ({ for_day: forDay }) => forDay == action.data.for_day
        );
      const updatedScheduleCallProfile = JSON.parse(
        JSON.stringify(state.dailyScheduleCallProfile)
      );

      updatedScheduleCallProfile[indexOfSelectedCallProfile] = action.data;

      return {
        ...state,
        isDailyScheduleCallProfileLoading: false,
        commitSuccess: "message.callProfile.scheduleProfileUpdatedSuccessfully",
        dailyScheduleCallProfile: updatedScheduleCallProfile,
      };

    case UPDATE_DAILY_SCHEDULE_CALL_PROFILE_ERROR:
      return {
        ...state,
        isDailyScheduleCallProfileLoading: false,
        commitError: action.error,
      };

    case UPDATE_AUTOMATIC_CALL_PROFILE_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case UPDATE_AUTOMATIC_CALL_PROFILE_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        automaticCallProfile: action.payload.data,
      };

    case UPDATE_AUTOMATIC_CALL_PROFILE_ERROR:
      return {
        ...state,
        isProcessing: false,
        commitError: action.error,
      };

    case FETCH_ALL_QUEUES_REQUEST:
      return {
        ...state,
        isCallQueuesLoading: true,
        error: "",
      };

    case FETCH_ALL_QUEUES_RECEIVED:
      return {
        ...state,
        isCallQueuesLoading: false,
        callProfileQueues: action.data,
      };

    case FETCH_ALL_QUEUES_FAILED:
      return {
        ...state,
        isCallQueuesLoading: false,
        error: action.data.error,
      };

    case FETCH_ALL_QUEUE_DEVICES_REQUEST:
      return {
        ...state,
        isCallQueuesLoading: true,
        error: "",
      };

    case FETCH_ALL_QUEUE_DEVICES_RECEIVED:
      return {
        ...state,
        isCallQueuesLoading: false,
        callQueueDevices: action.data,
      };

    case FETCH_ALL_QUEUE_DEVICES_FAILED:
      return {
        ...state,
        isCallQueuesLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
