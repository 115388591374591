import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  AVAILABLE_QUEUE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/dashboard_queues/available`,
  HOME_QUEUE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/dashboard_queues/configured`,
  UPDATE_QUEUE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/dashboard_queues/bulk_update`,
};

class ConfigureQueueAPI extends AuthenticatedRequest {
  /**
   *
   * @param {init} userLicenseID - user license id of the pbx user
   * @returns
   */
  static fetchHomeQueue(userLicenseID) {
    const FETCH_ALL_URL = URLS.HOME_QUEUE.replace(
      ":userLicenseID",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *
   * @param {init} userLicenseID - user license id of the pbx user
   * @returns
   */
  static fetchAvailableQueue(userLicenseID) {
    const FETCH_ALL_URL = URLS.AVAILABLE_QUEUE.replace(
      ":userLicenseID",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *
   * @param {init} userLicenseID - user license id of the pbx user
   * @returns
   */
  static updateQueue(userLicenseID, data) {
    const PATCH_URL = URLS.UPDATE_QUEUE.replace(
      ":userLicenseID",
      userLicenseID
    );

    return this.patch(PATCH_URL, data);
  }
}

export default ConfigureQueueAPI;
