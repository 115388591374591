import { Translate } from "react-thunk-i18nify";

import {
  faCircleMinus,
  faArrowRightArrowLeft,
  faVoicemail,
  faWifi,
  faBlenderPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAgentMobile, faPhoneOffice } from "constants/customIcon/icons";

import {
  INCOMING_CALL_TYPE,
  INCOMING_FORM_TYPE,
} from "./incomingCallAttributes";

// Add translate label according to value
const INCOMING_CALL_LABEL = {
  give_busy_signal: (
    <span>
      <FontAwesomeIcon icon={faCircleMinus} className="me-2 busy-icon" />
      <Translate value="dropdown.incomingCalls.giveBusySignal" />
    </span>
  ),
  initiate_call_on_devices: (
    <span>
      <FontAwesomeIcon icon={faBlenderPhone} className="me-2 option-icon" />
      <Translate value="dropdown.incomingCalls.initiateCallOnDevices" />
    </span>
  ),
  forward_to: (
    <span>
      <FontAwesomeIcon
        icon={faArrowRightArrowLeft}
        className="me-2 option-icon"
      />
      <Translate value="dropdown.incomingCalls.forwardTo" />
    </span>
  ),
  go_to_voicemail: (
    <span>
      <FontAwesomeIcon icon={faVoicemail} className="me-2 option-icon" />
      <Translate value="dropdown.incomingCalls.goToVoicemail" />
    </span>
  ),
  call_on_all_devices: (
    <span>
      <FontAwesomeIcon icon={faWifi} className="me-2 option-icon" />
      <Translate value="dropdown.incomingCalls.callOnAllDevices" />
    </span>
  ),
};

export const INITIATE_CALL_ON_DEVICES = {
  CellPhone: (
    <FontAwesomeIcon icon={faAgentMobile} className="me-2 option-icon" />
  ),
  SipDevice: (
    <FontAwesomeIcon icon={faPhoneOffice} className="me-2 option-icon" />
  ),
};

/**
 * The function will translate the value of sip device eg: T48 to Initiate call on T48
 * @param {array|null} sipDevices - It will return  all device selected by user
 * @returns
 */

const sipDeviceOption = (sipDevices, deviceName = undefined) => {
  if (sipDevices == null || !sipDevices.length) {
    return null;
  }

  return sipDevices.map(({ device_name, call_routing_device, ...rest }) => ({
    label: (
      <>
        {INITIATE_CALL_ON_DEVICES[call_routing_device.deviceable_type]}
        <Translate
          value="callProfile.incomingCalls.initiateCallOnSipDevice"
          device={device_name}
        />
      </>
    ),
    value: call_routing_device.deviceable_id,
    sipDevice: {
      call_routing_device_attributes: call_routing_device,
      device_name,

      ...rest,
    },
    deviceName: deviceName,
    updatedValue: (
      <>
        {INITIATE_CALL_ON_DEVICES[call_routing_device.deviceable_type]}
        {device_name}
      </>
    ),
  }));
};

/**
 * The function will translate the value of a queue device eg: T48 to Initiate call on T48
 * @param {array|null} queueDevices - It will return  all queue devices selected by user
 * @returns
 */

export const formatQueueDevicesOption = (queueDevices) => {
  if (queueDevices == null || !queueDevices.length) {
    return null;
  }

  return queueDevices.map(
    ({ id, device_name, deviceable_id, deviceable_type }) => ({
      label: (
        <>
          {INITIATE_CALL_ON_DEVICES[deviceable_type]}
          {device_name}
        </>
      ),
      value: deviceable_id,
      queueDevice: {
        id,
        deviceable_id,
        deviceable_type,
      },
      updatedValue: (
        <>
          {INITIATE_CALL_ON_DEVICES[deviceable_type]}
          {device_name}
        </>
      ),
    })
  );
};

/**
 *
 * @param {*} param0 - The option available for incoming devices
 * @param {*} type - The type of form
 * @param {*} sipDevices - The sip devices available for incoming call
 * @returns
 */

const incomingCallOptionItem = (
  { device_name, call_routing_device, ...rest },
  type = INCOMING_FORM_TYPE.CREATE,
  sipDevices = []
) => {
  if (type == INCOMING_FORM_TYPE.CREATE) {
    return incomingCallCreateOption(
      sipDevices,
      device_name,
      call_routing_device
    );
  }

  return incomingCallEditOption(
    call_routing_device,
    device_name,
    rest,
    sipDevices
  );
};

/**
 * The function will return the option for create incoming call
 * @param {array} sipDevices - The sip devices available for incoming call
 * @param {string} device_name - The device name
 * @param {object} call_routing_device - The call routing device
 * @returns
 */

function incomingCallCreateOption(
  sipDevices,
  device_name,
  call_routing_device
) {
  return {
    call_routing_device,
    device_name,
    selected_option: null,
    selected_sip_device: null,
    sipDevices: sipDeviceOption(sipDevices),
    forwardTo: null,
    ringing_duration: { value: 30, label: "30" },
    ringingStrategyAfterRingingDuration: null,
    ringingStrategyForwardTo: null,
    options: [
      {
        value: INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES],
      },
      {
        value: INCOMING_CALL_TYPE.BUSY_SIGNAL,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.BUSY_SIGNAL],
      },
      {
        value: INCOMING_CALL_TYPE.FORWARD_TO,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.FORWARD_TO],
      },
      {
        value: INCOMING_CALL_TYPE.GO_TO_VOICEMAIL,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.GO_TO_VOICEMAIL],
      },
      {
        value: INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES],
      },
    ],
  };
}

/**
 * The function will return the option for edit incoming call
 * @param {array} sipDevices - The sip devices available for incoming call
 * @param {string} device_name - The device name
 * @param {*} rest - The rest of the incoming call
 * @param {object} call_routing_device - The call routing device
 * @returns
 */
function incomingCallEditOption(
  call_routing_device,
  device_name,
  rest,
  sipDevices
) {
  let options = {
    call_routing_device,
    device_name,
    selected_sip_device: sipDeviceOption(
      rest?.incoming_call_routing_initiation_devices,
      device_name
    ),
    sipDevices: sipDeviceOption(sipDevices, device_name),
    forwardTo: null,
    ringing_duration: { value: 30, label: 30 },
    ringingStrategyAfterRingingDuration: null,
    call_forwarding_option: null,
    ringingStrategyForwardTo: null,
    selected_option: rest?.ringing_strategy && {
      value: rest.ringing_strategy,
      id: rest.id,
      strategy_after_ringing_duration: null,
      ringing_duration_seconds_until_next_strategy: null,
      label: INCOMING_CALL_LABEL[rest.ringing_strategy],
    },
    options: [
      {
        // add existing id to the option
        id: rest.id,
        value: INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES],
      },
      {
        // add existing id to the option
        id: rest.id,
        value: INCOMING_CALL_TYPE.BUSY_SIGNAL,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.BUSY_SIGNAL],
      },
      {
        // add existing id to the option
        id: rest.id,
        value: INCOMING_CALL_TYPE.FORWARD_TO,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.FORWARD_TO],
      },
      {
        // add existing id to the option
        id: rest.id,
        value: INCOMING_CALL_TYPE.GO_TO_VOICEMAIL,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.GO_TO_VOICEMAIL],
      },
      {
        // add existing id to the option
        id: rest.id,
        value: INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES,
        label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES],
      },
    ],
  };

  const ringingDuration =
    rest?.ringing_duration_seconds_until_next_strategy || 30;

  const ringingStrategyAfterRingingDuration =
    rest?.strategy_after_ringing_duration;

  switch (rest.ringing_strategy) {
    case INCOMING_CALL_TYPE.BUSY_SIGNAL:
    case INCOMING_CALL_TYPE.GO_TO_VOICEMAIL:
      return options;

    case INCOMING_CALL_TYPE.FORWARD_TO:
      return {
        ...options,
        forwardTo:
          ringingStrategyAfterRingingDuration !== INCOMING_CALL_TYPE.FORWARD_TO
            ? {
                id: rest?.call_forwarding_option?.id,
                external_number: rest?.call_forwarding_option?.external_number,
                external_number_name:
                  rest?.call_forwarding_option?.external_number_name,
              } || null
            : null,
      };

    case INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES:
      return {
        ...options,
        selected_sip_device: sipDeviceOption(
          rest?.incoming_call_routing_initiation_devices,
          device_name
        ),
        ringing_duration: { value: ringingDuration, label: ringingDuration },
        ringingStrategyAfterRingingDuration: {
          value: ringingStrategyAfterRingingDuration,
          label: INCOMING_CALL_LABEL[ringingStrategyAfterRingingDuration],
        },
        ringingStrategyForwardTo:
          ringingStrategyAfterRingingDuration === INCOMING_CALL_TYPE.FORWARD_TO
            ? {
                id: rest?.call_forwarding_option?.id,
                external_number: rest?.call_forwarding_option?.external_number,
                external_number_name:
                  rest?.call_forwarding_option?.external_number_name,
              } || null
            : null,
      };

    case INCOMING_CALL_TYPE.CALL_ON_SIP_DEVICES:
      options = {
        ...options,
        selected_sip_device: sipDeviceOption(
          rest?.incoming_call_routing_initiation_devices,
          device_name
        ),
        sipDevices: sipDeviceOption(sipDevices, device_name),
        ringing_duration: { value: ringingDuration, label: ringingDuration },
        ringingStrategyAfterRingingDuration: {
          value: ringingStrategyAfterRingingDuration,
          label: INCOMING_CALL_LABEL[ringingStrategyAfterRingingDuration],
        },
      };

      if (
        ringingStrategyAfterRingingDuration === INCOMING_CALL_TYPE.FORWARD_TO
      ) {
        return {
          ...options,
          ringingStrategyForwardTo:
            {
              id: rest?.call_forwarding_option?.id,
              external_number: rest?.call_forwarding_option?.external_number,
              external_number_name:
                rest?.call_forwarding_option?.external_number_name,
            } || null,
          call_forwarding_option: rest?.call_forwarding_option,
        };
      } else {
        return options;
      }

    case INCOMING_CALL_TYPE.CALL_ON_ALL_DEVICES:
      options = {
        ...options,
        ringing_duration: { value: ringingDuration, label: ringingDuration },
        ringingStrategyAfterRingingDuration: {
          value: ringingStrategyAfterRingingDuration,
          label: INCOMING_CALL_LABEL[ringingStrategyAfterRingingDuration],
        },
      };

      if (
        ringingStrategyAfterRingingDuration === INCOMING_CALL_TYPE.FORWARD_TO
      ) {
        return {
          ...options,
          ringingStrategyForwardTo:
            {
              id: rest?.call_forwarding_option?.id,
              external_number: rest?.call_forwarding_option?.external_number,
              external_number_name:
                rest?.call_forwarding_option?.external_number_name,
            } || null,
          call_forwarding_option: rest?.call_forwarding_option,
        };
      } else {
        return options;
      }

    default:
      return options;
  }
}

/**
 *
 * @param {array|null} incomingCall - It will return all selected device for incoming call
 * @param {*} type - The type of form
 * @param {*} sipDevices - The sip devices available for incoming call
 * @returns
 */
export const incomingCallOptions = (incomingCall, type, sipDevices) =>
  incomingCall?.map((item) => incomingCallOptionItem(item, type, sipDevices));

/**
 *
 * @param {number} seconds - The seconds which could be selected by user
 * @returns
 */

export const getRingingDurationOptions = (seconds = 50) => {
  const options = [];

  for (let sec = 5; sec <= seconds; sec += 5) {
    options.push({ value: sec, label: sec });
  }

  return options;
};

export const getStrategyAfterRingingDuration = () => [
  {
    value: INCOMING_CALL_TYPE.BUSY_SIGNAL,
    label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.BUSY_SIGNAL],
  },
  {
    value: INCOMING_CALL_TYPE.GO_TO_VOICEMAIL,
    label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.GO_TO_VOICEMAIL],
  },
  {
    value: INCOMING_CALL_TYPE.FORWARD_TO,
    label: INCOMING_CALL_LABEL[INCOMING_CALL_TYPE.FORWARD_TO],
  },
];
