import React from "react";

import PropTypes from "prop-types";

import EmptyContent from "components/EmptyContent/EmptyContent";

const EmptyTableUI = ({
  records,
  isSearching,
  isFiltering,
  searchTitle,
  searchDescription,
  filterTitle,
  filterDescription,
}) => {
  if (records.length) {
    return null;
  }

  if (isSearching) {
    return (
      <div className="flex-fill empty-table-content">
        <EmptyContent title={searchTitle} description={searchDescription} />
      </div>
    );
  }
  if (isFiltering) {
    return (
      <div className="flex-fill empty-table-content">
        <EmptyContent title={filterTitle} description={filterDescription} />
      </div>
    );
  }

  return null;
};

EmptyTableUI.defaultProps = {
  records: [],
  isFiltering: false,
  isSearching: false,
  searchTitle: "",
  searchDescription: "",
  filterTitle: "",
  filterDescription: "",
};

EmptyTableUI.propTypes = {
  records: PropTypes.array,
  isFiltering: PropTypes.bool,
  isSearching: PropTypes.bool,
  searchTitle: PropTypes.string,
  searchDescription: PropTypes.string,
  filterTitle: PropTypes.string,
  filterDescription: PropTypes.string,
};

export default EmptyTableUI;
