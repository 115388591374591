import React, { useEffect, useRef } from "react";

import { Tooltip } from "bootstrap";
import PropTypes from "prop-types";

const CustomTooltip = ({ children, title, placement, className }) => {
  const tooltipRef = useRef();

  const offset = title.length > 12 ? 70 : 20;

  useEffect(() => {
    if (typeof title == "object") return;
    new Tooltip(tooltipRef.current, {
      title: title,
      placement: placement || "bottom",
      trigger: "hover",
      offset: [offset, 0],
      template:
        '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner px-3"></div></div>',
    });
  }, []);

  return (
    <span ref={tooltipRef} className={className} role="tooltip">
      {children}
    </span>
  );
};

CustomTooltip.defaultProps = {
  children: <></>,
  title: "",
  placement: "bottom",
  className: "",
};

CustomTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  placement: PropTypes.string,
  className: PropTypes.string,
};

export default CustomTooltip;
