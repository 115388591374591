import React, { useCallback, useContext } from "react";

import PropTypes from "prop-types";

import Dropdown from "components/Dropdown/Dropdown";

import { AgentManagementContext } from "context-api/AgentManagementContext";

const CallPriority = ({ id }) => {
  const {
    callPriority,
    setCallPriority,
    isFormEdited,
    setFormEdited,
    setUpdatedRegisteredAgents,
    updatedRegisteredAgents,
  } = useContext(AgentManagementContext);

  const priorityOptions = updatedRegisteredAgents.map((_, index) => ({
    keyword: index + 1,
    title: index + 1,
  }));

  const updateForm = useCallback(() => {
    if (isFormEdited) return;
    setFormEdited(true);
  }, [isFormEdited]);

  const handleUpdateCallPriority = ({ title, keyword }) => {
    updateForm();
    setCallPriority((previous) => ({
      ...previous,
      [id]: {
        penalty: keyword,
        title,
        isDirty: previous[id].isDirty
          ? previous[id].isDirty
          : previous[id].penalty != keyword,
      },
    }));

    setUpdatedRegisteredAgents((prev) => {
      const copiedAgents = [...prev];

      return copiedAgents.map(({ id: registeredAgentID, ...rest }) => {
        if (registeredAgentID == id) {
          return {
            ...rest,
            id: registeredAgentID,
            penalty: keyword,
          };
        }

        return {
          ...rest,
          id: registeredAgentID,
        };
      });
    });
  };

  return (
    <Dropdown
      active={callPriority[id]?.title}
      options={priorityOptions}
      handleDropdownUpdate={handleUpdateCallPriority}
      containerClassName="call-priority me-2"
      activeClassName="px-0 px-sm-2 call-priority-dropdown"
    />
  );
};

CallPriority.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CallPriority;
