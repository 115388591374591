import React from "react";

import ProfileWrapper from "layouts/ProfileWrapper/ProfileWrapper";

import EditCallProfileForm from "components/CallProfile/Edit/EditCallProfileForm";

const EditCallProfile = () => (
  <ProfileWrapper>
    <div className=" pe-md-3 overflow-auto flex-fill h-100">
      <div className="d-flex flex-column bg-white p-3 ps-md-3 h-auto call-profile-detail">
        <EditCallProfileForm />
      </div>
    </div>
  </ProfileWrapper>
);

export default EditCallProfile;
