import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCirclePlus } from "constants/customIcon/icons";
import { PROFILE } from "constants/routes";

const CreateNewProfileButton = () => {
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const handleCreateNavigation = () => {
    replace(PROFILE.CREATE);
  };

  if (pathname === PROFILE.CREATE) return null;

  return (
    <div className="d-flex justify-content-end justify-content-md-start w-100 px-md-3  mb-3">
      <button
        className="btn btn-primary d-flex justify-content-between align-items-center create-button mx-auto mx-md-2"
        onClick={handleCreateNavigation}
        data-cy="create-call-profile"
      >
        <span>
          <Translate value="callProfile.create.createNewProfile" />
        </span>
        <FontAwesomeIcon icon={faCirclePlus} className="ms-3 create-icon" />
      </button>
    </div>
  );
};

export default CreateNewProfileButton;
