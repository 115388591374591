import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  FETCH_ALL: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/call_profiles`,
  DETAIL: `${apiUrlConfig.pbxUserEndPoint()}/call_profiles/:call_profile_id`,
  UPDATE: `${apiUrlConfig.pbxUserEndPoint()}/call_profiles/:call_profile_id`,
  CREATE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/call_profiles`,
  DELETE: `${apiUrlConfig.pbxUserEndPoint()}/call_profiles/:call_profile_id`,
  FETCH_ALL_OUTGOING_AVAILABLE_DEVICES: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/outgoing_call_routings/available_devices`,
  FETCH_OPTIONS_FOR_OUTGOING_DEVICE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/outgoing_call_routings/caller_id_options`,
  UPDATE_ACTIVE_PROFILE: `${apiUrlConfig.pbxUserEndPoint()}/call_profiles/:call_profile_id/change_to_active`,
  FETCH_ALL_INCOMING_AVAILABLE_DEVICES: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/incoming_call_routings/available_devices`,
  FETCH_AVAILABLE_SIP_DEVICES: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/incoming_call_routings/initiation_devices`,
  FETCH_CALL_PROFILE_DAILY_SCHEDULE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:user_license_id/call_profile_daily_schedules`,
  UPDATE_CALL_PROFILE_DAILY_SCHEDULE: `${apiUrlConfig.pbxUserEndPoint()}/call_profile_daily_schedules/:callProfileID`,
  AUTOMATIC_CALL_PROFILE: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/switch_call_profiles_automatically`,
  FETCH_ALL_QUEUES: `${apiUrlConfig.asteriskEndpoint()}/pbxes/:pbxID/queues`,
  FETCH_ALL_QUEUE_DEVICES: `${apiUrlConfig.pbxUserEndPoint()}/user_licenses/:userLicenseID/call_profile_queues/available_agents`,
};

class CallProfileApi extends AuthenticatedRequest {
  static fetchAll(userLicenseID) {
    const FETCH_ALL_URL = URLS.FETCH_ALL.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  static detail(callProfileID) {
    const DETAIL_URL = URLS.DETAIL.replace(":call_profile_id", callProfileID);

    return this.get(DETAIL_URL);
  }

  static update(callProfileID, data) {
    const UPDATE_URL = URLS.UPDATE.replace(":call_profile_id", callProfileID);

    return this.patch(UPDATE_URL, data);
  }

  static create(userLicenseID, data) {
    const CREATE_URL = URLS.CREATE.replace(":user_license_id", userLicenseID);

    return this.post(CREATE_URL, data);
  }

  /**
   *
   * @param {init} callProfileID ID of the call profile
   * @returns
   */

  static deleteProfile(callProfileID) {
    const DELETE_URL = URLS.DELETE.replace(":call_profile_id", callProfileID);

    return this.delete(DELETE_URL);
  }

  /**
   *
   * @param {init} userLicenseID user license ID of the current logged In user
   * @returns
   */

  static fetchOutgoingAvailableDevices(userLicenseID) {
    const FETCH_ALL_URL = URLS.FETCH_ALL_OUTGOING_AVAILABLE_DEVICES.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *
   * @param {init} userLicenseID user license ID of the current logged In
   * @returns
   */

  static fetchOutgoingCallOptions(userLicenseID) {
    const FETCH_ALL_URL = URLS.FETCH_OPTIONS_FOR_OUTGOING_DEVICE.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *
   * @param {init} callProfileID ID of the specific call profile
   * @param {*} activeProfile active profile
   * @returns
   */

  static updateActiveProfile(callProfileID, activeProfile) {
    const UPDATE_URL = URLS.UPDATE_ACTIVE_PROFILE.replace(
      ":call_profile_id",
      callProfileID
    );

    return this.patch(UPDATE_URL, activeProfile);
  }

  /**
   *
   * @param {init} userLicenseID ID of current logged In user
   * @returns
   */

  static fetchIncomingAvailableDevices(userLicenseID) {
    const FETCH_ALL_URL = URLS.FETCH_ALL_INCOMING_AVAILABLE_DEVICES.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *  @param {init} userLicenseID ID of current logged In user
   * @returns
   */

  static fetchIncomingAvailableSipDevices(userLicenseID) {
    const FETCH_ALL_URL = URLS.FETCH_AVAILABLE_SIP_DEVICES.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_ALL_URL);
  }

  /**
   *  @param {init} userLicenseID ID of current logged In user
   * @returns
   */

  static fetchDailyScheduleCallProfile(userLicenseID) {
    const FETCH_URL = URLS.FETCH_CALL_PROFILE_DAILY_SCHEDULE.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_URL);
  }

  /**
   * @param {init} callProfileID - ID of specific call profile
   */

  static updateScheduleCallProfile(callProfileID, payload) {
    const UPDATE_URL = URLS.UPDATE_CALL_PROFILE_DAILY_SCHEDULE.replace(
      ":callProfileID",
      callProfileID
    );

    return this.patch(UPDATE_URL, payload);
  }

  /**
   *
   * @param {init} userLicenseID - user license id of current user
   * @param {boolean} isActiveCallProfile - true if call profile is active for automatic call profile
   * @returns
   */
  static updateAutomaticCallProfile(userLicenseID, isActiveCallProfile) {
    const UPDATE_URL = URLS.AUTOMATIC_CALL_PROFILE.replace(
      ":userLicenseID",
      userLicenseID
    );

    return this.patch(UPDATE_URL, {
      is_automatic_call_profile_switching_enabled: isActiveCallProfile,
    });
  }

  /**
   * @param {init} pbxID - pbx id of the pbx user
   * @returns
   */
  static fetchAllQueues(pbxID) {
    const FETCH_ALL_QUEUES_URL = URLS.FETCH_ALL_QUEUES.replace(":pbxID", pbxID);

    return this.get(FETCH_ALL_QUEUES_URL);
  }

  /**
   * @param {int} userLicenseID - ID of current logged-in user
   * @returns
   */
  static fetchAllQueueDevices(userLicenseID) {
    const FETCH_ALL_QUEUE_DEVICES_URL = URLS.FETCH_ALL_QUEUE_DEVICES.replace(
      ":userLicenseID",
      userLicenseID
    );

    return this.get(FETCH_ALL_QUEUE_DEVICES_URL);
  }
}

export default CallProfileApi;
