import React from "react";

import PropTypes from "prop-types";

import CallProfileList from "components/CallProfile/List/CallProfileList";

import CallProfileProvider from "context-api/CallProfileContext";

const ProfileWrapper = ({ children, showFixedCalender }) => (
  <div
    className="user-profile w-100 h-100 d-flex flex-column justify-content-between flex-fill mx-0 p-0 flex-fill"
    role="document"
  >
    <CallProfileProvider>
      <div className="d-flex flex-column flex-md-row h-100">
        <CallProfileList isHidden={showFixedCalender} />
        {children}
      </div>
    </CallProfileProvider>
  </div>
);

ProfileWrapper.propTypes = {
  children: PropTypes.node,
  showFixedCalender: PropTypes.bool,
};

ProfileWrapper.defaultProps = {
  children: <></>,
  showFixedCalender: false,
};

export default ProfileWrapper;
