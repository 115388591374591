//controller to handle multiple audio play
class AudioController {
  constructor() {
    this.audio = null;
  }

  pauseAudio() {
    this.audio?.pause?.();
  }

  playAudio() {
    this.audio?.play?.();
  }

  // update audio
  updateAudio(audio, cb = null) {
    if (audio) {
      this.audio?.pause?.();
      this.audio = null;
      this.audio = new Audio(audio);

      this.endedCb = () => {
        this.audio = null;
        if (cb) cb();
      };

      this.audio.addEventListener("ended", this.endedCb);
    }
  }

  removeAudio() {
    this.audio?.pause?.();
    this.audio = null;
  }
}

const audioController = new AudioController();

export default audioController;
