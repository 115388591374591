import {
  CLEAR_PROFILE_ERRORS,
  FETCH_PROFILE_DETAIL_ERROR,
  FETCH_PROFILE_DETAIL_RECEIVED,
  FETCH_PROFILE_DETAIL_REQUEST,
  PROFILE_PICTURE_UPDATE_ERROR,
  PROFILE_PICTURE_UPDATE_REQUEST,
  PROFILE_PICTURE_UPDATE_RECEIVED,
  PROFILE_PICTURE_REMOVE_ERROR,
  PROFILE_PICTURE_REMOVE_RECEIVED,
  PROFILE_PICTURE_REMOVE_REQUEST,
  PROFILE_UPDATE_ERROR,
  PROFILE_UPDATE_RECEIVED,
  PROFILE_UPDATE_REQUEST,
  SWITCH_USER_LANGUAGE_REQUEST,
  SWITCH_USER_LANGUAGE_RECEIVED,
  SWITCH_USER_LANGUAGE_FAILED,
} from "redux/actions/userProfile";

const defaultUserProfileState = {
  isProfilePictureLoading: false,
  isLoading: false,
  profile: {},
  error: "",
  commitSuccess: "",
  isProcessing: false,
  commitError: "",
};

function reducer(state = defaultUserProfileState, action) {
  switch (action.type) {
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        commitSuccess: "",
      };
    case PROFILE_UPDATE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        profile: action.payload,
        commitSuccess: "message.userProfile.profileUpdatedSuccessfully",
      };
    case PROFILE_UPDATE_ERROR:
      const { response } = action.error;

      switch (response?.status) {
        case 422:
          const { data } = response;

          const error = data["user.password"][0]?.error;

          return {
            ...state,
            isLoading: false,
            error: error,
          };
        default:
          return {
            ...state,
            isLoading: false,
            error: action.error,
          };
      }
    case PROFILE_PICTURE_REMOVE_REQUEST:
      return {
        ...state,
        isProfilePictureLoading: true,
        error: "",
        commitSuccess: "",
      };
    case PROFILE_PICTURE_REMOVE_RECEIVED:
      return {
        ...state,
        isProfilePictureLoading: false,
        profile: action.payload,
        commitSuccess: "message.userProfile.profileImageRemovedSuccessfully",
      };
    case PROFILE_PICTURE_REMOVE_ERROR:
      return {
        ...state,
        isProfilePictureLoading: false,
        error: action.error,
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        commitSuccess: "",
        error: "",
        commitError: "",
      };
    case PROFILE_PICTURE_UPDATE_REQUEST:
      return {
        ...state,
        isProfilePictureLoading: true,
        error: "",
        commitSuccess: "",
      };
    case PROFILE_PICTURE_UPDATE_RECEIVED:
      return {
        ...state,
        isProfilePictureLoading: false,
        profile: action.payload,
        commitSuccess: "message.userProfile.profileUpdatedSuccessfully",
      };
    case PROFILE_PICTURE_UPDATE_ERROR:
      const { response: profilePictureUpdateResponse } = action.error;

      switch (profilePictureUpdateResponse?.status) {
        case 422:
          const { data } = profilePictureUpdateResponse;

          const error = data["user.password"][0]?.error;

          return {
            ...state,
            isProfilePictureLoading: false,
            error: error,
          };
        default:
          return {
            ...state,
            isProfilePictureLoading: false,
            error: profilePictureUpdateResponse,
          };
      }

    case FETCH_PROFILE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case FETCH_PROFILE_DETAIL_RECEIVED:
      return {
        ...state,
        isLoading: false,
        profile: action.profile,
      };

    case FETCH_PROFILE_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SWITCH_USER_LANGUAGE_REQUEST:
      return {
        ...state,
        isProcessing: true,
        commitSuccess: "",
        commitError: "",
      };

    case SWITCH_USER_LANGUAGE_RECEIVED:
      return {
        ...state,
        isProcessing: false,
        commitSuccess: "",
      };

    case SWITCH_USER_LANGUAGE_FAILED:
      return {
        ...state,
        isProcessing: false,
        commitError: action.message,
      };

    default:
      return state;
  }
}

export default reducer;
