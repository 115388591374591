import React from "react";
import Skeleton from "react-loading-skeleton";

import CellPhoneVoicemailBoxSkeleton from "./CellPhoneVoicemailBoxSkeleton";
import GeneralVoicemailSkeleton from "./GeneralVoicemailSkeleton";

const VoicemailBoxesSkeleton = () => (
  <div
    role="document"
    className="voicemail-boxes overflow-auto w-100 h-100 mx-0 layout-spacing"
  >
    <div className="bg-white px-0 h-100">
      <div className="p-4 title-container">
        <Skeleton width={120} height={20} />
      </div>
      <GeneralVoicemailSkeleton />
      <CellPhoneVoicemailBoxSkeleton />
    </div>
  </div>
);

export default VoicemailBoxesSkeleton;
