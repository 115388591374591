import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";

import LoginWrapper from "layouts/loginWrapper/LoginWrapper";

import LanguageToggler from "components/LanguageToggler/LanguageToggler";
import LoginForm from "components/Login/LoginForm";
import NewCustomerLink from "components/Login/NewCustomerLink";

import { AUTH, HOME } from "constants/routes";
import STATUS from "constants/statusCode";

import { resetPasswordClearErrors, setPasswordClear } from "redux/actions/auth";

const Login = ({ location }) => {
  const { isLoggedIn, statusCode } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const { state: routerState } = location;

  useEffect(() => {
    dispatch(resetPasswordClearErrors());
    dispatch(setPasswordClear());
  }, []);

  const redirectPath = routerState?.from ?? HOME.INDEX;

  if (statusCode === STATUS.FORBIDDEN) {
    return <Redirect to={AUTH.FORBIDDEN} from={AUTH.LOGIN} />;
  }

  if (!isLoggedIn) {
    return (
      <LoginWrapper>
        <LoginForm />
        <NewCustomerLink />
        <LanguageToggler />
      </LoginWrapper>
    );
  }

  return <Redirect from={AUTH.LOGIN} to={redirectPath} />;
};

Login.defaultProps = {
  location: {},
};

Login.propTypes = {
  location: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
};

export default Login;
