import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import PropTypes from "prop-types";

import PrivateRouteLayout from "layouts/Menu/PrivateRouteLayout";

import { AUTH, HOME } from "constants/routes";

function PrivateRoute({ component: Component, ...rest }) {
  const { isLoggedIn } = useSelector(({ auth }) => auth);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoggedIn) {
          return (
            <PrivateRouteLayout>
              <Component {...rest} />
            </PrivateRouteLayout>
          );
        }

        return (
          <Redirect
            to={{
              pathname: AUTH.LOGIN,
              state: {
                from:
                  location.pathname === AUTH.LOGIN
                    ? HOME.INDEX
                    : location.pathname,
              },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.defaultProps = {
  component: () => {},
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default PrivateRoute;
