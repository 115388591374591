class SocketUrlConfig {
  static baseEndPoint(token) {
    let basePath;

    switch (process.env.REACT_APP_ENV) {
      case "development":
        basePath = `ws://localhost:3000/cable?bearer_token=${token}`;
        // basePath = `wss://mit-dev-api.flexonet.dk/cable?bearer_token=${token}`;
        break;
      case "staging":
        basePath = `wss://mit-dev-api.flexonet.dk/cable?bearer_token=${token}`;
        break;
      case "production":
        basePath = `wss://mit-api.flexonet.dk/cable?bearer_token=${token}`;
        break;
      default:
        basePath = `wss://mit-dev-api.flexonet.dk/cable?bearer_token=${token}`;
        break;
    }

    return basePath;
  }

  static endPoint() {
    let token = null;

    const bearerTokenJSON = localStorage.getItem("bearerToken");

    if (bearerTokenJSON) {
      token = bearerTokenJSON?.split(" ")[1];
    }

    return this.baseEndPoint(token);
  }
}

export default SocketUrlConfig;
