import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { WAIT_TIME } from "constants/images";

const Statistics = ({
  image,
  value,
  nameTranslation,
  statisticsClassName,
  testID,
}) => (
  <div className={`statistics-item my-2 ${statisticsClassName}`}>
    <div className="d-flex align-items-center">
      <img src={image} alt={nameTranslation} />
      <p className="statistics-value mb-0 ms-1" data-cy={testID}>
        {value}
      </p>
    </div>
    <p className="text-uppercase statistics-name mb-0 mx-1 mt-1">
      <Translate value={nameTranslation} />
    </p>
  </div>
);

Statistics.defaultProps = {
  image: WAIT_TIME.SOURCE,
  value: 0,
  nameTranslation: "",
  statisticsClassName: "",
  testID: "",
};

Statistics.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameTranslation: PropTypes.string,
  statisticsClassName: PropTypes.string,
  testID: PropTypes.string,
};

export default Statistics;
