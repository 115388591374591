import React from "react";
import { Translate } from "react-thunk-i18nify";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Tooltip from "components/CustomTooltip/Tooltip";

const DeleteCallProfile = ({
  showTooltip,
  setDeleteModal,
  tooltipTranslationText,
}) => {
  if (!showTooltip) {
    return (
      <button
        className="btn delete-button me-md-1"
        onClick={() => setDeleteModal(true)}
        data-cy="delete-call-profile-button"
      >
        <FontAwesomeIcon icon={faTrash} className="text-white" />
      </button>
    );
  }

  return (
    <Tooltip
      title={<Translate value={tooltipTranslationText} />}
      className="active-call-profile-tooltip d-flex mt-2 bg-dark text-white fw-normal p-3"
    >
      <button
        className="btn delete-button"
        onClick={() => setDeleteModal(true)}
        data-cy="delete-call-profile-button"
        disabled={showTooltip}
      >
        <FontAwesomeIcon icon={faTrash} className="text-white" />
      </button>
    </Tooltip>
  );
};

DeleteCallProfile.defaultProps = {
  showTooltip: false,
  tooltipTranslationText: "",
};

DeleteCallProfile.propTypes = {
  showTooltip: PropTypes.bool,
  setDeleteModal: PropTypes.func.isRequired,
  tooltipTranslationText: PropTypes.string,
};

export default DeleteCallProfile;
