import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";

import { AutomaticCallProfileContext } from "context-api/AutomaticCallProfileContext";

const ActiveSelectValue = ({
  active,
  addIcon,
  isAutomaticCallProfileEnable,
}) => {
  if (isAutomaticCallProfileEnable && !active?.name) {
    return (
      <div className="ms-1 me-1 w-100 position-relative mt-1">
        <p className={`name ${activeNameClassName} mb-0 fw-bold`}>
          <Translate value="attributes.noProfile" />
        </p>
        <p className="active-profile-title mb-0 position-absolute">
          <Translate value="common.currentProfile" />
        </p>
      </div>
    );
  }

  if (!active?.name) {
    return (
      <div className="ms-1 me-1 w-100 position-relative mt-1">
        <p className={`name ${activeNameClassName} mb-0 fw-bold`}>
          <Translate value="attributes.selectAnOption" />
        </p>
        <p className="active-profile-title mb-0 position-absolute">
          <Translate value="common.currentProfile" />
        </p>
      </div>
    );
  }

  const {
    icon_json: { brand_name: brandName, icon_name: iconName },
    name,
  } = active;

  const activeNameClassName = addIcon ? "ms-2" : "";

  return (
    <>
      <FontAwesomeIcon
        icon={[brandName, iconName]}
        className="active-profile-icon"
      />
      <div className="ms-1 me-1 w-100 position-relative mt-1">
        <p className={`name ${activeNameClassName} mb-0 fw-bold`}>{name}</p>
        <p className="active-profile-title mb-0 position-absolute ms-2">
          <Translate value="common.currentProfile" />
        </p>
      </div>
    </>
  );
};

const DropdownIcon = ({ isEnable }) => {
  if (isEnable) return null;

  return <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />;
};

const MobileView = ({
  containerClassName,
  activeValueClassName,
  callProfiles,
  handleActiveCallProfileSelection,
  active,
  addIcon,
  isUserProfile,
}) => {
  const { isAutomaticCallProfileEnable } = useContext(
    AutomaticCallProfileContext
  );

  const { isActiveCallProfileUpdating } = useSelector(
    ({ callProfile }) => callProfile
  );

  const addOptionClassName = (name) => {
    if (active?.name !== name) return "";

    return "active-option";
  };

  const typeOfDropdown =
    !isAutomaticCallProfileEnable && !isAutomaticCallProfileEnable
      ? "button"
      : "";
  const userProfileClassName = isUserProfile ? "d-none" : "d-flex d-md-none";

  return (
    <div
      className={`btn-group custom-dropdown ${containerClassName} ${userProfileClassName}`}
    >
      <div
        className={`btn-sm dropdown-toggle active-profile rounded-0 text-nowrap border-0 position-relative d-flex align-items-center px-2 pt-3 pb-2 col-6 ${activeValueClassName}`}
        type={typeOfDropdown}
        data-bs-toggle="dropdown"
        aria-expanded="false"
        disabled={isAutomaticCallProfileEnable}
      >
        <ActiveSelectValue
          active={active}
          addIcon={addIcon}
          isAutomaticCallProfileEnable={isAutomaticCallProfileEnable}
        />
        <DropdownIcon
          isEnable={isAutomaticCallProfileEnable || isActiveCallProfileUpdating}
        />
        <MovingDottedLoader show={isActiveCallProfileUpdating} />
      </div>
      <ul
        className={`dropdown-menu dropdown-menu-end custom-dropdown-menu py-2 mt-1 px-2 border-0 ${activeValueClassName}`}
      >
        <li className="dropdown-item item-title py-0 px-3 d-flex align-items-center border-0 fw-bold">
          <Translate value="callProfile.selectCurrentProfile" />
        </li>
        <div className="item-list">
          {callProfiles.map(
            ({
              icon_json: { brand_name: brandName, icon_name: iconName },
              name,
              id,
              ...rest
            }) => (
              <li key={id}>
                <button
                  className={`dropdown-item ${addOptionClassName(
                    name
                  )} py-0 px-3 d-flex align-items-center border-0`}
                  onClick={() =>
                    handleActiveCallProfileSelection({
                      icon_json: { brand_name: brandName, icon_name: iconName },
                      name,
                      id,
                      ...rest,
                    })
                  }
                >
                  <span
                    className={`d-flex justify-content-center align-items-center profile-icon p-3 me-2 ${addOptionClassName(
                      name
                    )}`}
                  >
                    <FontAwesomeIcon icon={[brandName, iconName]} />
                  </span>

                  <span className="mt-1">{name}</span>
                </button>
              </li>
            )
          )}
        </div>
      </ul>
    </div>
  );
};

ActiveSelectValue.defaultProps = {
  active: null,
  addIcon: true,
  isAutomaticCallProfileEnable: false,
};

ActiveSelectValue.propTypes = {
  active: PropTypes.object,
  addIcon: PropTypes.bool,
  isAutomaticCallProfileEnable: PropTypes.bool,
};

DropdownIcon.defaultProps = {
  isEnable: false,
};

DropdownIcon.propTypes = {
  isEnable: PropTypes.bool,
};

MobileView.defaultProps = {
  containerClassName: "",
  activeValueClassName: "",
  activeProfileValue: null,
  callProfiles: [{}],
  active: null,
  addIcon: true,
  isUserProfile: false,
};

MobileView.propTypes = {
  containerClassName: PropTypes.string,
  activeValueClassName: PropTypes.string,
  activeProfileValue: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.node])
    .isRequired,
  callProfiles: PropTypes.arrayOf(PropTypes.shape({})),
  handleActiveCallProfileSelection: PropTypes.func.isRequired,
  active: PropTypes.shape({
    name: PropTypes.string,
  }),
  addIcon: PropTypes.bool,
  isUserProfile: PropTypes.bool,
};

export default MobileView;
