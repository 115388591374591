import { ConfigureQueueAPI } from "internal";

import { QUEUE_TYPE } from "constants/queue";

import { getErrors } from "utils/utils";

export const FETCH_HOME_QUEUE_REQUEST = "FETCH_HOME_QUEUE_REQUEST";
export const FETCH_HOME_QUEUE_RECEIVED = "FETCH_HOME_QUEUE_RECEIVED";
export const FETCH_HOME_QUEUE_ERROR = "FETCH_HOME_QUEUE_ERROR";

export const FETCH_AVAILABLE_QUEUE_REQUEST = "FETCH_AVAILABLE_QUEUE_REQUEST";
export const FETCH_AVAILABLE_QUEUE_RECEIVED = "FETCH_AVAILABLE_RECEIVED";
export const FETCH_AVAILABLE_QUEUE_ERROR = "FETCH_AVAILABLE_QUEUE_ERROR";

export const UPDATE_QUEUE_IN_DASHBOARD_REQUEST =
  "UPDATE_QUEUE_IN_DASHBOARD_REQUEST";
export const UPDATE_QUEUE_IN_DASHBOARD_RECEIVED =
  "UPDATE_QUEUE_IN_DASHBOARD_RECEIVED";
export const UPDATE_QUEUE_IN_DASHBOARD_ERROR =
  "UPDATE_QUEUE_IN_DASHBOARD_ERROR";

export const CLEAR_QUEUE_ERROR_IN_DASHBOARD = "CLEAR_QUEUE_ERROR_IN_DASHBOARD";

export const fetchHomeQueueReceived = (data) => ({
  type: FETCH_HOME_QUEUE_RECEIVED,
  data,
});

export const fetchHomeQueueFailed = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_HOME_QUEUE_ERROR,
    error,
  };
};

export const fetchHomeQueueRequest = (userLicenseID) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_HOME_QUEUE_REQUEST });

    const response = await ConfigureQueueAPI.fetchHomeQueue(userLicenseID);

    const data =
      response.data.map((queue) => ({
        ...queue,
        type: QUEUE_TYPE.HOME_QUEUE,
      })) || [];

    dispatch(fetchHomeQueueReceived(data));
  } catch (error) {
    dispatch(fetchHomeQueueFailed(error));
  }
};

export const fetchAvailableQueueReceived = (data) => ({
  type: FETCH_AVAILABLE_QUEUE_RECEIVED,
  data,
});

export const fetchAvailableQueueFailed = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: FETCH_AVAILABLE_QUEUE_ERROR,
    error,
  };
};

export const fetchAvailableQueueRequest =
  (userLicenseID) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_AVAILABLE_QUEUE_REQUEST });

      const response = await ConfigureQueueAPI.fetchAvailableQueue(
        userLicenseID
      );

      const data =
        response?.data?.map((queue) => ({
          ...queue,
          type: QUEUE_TYPE.AVAILABLE_QUEUE,
        })) || [];

      dispatch(fetchAvailableQueueReceived(data));
    } catch (error) {
      dispatch(fetchAvailableQueueFailed(error));
    }
  };

export const updateQueueInDashboardReceived = (data) => ({
  type: UPDATE_QUEUE_IN_DASHBOARD_RECEIVED,
  data,
});

export const updateQueueInDashboardFailed = (errorData) => {
  const error = getErrors(errorData);

  return {
    type: UPDATE_QUEUE_IN_DASHBOARD_ERROR,
    error,
  };
};

export const updateQueueInDashboardRequest =
  (userLicenseID, data) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_QUEUE_IN_DASHBOARD_REQUEST });

      const response = await ConfigureQueueAPI.updateQueue(userLicenseID, data);

      dispatch(updateQueueInDashboardReceived(response.data));
    } catch (error) {
      dispatch(updateQueueInDashboardFailed(error));
    }
  };

export const clearQueueErrors = () => ({
  type: CLEAR_QUEUE_ERROR_IN_DASHBOARD,
});
