import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import ForbiddenError from "components/Forbidden/ForbiddenError";

import { AUTH } from "constants/routes";
import STATUS from "constants/statusCode";

import { isEmpty } from "utils/isEmpty";
import updateStatusReducer, { REDUCER_TYPE } from "utils/updateReducer";

import { logoutRequest } from "redux/actions/auth";

const NavigationButton = ({ hideNavigation, handleNavigation }) => {
  if (hideNavigation) return null;

  return (
    <button className="btn btn-primary mt-2" onClick={handleNavigation}>
      <FontAwesomeIcon icon={faLeftLong} className="me-2" />
      <Translate value="pages.pageNotFound.returnToLoginPage" />
    </button>
  );
};

const Forbidden = ({ hideNavigation, location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, statusCode } = useSelector(({ auth }) => auth);

  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (statusCode) {
      setStatus(statusCode);
      dispatch(logoutRequest());
    }
  }, [statusCode]);

  const handleNavigation = () => {
    dispatch(logoutRequest());
    history.push(AUTH.LOGIN);
  };

  useEffect(() => {
    dispatch(updateStatusReducer(null, REDUCER_TYPE.AUTH));
  }, []);

  if (status !== STATUS.FORBIDDEN && statusCode !== STATUS.FORBIDDEN) {
    return <Redirect to={AUTH.LOGIN} />;
  }

  if (!isEmpty(currentUser) && location.pathname == AUTH.FORBIDDEN) {
    return history.goBack();
  }

  return (
    <ForbiddenError
      hideNavigation={hideNavigation}
      handleNavigation={handleNavigation}
    />
  );
};

Forbidden.defaultProps = {
  hideNavigation: false,
  location: { pathname: "" },
};

Forbidden.propTypes = {
  hideNavigation: PropTypes.bool,
  location: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.shape({ pathname: "" }),
  ]),
};

NavigationButton.defaultProps = {
  hideNavigation: false,
  handleNavigation: () => {},
};

NavigationButton.propTypes = {
  hideNavigation: PropTypes.bool,
  handleNavigation: PropTypes.func,
};

export default Forbidden;
