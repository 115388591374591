import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";

import { CHANNEL_LIST } from "services/Socket";
import cable from "services/socket/cable";

import {
  callQueuesStatisticsUpdate,
  fetchDeviceInQueueRequest,
  updateCallInQueue,
  silentFetchRegisteredAgentsRequest,
} from "redux/actions/callQueue";

import CallQueueItem from "./CallQueueItem/CallQueueItem";

const CallQueueDashboard = ({ containerClassName }) => {
  const { callQueues } = useSelector(({ callQueue }) => callQueue);
  const {
    currentUser: {
      pbx_number: pbxNumber,
      pbx_id: pbxID,
      user_license_id: userLicenseID,
    },
    bearerToken,
  } = useSelector(({ auth }) => auth);

  const [subscription, setSubscription] = useState();
  const [agentSubscription, setAgentSubscription] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.Cypress) return null;

    const connectToChannel = async () => {
      const subscription = await cable.subscribeTo(
        CHANNEL_LIST.PBX_QUEUE_CALLER_STATUS_CHANNEL,
        {
          pbx_number: pbxNumber,
        }
      );

      setSubscription(subscription);
    };

    if (!subscription) {
      connectToChannel();
    } else {
      subscription.on("message", ({ data }) => {
        const { identifier } = subscription;

        if (identifier == CHANNEL_LIST.PBX_QUEUE_CALLER_STATUS_CHANNEL) {
          dispatch(callQueuesStatisticsUpdate(pbxID, Number(data.queue_id)));
          dispatch(updateCallInQueue(data));
        }
      });
    }
  }, [subscription, bearerToken]);

  useEffect(() => {
    if (window.Cypress) return null;

    const connectToChannel = async () => {
      const subscription = await cable.subscribeTo(
        CHANNEL_LIST.PBX_QUEUE_AGENT_STATUS_CHANNEL,
        {
          pbx_number: pbxNumber,
        }
      );

      setAgentSubscription(subscription);
    };

    if (!agentSubscription) {
      connectToChannel();
    } else {
      agentSubscription.on("message", ({ data }) => {
        const { identifier } = agentSubscription;

        if (identifier == CHANNEL_LIST.PBX_QUEUE_AGENT_STATUS_CHANNEL) {
          dispatch(silentFetchRegisteredAgentsRequest(pbxID, data.queue_id));
          dispatch(fetchDeviceInQueueRequest(userLicenseID, data.queue_id));
        }
      });
    }
  }, [agentSubscription, bearerToken]);

  return (
    <article
      className={`call-queue-dashboard row ${containerClassName} ms-md-2 pe-lg-2 ms-lg-3 pe-lg-3 overflow-auto g-4`}
      data-cy="call-queue-dashboard"
    >
      {callQueues.map(({ asterisk_queue_id: id, name }) => (
        <CallQueueItem id={id} name={name} />
      ))}
    </article>
  );
};

CallQueueDashboard.defaultProps = {
  containerClassName: "",
};

CallQueueDashboard.propTypes = {
  containerClassName: PropTypes.string,
};

export default CallQueueDashboard;
