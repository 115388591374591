import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import "bootstrap/js/dist/dropdown";
import PropTypes from "prop-types";

import Dropdown from "components/Dropdown/Dropdown";

import filterSearchResults from "utils/filterSearchResults";

import {
  contactDetailRequest,
  contactsSearchRequest,
  fetchContactsRequest,
  searchContact,
} from "redux/actions/contact";

import ContactListItem from "./ContactListItem";
import SearchContact from "./SearchContact";

const contactOptions = [
  {
    keyword: "All",
    title: (
      <>
        <Translate value="dropdown.contactList.all" />{" "}
        <Translate value="common.contacts" />
      </>
    ),
  },
  {
    keyword: "Shared",
    title: (
      <>
        <Translate value="dropdown.contactList.shared" />{" "}
        <Translate value="common.contacts" />
      </>
    ),
  },
  {
    keyword: "Colleagues",
    title: (
      <>
        <Translate value="dropdown.contactList.colleagues" />
      </>
    ),
  },
];

const ContactList = ({
  activeContact,
  setActiveContact,
  containerClassName,
}) => {
  const [activeContactList, setActiveContactList] = useState("All");
  const [contactData, setContactData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const { contacts, isSearching } = useSelector(({ contact }) => contact);
  const { currentUser } = useSelector(({ auth }) => auth);
  const searchResult = useSelector(({ search }) => search);

  const updatedContacts = isSearching ? contactData : contacts;

  const activeContactListHandler = (contact) => {
    const { keyword } = contact;

    if (activeContactList == keyword) {
      return "active";
    }

    return "";
  };

  const handleContactsListUpdate = (updatedType) => {
    const { keyword } = updatedType;

    dispatch(
      fetchContactsRequest(currentUser?.user_license_id, keyword.toLowerCase())
    );

    setActiveContactList(keyword);
  };

  const handleSearchContact = (e) => {
    setSearchText(e.target.value);
    dispatch(searchContact(e.target.value));
  };

  useEffect(() => {
    dispatch(contactsSearchRequest(searchText));
    const result = filterSearchResults({
      resource: contacts,
      searchResult: searchResult.contacts,
    });

    setContactData(result);
  }, [searchResult]);

  useEffect(() => {
    dispatch(fetchContactsRequest(currentUser?.user_license_id, "all"));
  }, []);

  const showContactTitle = activeContactList.toLowerCase() != "colleagues" && (
    <Translate value="common.contacts" />
  );

  const handleContactDetailSelect = (activeItem) => {
    setActiveContact(activeItem);
    dispatch(contactDetailRequest(activeItem.id, activeItem.type));
  };

  return (
    <div
      className={`contact-list-container mh-100 col-12 col-md-4 col-lg-3 my-0 p-0 ${containerClassName}`}
    >
      <div className="contact-list card border-0 d-flex flex-column h-100">
        <div className="mb-3 d-flex justify-content-between pt-3 px-2 px-md-0">
          <Dropdown
            active={
              <>
                <Translate
                  value={`dropdown.contactList.${activeContactList.toLowerCase()}`}
                />{" "}
                {showContactTitle}
              </>
            }
            validActiveClassName={activeContactListHandler}
            options={contactOptions}
            handleDropdownUpdate={handleContactsListUpdate}
            handleDropdownDisabled={activeContactListHandler}
          />
        </div>
        <SearchContact handleSearchContact={handleSearchContact} />
        <hr className="alphabet-line mt-2 mb-1" />
        <ContactListItem
          handleContactDetailSelect={handleContactDetailSelect}
          activeContact={activeContact}
          contacts={updatedContacts}
        />
      </div>
    </div>
  );
};

ContactList.defaultProps = {
  activeContact: {},
  setActiveContact: () => {},
  containerClassName: "",
};

ContactList.propTypes = {
  activeContact: PropTypes.object,
  setActiveContact: PropTypes.func,
  containerClassName: PropTypes.string,
};

export default ContactList;
