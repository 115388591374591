export const CONFIGURE_FIXED_CALENDAR_FORMAT = {
  hour: "numeric",
  minute: "2-digit",
  meridiem: false,
  hour12: false,
};

export const LANGUAGE_TYPE = {
  USA: "en-US",
  DANISH: "da-DK",
};
