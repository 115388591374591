import React, { createContext, useState } from "react";

import PropTypes from "prop-types";

export const CallProfileContext = createContext(null);

const CallProfileProvider = ({ children }) => {
  const [isSubmitted, setSubmitted] = useState(false);

  return (
    <CallProfileContext.Provider
      value={{
        isSubmitted,
        setSubmitted,
      }}
    >
      {children}
    </CallProfileContext.Provider>
  );
};

CallProfileProvider.defaultProps = {
  children: null,
};

CallProfileProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
};

export default CallProfileProvider;
