import React from "react";

import PropTypes from "prop-types";

import PasswordBanner from "components/ChangePassword/PasswordBanner";
import PasswordForm from "components/ChangePassword/PasswordForm";
import PasswordNotification from "components/ChangePassword/PasswordNotification";

const ActivePasswordStatus = ({ commitSuccess }) => {
  if (commitSuccess) {
    return <PasswordNotification />;
  }

  return (
    <>
      <PasswordForm />
      <PasswordBanner />
    </>
  );
};

ActivePasswordStatus.defaultProps = {
  commitSuccess: "",
};

ActivePasswordStatus.propTypes = {
  commitSuccess: PropTypes.string,
};

export default ActivePasswordStatus;
