import React, { useEffect, useState } from "react";
import "bootstrap/js/dist/dropdown";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { useQuery } from "utils/useQuery";

import { fetchPbxUsersRequest } from "redux/actions/user";

const PbxUserDropdown = ({
  ownerText,
  handleSelectedPbxUser,
  containerClassName,
  queryName,
  pathIndex,
  initialQueryValue,
  isFetchRequestDisabled,
}) => {
  const initialValue = {
    id: null,
    fullName: ownerText,
    asteriskVoicemailBoxId: null,
  };
  const { pbxUsers, isLoading } = useSelector(({ user }) => user);
  const {
    currentUser: {
      pbx_id: pbxID,
      user_license_id: userLicenseID,
      asterisk_voicemail_box_id: asteriskVoicemailBoxId,
    },
    isPbxAdmin,
  } = useSelector(({ auth }) => auth);

  const { pbx_user: pbxUser, [queryName]: query } = useQuery() || {};

  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { pathname } = useLocation();

  const [selectedPbxUser, setSelectedPbxUser] = useState(initialValue);

  const setPbxUserInRoute = () => {
    let updatedType = userLicenseID;

    if (pbxUser) {
      updatedType = pbxUser;
    } else if (query) {
      replace(`${pathname}?${queryName}=${query}&pbx_user=${updatedType}`);
    } else if (pathname === pathIndex) {
      replace(
        `${pathname}?${queryName}=${initialQueryValue}&pbx_user=${updatedType}`
      );
    } else {
      replace(`${pathname}?pbx_user=${updatedType}`);
    }
  };

  useEffect(() => {
    setPbxUserInRoute();
    if (isFetchRequestDisabled) return;
    dispatch(fetchPbxUsersRequest(pbxID));
  }, []);

  useEffect(() => {
    if (pbxUsers.length == 0 && !isPbxAdmin) return null;
    if (userLicenseID != pbxUser && pbxUser && pbxUsers.length) {
      const { id, full_name: fullName } = pbxUsers.find(
        ({ id }) => id == pbxUser
      );

      setSelectedPbxUser({ id, fullName });
    }
  }, [pbxUsers]);

  const handleItemSelection = (pbxUser) => {
    setSelectedPbxUser(pbxUser);
    if (query) {
      replace(`${pathname}?${queryName}=${query}&pbx_user=${pbxUser.id}`);
    } else {
      replace(`${pathname}?pbx_user=${pbxUser.id}`);
    }

    handleSelectedPbxUser({
      id: pbxUser.id,
      asteriskVoicemailBoxId: pbxUser.asteriskVoicemailBoxId,
    });
  };

  if (!isPbxAdmin) return null;

  if (isLoading)
    return (
      <div className="d-flex align-items-center my-1">
        <Skeleton
          width={50}
          height={20}
          className="d-inline-block d-md-none mt-2 ms-2"
        />
        <div className="col-8 col-md-5 filter-container-skeleton">
          <Skeleton height={30} className="ms-3" />
        </div>
      </div>
    );

  if (pbxUsers?.length == 0) return null;

  return (
    <div
      className={`btn-group select-dropdown pbx-user-dropdown d-flex flex-column flex-sm-row justify-content-md-between align-items-sm-center ${containerClassName}`}
      data-testid="pbx-user-dropdown"
    >
      <p className="filter-dates d-flex d-md-none align-items-center mb-2 text-nowrap me-2 me-md-0">
        <Translate value="common.filterUsers" />
      </p>
      <div className="vr mx-3 vertical-line align-self-center d-none d-md-block" />
      <div
        data-cy="dropdown-toggle"
        className=" btn-sm dropdown-toggle profile-button text-nowrap border-0 position-relative d-flex align-items-center justify-content-between justify-content-sm-center py-2 px-3"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selectedPbxUser.fullName}
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon ms-2" />
      </div>
      <ul
        data-cy="dropdown-menu"
        className="dropdown-menu custom-dropdown-menu py-2 mt-2 px-2 border-0 overflow-auto"
      >
        <li>
          <button
            data-cy="dropdown-item"
            className="dropdown-item py-0 px-3 d-flex align-items-center border-0"
            onClick={() =>
              handleItemSelection({
                id: userLicenseID,
                fullName: ownerText,
                asteriskVoicemailBoxId,
              })
            }
          >
            <span className="mt-1">{ownerText}</span>
          </button>
        </li>
        {pbxUsers.map(
          ({
            full_name: fullName,
            id,
            asterisk_voicemail_box_id: asteriskVoicemailBoxId,
          }) => (
            <li key={id}>
              <button
                data-cy="dropdown-item"
                className="dropdown-item py-0 px-3 d-flex align-items-center border-0"
                onClick={() =>
                  handleItemSelection({ fullName, id, asteriskVoicemailBoxId })
                }
              >
                <span className="mt-1">{fullName}</span>
              </button>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

PbxUserDropdown.defaultProps = {
  ownerText: <Translate value="own" />,
  handleSelectedPbxUser: () => {},
  containerClassName: "",
  queryName: "",
  pathIndex: "",
  initialQueryValue: "",
  isFetchRequestDisabled: false,
};

PbxUserDropdown.propTypes = {
  ownerText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  handleSelectedPbxUser: PropTypes.func,
  containerClassName: PropTypes.string,
  queryName: PropTypes.string,
  pathIndex: PropTypes.string,
  initialQueryValue: PropTypes.string,
  isFetchRequestDisabled: PropTypes.bool,
};

export default PbxUserDropdown;
