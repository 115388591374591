import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Modal from "components/Modal/Modal";

import EmailFailed from "./EmailFailed";
import SelectEmail from "./SelectEmail";

const EmailModal = ({
  isVisible,
  handleOverlayClick,
  defaultEmailAddress,
  handleClose,
  handleSubmit,
}) => {
  const [email, setEmail] = useState(null);
  const [modalType, setModalType] = useState("select");
  const [selectedEmailType, setSelectedEmailType] = useState(null);

  const { commitError, isProcessing, commitSuccess } = useSelector(
    ({ voicemail }) => voicemail
  );

  const onSubmit = (email) => {
    setEmail(email);
    handleSubmit(email);
  };

  const onClose = () => {
    resetModalState();
    handleClose();
  };

  const resetModalState = () => {
    setSelectedEmailType(null);
    setModalType(null);
    setEmail(null);
  };

  useEffect(() => {
    if (!commitError) return;
    setModalType("error");
  }, [commitError]);

  useEffect(() => {
    if (!commitSuccess) return;
    resetModalState();
  }, [commitSuccess]);

  const modalContent =
    modalType == "error" ? (
      <EmailFailed
        error={commitError}
        email={email}
        handleRetry={() => handleSubmit(email)}
        handleBack={() => setModalType("select")}
        handleCancel={onClose}
        isLoading={isProcessing}
      />
    ) : (
      <SelectEmail
        defaultEmailAddress={defaultEmailAddress}
        handleClose={handleClose}
        handleSubmit={onSubmit}
        isLoading={isProcessing}
        selectedEmailType={selectedEmailType}
        setSelectedEmailType={setSelectedEmailType}
      />
    );

  return (
    <Modal
      isVisible={isVisible}
      handleOverlayClick={() => {
        if (!isProcessing) {
          handleOverlayClick();
        }
      }}
      className="custom-modal send-email p-3 p-sm-4"
    >
      {modalContent}
    </Modal>
  );
};

EmailModal.defaultProps = {
  isVisible: false,
  handleOverlayClick: () => {},
  defaultEmailAddress: "",
  handleClose: () => {},
  handleSubmit: () => {},
};

EmailModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOverlayClick: PropTypes.func,
  defaultEmailAddress: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default EmailModal;
