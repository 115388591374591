import React, { useEffect, useMemo, useState } from "react";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import FormError from "components/Form/FormError";
import InputLabel from "components/Form/InputLabel";

const SelectDropdown = ({
  input: { value, onBlur, name },
  meta: { touched, error, submitError },
  containerClassName,
  activeClassName,
  labelTranslation,
  children,
  activeElement,
  labelClassName,
  menuClassName,
  errorClassName,
}) => {
  const [isOpen, toggleDropdown] = useState(false);
  const [isTouched, setTouched] = useState(false);

  const handleBlur = () => {
    toggleDropdown(false);

    if (isTouched) {
      onBlur();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleBlur);

    return () => document.removeEventListener("click", handleBlur);
  }, [isTouched]);

  const getClassName = useMemo(
    () => (error, submitError, touched) => {
      if ((error || submitError) && touched) {
        return "error text-danger";
      } else if (value) {
        return "active";
      }

      return "";
    },
    [error, submitError, touched]
  );

  const handleOnClick = (e) => {
    e.stopPropagation();
    setTouched(true);
    toggleDropdown(!isOpen);

    const buttonList = document.getElementsByClassName("select-button-true");

    for (let i = 0; i < buttonList.length; i++) {
      buttonList[i].click?.();
    }
  };

  const activeDropdownClassName = isOpen ? "show" : "";

  return (
    <>
      <InputLabel
        name="dropdown"
        labelTranslation={labelTranslation}
        className={labelClassName}
      />
      <div
        className={`btn-group select-dropdown position-relative d-flex align-items-center ${containerClassName}`}
      >
        <button
          type="button"
          onClick={handleOnClick}
          className={`btn-sm dropdown-toggle text-nowrap position-relative d-flex justify-content-between align-items-center px-1 select-button bg-white w-100 ${activeClassName} ${getClassName(
            error,
            submitError,
            touched
          )} select-button-${isOpen}`}
          aria-expanded={isOpen}
          data-field-name={name}
        >
          {activeElement}
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`dropdown-icon ${getClassName(
              error,
              submitError,
              touched
            )} ms-2`}
          />
        </button>

        <ul
          className={`dropdown-menu custom-dropdown-menu position-absolute w-100 overflow-auto ${activeDropdownClassName} ${menuClassName}`}
        >
          {children}
        </ul>
        <FormError
          error={error}
          submitError={submitError}
          touched={touched}
          className={errorClassName}
        />
      </div>
    </>
  );
};

SelectDropdown.defaultProps = {
  options: [],
  className: "",
  containerClassName: "mt-1 px-3",
  activeClassName: "fw-bold",
  labelTranslation: "",
  labelClassName: "fw-bold mb-2 ms-1",
  menuClassName: "py-2",
  errorClassName: "",
};

SelectDropdown.propTypes = {
  options: PropTypes.array,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.array,
      PropTypes.string,
      PropTypes.number,
    ]),
    onBlur: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    warning: PropTypes.string,
    submitError: PropTypes.bool,
  }),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  labelTranslation: PropTypes.string,
  children: PropTypes.node.isRequired,
  activeElement: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  labelClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  errorClassName: PropTypes.string,
};

export default SelectDropdown;
