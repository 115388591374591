import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "react-table";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import NoCallQueueContent from "components/Home/Empty/NoCallQueueContent";
import TotalCallsInQueueSkeleton from "components/Skeleton/CallQueue/Detail/TotalCallsInQueueSkeleton";
import Table from "components/Table/Table";
import TableBody from "components/Table/TableBody";
import TableHeader from "components/Table/TableHeader";

import { TOTAL_CALLS_IN_CALL_QUEUE } from "constants/tableHeader";

import { fetchTotalCallsInQueueRequest } from "redux/actions/callQueue";

import WaitingTime from "./WaitingTime";

const TotalCallsTable = ({ queueID }) => {
  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  const {
    currentUser: { pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);

  const { callQueues } = useSelector(({ callQueue }) => callQueue);
  const { totalCalls, isTotalCallsLoading: isLoading } = callQueues.find(
    ({ asterisk_queue_id: id }) => id == queueID
  );

  useEffect(() => {
    dispatch(fetchTotalCallsInQueueRequest(pbxID, queueID));
  }, []);

  useEffect(() => {
    if (!totalCalls.length) return setTableData([]);
    const newTableData = [];

    totalCalls.forEach(
      ({ caller_name, caller_number, wait_time_in_seconds: waitTime }) => {
        newTableData.push({
          number: caller_number,
          name: caller_name,
          waitTime: <WaitingTime time={waitTime} />,
        });
      }
    );

    setTableData(newTableData);
  }, [totalCalls]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: TOTAL_CALLS_IN_CALL_QUEUE, data: tableData });

  if (isLoading) {
    return <TotalCallsInQueueSkeleton />;
  }

  if (tableData.length === 0) {
    return (
      <div className="px-3">
        <NoCallQueueContent
          titleTranslation="callQueue.empty.noCallInQueue"
          descriptionTranslate="callQueue.empty.descriptionNoCallInQueue"
          descriptionClassName=""
          queueID={queueID}
        />
      </div>
    );
  }

  return (
    <div
      className="total-calls-in-queue px-3 py-4"
      data-cy={`call-in-queue-${queueID}`}
    >
      <p className="total-calls-title mb-0 fw-bold">
        {tableData.length} <Translate value="callQueue.callInQueue" />
      </p>
      <div className="overflow-auto">
        <Table
          getTableProps={getTableProps}
          className="available-agent-table-list mb-0 px-0"
        >
          <TableHeader
            headerGroups={headerGroups}
            columnClassName="table-header-contain fw-normal"
          />
          <TableBody
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            prepareRow={prepareRow}
          />
        </Table>
      </div>
    </div>
  );
};

TotalCallsTable.propTypes = {
  queueID: PropTypes.number.isRequired,
};

export default TotalCallsTable;
