import React from "react";
import { toastr } from "react-redux-toastr";
import { I18n } from "react-thunk-i18nify";

import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const displayError = (errorText) => {
  const toastrOptions = {
    icon: <FontAwesomeIcon icon={faTimesCircle} />,
    status: "error",
  };

  return toastr.error(I18n.t("common.error"), errorText, toastrOptions);
};

const displaySuccess = (successText) => {
  const toastrOptions = {
    icon: <FontAwesomeIcon icon={faCheckCircle} />,
    status: "success",
  };

  return toastr.success(
    I18n.t("common.success"),
    I18n.t(successText),
    toastrOptions
  );
};

export { displayError, displaySuccess };
