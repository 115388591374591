import { useState } from "react";
import { I18n, I18nReRender } from "react-thunk-i18nify";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import FormError from "components/Form/FormError";
import InputLabel from "components/Form/InputLabel";

const PasswordField = ({
  input: { name, onBlur, onChange, value, ...input },
  meta: { touched, error, submitError },
  placeholderTranslation,
  labelTranslation,
  labelClassName,
  inputContainerClassName,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const icon = showPassword ? faEyeSlash : faEye;
  const inputType = showPassword ? "text" : "password";

  const checkError = (error, submitError, touched) => {
    if ((error || submitError) && touched) {
      return "error";
    } else if (value) {
      return "active";
    }

    return "";
  };

  return (
    <I18nReRender
      render={() => (
        <>
          <InputLabel
            name={name}
            className={labelClassName}
            labelTranslation={labelTranslation}
          />
          <div className={`position-relative mt-1 ${inputContainerClassName}`}>
            <div className="position-relative mt-1 w-100">
              <input
                {...input}
                type={inputType}
                name={name}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                className={`form-control w-100  ${checkError(
                  error,
                  submitError,
                  touched
                )}`}
                id={name}
                placeholder={I18n.t(placeholderTranslation)}
                data-testid={name}
              />
              <FontAwesomeIcon
                data-testid="password-icon"
                icon={icon}
                onClick={() => setShowPassword(!showPassword)}
                className="position-absolute top-50 input-icon"
              />
            </div>
            <FormError
              error={error}
              submitError={submitError}
              touched={touched}
            />
          </div>
        </>
      )}
    />
  );
};

PasswordField.defaultProps = {
  placeholderTranslation: "",
  labelTranslation: "",
  labelClassName: "fw-bold",
  inputContainerClassName: "",
};

PasswordField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    warning: PropTypes.string,
    submitError: PropTypes.bool,
  }),
  labelTranslation: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholderTranslation: PropTypes.string,
  inputContainerClassName: PropTypes.string,
};

export default PasswordField;
