import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

const HomeQueueSelection = ({ selectedQueues, handleQueueSelection }) => {
  if (selectedQueues === 0) return null;

  return (
    <div className="selected-employee d-flex justify-content-between align-items-center p-2 flex-wrap">
      <p className="mb-0 number-of-selected-employee">
        <Translate value="configureQueue.selectedQueue" />
        <b className="count-of-employee ms-1">{selectedQueues}</b>
      </p>
      <button
        onClick={handleQueueSelection}
        className="btn btn-primary fw-normal d-none d-md-flex align-items-center"
        type="button"
      >
        <Translate value="callQueue.agentManagement.removeSelectedFromList" />
      </button>
      <button
        onClick={handleQueueSelection}
        className="btn btn-primary fw-normal d-flex d-md-none align-items-center"
        type="button"
      >
        <Translate value="configureQueue.addSelectedQueueToHomePage" />
      </button>
    </div>
  );
};

HomeQueueSelection.defaultProps = {
  selectedQueues: 0,
  handleQueueSelection: () => {},
};

HomeQueueSelection.propTypes = {
  selectedQueues: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleQueueSelection: PropTypes.func,
};

export default HomeQueueSelection;
