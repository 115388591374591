import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  INDEX: `${apiUrlConfig.asteriskEndpoint()}/voicemail_boxes/:voicemail_box_id`,
  FETCH_CELL_PHONE: `${apiUrlConfig.asteriskEndpoint()}/user_licenses/:user_license_id/cell_phone_voicemail_boxes`,
  UPDATE: `${apiUrlConfig.asteriskEndpoint()}/voicemail_boxes/:voicemail_box_id`,
};

class VoicemailBoxApi extends AuthenticatedRequest {
  /**
   * fetch detail of voicemail details.
   * @param {number} voicemailBoxId ID of voicemail box
   **/

  static fetch(voicemailBoxId) {
    const FETCH_URL = URLS.INDEX.replace(":voicemail_box_id", voicemailBoxId);

    return this.get(FETCH_URL);
  }

  /**
   *
   * @param {int} userLicenseID ID of current logged In user
   * @returns
   */

  static fetchCellPhones(userLicenseID) {
    const FETCH_URL = URLS.FETCH_CELL_PHONE.replace(
      ":user_license_id",
      userLicenseID
    );

    return this.get(FETCH_URL);
  }

  /**
   *
   * @param {int} voicemailBoxId ID of voicemail box detail
   * @param {object} attributes payload for update voicemail box
   * @returns
   */

  static update(voicemailBoxId, attributes) {
    const UPDATE_URL = URLS.UPDATE.replace(":voicemail_box_id", voicemailBoxId);

    return this.patch(UPDATE_URL, attributes);
  }
}

export default VoicemailBoxApi;
