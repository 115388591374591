import React from "react";
import { Translate } from "react-thunk-i18nify";

import {
  faArrowLeft,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import MovingDottedLoader from "components/Loader/MovingDottedLoader";

const EmailFailed = ({
  isLoading,
  email,
  handleBack,
  handleCancel,
  handleRetry,
  error,
}) => {
  const loader = isLoading && <MovingDottedLoader />;
  const showError = error && (
    <p className="text-danger ">
      <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" /> {error}
    </p>
  );

  return (
    <div className="p-0 p-sm-1 p-md-2">
      <div className="d-flex align-items-center flex-wrap">
        <button
          onClick={handleBack}
          className="btn btn-link back-button text-decoration-none d-block d-md-none p-0 me-2"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
          <Translate value="common.back" />
        </button>
        <p className="heading fw-bold mb-0">
          <Translate value="voicemails.sendEmail.failedToSendEmail" />
        </p>
      </div>

      <p className="py-2 py-md-4 paragraph fw-normal my-0">
        <Translate value="voicemails.sendEmail.errorDescription" />{" "}
        <span className="text-primary"> {email}.</span>{" "}
        <Translate value="voicemails.sendEmail.pleaseTryAgainLater" />
      </p>
      {showError}
      <div className="w-100 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center pt-3">
        <button
          onClick={handleBack}
          className="btn btn-link back-button text-decoration-none d-none d-md-block"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
          <Translate value="common.back" />
        </button>
        <div className="d-flex flex-column-reverse flex-sm-row justify-content-between justify-content-md-end col-12 col-md-9">
          <button
            onClick={handleCancel}
            className="btn btn-outline-secondary fw-bold px-5 me-md-3"
          >
            <Translate value="common.cancel" />
          </button>
          <button
            onClick={handleRetry}
            className="btn btn-primary confirm-button fw-bold  mb-2 mb-md-0 px-5"
          >
            {loader}
            <Translate value="common.retry" />
          </button>
        </div>
      </div>
    </div>
  );
};

EmailFailed.defaultProps = {
  isLoading: false,
  email: "",
  handleBack: () => {},
  handleCancel: () => {},
  handleRetry: () => {},
  error: "",
};

EmailFailed.propTypes = {
  isLoading: PropTypes.bool,
  email: PropTypes.string,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  handleRetry: PropTypes.func,
  error: PropTypes.string,
};

export default EmailFailed;
