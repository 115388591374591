import React from "react";
import Skeleton from "react-loading-skeleton";

import TableSkeleton from "components/Skeleton/TableSkeleton";

const VoicemailSkeleton = () => (
  <div className="row w-100 h-100 mx-0 layout-spacing overflow-hidden">
    <div className="voicemails bg-white card border-0 px-0 voicemail-skeleton">
      <article className="top-header py-3 px-md-3 d-flex justify-content-between align-items-md-center flex-column flex-md-row">
        <div className="d-flex flex-column justify-content-between flex-md-row">
          <Skeleton width={150} height={30} className="mt-2" />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between justify-content-md-end align-items-md-center w-100">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center my-2">
            <Skeleton
              width={50}
              height={20}
              className="d-inline-block d-md-none me-md-2"
            />
            <div className="col-8 col-md-5 filter-container">
              <Skeleton height={30} className="ms-3 ms-md-0 me-0 me-md-3" />
            </div>
          </div>
          <div className="col-12 col-md-6 search-skeleton me-0 me-md-3">
            <Skeleton height={30} className="w-100" />
          </div>
        </div>
      </article>
      <div className="voice-table-container overflow-auto" role="table">
        <TableSkeleton />
      </div>
    </div>
  </div>
);

export default VoicemailSkeleton;
