import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { endOfDay, startOfMonth } from "date-fns";

import CallHistoryTable from "components/CallHistory/CallHistoryTable";
import TopHeader from "components/CallHistory/TopHeader";
import EmptyContent from "components/EmptyContent/EmptyContent";
import CallHistorySkeleton from "components/Skeleton/CallHistory/CallHistorySkeleton";
import CallHistoryTableSkeleton from "components/Skeleton/CallHistory/CallHistoryTableSkeleton";

import filterSearchResults from "utils/filterSearchResults";
import { useQuery } from "utils/useQuery";

import {
  callHistorySearchRequest,
  fetchCallHistoryRequest,
  filterCallHistoryByDateRequest,
  searchCallHistory,
} from "redux/actions/callHistory";

const CallHistory = () => {
  const dispatch = useDispatch();
  const { call_type: callType, pbx_user: pbxUser } = useQuery();

  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(true);

  const { callHistories, isSearching, isLoading, isProcessing, isFiltering } =
    useSelector(({ callHistory }) => callHistory);
  const {
    currentUser: { user_license_id: userLicenseId },
    isPbxAdmin,
  } = useSelector(({ auth }) => auth);

  const search = useSelector(({ search }) => search);

  const updatedCallHistory = isSearching || isFiltering ? data : callHistories;

  const callHistorySearchHandler = (e) => {
    dispatch(searchCallHistory(e.target.value));
    setSearchText(e.target.value);
  };

  const filterByDate = () => {
    if (date.length <= 1) return;

    const [startDate, end] = date;

    const endDate = endOfDay(end);

    const startDateUnixFormat = Math.floor(startDate.getTime() / 1000);
    const endDateUnixFormat = Math.floor(endDate.getTime() / 1000);

    dispatch(
      filterCallHistoryByDateRequest(
        pbxUser || userLicenseId,
        callType || "all",
        startDateUnixFormat,
        endDateUnixFormat
      )
    );
  };

  const fetchSearchResults = useCallback(() => {
    dispatch(callHistorySearchRequest(searchText));
    const result = filterSearchResults({
      resource: callHistories,
      searchResult: search.callHistories,
    });

    setData(result);
  }, [search.callHistories]);

  const updateCallHistoryTableHandler = ({ id }) => {
    dispatch(fetchCallHistoryRequest(id, callType));
  };

  useEffect(() => {
    fetchSearchResults();
  }, [search.callHistories]);

  useEffect(() => {
    setDate([startOfMonth(new Date()), new Date()]);
  }, []);

  useEffect(() => {
    if (date.length === 0) return;
    filterByDate();
  }, [date]);

  useEffect(() => {
    if (!isLoading && loading) {
      setLoading(false);
    }
  }, [isLoading]);

  if (loading && isLoading) {
    return <CallHistorySkeleton />;
  }

  if (
    callHistories.length === 0 &&
    !isPbxAdmin &&
    date.length === 0 &&
    callType === "all"
  ) {
    return (
      <EmptyContent
        title="callHistory.empty.emptyCallHistoryList"
        description="callHistory.empty.description"
      />
    );
  }

  const activeTable =
    isLoading || isProcessing ? (
      <CallHistoryTableSkeleton />
    ) : (
      <CallHistoryTable callHistories={updatedCallHistory} />
    );

  return (
    <div
      className="call-history row w-100 h-100 flex-fill mx-0 p-2 pe-md-3"
      role="document"
    >
      <div className="call-history px-2 card border-0 px-0 h-100">
        <TopHeader
          callHistories={updatedCallHistory}
          callHistorySearchHandler={callHistorySearchHandler}
          updateCallHistoryTableHandler={updateCallHistoryTableHandler}
          date={date}
          setDate={setDate}
        />
        {activeTable}
      </div>
    </div>
  );
};

export default CallHistory;
