import React from "react";

import PropTypes from "prop-types";

import AgentImage from "./AgentImage";

const Employee = ({ name, profilePicture, hideStatus }) => (
  <div className="register-agent d-flex">
    <div className="d-flex">
      <AgentImage
        image={profilePicture?.url}
        status={null}
        hideStatus={hideStatus}
      />
      <p
        className="mb-0 align-self-center 
         agent-name"
      >
        {name}
      </p>
    </div>
  </div>
);

Employee.defaultProps = {
  name: "",
  profilePicture: {},
  hideStatus: false,
};

Employee.propTypes = {
  name: PropTypes.string,
  profilePicture: PropTypes.shape({
    url: PropTypes.string,
  }),
  hideStatus: PropTypes.bool,
};

export default Employee;
