import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import CallQueueStatisticsSkeleton from "components/Skeleton/CallQueue/Detail/CallQueueStatisticsSkeleton";

import { TOTAL_CALL, INCOMING_CALL, MISSED_CALL } from "constants/images";

import { convertToDuration } from "utils/timeFormatter";

import { callQueueStatisticsRequest } from "redux/actions/callQueue";

import Statistics from "./Statistics";

const CallQueueStatistics = ({ queueID }) => {
  const dispatch = useDispatch();

  const {
    currentUser: { pbx_id: pbxID },
  } = useSelector(({ auth }) => auth);
  const { callQueues } = useSelector(({ callQueue }) => callQueue);
  const { callQueueStatistic, isStatisticsLoading: isLoading } =
    callQueues.find(({ asterisk_queue_id: id }) => id == queueID);

  useEffect(() => {
    dispatch(callQueueStatisticsRequest(pbxID, queueID));
  }, []);

  if (isLoading) {
    return <CallQueueStatisticsSkeleton />;
  }

  return (
    <div className="call-queue-statistics home py-4">
      <div className="row px-3">
        <Statistics
          image={TOTAL_CALL.SOURCE}
          value={callQueueStatistic.total_calls}
          nameTranslation="callQueue.default.totalCalls"
          statisticsClassName="col-6 col-xxl-3"
          testID={`queue-statistics-total-calls-${queueID}`}
        />
        <Statistics
          image={INCOMING_CALL.SOURCE}
          value={callQueueStatistic.answered_calls}
          nameTranslation="callQueue.default.answeredCalls"
          statisticsClassName="col-6 col-xxl-3"
          testID={`queue-statistics-answer-calls-${queueID}`}
        />
        <Statistics
          image={MISSED_CALL.SOURCE}
          value={callQueueStatistic.missed_calls}
          nameTranslation="callQueue.default.missedCalls"
          statisticsClassName="col-6 col-xxl-3"
          testID={`queue-statistics-missed-calls-${queueID}`}
        />
        <Statistics
          value={convertToDuration(
            callQueueStatistic.average_waiting_time_in_seconds
          )}
          nameTranslation="callQueue.default.averageCallDuration"
          statisticsClassName="col-6 col-xxl-3"
          testID={`queue-statistics-average-calls-${queueID}`}
        />
      </div>
    </div>
  );
};

CallQueueStatistics.propTypes = {
  queueID: PropTypes.number.isRequired,
};

export default CallQueueStatistics;
