import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import LoginWrapper from "layouts/loginWrapper/LoginWrapper";

import NewCustomerLink from "components/Login/NewCustomerLink";
import NewPassword from "components/SetPassword/NewPassword";
import PasswordNotification from "components/SetPassword/PasswordNotification";

import { setPasswordClear } from "redux/actions/auth";

const SetPassword = () => {
  const [passwordNotify, setPasswordNotify] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(setPasswordClear()), []);

  return (
    <LoginWrapper>
      {passwordNotify ? (
        <PasswordNotification />
      ) : (
        <NewPassword onPasswordNotification={() => setPasswordNotify(true)} />
      )}
      <NewCustomerLink />
    </LoginWrapper>
  );
};

export default SetPassword;
