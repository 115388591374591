import React from "react";
import Skeleton from "react-loading-skeleton";

const CallQueueStatisticsSkeleton = () => (
  <div className="call-queue-statistics py-4">
    <div className="row px-3">
      <div className="statistics-item my-2 col-6 col-xxl-3">
        <div className="d-flex align-items-center">
          <Skeleton width={20} height={25} />
          <Skeleton width={40} height={20} className="ms-1" />
        </div>
        <Skeleton width={120} height={22} className="mt-1" />
      </div>
      <div className="statistics-item my-2 col-6 col-xxl-3">
        <div className="d-flex align-items-center">
          <Skeleton width={20} height={30} />
          <Skeleton width={40} height={20} className="ms-1" />
        </div>
        <Skeleton width={120} height={22} className="mt-1" />
      </div>
      <div className="statistics-item my-2 col-6 col-xxl-3">
        <div className="d-flex align-items-center">
          <Skeleton width={20} height={30} />
          <Skeleton width={40} height={20} className="ms-1" />
        </div>
        <Skeleton width={120} height={22} className="mt-1" />
      </div>
      <div className="statistics-item my-2 col-6 col-xxl-3">
        <div className="d-flex align-items-center">
          <Skeleton width={20} height={30} />
          <Skeleton width={40} height={20} className="ms-1" />
        </div>
        <Skeleton width={120} height={22} className="mt-1" />
      </div>
    </div>
  </div>
);

export default CallQueueStatisticsSkeleton;
