import { addMinutes } from "date-fns";

export const totalMinInADay = 1439;
export const timeTillTenMorning = 600;
export const timeTillSixAfternoon = 1080;

// weekdays name
const weekDayName = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const ELEMENT_TYPE = {
  NEWLY_ADDED: "newly added",
  FETCH_ELEMENT: "fetch element",
  UPDATE_ELEMENT: "updated element",
  TIME_SORTING_REMAINING: "time sorting remaining",
};

/**
 * It will return time format of current week day with updated time received from API
 * @param {date} date - Date is required for setting time between 0 - 24 hr format
 * @param {*} day -  day in a week i.e sunday, monday, tuesday etc.
 * @param {*} weekDays - Current time format of current weeks.
 * @returns
 */
export const weekDayFormat = (date, day, weekDays) => {
  const formatDay = day.toLowerCase();
  const time = date.split("T")[1];
  const updatedDate = `${weekDays[formatDay]}T${time}`;

  return updatedDate;
};

/**
 * function to get data for fixed calendar
 * @param {array} data - Current data fetched for fixed calendar
 * @returns
 */
export const fixedCalendarFormatter = (data) => {
  const now = Date.now();
  // Milliseconds in a day
  const DAY = 60 * 60 * 24 * 1000;
  // day number for today i.e 0,1, 2, 3 upto 6
  const today = new Date(now).getDay();
  const weekDays = {};

  // get days before current day
  for (let i = today; i >= 0; i--) {
    const date = new Date(now - DAY * i);

    // only add year month and date in ISO for current week
    if (date.getDay() != "sunday") {
      weekDays[weekDayName[date.getDay()]] = date
        .toISOString()
        .replace(/T.*$/, "");
    }
  }

  // get days after current day
  for (let i = 1; i < 8 - today; i++) {
    const date = new Date(now + DAY * i);

    // only add year month and date in ISO for current week
    weekDays[weekDayName[date.getDay()]] = date
      .toISOString()
      .replace(/T.*$/, "");
  }

  const timeFrameForEachDay = [];

  // format data for fixed calendar
  data.forEach(
    ({
      call_profile_daily_schedule_time_frames: totalTimeFrame,
      for_day: day,
    }) => {
      totalTimeFrame.forEach(
        ({
          start_time: start,
          id,
          end_time: end,
          call_profile: { id: profileID, name },
        }) => {
          const startTime = new Date(weekDayFormat(start, day, weekDays));
          const endTime = new Date(weekDayFormat(end, day, weekDays));

          timeFrameForEachDay.push({
            id: id,
            profileID,
            start: new Date(
              addMinutes(startTime, startTime.getTimezoneOffset())
            ).toISOString(),
            end: new Date(
              addMinutes(endTime, endTime.getTimezoneOffset())
            ).toISOString(),
            title: name,
            day,
            elementType: ELEMENT_TYPE.FETCH_ELEMENT,
          });
        }
      );
    }
  );

  return timeFrameForEachDay;
};
