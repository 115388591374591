import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import RecordDetail from "components/RecordDetail/RecordDetail";

import { isEmpty } from "utils/isEmpty";

const ContactInformation = () => {
  const { contactDetailData } = useSelector(({ contact }) => contact);

  const [contactItems, setContactItems] = useState([]);

  const addDetailChildren = (keyword, value) => {
    if (!value || value.length == 0) return [];
    let newChildren = [];

    value.forEach((item, index) => {
      newChildren.push({
        label: <Translate value={keyword} index={index + 1} />,
        value: item,
      });
    });

    return newChildren;
  };

  useEffect(() => {
    if (isEmpty(contactDetailData)) return;

    const pbxNumber = contactDetailData.pbx_number
      ? `(${contactDetailData?.pbx_number})`
      : "";

    const updatedContactItem = [
      {
        id: 1,
        label: <Translate value="common.extensionNumber" />,
        value: contactDetailData?.extension_number
          ? `${contactDetailData.extension_number || ""} ${pbxNumber}`
          : null,
        children: [],
      },
      {
        id: 2,
        label: <Translate value="common.profile" />,
        value: contactDetailData?.active_call_profile ? (
          <>
            <FontAwesomeIcon
              icon={[
                contactDetailData.active_call_profile.icon_json.brand_name,
                contactDetailData.active_call_profile.icon_json.icon_name,
              ]}
              className="me-1 active-profile-icon"
            />
            {contactDetailData.active_call_profile.name}
          </>
        ) : null,
      },
      {
        id: 3,
        label: <Translate value="common.landlineNumbers" />,
        value: "",
        children: addDetailChildren(
          "contact.contactDetail.landlineNumberIndex",
          contactDetailData?.landline_numbers
        ),
      },
      {
        id: 4,
        label: <Translate value="common.emailAddress" />,
        value: contactDetailData?.email || "",
      },
      {
        id: 5,
        label: <Translate value="common.mobileNumbers" />,
        value: "",
        children: addDetailChildren(
          "contact.contactDetail.mobileNumberIndex",
          contactDetailData?.cell_phone_numbers || [
            contactDetailData?.cell_number,
          ]
        ),
      },
      {
        id: 6,
        label: <Translate value="common.note" />,
        value: contactDetailData?.description || "",
      },
    ];

    setContactItems(updatedContactItem);
  }, [contactDetailData]);

  return (
    <div
      data-cy="contact-information"
      className="row record-detail px-1 py-3 mx-auto my-0"
    >
      {contactItems.map(({ label, value, children, id }) => (
        <RecordDetail
          label={label}
          value={value}
          children={children}
          key={id}
        />
      ))}
    </div>
  );
};

export default ContactInformation;
