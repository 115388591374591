import PropTypes from "prop-types";

const FormError = ({ error, submitError, touched, className }) => {
  if ((error || submitError) && touched) {
    return (
      <span
        data-testid="error-text"
        className={`error-message fw-bold ms-1 ${className}`}
      >
        {error || submitError}
      </span>
    );
  }

  return null;
};

FormError.defaultProps = {
  error: null,
  submitError: null,
  touched: false,
  className: "",
};

FormError.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  submitError: PropTypes.bool,
  className: PropTypes.string,
};

export default FormError;
