import { USER_ROLES } from "./userRoles";
/*
**
PBX Admin:
 - call history: 
 1. Able to access own call history data.
 2. Able to access call history of pbx users under his/her own account.

 - voicemail 
 1. Able to access own voicemail data.
 2. Able to access voicemails of pbx users under his/her own account.

  - voicemail box
 1. Able to access own voicemail box data.
 2. Able to access voicemail box of pbx users under his/her own account.
 

PBX User:
  - call history: 
 1. Able to access own call history data.

 - voicemail 
 1. Able to access own voicemail data.

  - voicemail box
 1. Able to access own voicemail box data.
**
*/

export const ACTION_LIST = {
  VIEW_OWN_VOICEMAIL: "voicemail:ownVoicemail",
  VIEW_OWN_VOICEMAIL_BOX: "voicemail:ownVoicemailBox",
  VIEW_OWN_CALL_HISTORY: "callHistory:ownCallHistory",
  PBX_USER_VOICEMAIL_VIEW: "pbxUser_voicemail:view",
  PBX_USER_VOICEMAIL_BOX_VIEW: "pbxUser_voicemailBox:view",
  PBX_USER_CALL_HISTORY_VIEW: "pbxUser_callHistory:view",
  ADD_QUEUE_EXTERNAL_NUMBER: "queueExternalNumber:add",
  VIEW_AGENT_MANAGEMENT: "agent_management:view",
};

const rules = {};

rules[USER_ROLES.PBX_ADMIN] = {
  static: [
    ACTION_LIST.VIEW_OWN_VOICEMAIL,
    ACTION_LIST.VIEW_OWN_CALL_HISTORY,
    ACTION_LIST.VIEW_OWN_VOICEMAIL_BOX,
  ],
  dynamic: {
    [ACTION_LIST.PBX_USER_VOICEMAIL_VIEW]: ({ currentUser: { role } }) =>
      role == USER_ROLES.PBX_ADMIN,
    [ACTION_LIST.PBX_USER_VOICEMAIL_BOX_VIEW]: ({ currentUser: { role } }) =>
      role == USER_ROLES.PBX_ADMIN,
    [ACTION_LIST.PBX_USER_CALL_HISTORY_VIEW]: ({ currentUser: { role } }) =>
      role == USER_ROLES.PBX_ADMIN,
    [ACTION_LIST.ADD_QUEUE_EXTERNAL_NUMBER]: ({ currentUser: { role } }) =>
      role == USER_ROLES.PBX_ADMIN,
    [ACTION_LIST.VIEW_AGENT_MANAGEMENT]: ({ currentUser: { role } }) =>
      role == USER_ROLES.PBX_ADMIN,
  },
};

rules[USER_ROLES.PBX_USER] = {
  static: [
    ACTION_LIST.VIEW_OWN_VOICEMAIL,
    ACTION_LIST.VIEW_OWN_CALL_HISTORY,
    ACTION_LIST.VIEW_OWN_VOICEMAIL_BOX,
  ],
  dynamic: {},
};

export default rules;
