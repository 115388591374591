import React, { createContext, useState } from "react";

import PropTypes from "prop-types";

export const AgentManagementContext = createContext(null);

const AgentManagementProvider = ({ children }) => {
  const [updatedRegisteredAgents, setUpdatedRegisteredAgents] = useState([]);
  const [updatedAvailableAgents, setUpdatedAvailableAgents] = useState([]);
  const [deleteRegisteredAgentID, setDeleteRegisteredAgentID] = useState([]);
  const [deleteExternalNumberID, setDeleteExternalNumberID] = useState([]);
  const [callPriority, setCallPriority] = useState({});
  const [isFormEdited, setFormEdited] = useState(false);

  return (
    <AgentManagementContext.Provider
      value={{
        updatedRegisteredAgents,
        setUpdatedRegisteredAgents,
        updatedAvailableAgents,
        setUpdatedAvailableAgents,
        deleteRegisteredAgentID,
        setDeleteRegisteredAgentID,
        deleteExternalNumberID,
        setDeleteExternalNumberID,
        callPriority,
        setCallPriority,
        isFormEdited,
        setFormEdited,
      }}
    >
      {children}
    </AgentManagementContext.Provider>
  );
};

AgentManagementProvider.defaultProps = {
  children: null,
};

AgentManagementProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
};

export default AgentManagementProvider;
