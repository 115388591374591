import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import LoginWrapper from "layouts/loginWrapper/LoginWrapper";

import EmailNotification from "components/ForgotPassword/EmailNotification";
import RecoverPassword from "components/ForgotPassword/RecoverPassword";
import NewCustomerLink from "components/Login/NewCustomerLink";

import { setPasswordClear } from "redux/actions/auth";

const ForgotPassword = () => {
  const [emailNotify, setEmailNotify] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => () => dispatch(setPasswordClear()), []);

  return (
    <LoginWrapper>
      {emailNotify ? (
        <EmailNotification />
      ) : (
        <RecoverPassword
          onEmailNotification={() => {
            setEmailNotify(true);
          }}
        />
      )}
      <NewCustomerLink />
    </LoginWrapper>
  );
};

export default ForgotPassword;
