import { formatQueueDevicesOption } from "./incomingCallOptions";

export const QUEUE_REGISTRATION_FORM_TYPE = {
  CREATE: "create",
  EDIT: "edit",
};

/**
 * Add incoming queue devices selected by user
 * @param {array|null} callProfileQueueDevices - It will return all queue device selected by user
 * @returns
 */
const formatQueueDevicesAttributes = (callProfileQueueDevices) => {
  if (callProfileQueueDevices == null || !callProfileQueueDevices.length) {
    return [];
  }

  return callProfileQueueDevices.map(({ queueDevice }) => queueDevice);
};

/**
 * initial attributes for edit form
 * @param {array} callProfileQueues - consists of all queues that the user is assigned into
 * @returns
 */
export const editFormAttributes = (callProfileQueues) => {
  if (!callProfileQueues) return [];

  return callProfileQueues.map(
    ({
      asterisk_queue_id: asteriskQueueID,
      id,
      qu_name: queueName,
      call_profile_queue_devices: callProfileQueueDevices,
    }) => ({
      id: id,
      asterisk_queue_id: asteriskQueueID,
      name: queueName,
      call_profile_queue_devices_attributes: formatQueueDevicesOption(
        callProfileQueueDevices,
        id
      ),
    })
  );
};

const onSubmitEditFormAttributes = (callProfileQueues) =>
  callProfileQueues.map(
    ({ id, asterisk_queue_id, call_profile_queue_devices_attributes }) => ({
      id: id,
      asterisk_queue_id: asterisk_queue_id,
      call_profile_queue_devices_attributes: formatQueueDevicesAttributes(
        call_profile_queue_devices_attributes
      ),
    })
  );

/**
 * initial attributes for create form
 * @param {array} callProfileQueues - It consists of all queues that the user is assigned into
 * @returns
 */
export const createFormAttributes = (callProfileQueues) =>
  callProfileQueues.map(({ qu_id, qu_name }) => ({
    asterisk_queue_id: qu_id,
    name: qu_name,
    call_profile_queue_devices_attributes: null,
  }));

/**
 * create form attributes to be submitted
 * @param {array} callProfileQueues - It consists of all queues that the user is assigned into
 * @returns
 */
const onSubmitCreateFormAttributes = (callProfileQueues) =>
  callProfileQueues.map(
    ({ asterisk_queue_id, call_profile_queue_devices_attributes }) => ({
      asterisk_queue_id: asterisk_queue_id,
      call_profile_queue_devices_attributes: formatQueueDevicesAttributes(
        call_profile_queue_devices_attributes
      ),
    })
  );

/**
 * attributes based on the form type
 * @param {array} callProfileQueues - It consists of all call profile queues
 * @param {string} type - It is the form type i.e., either create or edit
 * @returns
 */
export const profileQueuesAttributes = (
  callProfileQueues,
  type = QUEUE_REGISTRATION_FORM_TYPE.CREATE
) => {
  if (type == QUEUE_REGISTRATION_FORM_TYPE.CREATE) {
    return onSubmitCreateFormAttributes(callProfileQueues);
  }

  return onSubmitEditFormAttributes(callProfileQueues);
};

export const updateWithRemovableDevicesForCallQueues = (
  callProfileQueuesAttributes,
  initialValuesForQueueRegistration,
  isAutomaticQueueRegistrationEnabled
) => {
  if (
    !isAutomaticQueueRegistrationEnabled ||
    initialValuesForQueueRegistration.length === 0
  )
    return callProfileQueuesAttributes;

  return callProfileQueuesAttributes.map((queueItem) => {
    const { id, call_profile_queue_devices_attributes: updatedQueueDevices } =
      queueItem;
    const initialQueue = initialValuesForQueueRegistration.find(
      ({ id: initialID }) => initialID === id
    );

    const deleteQueueDevice = [];

    if (initialQueue["call_profile_queue_devices_attributes"]) {
      initialQueue["call_profile_queue_devices_attributes"].forEach(
        (queueDevice) => {
          const {
            queueDevice: { deviceable_id: deviceId, id: queueID },
          } = queueDevice;

          const elementIndex = updatedQueueDevices?.findIndex(
            ({ queueDevice }) => queueDevice.deviceable_id === deviceId
          );

          if (elementIndex < 0) {
            deleteQueueDevice.push({
              ...queueDevice,
              queueDevice: {
                id: queueID,
                _destroy: true,
              },
            });
          }
        }
      );
    }

    const updateQueueDevicesAttributes = updatedQueueDevices?.map(
      (queueDevice) => {
        const {
          queueDevice: { deviceable_id: deviceId },
        } = queueDevice;

        const oldQueueDevice = initialQueue[
          "call_profile_queue_devices_attributes"
        ]?.find(({ queueDevice }) => queueDevice.deviceable_id === deviceId);

        return {
          ...queueDevice,
          queueDevice: {
            id: oldQueueDevice?.queueDevice?.id,
            ...queueDevice.queueDevice,
          },
        };
      }
    );

    return {
      ...queueItem,
      call_profile_queue_devices_attributes:
        updateQueueDevicesAttributes?.concat(deleteQueueDevice) || [],
    };
  });
};
