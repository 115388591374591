import React from "react";

import ProfileWrapper from "layouts/ProfileWrapper/ProfileWrapper";

import CreateCallProfileForm from "components/CallProfile/Create/CreateCallProfileForm";

const CreateCallProfile = () => (
  <ProfileWrapper>
    <div className=" pe-md-3 overflow-auto flex-fill h-100">
      <div className="d-flex flex-column bg-white p-3 ps-md-3 h-auto call-profile-detail">
        <CreateCallProfileForm />
      </div>
    </div>
  </ProfileWrapper>
);

export default CreateCallProfile;
