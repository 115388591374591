import React from "react";
import Skeleton from "react-loading-skeleton";

import CallHistoryTableSkeleton from "./CallHistoryTableSkeleton";

const CallHistorySkeleton = () => (
  <div className="row w-100 h-100 mx-0 layout-spacing overflow-hidden">
    <div className="voicemails bg-white card border-0 px-0 voicemail-skeleton">
      <article className="top-header py-3 px-md-3 d-flex justify-content-between align-items-md-center flex-column flex-md-row">
        <div className="d-flex flex-column justify-content-between flex-md-row ms-3 ms-sm-0">
          <Skeleton width={150} height={30} className="mt-2" />
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center my-3 my-sm-1 me-sm-3">
            <Skeleton
              width={50}
              height={20}
              className="d-inline-block d-md-none"
            />
            <div className="col-8 col-md-5 filter-container ms-sm-3">
              <Skeleton height={30} />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between justify-content-md-end align-items-md-center w-100 ms-3 ms-sm-0">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center my-2 me-sm-3">
            <Skeleton
              width={50}
              height={20}
              className="d-inline-block d-md-none"
            />
            <div className="col-8 col-md-5 filter-container ms-sm-3 ms-md-0 me-0 me-md-3">
              <Skeleton height={30} />
            </div>
          </div>
          <div className="col-12 col-md-6 search-skeleton me-sm-3">
            <Skeleton height={30} className="w-100" />
          </div>
        </div>
      </article>
      <CallHistoryTableSkeleton />
    </div>
  </div>
);

export default CallHistorySkeleton;
