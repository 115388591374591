import React from "react";
import { Field } from "react-final-form";
import { Translate } from "react-thunk-i18nify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import FormWrapper from "components/Form/FormWrapper";
import InputField from "components/Form/InputField/InputField";

import { faMobileSignalOut } from "constants/customIcon/icons";

import { externalNumberValidation, required } from "utils/validation";

const DesktopView = ({ handleSubmit }) => (
  <div className="d-none d-md-flex row align-items-start modal-body p-3">
    <div
      className="nav flex-column nav-pills forward-to-tab col-4 p-0"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <button
        className="nav-link position-relative active"
        id="v-pills-external-number-tab"
        data-bs-toggle="pill"
        data-bs-target="#v-pills-external-number"
        type="button"
        role="tab"
        aria-controls="v-pills-external-number"
        aria-selected="true"
      >
        <FontAwesomeIcon icon={faMobileSignalOut} className="me-1" />
        <Translate value="callProfile.incomingCalls.externalNumber" />
        <span className="vr indicator" />
      </button>
    </div>
    <div className="tab-content col-8" id="v-pills-tabContent">
      <div
        className="tab-pane fade show active w-100"
        id="v-pills-external-number"
        role="tabpanel"
        aria-labelledby="v-pills-external-number-tab"
      >
        <FormWrapper
          formClassName="p-0 d-flex flex-column justify-content-between h-100 w-100"
          submitText="common.add"
          submitClassName="btn btn-primary add-button my-3"
          submitContainerClassName="justify-content-md-end mt-md-3"
          cancelClassName="btn btn-outline-secondary d-block d-md-none my-md-3"
          showCancelButton={false}
          onSubmitHandler={handleSubmit}
        >
          <div className="mb-3">
            <Field
              name="external_number_name"
              component={InputField}
              placeholderTranslation="attributes.name"
              labelTranslation="attributes.name"
              validate={required("validationErrors.REQUIRED")}
            />
          </div>
          <Field
            name="external_number"
            component={InputField}
            placeholderTranslation="attributes.insertNumber"
            labelTranslation="attributes.insertNumber"
            validate={externalNumberValidation}
          />
        </FormWrapper>
      </div>
    </div>
  </div>
);

DesktopView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default DesktopView;
