import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { EMPTY_CONTACT } from "constants/images";

const EmptyContent = ({ title, description, isRecordsPresent }) => {
  if (isRecordsPresent) return null;

  return (
    <article
      data-cy="empty-content"
      className="overflow-auto mh-100 h-100 w-100 layout-spacing"
      data-testid="empty-content"
    >
      <div className="empty-content h-100">
        <div className="col-md-7 col-lg-6 col-xxl-4 p-0 d-flex flex-column align-items-center justify-content-center mx-auto h-100">
          <figure>
            <img src={EMPTY_CONTACT.SOURCE} alt="empty content" />
          </figure>
          <h5 className="mt-4 mb-2 text-center title fw-bold">
            <Translate value={title} />
          </h5>
          <p className="text-center detail">
            <Translate value={description} />
          </p>
        </div>
      </div>
    </article>
  );
};

EmptyContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isRecordsPresent: PropTypes.bool,
};

EmptyContent.defaultProps = {
  title: "",
  description: "",
  isRecordsPresent: false,
};

export default EmptyContent;
