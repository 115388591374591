import React from "react";
import Skeleton from "react-loading-skeleton";

import PropTypes from "prop-types";

const ProfileHeaderSkeleton = ({ showEditProfile }) => {
  const showEditProfileButton = showEditProfile ? (
    <Skeleton width={120} height={30} />
  ) : (
    <div className="button-replacement skeleton" />
  );

  const showUserName = showEditProfile ? (
    <div className="mt-3">
      <Skeleton width={200} height={15} className="mb-2" />
      <Skeleton width={150} height={10} />
    </div>
  ) : (
    ""
  );

  const showHeader = showEditProfile ? "d-none d-md-block" : "mb-3 mb-md-0";

  return (
    <div className="profile-header d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-md-between pb-4 px-2 flex-fill">
      <Skeleton width={120} height={20} className={showHeader} />

      <div className="text-center">
        <Skeleton circle width={130} height={130} />
        {showUserName}
      </div>
      {showEditProfileButton}
    </div>
  );
};

ProfileHeaderSkeleton.defaultProps = {
  showEditProfile: false,
};

ProfileHeaderSkeleton.propTypes = {
  showEditProfile: PropTypes.bool,
};

export default ProfileHeaderSkeleton;
