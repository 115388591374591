export const ICON_LIST = [
  {
    id: 1,
    brand_name: "pbx-user",
    icon_name: "snooze",
  },
  {
    id: 2,
    brand_name: "far",
    icon_name: "comments",
  },
  {
    id: 3,
    brand_name: "fas",
    icon_name: "user-tie",
  },
  {
    id: 4,
    brand_name: "fas",
    icon_name: "handshake",
  },
  {
    id: 5,
    brand_name: "fas",
    icon_name: "glass-cheers",
  },
  {
    id: 6,
    brand_name: "fas",
    icon_name: "mug-hot",
  },
  {
    id: 7,
    brand_name: "fas",
    icon_name: "walking",
  },
  {
    id: 8,
    brand_name: "fas",
    icon_name: "running",
  },
  {
    id: 9,
    brand_name: "fas",
    icon_name: "skiing",
  },
  {
    id: 10,
    brand_name: "fas",
    icon_name: "hiking",
  },
  {
    id: 11,
    brand_name: "fas",
    icon_name: "business-time",
  },
  {
    id: 12,
    brand_name: "fas",
    icon_name: "user-clock",
  },
  {
    id: 13,
    brand_name: "fas",
    icon_name: "users",
  },
  {
    id: 14,
    brand_name: "pbx-user",
    icon_name: "presentation",
  },
];
