import React, { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { I18nReRender, Translate } from "react-thunk-i18nify";

import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flatPicker from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da";
import { english } from "flatpickr/dist/l10n/default";
import PropTypes from "prop-types";

import { defaultLanguage } from "locales/root";

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => (
  <I18nReRender
    render={() => (
      <>
        <input
          {...props}
          defaultValue={defaultValue}
          ref={inputRef}
          value={value ? value : ""}
          data-testid="timepicker-input"
        />
      </>
    )}
  />
);

const TimePicker = ({ time, setTime, callback, isDisabled }) => {
  const flatPickerRef = useRef();
  const placeholderRef = useRef();
  const { locale } = useSelector(({ i18n }) => i18n);

  const placeholderText =
    typeof time == "string" ? (
      <p
        data-testid="timepicker-placeholder"
        className="position-absolute top-50 translate-middle w-100 placeholder-text ms-1"
        ref={placeholderRef}
      >
        <Translate value="common.selectTime" />
      </p>
    ) : (
      ""
    );

  useEffect(() => {
    flatPickerRef.current.flatpickr.input.type = "button";
  }, []);

  useEffect(() => {
    const currentFlatPickerLanguage =
      defaultLanguage != locale ? Danish : english;

    flatPicker.localize(currentFlatPickerLanguage);
  }, [locale]);

  const disabledClassName = isDisabled ? "disabled pe-0" : "";

  return (
    <div
      data-cy="time-picker"
      data-testid="timepicker"
      className={`time ${disabledClassName} position-relative d-flex align-items-center px-3`}
    >
      <FontAwesomeIcon
        icon={faClock}
        className="icon"
        data-testid="time-icon"
      />
      <Flatpickr
        ref={flatPickerRef}
        options={{
          enableTime: true,
          position: "auto center",
          dateFormat: "h:i K",
          altFormat: "h:i K",
          noCalendar: true,
          disableMobile: true,
          time_24hr: false,
        }}
        className="date border-0 p-2 w-auto text-center"
        value={time}
        onValueUpdate={([date]) => {
          if (typeof time == "string" && date && placeholderRef.current) {
            placeholderRef.current.style.display = "none";
          }
        }}
        onChange={([date]) => {
          setTime(date);
          callback();
        }}
        disabled={isDisabled}
        render={({ defaultValue, value, ...props }, ref) => (
          <CustomInput
            defaultValue={defaultValue}
            value={value}
            inputRef={ref}
            {...props}
          />
        )}
      />
      {placeholderText}
    </div>
  );
};

CustomInput.defaultProps = {
  value: "",
  defaultValue: "",
  inputRef: () => {},
};

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  defaultValue: PropTypes.string,
  inputRef: PropTypes.func,
};

TimePicker.defaultProps = {
  time: {},
  setTime: () => {},
  callback: () => {},
  isDisabled: false,
};

TimePicker.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  setTime: PropTypes.func,
  callback: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default React.memo(TimePicker);
