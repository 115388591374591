import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_RECEIVED,
  CHANGE_PASSWORD_REQUEST,
  CLEAR_CHANGE_PASSWORD_ERROR,
} from "redux/actions/changePassword";

const defaultChangePasswordState = {
  isLoading: false,
  error: "",
  commitSuccess: "",
};

function reducer(state = defaultChangePasswordState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
        commitSuccess: "",
      };
    case CHANGE_PASSWORD_RECEIVED:
      return {
        ...state,
        isLoading: false,
        commitSuccess: "message.changePassword.passwordChangedSuccessfully",
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case CLEAR_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        commitSuccess: "",
        error: "",
      };

    default:
      return state;
  }
}

export default reducer;
