import PropTypes from "prop-types";

const Tooltip = ({ children, title, className, containerClassName }) => (
  <div
    data-cy="tooltip"
    className={`tool-tip position-relative ${containerClassName}`}
    data-testid="tooltip"
  >
    {children}
    <span
      className={`tooltip-text justify-content-center text-center position-absolute py-2 ${className}`}
    >
      {title}
    </span>
  </div>
);

Tooltip.defaultProps = {
  className: "d-flex small px-2",
  children: <></>,
  title: "",
  containerClassName: "",
};

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default Tooltip;
