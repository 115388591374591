import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";

import PropTypes from "prop-types";

import ProfileListSkeleton from "components/Skeleton/CallProfiles/List/ProfileListSkeleton";

import { AutomaticCallProfileContext } from "context-api/AutomaticCallProfileContext";

import { PROFILE } from "constants/routes";

import { displayError } from "utils/toaster";

import {
  clearCallProfileErrors,
  fetchCallProfilesRequest,
  fetchOutgoingCallOptionsRequest,
} from "redux/actions/callProfile";

import ProfileListItem from "./CallProfileListItem";
import CreateNewProfileButton from "./CreateNewProfileButton";

const CallProfileList = ({ isHidden }) => {
  const [active, setActive] = useState(null);
  const { push } = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { path } = useRouteMatch();

  const { callProfiles, isLoading, error } = useSelector(
    ({ callProfile }) => callProfile
  );
  const {
    currentUser: { user_license_id: userLicenseID },
  } = useSelector(({ auth }) => auth);

  const { showFixedCalendar } = useContext(AutomaticCallProfileContext);

  useEffect(() => {
    setActive(id);
  }, [id]);

  useEffect(() => {
    if (error) {
      displayError(error);
    }
  }, [error]);

  useEffect(() => {
    if ((path == PROFILE.EDIT || path == PROFILE.CREATE) && callProfiles.length)
      return;
    dispatch(fetchCallProfilesRequest(userLicenseID));

    return () => {
      dispatch(clearCallProfileErrors());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchOutgoingCallOptionsRequest(userLicenseID));
  }, []);

  const selectProfileHandler = (id) => {
    setActive(id);
    push(PROFILE.addID(PROFILE.EDIT, id));
  };

  const createClassName = pathname === PROFILE.CREATE ? "create" : "";
  const callProfileDetail =
    path === PROFILE.CREATE || path == PROFILE.EDIT || isHidden
      ? "d-none d-md-block"
      : "";

  const callProfileClassName = id || showFixedCalendar ? "" : "default";

  if (isLoading) {
    return <ProfileListSkeleton containerClassName={callProfileDetail} />;
  }

  if (!callProfiles.length) {
    return null;
  }

  return (
    <div className={`bg-white py-4 px-0 me-3 me-md-0 ${callProfileDetail} `}>
      <div className="call-profile-list h-100">
        <CreateNewProfileButton />
        <div
          className={`list ${createClassName} ${callProfileClassName} overflow-auto`}
        >
          <div
            className="d-flex flex-column align-items-center profile-content px-md-3"
            data-cy="call-profile-list"
          >
            {callProfiles.map(
              ({ name, description, icon_json: iconJson, id }) => (
                <ProfileListItem
                  key={id}
                  title={name}
                  description={description}
                  iconJson={iconJson}
                  selectProfileHandler={() => selectProfileHandler(id)}
                  activeProfileId={active == id}
                  callProfileID={id}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CallProfileList.defaultProps = {
  isHidden: false,
};

CallProfileList.propTypes = {
  isHidden: PropTypes.bool,
};

export default CallProfileList;
