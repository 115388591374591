const customResourcesList = ["callHistories", "cellPhones"];

const resourceList = {
  callHistories: "callHistory",
  cellPhones: "voicemailBox",
};

const singularizeResourceName = (resourceName) => {
  if (customResourcesList.includes(resourceName)) {
    return resourceList[resourceName];
  }

  return resourceName.substring(0, resourceName.length - 1);
};

const customResourceSelector = (resourceName, state) => {
  const resourceIdentifier = singularizeResourceName(resourceName);

  return state[resourceIdentifier][resourceName];
};

export default customResourceSelector;
