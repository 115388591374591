import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { AUTH } from "constants/routes";

const MenuItem = ({ icon, title, path, onMenuItemClick, testID }) => {
  const checkExactPath = path == AUTH.INDEX ? true : false;

  const alignIconClassName = title
    ? "justify-content-start"
    : "justify-content-center";
  const iconSpacingClassName = title ? "me-2" : "";

  return (
    <div
      className="menu-item-container my-4 ms-2"
      onClick={onMenuItemClick}
      role="button"
      data-cy={testID}
    >
      <NavLink
        exact={checkExactPath}
        to={path}
        className={`menu-item d-flex align-items-center ${alignIconClassName} px-2`}
        activeClassName="active fw-bold"
      >
        <FontAwesomeIcon
          icon={icon}
          className={`${iconSpacingClassName} menu-item-icon`}
        />
        {title}
      </NavLink>
    </div>
  );
};

MenuItem.defaultProps = {
  icon: {},
  title: {},
  path: "/",
  onMenuItemClick: () => {},
  testID: "test-ID-menu-item",
};

MenuItem.propTypes = {
  icon: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  path: PropTypes.string,
  onMenuItemClick: PropTypes.func,
  testID: PropTypes.string,
};

export default MenuItem;
