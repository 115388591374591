import React from "react";

import { PASSWORD_BANNER } from "constants/images";

const PasswordBanner = () => (
  <article
    className="password-banner d-none d-md-block col-6 ms-auto"
    data-testid="password-banner"
  >
    <figure className="d-flex justify-content-end">
      <img src={PASSWORD_BANNER.SOURCE} alt="password banner" />
    </figure>
  </article>
);

export default PasswordBanner;
