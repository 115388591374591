import React from "react";
import Skeleton from "react-loading-skeleton";

const ProfileListItemSkeleton = () => (
  <div className="call-profile-list-item skeleton d-flex flex-column align-items-center mx-2 my-3 px-4 py-5 p-md-4">
    <div className="rounded-circle d-flex align-items-center justify-content-center mb-2">
      <Skeleton circle height={50} width={50} />
    </div>
    <Skeleton height={15} width={100} className="my-1" />
    <Skeleton height={15} width={180} />
  </div>
);

export default ProfileListItemSkeleton;
