import React from "react";

import PropTypes from "prop-types";

import SearchBar from "components/SearchBar/SearchBar";

const SearchContact = ({ handleSearchContact }) => (
  <SearchBar
    onSearchHandler={handleSearchContact}
    placeholderText="attributes.searchContact"
    className="mb-3 px-2 px-sm-3"
  />
);

SearchContact.defaultProps = {
  handleSearchContact: () => {},
};

SearchContact.propTypes = {
  handleSearchContact: PropTypes.func,
};

export default SearchContact;
