import React from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import NotFound from "pages/NotFound";

import userPermissionRules from "constants/userPermission";

const check = (rules, currentUser, action, data) => {
  const permissions = rules[currentUser?.role];

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];

    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition({ ...data, currentUser });
  }

  return false;
};

const Can = ({ perform, children, data, redirect, no }) => {
  const { currentUser } = useSelector(({ auth }) => auth);
  const hasPermission = check(userPermissionRules, currentUser, perform, data);

  if (hasPermission) {
    return children;
  }

  if (redirect) return <NotFound />;

  return no();
};

Can.defaultProps = {
  redirect: false,
  no: () => null,
  children: null,
  perform: "",
};

Can.propTypes = {
  redirect: PropTypes.bool,
  no: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  perform: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.array,
  ]),
};

export default Can;
