import React from "react";
import Skeleton from "react-loading-skeleton";

import RegisteredAgentListSkeleton from "components/Skeleton/CallQueue/RegisteredAgents/RegisteredAgentListSkeleton";

import CallQueueStatisticsSkeleton from "./CallQueueStatisticsSkeleton";
import TotalCallsInQueueSkeleton from "./TotalCallsInQueueSkeleton";

const CallQueueItemSkeleton = () => (
  <div className="call-queue-item col-12 col-lg-6">
    <div className="bg-white w-100 edit-call-queue">
      <div className="call-queue-title p-3 d-flex align-items-center">
        <Skeleton width={250} height={30} />
      </div>
      <CallQueueStatisticsSkeleton />
      <TotalCallsInQueueSkeleton />
      <RegisteredAgentListSkeleton />
    </div>
  </div>
);

export default CallQueueItemSkeleton;
