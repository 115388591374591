import { AuthenticatedRequest } from "internal";

import apiUrlConfig from "configs/apiUrlConfig";

const URLS = {
  UPDATE_NOTE: `${apiUrlConfig.pbxUserEndPoint()}/notes/:id`,
};

class NoteApi extends AuthenticatedRequest {
  static updateNote(id, attribute) {
    const UPDATE_NOTE = URLS.UPDATE_NOTE.replace(":id", id);

    return this.patch(UPDATE_NOTE, attribute);
  }
}

export default NoteApi;
