import React from "react";
import { Translate } from "react-thunk-i18nify";

import PropTypes from "prop-types";

import { EMPTY_CALL_PROFILE } from "constants/images";

const EmptyAgentUI = ({
  headingTranslation,
  titleTranslation,
  descriptionTranslation,
}) => (
  <div className="agents">
    <div className="available-agent-header d-flex flex-column flex-sm-row align-items-sm-center justify-content-between ">
      <p className="mb-0 available-agent-title mb-2 mb-sm-0 fw-bold">
        <Translate value={headingTranslation} />
      </p>
    </div>
    <article className="empty-content overflow-auto mh-100 h-100 w-100 d-flex  flex-column justify-content-center align-items-center px-3 mt-2">
      <figure>
        <img src={EMPTY_CALL_PROFILE.SOURCE} alt="non contact selected" />
      </figure>
      <h5 className="mt-4 mb-2 text-center title">
        <Translate value={titleTranslation} />
      </h5>
      <p className="text-center detail">
        <Translate value={descriptionTranslation} />
      </p>
    </article>
  </div>
);

EmptyAgentUI.defaultProps = {
  headingTranslation: "callQueue.agentManagement.availableAgents",
  titleTranslation: "callQueue.empty.emptyAvailableAgent",
  descriptionTranslation: "callQueue.empty.descriptionAvailableAgent",
};

EmptyAgentUI.propTypes = {
  headingTranslation: PropTypes.string,
  titleTranslation: PropTypes.string,
  descriptionTranslation: PropTypes.string,
};

export default EmptyAgentUI;
