import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Translate } from "react-thunk-i18nify";

import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { AUTH } from "constants/routes";

import LocalStorageManager, { CONSTANTS } from "lib/LocalStorageManager";

import { setLanguage } from "services/i18n";

import { switchUserLanguageRequest } from "redux/actions/userProfile";

const languageType = {
  "en-US": "en",
  "da-DK": "da",
};

const ShowLanguageText = ({ showText }) => {
  if (!showText) return null;

  return (
    <p
      className="me-2 d-flex language-text m-1"
      data-testid="show-language-text"
    >
      <FontAwesomeIcon icon={faLanguage} className="mb-0 language-icon me-1" />
      <Translate value="languageToggler.label" />
    </p>
  );
};

const LanguageToggler = ({ showText }) => {
  const { locale } = useSelector(({ i18n }) => i18n);
  const { currentUser } = useSelector(({ auth }) => auth);
  const { commitError } = useSelector(({ userProfile }) => userProfile);

  const [activeLanguage, setActiveLanguage] = useState(locale);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (commitError) {
      setLanguage(activeLanguage);
    }
  }, [commitError]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.locale != locale) {
        setLanguage(currentUser?.locale);
      }
      setActiveLanguage(currentUser?.locale);
    }
  }, [currentUser]);

  const activeLanguageClassName = (language) => {
    const activeLang = locale ?? "da-DK";

    if (activeLang == language) {
      return "active";
    }

    return "";
  };

  const handleLanguageToggle = (lang) => {
    setLanguage(lang);
    if (pathname === AUTH.LOGIN) {
      LocalStorageManager.set(CONSTANTS.CURRENT_SELECTED_LANGUAGE, lang);
    } else {
      dispatch(switchUserLanguageRequest(currentUser?.id, languageType[lang]));
    }
  };

  const spacingButton = showText ? "me-2" : "me-1";
  const buttonAlignment = !showText ? "text-center" : "";

  return (
    <div className="d-flex align-items-center language-toggle">
      <ShowLanguageText showText={showText} />
      <div className={`button-group mx-1 ${buttonAlignment}`}>
        <button
          className={`${spacingButton} btn btn-primary language-button p-0 ms-1 my-1 ${activeLanguageClassName(
            "en-US"
          )}`}
          data-testid="english-language-toggler"
          onClick={() => handleLanguageToggle("en-US")}
        >
          <Translate value="languageToggler.en" />
        </button>
        <button
          className={`btn btn-primary p-0 language-button mx-1 my-1 ${activeLanguageClassName(
            "da-DK"
          )} `}
          data-testid="danish-language-toggler"
          onClick={() => handleLanguageToggle("da-DK")}
        >
          <Translate value="languageToggler.dk" />
        </button>
      </div>
    </div>
  );
};

ShowLanguageText.defaultProps = {
  showText: true,
};

ShowLanguageText.propTypes = {
  showText: PropTypes.bool,
};

LanguageToggler.defaultProps = {
  showText: true,
};

LanguageToggler.propTypes = {
  showText: PropTypes.bool,
};

export default LanguageToggler;
