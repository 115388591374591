import React from "react";
import Skeleton from "react-loading-skeleton";

const Detail = () => (
  <div className="d-flex flex-column flex-sm-row w-100 align-items-sm-center justify-content-start my-2">
    <div className="flex-fill px-2">
      <Skeleton height={15} width={150} className="detail-header flex-fill" />
    </div>
    <div className="flex-fill">
      <Skeleton
        height={12}
        width={200}
        className=" detail-value flex-fill mt-2"
      />
    </div>
  </div>
);

const SubDetail = () => (
  <>
    <div className="px-3 ms-1 my-2">
      <Skeleton height={15} width={150} className="detail-header flex-fill" />
    </div>
    <div className="d-flex  flex-column flex-sm-row align-items-sm-center mx-2">
      <div className="flex-fill ms-2">
        <Skeleton
          height={10}
          width={140}
          className="col-12 col-sm-6 detail-sub-title"
        />
      </div>
      <div className="flex-fill me-2">
        <Skeleton
          height={10}
          width={180}
          className="col-12 col-sm-6 detail-sub-value"
        />
      </div>
    </div>
  </>
);

const ContactInformationSkeleton = () => (
  <>
    <div className="row record-detail px-1 py-3 mx-auto my-0">
      <Detail />
      <Detail />
      <Detail />
      <SubDetail />
    </div>
  </>
);

export default ContactInformationSkeleton;
