import React from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import FormWrapper from "components/Form/FormWrapper";
import InputField from "components/Form/InputField/InputField";
import MovingDottedLoader from "components/Loader/MovingDottedLoader";

import { SEND_EMAIL, USER_EMAIL } from "constants/images";

import { emailValidation, required } from "utils/validation";

const EMAIL_TYPES = {
  DEFAULT: "defaultEmail",
  USER_LICENSE: "userLicenseEmail",
  MANUAL_EMAIL: "manualEmail",
};

const SelectEmail = ({
  handleClose,
  defaultEmailAddress,
  handleSubmit,
  isLoading,
  selectedEmailType,
  setSelectedEmailType,
}) => {
  const {
    currentUser: { email: userEmail },
  } = useSelector(({ auth }) => auth);

  const setEmailType = (emailType) => {
    if (emailType === selectedEmailType) {
      return setSelectedEmailType(null);
    } else {
      setSelectedEmailType(emailType);
    }
  };

  const selectedEmailSubmitHandler = () => {
    let selectedEmail = "";

    switch (selectedEmailType) {
      case EMAIL_TYPES.DEFAULT:
        selectedEmail = defaultEmailAddress;
        break;
      case EMAIL_TYPES.USER_LICENSE:
        selectedEmail = userEmail;
        break;
      default:
        selectedEmail = defaultEmailAddress;
    }

    handleSubmit(selectedEmail);
  };

  const manualEmailSubmitHandler = ({ userEmail }) => {
    handleSubmit(userEmail);
  };

  const loader = isLoading && <MovingDottedLoader />;

  const manualEmail =
    selectedEmailType === EMAIL_TYPES.MANUAL_EMAIL ? (
      <FormWrapper
        onSubmit={manualEmailSubmitHandler}
        validation={(values) =>
          required(values, "validationErrors.INVALID_EMAIL_ADDRESS")
        }
        isLoading={isLoading}
        onSubmitHandler={manualEmailSubmitHandler}
        submitText="common.send"
        showCancelButton={false}
        submitContainerClassName="justify-content-md-end mt-md-3 "
        submitClassName="btn btn-primary px-5 mt-3 mb-0"
      >
        <div className="mb-3 w-100 position-relative">
          <Field
            name="userEmail"
            validate={emailValidation}
            component={InputField}
            placeholderTranslation="attributes.emailAddress"
            labelTranslation="attributes.emailAddress"
            InputFieldIcon={faEnvelope}
          />
        </div>
      </FormWrapper>
    ) : (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary px-5"
          disabled={selectedEmailType === null || isLoading}
          onClick={selectedEmailSubmitHandler}
        >
          {loader}
          <Translate value="common.send" />
        </button>
      </div>
    );

  const handleActiveEmailType = (keyword) => {
    if (keyword === selectedEmailType) {
      return "active";
    }

    return "";
  };

  const mailsList = [
    {
      id: 1,
      keyword: EMAIL_TYPES.DEFAULT,
      icon: USER_EMAIL.SOURCE,
      title: "voicemails.sendEmail.defaultVoicemailEmail",
      email: defaultEmailAddress,
    },
    {
      id: 2,
      keyword: EMAIL_TYPES.USER_LICENSE,
      icon: SEND_EMAIL.SOURCE,
      title: "voicemails.sendEmail.userLicenseEmail",
      email: userEmail,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="mb-2">
          <p className="heading fw-bold mb-2">
            <Translate value="voicemails.sendEmail.sendEmailTo" />
          </p>

          <p className="sub-heading">
            <Translate value="voicemails.sendEmail.pleaseSelectEmail" />
          </p>
        </div>

        <FontAwesomeIcon
          icon={faTimes}
          onClick={handleClose}
          className="mb-1 close-icon"
        />
      </div>

      <div className="d-flex flex-column flex-md-row justify-content-between">
        {mailsList.map(({ keyword, icon, title, email, id }) => (
          <div
            className={`d-flex flex-column align-items-center justify-content-between select-mail ${handleActiveEmailType(
              keyword
            )} p-2 my-2 my-md-0 flex-fill`}
            role="button"
            key={id}
            onClick={() => setEmailType(keyword)}
          >
            <div className="d-flex justify-content-end w-100">
              <input
                type="checkbox"
                name="checkbox"
                id={keyword}
                className="form-check-input rounded-circle"
                checked={keyword === selectedEmailType}
                onChange={() => setEmailType(keyword)}
              />
            </div>
            <img src={icon} alt={`send ${title}`} className="mb-2" />
            <div className="text-center">
              <p className="mb-2 fw-bold send-mail-heading ">
                <Translate value={title} />
              </p>
              <p className="mb-2 email">{email}</p>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="form-check form-check-inline my-3 ps-3">
          <input
            className="form-check-input rounded-circle me-2 mt-1"
            type="checkbox"
            name="sendEmailManually"
            id="sendEmailManually"
            checked={selectedEmailType === EMAIL_TYPES.MANUAL_EMAIL}
            onChange={() => setEmailType(EMAIL_TYPES.MANUAL_EMAIL)}
          />
          <label className="form-check-label" htmlFor="sendEmailManually">
            <Translate value="voicemails.sendEmail.sendEmailManually" />
          </label>
        </div>
        {manualEmail}
      </div>
    </>
  );
};

SelectEmail.defaultProps = {
  defaultEmailAddress: "",
  handleClose: () => {},
  handleSubmit: () => {},
  isLoading: false,
  selectedEmailType: "",
  setSelectedEmailType: () => {},
};

SelectEmail.propTypes = {
  handleClose: PropTypes.func.isRequired,
  defaultEmailAddress: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  selectedEmailType: PropTypes.string,
  setSelectedEmailType: PropTypes.func,
};

export default SelectEmail;
