import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-thunk-i18nify";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import ContactImage from "components/Home/ContactDetail/ContactImage";
import ContactInformation from "components/Home/ContactDetail/ContactInformation";
import NoteMessage from "components/Home/ContactDetail/NoteMessage";

import { snakeCaseToKebabCase } from "utils/utils";

import { contactDetailClear } from "redux/actions/contact";

const ContactDetail = ({ setActiveContact }) => {
  const {
    contactDetailData: {
      name,
      debtor_name: debtorName,
      note,
      status,
      profile_picture: profilePicture,
      id,
    },
  } = useSelector(({ contact }) => contact);

  const dispatch = useDispatch();

  const userStateClassName = snakeCaseToKebabCase(status) || "unavailable";

  const activeProfileClassNames = name ? "active" : "d-none d-md-block";

  const handleIndividualData = () => {
    setActiveContact({});
    dispatch(contactDetailClear());
  };

  useEffect(() => () => dispatch(contactDetailClear()), []);

  return (
    <div className="w-100" data-cy={`contact-detail-${id}`}>
      <div className={`contact-detail ${activeProfileClassNames} h-100`}>
        <div className="h-100  ms-md-4 mt-5 pt-5 pt-md-0 mt-md-0">
          <div className="mb-0 d-none d-md-block">
            <button
              data-cy="return-to-dashboard-button"
              className="btn btn-outline-primary return-to-dashboard-button mb-2 d-flex align-items-center pe-4"
              onClick={handleIndividualData}
            >
              <span className="rounded-circle bg-primary mx-2 back-icon d-inline-block">
                <FontAwesomeIcon icon={faArrowLeft} className="text-white" />
              </span>
              <Translate value="home.returnToQueueDashboard" />
            </button>
          </div>
          <div className="profile-header p-3 d-flex align-items-center">
            <div className="d-flex align-items-center me-2 icon-container">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="d-block d-md-none me-3 right-arrow"
                size="2x"
                onClick={handleIndividualData}
              />
              <div className="d-flex justify-content-center justify-content-sm-center w-100">
                <div className="rounded-circle avatar me-2 position-relative d-flex align-items-center justify-content-center">
                  <ContactImage url={profilePicture?.url} />
                  <span
                    data-cy="user-status"
                    className={`rounded-circle position-absolute user-status ${userStateClassName}`}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column text-start justify-content-center ms-1 ms-md-3">
              <h5 data-cy="contact-name" className="name mb-0 fw-bold">
                {name}
                <NoteMessage className="ms-1" text={note?.description} />
              </h5>
              <p data-cy="debtor-name" className="company mb-0">
                {debtorName}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between title-container">
            <h5
              data-cy="contact-information-title"
              className="contact-information-title py-3 mx-3 mb-0 fw-bold"
            >
              <Translate value="common.contactInformation" />
            </h5>
          </div>
          <ContactInformation />
        </div>
      </div>
    </div>
  );
};

ContactDetail.defaultProps = {
  setActiveContact: () => {},
};

ContactDetail.propTypes = {
  setActiveContact: PropTypes.func,
};

export default ContactDetail;
