import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import Tooltip from "components/CustomTooltip/CustomTooltip";

const NoteMessage = ({ text, className }) => {
  if (!text) return null;

  if (text) {
    return (
      <Tooltip title={text} className={`note-message ${className}`}>
        <FontAwesomeIcon
          icon={faCommentDots}
          data-cy="note-icon"
          className="ms-1 note-icon"
        />
      </Tooltip>
    );
  }
};

NoteMessage.defaultProps = {
  text: "",
  className: "",
};
NoteMessage.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default NoteMessage;
